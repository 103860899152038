import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function OpenCashRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = `${currentDate.getFullYear()}/${String(currentDate.getMonth() + 1).padStart(2, '0')}/${String(currentDate.getDate()).padStart(2, '0')}`;
  const isCashRegisterDone = useSelector((state) => state?.cashierDetails?.cashRegister);
  console.log("isCashRegisterDone", isCashRegisterDone)
  useEffect(() => {
    try {
      if (isCashRegisterDone !== false) {
        navigate('/cashier/dashboard')
      }

    } catch (error) {
      console.error("Error parsing or handling cashier data:", error);
    }
  }, [isCashRegisterDone]);

  return (
    <div className='dashboard-cashier'>
      <div className='p-3 w-100'>
        <div className='cash-register-info text-center mt-5'>
          <h4>{t('input_placeholder_date')}: {formattedDate}</h4>
          <p>{t('open_cash_register_description')}</p>
          <button className='btn btn-primary' onClick={()=>navigate('/cashier/penomination-details')}>{t('open_cash_register')}</button>
        </div>
      </div>
    </div>
  );
}
