import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import logo from '../../../assets/website/images/logo-white.svg';
import { Link , NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CMSOptions } from '../services/website.services'
import { MENU_LOCATION } from '../../../utils/Constants';

const WebsiteFooter = () => {

  const [getGlobalData, setGlobalData] = useState({});
  const [dynamicOptions, setDynamicOptions] = useState([]);
  const myGlobalData = useSelector((state) => state.globalData?.data);
  const [refreshOptionsAPI, setRefreshOptionsAPI] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (myGlobalData) {
      const settingsData = JSON.parse(myGlobalData);
      setGlobalData(settingsData);
    }
  }, [myGlobalData]);

    
  
  const handelLogoClick = () => {
    document.querySelector("html, body").scrollTop = 0;
  }
  
  useEffect(() => {
    CMSOptions(MENU_LOCATION.menu_of_page_footer).then((response) => {
      setDynamicOptions(response?.data);
    }).catch((error) => {
      console.log("error of terms&conditions", error);
    });
  }, [refreshOptionsAPI]);
  
  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setRefreshOptionsAPI(!refreshOptionsAPI);
    })
  });

  return (
    <div className='footer-marketing'>
      <div className='footer-main'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-3'>
              <div className='footer-logo-info'>
                <Link className="navbar-brand" to={'/'}>
                  <img src={logo} alt='website logo' onClick={handelLogoClick} />
                </Link>
                <p>{t('web_footer_text')}</p>
              </div>
            </div>
            <div className='col-md-2 ms-auto'>
              <h4 className='footer-head'>{t('web_footer_company')}</h4>
              <ul className='footer-link'>
                <li>
                  <a href='/#'>{t('web_footer_our_story')}</a>
                </li>
                <li>
                  <Link to='/#about_us'>{t('web_footer_about_us')}</Link>
                </li>
                <li>
                  <Link to='/#service_overview'>{t('web_footer_service_overview')}</Link>
                </li>
                <li>
                <NavLink className="nav-link" to={'/manual-guide'}>{t("web_nav_link_manual_guide")}</NavLink>
                  {/* <a href='#'>{t('web_footer_manual_guide')}</a> */}
                </li>
              </ul>
            </div>
            <div className='col-md-2'>
              <h4 className='footer-head'>{t('web_footer_legal')}</h4>
              <ul className='footer-link'>
                {/* <li>
                  <Link to={'/privacy-policy'}>{t('web_footer_privacy_policy')}</Link>
                </li>
                <li>
                  <Link to={'/terms-and-condition'}>{t('web_footer_terms_condition')}</Link>
                </li>
                <li>
                  <a href='#'>{t('web_footer_support')} </a>
                </li>
                <li>
                  <Link to={'/faq'}>{t('web_footer_faq')}</Link>
                </li> */}
                {
                  dynamicOptions?.map((item, i) => {
                    return (
                      <li key={i}>
                        <Link to={`/org-info/${item?.slug}`}>{item?.title}</Link>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className="col-md-3">
              <h4 className='footer-head'>{t('web_footer_contact')}</h4>
              {getGlobalData?.social_media_details ?
                <>
                  <ul className="social-icon">
                    {getGlobalData?.social_media_details?.map((item, index) => (
                      <li key={index}>
                        <a href={item?.link} title={item?.title} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: (item?.icon) }}>
                        </a>
                      </li>
                    ))
                    }
                  </ul>
                </> : <></>
              }
            </div>
          </div>
        </div>
      </div>
      <div className='copyright-text text-center'>
        {getGlobalData?.copy_right_text}
      </div>
    </div>
  )
}

export default WebsiteFooter
