import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  ATLEAST_ONE_CAPITAL_REGEX,
  ATLEAST_ONE_NUMBER_REGEX,
  ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  ATLEAST_ONE_SMALL_REGEX,
  PHONE_NO_LENGTH,
} from "../../../utils/Constants"
import { PHONE_VALIDATION } from "../../../utils/commonValidations";

export const useValidationSchemas = () => {
  const { t } = useTranslation();

  const loginValidationSchema = Yup.object().shape({
    restaurant_id: Yup.string().required(t("validation_restaurant_id_required")),
    // email: Yup.string()
    //   .trim()
    //   .email("label_email_invalid_format_error")
    //   .test('no-uppercase', "label_email_uppercase_error", function (value) {
    //     if (value) {
    //       return !/[A-Z]/.test(value);
    //     }
    //     return true;
    //   })
    //   .required("label_email_required_error"),
    password: Yup.string().required(t("validation_password_required"))
  })

  const forgetPasswordValidationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("label_email_invalid_format_error")
      .test('no-uppercase', "label_email_uppercase_error", function (value) {
        if (value) {
          return !/[A-Z]/.test(value);
        }
        return true;
      })
      .required("label_email_required_error"),
  })

  const resetPswValidationSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required(t("label_new_password_error"))
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        t("password_validation_lowercase")
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        t("Password_Validation_uppercase")
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        t("Password_Validation_number")
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        t("Password_Validation_special_char")
      )
      .min(PASSWORD_MIN_LENGTH, t("Password_Validation_minimum_length"))
      .max(
        PASSWORD_MAX_LENGTH,
        t("Password_Validation_maximum_length")
      ),
    confirmPassword: Yup.string()
      .required(t("label_confirm_password_error"))
      .oneOf([Yup.ref("newPassword"), null], t("password_validation_does_not_match")),
  });
  const otpValidationSchema = Yup.object().shape({
    otp: Yup.string()
      .required(t("validation_OTP_is_required"))
      .length(4, t("otp_error_msg"))
      .matches(/^\d{4}$/, t("invalid_otp")),
  });

  const restaurantProfileValidationSchema = Yup.object({
    restaurantName: Yup.string().required('label_restaurants_error'),
    restaurantAddress: Yup.string().required('restaurant_address_error'),
    city: Yup.string().required('label_city_error'),
    zipCode: Yup.string().required('zip_code_error'),
    website: Yup.string().url('invalid_web_url'),
    cuisineType: Yup.array().min(1, 'label_cuisine_type_error').required('label_cuisine_type_error'),
    openingTime: Yup.string().required('opening_time_error'),
    closingTime: Yup.string().required('closing_time_error').test('greater_time', 'rest_validation_closing_time_should_be_greater_than_opening_time', function (value) {
      // const [opHours, opMins] = this.parent?.openingTime?.split(":");
      // const [closeHours, closeMins] = value?.split(":");
      // if(opHours > closeHours){
      //   return false;
      // }else if(opHours === closeHours){
      //   if(opMins >= closeMins){
      //     return false;
      //   }
      // }
      // return true;
      if (this.parent?.openingTime && value) {
        try {
          const openDate = new Date(this.parent?.openingTime);
          const closeDate = new Date(value);
          return closeDate.getTime() > openDate.getTime();
        } catch (err) {
          console.log("err", err);
        }
      }
      return true;
    }),
  });

  const profileValidationSchema = Yup.object({
    email: Yup.string().email('validation_err_invalid_email').required('label_email_error'),
    opening: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
    settlement: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
    cancel_sales: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
    edit_sales: Yup.number().oneOf([0, 1], 'Value must be 0 or 1').required('This field is required'),
  });


  const CashierPasswordValidationSchema = Yup.object({
    cashier_password: Yup.string()
      .required("validation_password_required")
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        "password_validation_lowercase"
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        "Password_Validation_uppercase"
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        "Password_Validation_number"
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        "Password_Validation_special_char"
      )
      .min(PASSWORD_MIN_LENGTH, "Password_Validation_minimum_length")
      .max(
        PASSWORD_MAX_LENGTH,
        "Password_Validation_maximum_length"
      )
  });


  const categoryValidationSchema = Yup.object({
    name: Yup.string().required("category_name_error"),
    name_ja: Yup.string().required("category_name_ja_error"),
    tax_type: Yup.string().required("tax_type_error"),
    color: Yup.string().required("color_error"),
    short_description: Yup.string().required("label_short_description_error"),
    short_description_ja: Yup.string().required("label_short_description_japanese_error"),
  });


  const menuItemsValidationSchema = Yup.object().shape({
    item_name: Yup.string()
      .required('item_name_required'),
    item_name_ja: Yup.string()
      .required('item_name_ja_required'),
    category_id: Yup.string()
      .required('category_id_required'),
    item_code: Yup.string()
      .required('item_code_required'),
    short_description: Yup.string()
      .required('short_description_required'),
    short_description_ja: Yup.string()
      .required('short_description_ja_required'),
    price: Yup.number()
      .required('price_required')
      .moreThan(0, 'price_more_than_zero'),
    cost_price: Yup.number()
      .required('cost_price_required')
      .moreThan(0, 'cost_price_more_than_zero'),
    consumption_tax_rate: Yup.number()
      .required('consumption_tax_rate_required')
      .moreThan(0, 'consumption_tax_rate_more_than_zero'),
    spice_level_needed: Yup.boolean()
      .required('spice_level_needed_required'),
    image: Yup.mixed()
      .required('label_image_error')
      .test(
        'fileType',
        'supported_file_error',
        value =>
          !value ||
          typeof value === 'string' ||
          ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type)
      )
      .test(
        'fileSize',
        'image_max_size_error',
        value =>
          !value ||
          typeof value === 'string' ||
          value.size <= 1.5 * 1024 * 1024
      )
      .test(
        'isValidUrlOrFile',
        'invalid_url_error',
        value => !value || typeof value !== 'string' || /^(ftp|http|https):\/\/[^ "]+$/.test(value)
      ),
    type: Yup.string()
      .required('type_required'),
    addons: Yup.array().when('type', ([type], schema) => {
      return type === "set_of_items" ? Yup.array().min(1, 'addons_minimum_one_required').of(
        Yup.object().shape({
          option_id: Yup.string().required('addon_required_error'),
          item_id: Yup.string().required('addon_item_required_error'),
        })
      ) : schema;
    }),


  });


  const staffValidationSchema = Yup.object({
    name: Yup.string().required("label_name_error"),
    employee_id: Yup.string().required("employee_id_error"),
    email: Yup.string().trim().required('label_email_error').email('invalid_email'),
    phone_number: Yup.string()
      .test('phone_required', 'label_phone_number_required_error', (value) => {
        console.log("value", value)
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length;
      }
      )
      .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && /^\d+$/.test(phoneNumber);
      }
      )
      .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
      }
      )
      .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
      }
      ),
    password: Yup.string()
      .required(t("validation_password_required"))
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        t("password_validation_lowercase")
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        t("Password_Validation_uppercase")
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        t("Password_Validation_number")
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        t("Password_Validation_special_char")
      )
      .min(PASSWORD_MIN_LENGTH, t("Password_Validation_minimum_length"))
      .max(
        PASSWORD_MAX_LENGTH,
        t("Password_Validation_maximum_length")
      ),
  });


  const editStaffValidationSchema = Yup.object({
    name: Yup.string().required("label_name_error"),
    employee_id: Yup.string().required("employee_id_error"),
    email: Yup.string().trim().required('label_email_error').email('invalid_email'),
    phone_number: Yup.string()
      .test('phone_required', 'label_phone_number_required_error', (value) => {
        console.log("value", value)
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length;
      }
      )
      .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && /^\d+$/.test(phoneNumber);
      }
      )
      .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
      }
      )
      .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
        const phoneNumber = value ? (value.split(" "))[1] : null;
        return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
      }
      ),
    password: Yup.string()
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        t("password_validation_lowercase")
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        t("Password_Validation_uppercase")
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        t("Password_Validation_number")
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        t("Password_Validation_special_char")
      )
      .min(PASSWORD_MIN_LENGTH, t("Password_Validation_minimum_length"))
      .max(
        PASSWORD_MAX_LENGTH,
        t("Password_Validation_maximum_length")
      ),
  });


  const authenticateStaffValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("validation_password_required"))
      .matches(
        ATLEAST_ONE_SMALL_REGEX,
        t("password_validation_lowercase")
      )
      .matches(
        ATLEAST_ONE_CAPITAL_REGEX,
        t("Password_Validation_uppercase")
      )
      .matches(
        ATLEAST_ONE_NUMBER_REGEX,
        t("Password_Validation_number")
      )
      .matches(
        ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
        t("Password_Validation_special_char")
      )
      .min(PASSWORD_MIN_LENGTH, t("Password_Validation_minimum_length"))
      .max(
        PASSWORD_MAX_LENGTH,
        t("Password_Validation_maximum_length")
      ),
  });
  return {
    loginValidationSchema,
    forgetPasswordValidationSchema,
    resetPswValidationSchema,
    otpValidationSchema,
    restaurantProfileValidationSchema,
    CashierPasswordValidationSchema,
    profileValidationSchema,
    categoryValidationSchema,
    menuItemsValidationSchema,
    staffValidationSchema,
    editStaffValidationSchema,
    authenticateStaffValidationSchema
  };
};


