import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { Outlet} from "react-router-dom";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import GlobalLoader from "./globalLoader";
import { setUserType } from "../../../redux/slices/userType";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantSettings } from "../services/table.services";
import { addRestData } from '../../../redux/slices/restSettings';

const CashierLayout = () => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.classList.add('restaurant_inner_body');
        }
    }, []);

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            import("../../../assets/cashier/css/style.css"),
            import("../../../assets/restaurant/css/style.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);

    useMemo(() => {
        getRestaurantSettings().then((globalDetails) => {
            dispatch(addRestData(globalDetails?.data));
        });
    }, []);

    return (
        <>
            {loaderState ? (
                <GlobalLoader />
            ) : (
                <div>
                    <Header />
                    <Outlet />
                </div>
            )}
        </>
    );
};
export default CashierLayout;