import React from 'react';
import card1 from '../../../assets/cashier/images/card-img.svg';
import card2 from '../../../assets/cashier/images/card2-img.svg';
import HomeIcon from '../../../assets/cashier/images/home-img.svg';
import InvoiceIcon from '../../../assets/cashier/images/invoice-img.svg';
import PayIcon from '../../../assets/cashier/images/pay-img.svg';
import ReprintIcon from '../../../assets/cashier/images/reprint-img.svg';
import AmericanCard from '../../../assets/cashier/images/american-card.png';
import amex from '../../../assets/cashier/images/amex.png';
import diners from '../../../assets/cashier/images/diners.png';
import JcbCard from '../../../assets/cashier/images/jcb-card.png';
import visa from '../../../assets/cashier/images/visa.png';
import Mastercard from '../../../assets/cashier/images/mastercard.png';
import aupay from '../../../assets/cashier/images/au-pay.png';
import linepay from '../../../assets/cashier/images/line-pay.png';
import merupay from '../../../assets/cashier/images/meru-pay.png';
import paypay from '../../../assets/cashier/images/pay-pay.png';
import rakutenpay from '../../../assets/cashier/images/rakuten-pay.png';
import dbarai from '../../../assets/cashier/images/d-barai.png';
import checkImg from '../../../assets/cashier/images/check-img.png';

export default function PaymentSuccessInfo() {
  return (
    <div className='dashboard-cashier-wrap'>
      {/* <div className='loader-box'>
        <div className='loader-in'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#FFFFFF"></stop><stop offset=".3" stop-color="#FFFFFF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#FFFFFF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#FFFFFF" stop-opacity=".3"></stop><stop offset="1" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
          <span>Waiting for payment received </span>
        </div>
      </div> */}
      <div class="cashier-head text-center">
        <h4>Payment</h4>
        <div class="btns-head-in-left">
          <button class="btn btn-icon"><i class="ti ti-arrow-left"></i></button>
        </div>
      </div>
      <div className='payment-wraper'>
        <div className='payment-wraper-in'>
          <div className='paymnet-typing shadow-box'>
            <span>TOTAL</span>
            <h4>¥ 420</h4>
          </div>
          <div className='payment-keypad-box shadow-box'>
            <div className='paymnet-success-msg'>
              <figure className='check-img'>
                <img src={checkImg}/>
              </figure>
              <span>Order no. #545512</span>
              <h2>¥ 420</h2>
              <h4>Payment has been made
              successfully</h4>
              <button className='btn btn-primary'>Back to home</button>
            </div>
          </div>
          <div className='keypad-action-btn-left gap-3'>
            <button className='keypad-action-btns' >
              <img src={InvoiceIcon} />
              <span>Print Invoice</span>
            </button>
            <button className='keypad-action-btns' >
              <img src={ReprintIcon} />
              <span>Reprint</span>
            </button>
          </div>
          <div className='keypad-action-btn-right gap-3'>
            <button className='keypad-action-btns'>
              <img src={HomeIcon} />
              <span>Home</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
