import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as restaurantService from "../../services/restaurant.services";
import Loader from "../../common/loader";
import { formateDate, removeSpecialCharacter } from "../../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
// import $ from "jquery";

const ViewEnrollments = (props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const params = useParams();
    // const [query] = useSearchParams();
    const [showdefault, setShowDefault] = useState({});


    useEffect(() => {   
        restaurantService
            .RestEnrollList({ restaurant_id: params.id })
            .then((response) => {
                setShowDefault(response && response.data && response.data.list ? response?.data?.list[0] : []);
                console.log("View response-", showdefault);
            })
            .catch((error) => {
                console.log("error=====>", error);
            });
    }, [params.id]);

    const remainingDays = (data) => {
        if (data && data.days_in_expiry <= 5) {
            return (<>
                {data.days_in_expiry}{t("input_value_days")}{"     "}
                <span
                    className="badge badge-primary cp"
                    // onClick={navigate(`restaurant-management/restaurant/view/${data.id}/enroll-restaurant/renew-plan`)}
                    onClick={() => {
                        navigate(`/admin/restaurant-management/restaurant/view/${data.restaurant_id}/enroll-restaurant/renew-plan`)
                    }}
                    href="#">
                    {t("btn_renew")}
                </span>
            </>)
        }
        else {
            return data.days_in_expiry
        }
    }

    return (
        <>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">

                        {/* <div className="m-4">
              <h6 className="main-content-label mb-3">
                {t("label_restaurants_name")}
              </h6>
            </div> */}

                        <div className="">

                            {showdefault && Object.keys(showdefault).length > 0 ? (
                                <div className="row">
                                    <div className="col-lg-12 form-group">
                                        <table
                                            id="simple-table"
                                            className="table  table-bordered table-hover"
                                        >
                                            <tbody>
                                                {/* <tr>
                                                    <th>{t("label_logo")}</th>
                                                    <td>
                                                        <img
                                                            src={
                                                                showdefault && showdefault.restaurent_info[0].restaurant_logo
                                                                    ? showdefault.restaurent_info[0].restaurant_logo
                                                                    : "N/A"
                                                            }
                                                            alt="profile"
                                                            style={{ height: "100px" }}
                                                        />
                                                    </td>
                                                </tr> */}
                                                <tr>
                                                    <th>{t("label_restaurant_name")}</th>
                                                    <td className="text-capitalize">
                                                        {showdefault && showdefault.restaurent_info[0].restaurant_name

                                                            ? showdefault.restaurent_info[0].restaurant_name
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_email")}</th>
                                                    <td className="text-capitalize">
                                                        {showdefault && showdefault.restaurent_info[0].email

                                                            ? showdefault.restaurent_info[0].email
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_phone_number")}</th>
                                                    <td>
                                                        {showdefault && showdefault?.phone_number
                                                            ? showdefault.phone_number
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_company_name")}</th>
                                                    <td className="text-capitalize">
                                                        {showdefault && showdefault.company_name
                                                            ? showdefault.company_name
                                                            : "N/A"}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>{t("label_representative_name")}</th>
                                                    <td className="text-capitalize">
                                                        {showdefault && showdefault.representative_name ? showdefault.representative_name : "N/A"}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>{t("label_contractor_name")}</th>
                                                    <td>
                                                        {showdefault && showdefault.contractor_name
                                                            ? showdefault.contractor_name
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_plan_name")}</th>
                                                    <td>
                                                        {showdefault && showdefault.plan_name
                                                            ? removeSpecialCharacter(showdefault.plan_name)
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("contract_plan_duration_type")}</th>
                                                    <td>
                                                        {showdefault && showdefault.contract_plan_duration_type
                                                            ? showdefault.contract_plan_duration_type
                                                            : "N/A"}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>{t("label_expiry_date")}</th>
                                                    <td>
                                                        {showdefault && showdefault.expiry_date
                                                            ? formateDate(showdefault.expiry_date)
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_days_remaining_expiry")}</th>
                                                    <td>
                                                        {remainingDays(showdefault)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_membership_cost")}</th>
                                                    <td>
                                                        {showdefault && String(showdefault.membership_cost)
                                                            ? `¥${showdefault.membership_cost}`
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_company_address")}</th>
                                                    <td>
                                                        {showdefault && showdefault.company_address
                                                            ? showdefault.company_address
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_shipping_address")}</th>
                                                    <td>
                                                        {showdefault && showdefault.shipping_address
                                                            ? showdefault.shipping_address
                                                            : "N/A"}
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <th>{t("list_heading_created_date")}</th>
                                                    <td>
                                                        {showdefault && showdefault.createdAt
                                                            ? formateDate(showdefault.createdAt)
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                {/* <tr>
                          <th>{t("label_modified_date")}</th>
                          <td>
                            {showdefault && showdefault.updatedAt
                              ? formateDate(showdefault.updatedAt)
                              : "N/A"}
                          </td>
                        </tr> */}
                                                {/* <tr>
                          <th>Restaurant Images</th>
                          <td>
                            {showdefault && showdefault.images ? (
                              showdefault.images.map((img, i) => (
                                <img
                                  key={i}
                                  src={img}
                                  alt="Img"
                                  style={{
                                    height: "100px",
                                    marginLeft: ".5rem",
                                  }}
                                />
                              ))
                            ) : (
                              <div>N/A</div>
                            )}
                          </td>
                        </tr> */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                showdefault.length === 0 ? 
                                <div className="text-center">{t("msg_no_records")} </div> 
                                :
                                <Loader />
                            )}
                        </div>

                        <div className="mt-2 back-button mx-0">
                          {showdefault.length === 0 && <button
                                className="btn ripple btn-success mlAction mx-2"
                                type="button"
                                onClick={() =>
                                    navigate(`/admin/restaurant-management/restaurant/enroll-restaurant/${params?.id}`)
                                }
                            >
                                
                                {t("btn_enroll_restaurant")}
                            </button>}  


                            <button
                                className="btn ripple btn-dark"
                                type="button"
                                onClick={() =>
                                    navigate("/admin/restaurant-management/restaurant/list/1")
                                }
                            >
                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                {t("btn_back")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewEnrollments;
