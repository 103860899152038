import React from 'react'
import { Link } from 'react-router-dom'

export default function AbcAnalysis() {
  return (
    <main id='main-content'>
        <div className='menu-level-3'>
            <ul className="nav nav-pills gap-3">
                <li className="nav-item">
                    <Link className="nav-link active" to={'/restaurant/sales/abc-analysis'}>Product basis</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link " to={'/restaurant/sales/abc-analysis-category-basis'}>Category basis</Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={'/restaurant/sales/abc-analysis-day-basis'}>Days basis</Link>
                </li>
            </ul>
        </div>
        <div className='inner-container'>
            <div className='d-flex tabs-with-etc justify-content-between align-items-center mb-4'>
                <ul className="nav nav-pills gap-3">
                    <li className="nav-item">
                        <Link className="nav-link active">By day</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link ">By month</Link>
                    </li>
                </ul>
                <div className='tabls-dowpdown'>
                    <select className='form-control cp'>
                        <option selected disabled>Choose payment method type</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                    </select>
                </div>
            </div>
            <div className='card-outer-header d-flex justify-content-between align-items-center mb-4'>
                <button className='btn btn-date'><i className='ti ti-calendar'></i> 14 May 2024 <i className='ti ti-chevron-down'></i></button>
                <div className='card-head-group d-flex align-items-center gap-2'>
                    <button className='btn btn-black'><i className='ti ti-download'></i> Download</button>
                    <button className='btn btn-black'><i className='ti ti-printer'></i> Print</button>
                </div>
            </div>
            <div className='card'>
                <div className='card-body'>
                    <div className='table-responsive'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Product name</th>
                                    <th>Quantity</th>
                                    <th>Amount of sales</th>
                                    <th>Cost price</th>
                                    <th>Profit</th>
                                    <th>Profit rate</th>
                                    <th>Composition Ratio</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr>
                                    <td>Hot coffee</td>
                                    <td>14</td>
                                    <td>¥199,255</td>
                                    <td>0</td>
                                    <td>¥199,255</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                                <tr className='total-tr'>
                                    <td>Total</td>
                                    <td>999</td>
                                    <td>¥60,719</td>
                                    <td>0</td>
                                    <td>¥60,719</td>
                                    <td>40%</td>
                                    <td>9.5</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
  )
}
