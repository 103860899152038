import React, { useEffect, useState } from "react";
import { DateRangePicker  , CustomProvider} from "rsuite";
import { useTranslation } from "react-i18next";
import ja from 'rsuite/locales/ja_JP';
import en from 'rsuite/locales/en_US';

const CustomRangepicker = (props) => {
  const [value, setValue] = useState([]);
  const { t } = useTranslation();
  const selected_language =localStorage.getItem("system_language")
  // console.log(selected_language)

  // useEffect(() => {
  //   if (props.resetdate) {
  //     setValue([]);
  //   }
  //   if (props.setdate) {
  //     setValue([
  //       moment().startOf("day").toDate(),
  //       moment().endOf("day").toDate(),
  //     ]);
  //   }
  // }, [props.resetdate, props.setdate]);

  useEffect(() => {
    setValue([]);
}, [props.resetdate])

  function handleChange(daterange) {
    if (daterange && daterange.length === 2) {
      props.GetDateRange({ start: daterange[0], end: daterange[1] });
      setValue(daterange);
    } else {
      props.GetDateRange({});
      setValue([]);
    }
  }

  return (
    <>
      <div className="form-group mb-0 rangepicker_container filter_design">
        <i className="fa fa-calendar glyphicon glyphicon-calendar calender_icon"></i>
        <CustomProvider locale={selected_language==="ja"?ja:en}>
        <DateRangePicker
          value={value}
          placeholder={t("input_placeholder_date")}
          placement="bottomEnd"
          cleanable={false}
          onChange={handleChange}
          // renderInput={(props, ref) => (
          //   <input
          //     {...props}
          //     ref={ref}
          //     readOnly
          //   />
          // )}
        />
        </CustomProvider>
      </div>
    </>
  );
};

export default CustomRangepicker;
