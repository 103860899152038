import React, { useEffect, useMemo, useState } from 'react';
import CustomError from '../../../utils/customError';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useSelector } from 'react-redux';
import PhoneInput from '../../../utils/PhoneInput';
import { ITEM_TYPES, PHONE_NO_LENGTH, SWAL_SETTINGS } from '../../../utils/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSessionKey, getSubTotalQRApp, handleServerValidations, pluralText, showPrice } from '../../../utils/commonfunction';
import Swal from "sweetalert2";
import trash from "../../../assets/restaurant/images/trash.svg";
import { useTranslation } from 'react-i18next';
import { createOrder, detail } from '../services/order.service';


export default function ReviewOrder() {
  const queryParams = new URLSearchParams(window.location.search);
  const restaurant_id = queryParams.get('restaurant_id');
  const table_id = queryParams.get('table_id');
  const order_type = queryParams.get('order_type');
  const tag = queryParams.get('tag');
  const tableDetails = useSelector((state) => { return state.QrOrderToken.tableDetails });
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [numberOfPersonOptions, setNumberOfPersonOptions] = useState([]);
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const order_id = queryParams.get('order_id') ? queryParams.get('order_id') : null;
  const [orderDetail, setOrderDetail] = useState(null);
  const [customerDetails, setCustomerDetails] = useState(location?.state?.customerDetails || null);

  useEffect(() => {
    if (tableDetails && tableDetails?.capacity) {
      let i = 1;
      const numberOfPersonOptionsTemp = [];
      for (i; i <= tableDetails?.capacity; i++) {
        numberOfPersonOptionsTemp.push(i);
      }
      setNumberOfPersonOptions(numberOfPersonOptionsTemp);
    }
  }, [tableDetails]);


  useEffect(() => {
    if (order_id) {
      detail({ o_id: order_id }, { token: token })
        .then((response) => {
          setOrderDetail(response?.data);
          let customerData = {
            customer_name: response?.data?.customerDetails?.customer_name || "",
            customer_phone_number: response?.data?.customerDetails?.customer_phone_number || "",
            number_of_persons: response?.data?.number_of_persons || "",
            customer_note: response?.data?.customer_note || "",
          }
          setCustomerDetails(customerData)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [order_id])

  const addForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      ordered_items: [...(location?.state?.selectedItems || [])],
      customer_name: customerDetails?.customer_name || "",
      customer_phone_number: customerDetails?.customer_phone_number || "",
      number_of_persons: customerDetails?.number_of_persons || "",
      customer_note: customerDetails?.customer_note || "",
      parent_id: order_id || ""
    },
    validationSchema: Yup.object({
      ordered_items: Yup.array().min(1, 'At least one item is required'),
      number_of_persons: Yup.number().required('Please select number of persons'),
      customer_phone_number: Yup.string()
        .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
          const tempLength = (value && value.split(" ") && value.split(" ")[1]) ? value.split(" ").length : 0;
          if (tempLength > 1) {
            const phoneNumber = (value?.split(" "))[1];
            return phoneNumber && /^\d+$/.test(phoneNumber);
          } else {
            return true;
          }
        }
        )
        .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
          const tempLength = (value && value.split(" ") && value.split(" ")[1]) ? value.split(" ").length : 0;
          if (tempLength > 1) {
            const phoneNumber = (value?.split(" "))[1];
            return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
          } else {
            return true;
          }
        }
        )
        .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
          const tempLength = (value && value.split(" ") && value.split(" ")[1]) ? value.split(" ").length : 0;
          if (tempLength > 1) {
            const phoneNumber = (value?.split(" "))[1];
            return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
          } else {
            return true;
          }
        }
        )
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let formData = {
        ...values,
        restaurant_id,
        table_id,
        type: order_type,
        tag: tag,
        ...(getSubTotalQRApp(values?.ordered_items) || {}),
        parent_id: order_id || ""
      }
      // console.log("onSubmit formData", formData);

      createOrder(formData, { token: token }).then((response) => {
        if (response.success) {
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
          resetForm();
          const mySearchParams = new URLSearchParams(window.location.search);
          mySearchParams.set("order_id", response?.data?.orderData?.parent_id ?  response?.data?.orderData?.parent_id  :  response?.data?.orderData?._id)
          navigate({
            pathname: "/qr-order/place",
            search: mySearchParams.toString()
          })
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }

        // console.log(response.data)
      })
        .catch((err) => {
          console.log(err)
        })


      // let data = [...selectedItems];
      // let index = data.findIndex(item => item._id === values._id);
      // if (index >= 0) {
      //   data[index] = values;
      // } else {
      //   data.push(values);
      // }
      // setSelectedItems(data);
      // addFormCloseButton?.current?.click();
    },
  });


  useEffect(() => {
    if (location?.state?.selectedItems) {
      /** User has selected items */
    } else {
      const searchParams = location.search;
      navigate(`/qr-order${searchParams}`);
    }
  }, [location]);

  const deleteRecord = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const index = addForm.values?.ordered_items?.findIndex(record => record._id === item?._id);
        if (index !== -1) {
          let data = [...addForm.values?.ordered_items];
          data.splice(index, 1);
          addForm.setFieldValue('ordered_items', data);
        }
      }
    });
  };

  return (
    <>
      <div className='mobile-container'>
        <form onSubmit={addForm.handleSubmit}>
          <div className='mobile-container-wraper'>
            <div className='qr-body-wrap'>
              <div className='p-3'>
                <div className='form-review-order'>
                  <div className='mb-3'>
                    <label className='form-label'>{t('number_of_person_text')}<span>*</span></label>
                    <select className='form-control cp' name='number_of_persons' value={addForm?.values?.number_of_persons} onChange={(e) => {
                      addForm.setFieldValue("number_of_persons", e.target.value ? Number(e.target.value) : "");
                    }}>
                      <option value={""}>{t('input_select_text')}</option>
                      {
                        numberOfPersonOptions?.map((option, index) => {
                          return (<option value={option} key={index}>{option}</option>)
                        })
                      }
                    </select>
                    <CustomError className="text-danger" name="number_of_persons" form={addForm} />
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>{t('full_name_label')}</label>
                    <input type='text' className='form-control' placeholder='Enter name' name="customer_name" value={addForm?.values?.customer_name} onChange={addForm.handleChange} disabled={order_id?true:false}/>
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>{t('label_phone_number')}</label>
                    {/* <input type='text' className='form-control' placeholder='Enter your phone number' name="phone_number" value={addForm?.values?.phone_number} onChange={addForm.handleChange} /> */}
                    <PhoneInput disabled={order_id?true:false}
                      value={addForm?.values?.customer_phone_number}
                      name={'customer_phone_number'} onChange={(e) => {
                        if (e?.number) {
                          addForm.setFieldValue('customer_phone_number', e?.number);
                        } else {
                          addForm.setFieldValue('customer_phone_number', "");
                        }
                      }} onBlur={addForm.handleBlur} />
                    <CustomError name="customer_phone_number" form={addForm} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                  </div>
                </div>
                {
                  addForm?.values?.ordered_items?.length ?
                    <div className='items-edit-delete'>
                      <h4>{t('label_items')} </h4>
                      <div className='d-flex gap-2 flex-column'>
                        {
                          addForm?.values?.ordered_items?.map((item, index) => {
                            return (
                              <div className='card card-2' key={index}>
                                <div className='p-2'>
                                  <div className='d-flex align-items-center justify-content-between'>
                                    <div className=''>
                                      <span className='d-block'>{item?.item_name}</span>
                                      {
                                        item?.type === ITEM_TYPES.SET_OF_ITEMS ?
                                          <div className='w-100'>
                                            {
                                              item?.addons?.map((addon, addonIndex) => {
                                                return <small className={'border px-1 rounded-2' + (addonIndex + 1 < item?.addons?.length ? " me-1" : "")} key={addonIndex}>{addon?.item_name}</small>
                                              })
                                            }
                                          </div>
                                          : null
                                      }
                                      <small>¥ {showPrice(item?.price)}</small>
                                    </div>
                                    <div className='d-flex gap-2'>
                                      <button type='button' onClick={() => {
                                        const mySearchParams = new URLSearchParams(window.location.search);
                                        console.log("itemitemitemitem", item);
                                        mySearchParams.set('category_id', item?.category_id);
                                        mySearchParams.set('openEdit', item?._id);
                                        navigate({
                                          pathname: "/qr-order",
                                          search: mySearchParams.toString()
                                        },
                                          {
                                            state: {
                                              selectedItems: addForm?.values?.ordered_items,
                                              customerDetails:addForm?.values
                                            }
                                          });
                                      }} className='btn btn-icon'><i className='ti ti-edit'></i></button>
                                      <button type='button' onClick={() => { deleteRecord(item) }} className='btn btn-icon btn-danger'><i className='ti ti-trash'></i></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                    :
                    <div className='items-edit-delete'>
                      <h4>{t('no_order_text')}</h4>
                      <button className="btn btn-primary w-100" type='button' onClick={() => {
                        const searchParams = location.search;
                        navigate(`/qr-order${searchParams}`);
                      }}>{t('select_item_label')}</button>
                    </div>
                }
                <div className='mb-3 mt-4'>
                  <label className='form-label'>{t('requirement_text')}</label>
                  <textarea style={{ height: '120px' }} placeholder='Write a note' className='form-control' name='customer_note' value={addForm?.values?.customer_note} onChange={addForm.handleChange}></textarea>
                </div>
                {/* Showing the pricing breakdown for selected items */}
                {
                  addForm?.values?.ordered_items?.length ?
                    <div className='info-order-review'>
                      <div className='card card-2'>
                        <div className='p-2'>
                          <table width={'100%'}>
                            <tbody>
                              <tr>
                                <td>{t('sub_total_label')}({addForm?.values?.ordered_items?.length} {pluralText(addForm?.values?.ordered_items?.length, t("label_item"), t("label_items"))})</td>
                                <td className='text-end'>¥ {showPrice(getSubTotalQRApp(addForm?.values?.ordered_items)?.subtotal)}</td>
                              </tr>
                              <tr>
                                <td>{t('total_tax_label')}</td>
                                <td className='text-end'>¥ {showPrice(getSubTotalQRApp(addForm?.values?.ordered_items)?.tax_price)}</td>
                              </tr>
                              <tr>
                                <td></td>
                                <td className='text-end'>
                                  <span className='d-block'>(8% {t('tax_on_text')} ¥ {showPrice(getSubTotalQRApp(addForm?.values?.ordered_items)?.total_tax_8)})</span>
                                  <span className='d-block'>(10% {t('tax_on_text')} ¥ {showPrice(getSubTotalQRApp(addForm?.values?.ordered_items)?.total_tax_10)})</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <h4>{t('grand_total_text')}</h4>
                                </td>
                                <td className='text-end'>
                                  <h4>¥ {showPrice(getSubTotalQRApp(addForm?.values?.ordered_items)?.grand_total)}</h4>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    : null
                }
              </div>
            </div>
            <div className="cart-bottom p-3">
              <button className="btn btn-primary w-100" type='submit' >{t('place_order_label')}</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
