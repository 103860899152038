import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";
const options = {role: ROLE.SUPER_ADMIN};
const path = "admin/user"

export const updateProfileUsers = async (values) => {
    return await axiosInstance(options).put(`${path}/edit-profile`, values)
}

export const changepassword = async (values) => {
    return await axiosInstance(options).patch(`${path}/change-password`, values);
}

