import React, { useEffect, useMemo, useRef, useState } from 'react';
import { categoryList, menuItemList, menuItemDetail } from '../services/menu.service';
import Skeleton from 'react-loading-skeleton';
import { CONSUMPTION_TAX_RATES, DISPLAY_METHOD_TAX_OPTIONS, ITEM_TYPES, LOADER_TIMEOUT_TIME, ORDER_PLATFORM, SPICE_LEVELS } from '../../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import { getSessionKey, getSubTotalQRApp, getTranslatedKey, getUniqueID, isSameArray, pluralText, showPrice } from '../../../utils/commonfunction';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import RecordNotFound from '../../restaurant/shared/recordNotFound';
import { SetCartLength } from '../../../redux/slices/qrcodeDetails';



export default function AddMenu() {
  const queryParams = new URLSearchParams(window.location.search);
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const [categoriesList, setCategoriesList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [catLoader, setCatLoader] = useState(true);
  const [itemLoader, setItemLoader] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(queryParams.get('category_id')||'');
  const [currentItem, setCurrentItem] = useState({});
  const addFormCloseButton = useRef();
  const addFormOpenButton = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [langChanged, setLangChanged] = useState(false);
  const {t} = useTranslation();
  const [selectedItems, setSelectedItems] = useState([...(location?.state?.selectedItems || [])]);
  const [customerDetails, setCustomerDetails] = useState(location?.state?.customerDetails || null);
  const CartLengthData = useSelector((state)=>state.QrOrderToken.CartLength);
  const dispatch = useDispatch();
  const base = queryParams.get('base');
  const ChargeSettings = useSelector((state) => {
    return state.QrOrderToken.ChargeSettings;
  });
  const [removableItems, setRemovableItems] = useState([]);
  const removableItemsOpenButton = useRef();
  const removableItemsCloseButton = useRef();
  const newToken = useSelector((state)=>{return state.QrOrderToken.qrPanelToken});

  useEffect(()=>{
    setToken(newToken);
  },[newToken]);

  const addForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: '',
      type: '',
      image: '',
      item_name: '',
      item_name_ja: '',
      addons: [],
      spice_level: '',
      spice_level_needed: null,
      price: '',
      tax_price: '',
      total_price: '',
      short_description: '',
      short_description_ja: '',
      consumption_tax_rate: '',
    },
    validationSchema: Yup.object({
      addons: Yup.array().of(
        Yup.object({
          item_id: Yup.string().required(t('select_item_text'))
        })
      )
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let data = [...selectedItems];
      let index = -1;
      function getAddonArray(item){
        return item.addons.map(a => `${a.item_id}-${a.spice_level}`);
      }
      if(values.type === ITEM_TYPES.SET_OF_ITEMS){
        const item = values;
        const comboAddons = item.addons.map(a => `${a.item_id}-${a.spice_level}`);
        index = data.findIndex(record => record._id === item._id && isSameArray(comboAddons, getAddonArray(record)));
      }else{
        index = data.findIndex(item => item._id === values._id && item.spice_level === values.spice_level);
      }
      if (index >= 0) {
        data[index].quantity += 1;
      } else {
        data.push({
          ...values,
          quantity:1,
          temp_item_id:getUniqueID('selected-item-')
        });
      }
      setSelectedItems(data);
      addFormCloseButton?.current?.click();
      addForm.resetForm();
    },
  });

  /** If user comes to edit an item */
  useEffect(() => {
    const editableId = queryParams.get('openEdit');
    if (editableId && editableId.length) {
      const data = selectedItems.filter(item => item?._id === editableId)[0];
      if(data){
        fetchMenuItemDetail(editableId).then(()=>{
          addForm.setValues(data);
          addFormOpenButton?.current?.click();
        }).catch((err)=>{
          console.error("Error while getting info", err);
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (token && token.length) {
      setCatLoader(true);
      categoryList({}, { token: token })
        .then((response) => {
          setCategoriesList(response.data.list);
          if(!selectedCategory){
            setSelectedCategory(response.data.list[0]?._id);
          }
          setTimeout(() => { 
            setCatLoader(false);
            const appData = { action: 'stop-loader', data: {} };
            window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
          }, LOADER_TIMEOUT_TIME);
        })
        .catch((err) => { console.error(err) });
    }
  }, [token, langChanged]);

  useEffect(() => {
    if (token && token.length) {
      setItemLoader(true);
      let filterCategory = {};
      if (selectedCategory) {
        filterCategory.category_id = selectedCategory;
      }
      menuItemList(filterCategory, { token: token })
        .then((response) => {
          setItemList(response.data.list);
          setTimeout(() => { setItemLoader(false) }, LOADER_TIMEOUT_TIME)
        })
        .catch((err) => { console.error(err) });
    }
  }, [token, selectedCategory, langChanged]);

  const fetchMenuItemDetail = (_id) => {
    return new Promise((resolve, reject)=>{
      menuItemDetail({ o_id: _id }, { token: token }).then((response) => {
        setCurrentItem(response?.data);
        // let ifNotExists = selectedItems.findIndex(record => record?._id === _id) === -1;
        // if (ifNotExists) {
          if (response?.data.type === ITEM_TYPES.ITEM) {
            addForm.setValues({
              ...response.data,
              spice_level: response?.data?.spice_level_needed ? SPICE_LEVELS.LOW : null,
              spice_level_needed: response?.data?.spice_level_needed || false,
            });
          } else {
            let data = {
              ...response?.data, addons: response?.data?.addons?.map((addon) => {
                return {
                  item_id: "",
                  item_name: "",
                  item_name_ja: "",
                  spice_level: null,
                  spice_level_needed: false,
                  addon_id: addon?._id,
                  addon_name: addon.addon_name,
                }
              })
            };
            addForm.setValues(data);
          }
        // }else{
        //   console.log("8888")
        // }
        setTimeout(() => {
          resolve(response);
        }, 100);
      }).catch((err) => { console.error(err); reject(err); })
    })
  }
  useEffect(()=>{
    let result = 0;
    selectedItems.forEach((record)=>{
      result += record?.quantity;
    });
    dispatch(SetCartLength(result));
  },[selectedItems]);

  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setLangChanged(!langChanged);
    })
  });
  const isItemSelected = (item)=>{
    return selectedItems?.findIndex(record=>record?._id === item?._id) !== -1;
  };
  const getCurrentQuantity = (item)=>{
    let result = 0;
    const records = selectedItems?.filter(record=>record?._id === item?._id);
    records.forEach((record)=>{
      result += record?.quantity;
    });
    return result;
  };
  const handleChangeQuantity = (item, type) => {
    console.log("kkkllllll",item);
    let allItems = [...selectedItems];
    if (type === "increase") {
      fetchMenuItemDetail(item?._id).then(()=>{
        addFormOpenButton?.current?.click();
      }).catch((err)=>{
        console.error(err);
      });
    } else {
      const commonItems = allItems.filter((record) => record?._id === item?._id);
      if (commonItems.length > 1) {
        setRemovableItems(commonItems);
        removableItemsOpenButton?.current?.click();
      }else{
        const index = allItems.findIndex((record) => record?._id === item?._id);
        if(allItems[index].quantity > 1){
          allItems[index].quantity -= 1;
        }else{
          allItems.splice(index, 1);
        }
      }
    }
    setSelectedItems(allItems);
  };

  const deleteSubQuantity = (item) => {
    let primaryItems = [...selectedItems];
    let tempItems = [...removableItems];
    const primaryIndex = primaryItems.findIndex((record) => record?.temp_item_id === item?.temp_item_id);
    const secondaryIndex = tempItems.findIndex((record) => record?.temp_item_id === item?.temp_item_id);
    if(item.quantity > 1){
      primaryItems[primaryIndex].quantity -= 1;
    }else{
      if(primaryIndex !== -1){
        primaryItems.splice(primaryIndex, 1);
      }
      if(secondaryIndex !== -1){
        tempItems.splice(secondaryIndex, 1);
      }
    }
    setRemovableItems(tempItems);
    setSelectedItems(primaryItems);
    if(tempItems.length===0){
      removableItemsCloseButton?.current?.click();
    }
  };

  const handleAsterisk = (item) => {
    let result = null;
    if (
      item?.consumption_tax_rate === CONSUMPTION_TAX_RATES.TAKE_OUT &&
      ChargeSettings?.icon_display_eligible_for_reduced_tax_rate
    ) {
      result = <span className="fw-bold">*</span>;
    }
    return result;
  };

  return (
    <>
      <div className="qr-body-wrap">
        <div className="menu-qr-main-wrap">
          <div className="qr-menu-left">
            {!catLoader ? (
              <div className="qr-menu-list">
                {categoriesList.length > 0
                  ? categoriesList.map((item, index) => {
                      return (
                        <button
                          className={"menu-btn" + (selectedCategory === item._id ? ` active` : "")}
                          key={index}
                          onClick={() => {
                            setSelectedCategory(item?._id);
                            const searchParams = new URLSearchParams(window.location.search);
                            searchParams.set("category_id", item?._id);
                            navigate({
                              pathname: location.pathname,
                              search: searchParams.toString(),
                            });
                          }}
                        >
                          {getTranslatedKey('name',item)}{" "}
                        </button>
                      );
                    })
                  : ""}
                {/* <button className='menu-btn active'>Fries </button> */}
              </div>
            ) : (
              <div className="qr-menu-list">
                <Skeleton style={{ height: "37px" }} />
                <Skeleton style={{ height: "37px" }} />
                <Skeleton style={{ height: "37px" }} />
                <Skeleton style={{ height: "37px" }} />
                <Skeleton style={{ height: "37px" }} />
              </div>
            )}
          </div>
          <div className="qr-menu-right">
            {!itemLoader ? (
              <>
                {itemList.length ? (
                  <div className="qr-menu-item-list">
                    {itemList.map((item, index) => {
                      return (
                        <div className={"qr-menu-item-col" + (isItemSelected(item) ? " item-selected" : "")} key={index}>
                          <figure>
                            <img src={item?.image} alt={item?.item_name} />
                          </figure>
                          <figcaption>
                            <div>
                              <span>{getTranslatedKey('item_name',item)}</span>
                              {/* <span>¥ {showPrice(item?.price)}</span> */}
                              <span>¥ {ChargeSettings?.display_method_product_price===DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ?showPrice(item?.price):showPrice(item?.total_price)}</span>
                            </div>
                            {/* <button
                              className="btn btn-sm btn-primary"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#itemModifyCanvas"
                              aria-controls="itemModifyCanvas"
                              onClick={() => {
                                fetchMenuItemDetail(item?._id);
                              }}
                            >
                              {isItemSelected(item) ? t("btn_update") : t("link_add")}
                            </button> */}

                            {isItemSelected(item) ? 
                              
                              <div className="border border-1 rounded-1 mb-2 quantity-box d-flex justify-content-between">
                                <button
                                  className="btn btn-sm py-1 px-2 h-auto"
                                  type="button"
                                  onClick={() => {
                                    handleChangeQuantity(
                                      item,
                                      "decrease",
                                      // getSelectedItem(item)?.quantity
                                      getCurrentQuantity(item)
                                    );
                                  }}
                                >
                                  <i className="ti ti-minus"></i>
                                </button>
                                <span className="d-flex align-items-center">
                                  {getCurrentQuantity(item)}
                                </span>
                                <button
                                  className="btn btn-sm py-1 px-2 h-auto"
                                  type="button"
                                  onClick={() => {
                                    handleChangeQuantity(
                                      item,
                                      "increase",
                                      getCurrentQuantity(item)
                                    );
                                  }}
                                >
                                  <i className="ti ti-plus"></i>
                                </button>
                              </div>
                            
                            :
                            
                              <button
                                className="btn btn-sm btn-primary"
                                data-bs-toggle="offcanvas"
                                data-bs-target="#itemModifyCanvas"
                                aria-controls="itemModifyCanvas"
                                onClick={() => {
                                  fetchMenuItemDetail(item?._id);
                                }}
                              >
                                {t("link_add")}
                              </button>
                            }
                          </figcaption>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="">
                    <RecordNotFound heading="no_assigned_item_text" />
                  </div>
                )}
              </>
            ) : (
              <div className="qr-menu-item-list">
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className="qr-menu-item-col">
                  <Skeleton style={{ height: "70px" }} className="rounded-3 mb-3" />
                  <figcaption>
                    <Skeleton containerClassName="d-flex" />
                    <Skeleton containerClassName="d-flex" inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName="d-flex" style={{ height: "33px" }} />
                  </figcaption>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {CartLengthData ? (
        <div className="cart-bottom p-3">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span>
              {CartLengthData} {pluralText(CartLengthData, t("label_item"), t("label_items"))}
            </span>
            {
              ChargeSettings?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ?
              <span>¥ {showPrice(getSubTotalQRApp(selectedItems)?.subtotal)}</span>
              :
              <span>¥ {showPrice(getSubTotalQRApp(selectedItems)?.subtotal + getSubTotalQRApp(selectedItems)?.tax_price)}</span>
            }
          </div>
          <button
            className="btn btn-primary w-100"
            onClick={() => {
              const searchParams = window.location.search;
              navigate(`/qr-order/review${searchParams}`, {
                state: {
                  selectedItems: selectedItems,
                  customerDetails: customerDetails,
                },
              });
            }}
          >
            {base=== ORDER_PLATFORM.WEB ? t("review_place_order_text"):t("button_text_next")}
          </button>
        </div>
      ) : null}
      <button className="btn btn-sm btn-primary d-none" ref={addFormOpenButton} data-bs-toggle="offcanvas" data-bs-target="#itemModifyCanvas" aria-controls="itemModifyCanvas"></button>
      <div className="offcanvas offcanvas-bottom qr-offcanvas" data-bs-backdrop="static" tabIndex="-1" id="itemModifyCanvas" aria-labelledby="itemModifyCanvasLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="itemModifyCanvasLabel">
            {/* {addForm?.values?.item_name} */}
            {getTranslatedKey('item_name',addForm?.values)}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            ref={addFormCloseButton}
            onClick={() => {
              const searchParams = new URLSearchParams(window.location.search);
              searchParams.delete("openEdit");
              navigate({
                pathname: location.pathname,
                search: searchParams.toString(),
              });
            }}
          ></button>
        </div>

        <div className="offcanvas-body small">
          <small className="mb-3 d-block"> {getTranslatedKey('short_description',addForm?.values)}</small>
          {/* {addForm?.values?.short_description} */}
          <form onSubmit={addForm.handleSubmit}>
            {addForm?.values?.type === ITEM_TYPES.ITEM ? (
              <>
                {addForm?.values?.spice_level_needed ? (
                  <>
                    <div className="card card-2">
                      <div className="p-3">
                        <div className="row-veg-card">
                          <div className="head-veg-card d-flex align-items-center justify-content-between mb-2">
                            <h4>{t("spice_level_text")}</h4>
                          </div>
                          <div className="head-veg-card d-flex align-items-center justify-content-between mb-3">
                            <h4>{getTranslatedKey('item_name',addForm?.values) || getTranslatedKey('addon_name',addForm?.values)}</h4>
                          </div>
                          <div className="veg-tags">
                            <ul>
                              <li>
                                <div className="check-input-tick">
                                  <input
                                    type="radio"
                                    className="cp"
                                    onChange={addForm.handleChange}
                                    name="spice_level"
                                    id={"spice_level_low" + addForm?.values?._id}
                                    checked={addForm?.values?.spice_level === SPICE_LEVELS.LOW}
                                    value={SPICE_LEVELS.LOW}
                                  />
                                  <label htmlFor={"spice_level_low" + addForm?.values?._id} className="cp">
                                    <i className="ti ti-circle-check"></i> {t("label_low")}
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="check-input-tick">
                                  <input
                                    type="radio"
                                    className="cp"
                                    onChange={addForm.handleChange}
                                    name="spice_level"
                                    id={"spice_level_medium" + addForm?.values?._id}
                                    checked={addForm?.values?.spice_level === SPICE_LEVELS.MEDIUM}
                                    value={SPICE_LEVELS.MEDIUM}
                                  />
                                  <label htmlFor={"spice_level_medium" + addForm?.values?._id} className="cp">
                                    <i className="ti ti-circle-check"></i> {t("label_medium")}
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="check-input-tick">
                                  <input
                                    type="radio"
                                    className="cp"
                                    onChange={addForm.handleChange}
                                    name="spice_level"
                                    id={"spice_level_high" + addForm?.values?._id}
                                    checked={addForm?.values?.spice_level === SPICE_LEVELS.HIGHT}
                                    value={SPICE_LEVELS.HIGHT}
                                  />
                                  <label htmlFor={"spice_level_high" + addForm?.values?._id} className="cp">
                                    <i className="ti ti-circle-check"></i> {t("label_high")}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <h4 className="mb-3">{t("label_choose_option")}</h4>
                <div className="card card-2">
                  <div className="p-3">
                    {currentItem?.addons?.map((addon, index) => {
                      return (
                        <div key={index}>
                          <div className="row-veg-card">
                            <div className="head-veg-card d-flex align-items-center justify-content-between mb-3">
                              <h4>{getTranslatedKey('addon_name',addon)}</h4>
                              {/* {addon?.addon_name}  */}
                              <div className="form-group">
                                <select
                                  className="form-control w-150px cp"
                                  onBlur={addForm.handleBlur}
                                  name={`addons[${index}].item_id`}
                                  value={addForm?.values?.addons[index]?.item_id}
                                  onChange={(e) => {
                                    addForm.handleChange(e);
                                    let selectedItemData = addon?.items.filter((item) => item?._id === e?.target?.value)[0];
                                    if (selectedItemData) {
                                      addForm.setFieldValue(`addons[${index}].item_name_ja`, selectedItemData?.item_name_ja);
                                      addForm.setFieldValue(`addons[${index}].item_name`, selectedItemData?.item_name);
                                      addForm.setFieldValue(`addons[${index}].spice_level_needed`, selectedItemData?.spice_level_needed);
                                      if (selectedItemData?.spice_level_needed) {
                                        if (addForm?.values?.addons[index]?.spice_level) {
                                          /** Already selected by user */
                                        } else {
                                          addForm.setFieldValue(`addons[${index}].spice_level`, SPICE_LEVELS.LOW);
                                        }
                                      } else {
                                        addForm.setFieldValue(`addons[${index}].spice_level`, null);
                                      }
                                    }
                                    setTimeout(() => {
                                      addForm.handleBlur(e);
                                    }, 100);
                                  }}
                                >
                                  <option value={""}>{t("input_select_text")}</option>
                                  {addon?.items?.map((option, opIndex) => {
                                    return (
                                      <option key={opIndex} value={option?._id}>
                                        {getTranslatedKey('item_name',option)}
                                      </option>
                                    );
                                  })}
                                </select>
                                {addForm?.touched && addForm?.touched.addons && addForm?.touched.addons[index]?.item_id && !addForm?.values?.addons[index]?.item_id ? (
                                  <span className="validation-error text-danger fs-14">{t("select_item_text")}</span>
                                ) : null}
                              </div>
                            </div>
                            {(function () {
                              let selectedItemData = addon?.items.filter((item) => item?._id === addForm?.values?.addons[index]?.item_id)[0];
                              if (selectedItemData && selectedItemData?.spice_level_needed) {
                                return (
                                  <>
                                    <div className="head-veg-card d-flex align-items-center justify-content-between mb-2">
                                      <h4>{t("spice_level_text")}</h4>
                                    </div>
                                    <div className="veg-tags">
                                      <ul>
                                        <li>
                                          <div className="check-input-tick">
                                            <input
                                              type="radio"
                                              className="cp"
                                              onChange={addForm.handleChange}
                                              name={`addons[${index}].spice_level`}
                                              id={"spice_level_low" + addon?._id}
                                              checked={addForm?.values?.addons[index]?.spice_level === SPICE_LEVELS.LOW}
                                              value={SPICE_LEVELS.LOW}
                                            />
                                            <label htmlFor={"spice_level_low" + addon?._id} className="cp">
                                              <i className="ti ti-circle-check"></i> {t("label_low")}
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="check-input-tick">
                                            <input
                                              type="radio"
                                              className="cp"
                                              onChange={addForm.handleChange}
                                              name={`addons[${index}].spice_level`}
                                              id={"spice_level_medium" + addon?._id}
                                              checked={addForm?.values?.addons[index]?.spice_level === SPICE_LEVELS.MEDIUM}
                                              value={SPICE_LEVELS.MEDIUM}
                                            />
                                            <label htmlFor={"spice_level_medium" + addon?._id} className="cp">
                                              <i className="ti ti-circle-check"></i> {t("label_medium")}
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="check-input-tick">
                                            <input
                                              type="radio"
                                              className="cp"
                                              onChange={addForm.handleChange}
                                              name={`addons[${index}].spice_level`}
                                              id={"spice_level_high" + addon?._id}
                                              checked={addForm?.values?.addons[index]?.spice_level === SPICE_LEVELS.HIGHT}
                                              value={SPICE_LEVELS.HIGHT}
                                            />
                                            <label htmlFor={"spice_level_high" + addon?._id} className="cp">
                                              <i className="ti ti-circle-check"></i> {t("label_high")}
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                );
                              }
                              return null;
                            })()}
                          </div>
                          {index + 1 < currentItem?.addons.length ? <hr className="my-3" /> : null}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <button className="btn btn-primary w-100 mt-4" type="submit">
              {isItemSelected(addForm?.values) ? t("btn_update") : t("link_add")}
            </button>
          </form>
        </div>
      </div>

      





      <button
        className="btn btn-dark w-100 mt-2 d-none"
        data-bs-toggle="modal"
        data-bs-target="#removeItemsOptions"
        ref={removableItemsOpenButton}
      ></button>
      <div
        className="modal fade qr-modal"
        id="removeItemsOptions"
        tabIndex="-1"
        aria-labelledby="removeItemsOptionsLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Remove Items</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={removableItemsCloseButton}></button>
            </div>
            <div className="modal-body">
            <h4>{t("label_items")} </h4>
                <div className="d-flex gap-2 flex-column">
                  {removableItems?.map(
                    (item, index) => {
                      return (
                        <div className="card card-2" key={index}>
                          <div className="p-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="">
                                <span className="d-block">
                                  {getTranslatedKey("item_name", item)}{" "}
                                  {handleAsterisk(item)}{" "}
                                  {item?.quantity > 1 ? (
                                    <strong>{`(x${item?.quantity})`}</strong>
                                  ) : null}
                                  <br />
                                  {item?.spice_level ? (
                                    <span className="fs-12">
                                      {t("spice_level_text")} -{" "}
                                      <strong>
                                        {t("label_" + item?.spice_level)}
                                      </strong>
                                    </span>
                                  ) : null}
                                </span>
                                {item?.type === ITEM_TYPES.SET_OF_ITEMS ? (
                                  <div className="w-100 pt-2">
                                    {item?.addons?.map(
                                      (addon, addonIndex) => {
                                        return (
                                          <div
                                            className={
                                              "border p-2 rounded-2 mb-2" +
                                              (addonIndex + 1 <
                                                item?.addons?.length
                                                ? " me-1"
                                                : "")
                                            }
                                            key={addonIndex}
                                          >
                                            <span className="d-block fs-12">
                                              {getTranslatedKey(
                                                "item_name",
                                                addon
                                              )}
                                              {addon?.count > 1 ? (
                                                <strong>{`(x${addon?.count})`}</strong>
                                              ) : null}
                                            </span>
                                            {addon?.spice_level ? (
                                              <span className="d-block fs-12">
                                                {t("spice_level_text")} -{" "}
                                                <strong>
                                                  {t(
                                                    "label_" +
                                                    addon?.spice_level
                                                  )}
                                                </strong>
                                              </span>
                                            ) : null}
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : null}
                                <small>
                                  
                                  ¥ {ChargeSettings?.display_method_product_price===DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ?showPrice(item?.price * item?.quantity):showPrice(item?.total_price * item?.quantity)}
                                </small>
                              </div>
                              <div>
                                <div className="d-flex gap-2">
                                  <button
                                    className="btn btn-icon btn-danger"
                                    type="button"
                                    onClick={() => {
                                      deleteSubQuantity(item);
                                    }}
                                  >
                                     <i className="ti ti-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}