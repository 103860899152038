import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='privacy-policy-pages py-5'>
      <div className='container'>
        <div className='page-header'>
          <h1>Privacy policy </h1>
        </div>
        <div className='cms-wraper'>
          <h2>1. Information We Collect</h2>
          <h3>1.1 Personal Information</h3>
          <p>We may collect personal information that you provide directly to us, including but not limited to:</p>
          <ul className='list-li-dot'>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>Payment information</li>
          </ul>
          <br></br>
          <h3>1.1 Personal Information</h3>
          <p>We may collect information about your use of the Services, such as:</p>
          <ul className='list-li-dot'>
            <li>Order history</li>
            <li>Transaction details</li>
            <li>Customer preferences and feedback</li>
          </ul>
          <br></br>
          <h2>2. How We Use Your Information</h2>
          <h3>2.1 To Provide and Improve Our Services</h3>
          <p>We use the information we collect to:</p>
          <ul className='list-li-dot'>
            <li>Process transactions and manage orders</li>
            <li>Improve the functionality and performance of our POS system</li>
            <li>Personalize customer experiences and preferences</li>
          </ul>
          <br></br>
          <h3>2.2 Communication</h3>
          <p>We may use your personal information to:</p>
          <ul className='list-li-dot'>
            <li>Respond to your inquiries and provide customer support</li>
            <li>Send updates, promotional materials, and other communications</li>
          </ul>
          <br></br>
          <h2>3. Sharing Your Information</h2>
          <h3>3.1 Service Providers</h3>
          <p>We may share your information with third-party service providers who perform services on our behalf, such as:</p>
          <ul className='list-li-dot'>
            <li>Payment processors</li>
            <li>Data analytics providers</li>
            <li>Customer support services</li>
          </ul>
          <br></br>
          <h3>3.2 Communication</h3>
          <p>We may use your personal information to:</p>
          <ul className='list-li-dot'>
            <li>Respond to your inquiries and provide customer support</li>
            <li>Send updates, promotional materials, and other communications</li>
          </ul>
          <br></br>
          <h2>4. Data Security</h2>
          <p>We implement appropriate technical and organizational measures to protect your information from unauthorized access, use, or disclosure. However, no data transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>
          <br></br>
          <h2>5. Children's Privacy</h2>
          <p>Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.</p>
          <br></br>
          <h2>6. Changes to This Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. Your continued use of the Services after any changes constitutes your acceptance of the new Privacy Policy.</p>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
