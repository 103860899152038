import React, { useEffect, useMemo, useRef, useState } from 'react';
import trash from '../../../../../assets/restaurant/images/trash.svg';
import { getCategories, addCategory, deleteCategory, editCategory, sortCategories, categoryDetail } from '../../../services/category.services';
import { LOADER_TIMEOUT_TIME, SWAL_SETTINGS } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../../validator/validation";
import { useTranslation } from "react-i18next";
import { getColorType, getTranslatedKey, globalLoader, handleOffcanvasScroll, handleServerValidations, useDebouncing } from '../../../../../utils/commonfunction';
import { CATEGORIES_TAX_TYPES } from '../../../../../utils/Constants';
import { ReactSortable } from 'react-sortablejs';
import CustomError from "../../../../../utils/customError";
import { Link } from 'react-router-dom';
import GridLoader from '../../../shared/gridLoader';
import RecordNotFound from '../../../shared/recordNotFound';

export default function CategoriesListing() {
    const [sortableCategories, setSortableCategories] = useState([]);
    const [search, setSearch] = useState({})
    const [refreshList, setRefreshList] = useState(false);
    const [List, setList] = useState([]);
    const addCategoryCloseBtn = useRef();
    const editCategoryCloseBtn = useRef();
    const sortingCloseBtn = useRef();
    const [loader, setLoader] = useState(true);
    const { t } = useTranslation();
    const { categoryValidationSchema } = useValidationSchemas();
    const [globalSearch, setGlobalSearch] = useState('');
    const [langChanged, setLangChanged] = useState(false);

    const addForm = useFormik({
        initialValues: {
            name: "",
            name_ja: "",
            tax_type: "",
            color: "#000000",
            short_description: "",
            short_description_ja: "",
        },
        validationSchema: categoryValidationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("name", values.name);
            formData.append("name_ja", values.name_ja);
            formData.append("tax_type", values.tax_type);
            formData.append("color", values.color);
            formData.append("short_description", values.short_description);
            formData.append("short_description_ja", values.short_description_ja);
            //   console.log(values)
            addCategory(formData)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm();
                        addCategoryCloseBtn?.current?.click();
                        setRefreshList(!refreshList);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error updating profile", error);
                });
            console.log(values);
        },
    });

    const editForm = useFormik({
        initialValues: {
            _id: "",
            name: "",
            name_ja: "",
            tax_type: "",
            color: "",
            short_description: "",
            short_description_ja: "",
        },
        validationSchema: categoryValidationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("o_id", values?._id);
            formData.append("name", values?.name);
            formData.append("name_ja", values?.name_ja);
            formData.append("tax_type", values?.tax_type);
            formData.append("color", values?.color);
            formData.append("short_description", values?.short_description);
            formData.append("short_description_ja", values?.short_description_ja);
            editCategory(formData)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm();
                        setRefreshList(!refreshList);
                        editCategoryCloseBtn?.current?.click();
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error updating profile", error);
                });
            // console.log(values);
        },
    });

    const deleteCategories = (item) => {
        Swal.fire({
            title: t("msg_are_you_sure"),
            text: t("btn_delete_text"),
            imageUrl: trash,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: t("btn_delete"),
            cancelButtonText: t("btn_cancel"),
            reverseButtons: true,
            customClass: {
                popup: 'swal-restaurant',
                confirmButton: 'btn btn-danger col',
                cancelButton: 'btn btn-secondary col'
            },
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                globalLoader(true);
                deleteCategory({ o_id: item._id }).then((response) => {
                    globalLoader(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        setRefreshList(!refreshList);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                }).catch((error) => {
                    globalLoader(false);
                    console.log("deleteError");
                });
            } else {
                setSortableCategories(List);
            }
        });

    };

    useMemo(()=>{
        document.addEventListener('language_change',function(){
          setLangChanged(!langChanged);
        })
      });

    useEffect(() => {
        setLoader(true);
        const formData = new FormData();
        if (Object.keys(search).length) {
            formData.append("search", JSON.stringify(search));

        }
        formData.append("global_search", globalSearch);
        getCategories(formData).then(data => {
            setList(data && data.data && data.data.list ? data.data.list : []);
            setSortableCategories(data && data.data && data.data.list ? data.data.list : []);
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
        }).catch(error => {
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
            console.log("error ====> ", error);
        });
    }, [search, refreshList, globalSearch , langChanged]);

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };

    const prepareGlobalSearch = (value) => {
        setGlobalSearch(value);
    };
    const searchDebounce = useDebouncing(prepareGlobalSearch);

    const getCategoryDetail = (item) => {
        categoryDetail({ o_id: item._id }).then((response) => {
            if (response.success) {
                editForm.setValues({
                    _id: item._id,
                    name: response?.data?.name || "",
                    name_ja: response?.data?.name_ja || "",
                    tax_type: response?.data?.tax_type || "",
                    color: response?.data?.color || "",
                    short_description: response?.data?.short_description || "",
                    short_description_ja: response?.data?.short_description_ja || "",
                });
            } else {
                Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                });
            }
        }).catch((err) => {
            console.log(err)
        });
    };

    function handleSortCategories(closeModal = true) {
        sortCategories({ sorted_array: sortableCategories }).then((response) => {
            if (response.success) {
                Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                });
                if (closeModal) {
                    sortingCloseBtn?.current?.click();
                }
                setRefreshList(!refreshList);
            } else {
                Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                });
            }
        }).catch((error) => {
            console.error("Error updating profile", error);
        });
    }
    function isSortingChanged(){
        let changed = false;
        let i = 0;
        for (i; i < List.length; i++) {
            if (List[i]?.order !== sortableCategories[i]?.order) {
                changed = true;
                break;
            } else {
                changed = false;
            }
        };
        return changed;
    }
    const handleCloseSorting = () => {
        if (isSortingChanged()) {
            Swal.fire({
                title: t("question_to_save_data"),
                text: t("confirm_heading_found_unsaved_data_description"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#6c757d",
                cancelButtonColor: "#dc3545",
                confirmButtonText: t("btn_text_save_changes"),
                cancelButtonText: t("btn_text_discard"),
                customClass: {
                    popup: 'swal-restaurant',
                    confirmButton: 'btn btn-secondary col',
                    cancelButton: 'btn btn-danger col'
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    handleSortCategories(false);
                } else {
                    setSortableCategories(List);
                }
            });
        }
    };
    return (
        <main id='main-content'>
            <div className='inner-container'>
                <div className='page-header d-flex align-items-center'>
                    <h4 className='heading-title-text'>{t("label_categories")} ({List.length}) </h4>
                </div>
                <div className='card-outer-header d-flex justify-content-between align-items-center mb-4'>
                    <div className='search-box'>
                        <input type='text' className='form-control search-input' placeholder={t("input_placeholder_search")} onInput={(e) => {
                            searchDebounce(e.target.value);
                        }} />
                    </div>
                    <div className='card-head-group d-flex align-items-center gap-2'>
                        <div className="tabls-dowpdown">
                            <select className="form-control cp" onChange={(e) => { prepareSearch('tax_type', e.target.value) }}>
                                <option value="">{t("label_type")}</option>
                                {CATEGORIES_TAX_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                            </select>
                        </div>
                        <button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#sort-modal">{t("label_sort_category")}</button>
                        <button className='btn btn-primary' data-bs-toggle="offcanvas" data-bs-target="#add-new-category"><i className='ti ti-plus'></i> {t("label_create_category")}</button>
                    </div>
                </div>
                <div className='dashboard-content-wraper'>
                    <div className='category-list-wrap'>
                        {
                            loader ?
                                <GridLoader />
                                :
                                List.length ?
                                    <div className='category-list-grid'>
                                        {List.map((item, index) => {
                                            return (<div key={index} className={'category-box '+(`category_bg_${getColorType(item.color)}`)} style={{ background: item.color }}>
                                                <div className='category-name'>
                                                    <span>{getTranslatedKey('name',item)} </span>
                                                    <div className='category-name-action d-flex gap-2'>
                                                        <button className='btn-icon' onClick={() => { getCategoryDetail(item); }} data-bs-toggle="offcanvas" data-bs-target="#view-new-category">
                                                            <i className='ti ti-eye'></i>
                                                        </button>
                                                        <button className='btn-icon' onClick={() => { getCategoryDetail(item); }} data-bs-toggle="offcanvas" data-bs-target="#edit-new-category">
                                                            <i className='ti ti-edit'></i>
                                                        </button>
                                                        <button onClick={() => { deleteCategories(item) }} className='btn-icon' type='button'>
                                                            <i className='ti ti-trash'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className='category-item-info'>
                                                    <Link className='text-white' to={`/restaurant/menu/menu-item/list/${item?._id}`}>{item?.items_count} {t("label_items")}</Link>
                                                </div>
                                            </div>)
                                        })}
                                    </div>
                                    :
                                    <RecordNotFound heading="no_category_found_text" text="no_category_found_text_para" />
                        }
                    </div>
                </div>
            </div>
            {/* modal for edit a category  */}

            <div className="offcanvas offcanvas-end right-modal-w" id="edit-new-category" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">{t("label_edit_category")}</h5>
                    <button type="button" className="btn btn-icon ms-auto" ref={editCategoryCloseBtn} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { editForm.resetForm() }}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="offcanvas-body" onScroll={(e) => {
                    handleOffcanvasScroll(e, "edit-new-category");
                }}>
                    <form onSubmit={editForm.handleSubmit}>
                        <div className="mb-3">
                            <input type="hidden" name="_id" value={editForm.values._id} onChange={editForm.handleChange} />
                            <label className="form-label">{t("label_category_name")} (English)<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("category_name_placeholder")}
                                name="name"
                                value={editForm.values.name}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.name && editForm.errors.name ? (
                                <div className="text-danger">{t(editForm.errors.name)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_category_name")} (Japanese)<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("category_name_placeholder")}
                                name="name_ja"
                                value={editForm.values.name_ja}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.name_ja && editForm.errors.name_ja ? (
                                <div className="text-danger">{t(editForm.errors.name_ja)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_type_tax")}<span>*</span></label>
                            <select
                                className="form-control cp"
                                name="tax_type"
                                value={editForm.values.tax_type}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            >
                                <option value="" label={t("input_select_text")} />
                                {CATEGORIES_TAX_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                            </select>
                            {editForm.touched.tax_type && editForm.errors.tax_type ? (
                                <div className="text-danger">{t(editForm.errors.tax_type)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3 input-type-color">
                            <label className="form-label">{t("label_color")}<span>*</span></label>
                            <div className="input-type-color-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={editForm.values.color}
                                    onChange={editForm.handleChange}
                                    onBlur={editForm.handleBlur}
                                />
                                <input
                                    type="color"
                                    name="color"
                                    className='cp'
                                    value={editForm.values.color}
                                    onChange={editForm.handleChange}
                                    onBlur={editForm.handleBlur}
                                />
                            </div>
                            {editForm.touched.color && editForm.errors.color ? (
                                <div className="text-danger">{t(editForm.errors.color)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_short_description")}<span>*</span></label>
                            <textarea
                                className="form-control"
                                style={{ height: "120px" }}
                                name="short_description"
                                value={editForm.values.short_description}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.short_description && editForm.errors.short_description ? (
                                <div className="text-danger">{t(editForm.errors.short_description)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_short_description_ja")}<span>*</span></label>
                            <textarea
                                className="form-control"
                                style={{ height: "120px" }}
                                name="short_description_ja"
                                value={editForm.values.short_description_ja}
                                onChange={editForm.handleChange}
                                onBlur={editForm.handleBlur}
                            />
                            {editForm.touched.short_description_ja && editForm.errors.short_description_ja ? (
                                <div className="text-danger">{t(editForm.errors.short_description_ja)}</div>
                            ) : null}
                        </div>

                        <div className="button-form">
                            <button type="submit" className="btn btn-primary w-100">
                                {t("btn_update")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* modal for add a category  */}

            <div className="offcanvas offcanvas-end right-modal-w" id="add-new-category" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">{t("label_create_category")}</h5>
                    <button type="button" className="btn btn-icon ms-auto" ref={addCategoryCloseBtn} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => { addForm.resetForm() }}>
                        <i className="ti ti-x"></i>
                    </button>
                </div>
                <div className="offcanvas-body" onScroll={(e) => {
                    handleOffcanvasScroll(e, "add-new-category");
                }}>
                    <form onSubmit={addForm.handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label">{t("label_category_name")} (English)<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("category_name_placeholder")}
                                name="name"
                                value={addForm.values.name}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.name && addForm.errors.name ? (
                                <div className="text-danger">{t(addForm.errors.name)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_category_name")}(Japanese)<span>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={t("category_name_placeholder")}
                                name="name_ja"
                                value={addForm.values.name_ja}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.name_ja && addForm.errors.name_ja ? (
                                <div className="text-danger">{t(addForm.errors.name_ja)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_type_tax")}<span>*</span></label>
                            <select
                                className="form-control cp"
                                name="tax_type"
                                value={addForm.values.tax_type}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            >
                                <option value="" label={t("input_select_text")} />
                                {CATEGORIES_TAX_TYPES.map((item, index) => { return (<option key={index} value={item.value}>{t(item.label)}</option>) })}
                            </select>

                            <CustomError name="tax_type" className='text-danger' form={addForm} />
                        </div>

                        <div className="mb-3 input-type-color">
                            <label className="form-label">{t("label_color")}<span>*</span></label>
                            <div className="input-type-color-box">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="color"
                                    value={addForm.values.color}
                                    onChange={addForm.handleChange}
                                    onBlur={addForm.handleBlur}
                                />
                                <input
                                    type="color"
                                    name="color"
                                    className='cp'
                                    value={addForm.values.color}
                                    onChange={addForm.handleChange}
                                    onBlur={addForm.handleBlur}
                                />
                            </div>
                            {addForm.touched.color && addForm.errors.color ? (
                                <div className="text-danger">{t(addForm.errors.color)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_short_description")}<span>*</span></label>
                            <textarea
                                className="form-control"
                                style={{ height: "120px" }}
                                name="short_description"
                                value={addForm.values.short_description}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.short_description && addForm.errors.short_description ? (
                                <div className="text-danger">{t(addForm.errors.short_description)}</div>
                            ) : null}
                        </div>

                        <div className="mb-3">
                            <label className="form-label">{t("label_short_description_ja")}<span>*</span></label>
                            <textarea
                                className="form-control"
                                style={{ height: "120px" }}
                                name="short_description_ja"
                                value={addForm.values.short_description_ja}
                                onChange={addForm.handleChange}
                                onBlur={addForm.handleBlur}
                            />
                            {addForm.touched.short_description_ja && addForm.errors.short_description_ja ? (
                                <div className="text-danger">{t(addForm.errors.short_description_ja)}</div>
                            ) : null}
                        </div>

                        <div className="button-form">
                            <button type="submit" className="btn btn-primary w-100">
                                {t("btn_submit")}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* modal for sort category */}

            <div className="modal fade sort-modal" data-bs-backdrop="static" id="sort-modal" tabIndex="-1" aria-labelledby="sort-modalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">{t("label_categories")} </h1>
                            <button type="button" className="btn-close btn-icon" onClick={handleCloseSorting} data-bs-dismiss="modal" aria-label="Close">
                                <i className='ti ti-x'></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {
                                sortableCategories && sortableCategories.length ?
                                    <ReactSortable
                                        list={sortableCategories}
                                        setList={(newList) => setSortableCategories(newList)}
                                        ghostClass="dropArea"
                                        handle=".dragHandle"
                                        filter=".ignoreDrag"
                                        preventOnFilter={true}
                                        className="grid-drag-container"
                                    >
                                        <>
                                            {sortableCategories.map((record, i) => (
                                                <div className={'category-box dragHandle '+(`category_bg_${getColorType(record.color)}`)} key={i} style={{ background: record?.color }}>
                                                    <div className='category-name'>
                                                        <span>{getTranslatedKey('name',record)}</span>
                                                    </div>
                                                    <div className='category-item-info'>
                                                        <span>{record?.items_count} Items</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    </ReactSortable>
                                    : <RecordNotFound heading="no_category_found_text" text="no_category_found_text_para" />
                            }
                        </div>
                        <div className="modal-footer justify-content-center">
                            <button type="button" className="btn btn-danger me-2 d-none" data-bs-dismiss="modal" ref={sortingCloseBtn} aria-label="Close">Close</button>
                            <button type="button" className="btn btn-primary" disabled={!isSortingChanged()} onClick={handleSortCategories}>{t("btn_save_changes")}</button>
                        </div>
                    </div>
                </div>
            </div>


            {/* modal for view  */}

            <div className="offcanvas offcanvas-end right-modal-w" id="view-new-category" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">{t("view_category_label")}</h5>
                    <button type="button" className="btn btn-icon ms-auto" data-bs-dismiss="offcanvas" aria-label="Close" >
                        <i className="ti ti-x"></i>
                    </button>
                </div>

                <div className="offcanvas-body" onScroll={(e) => {
                    handleOffcanvasScroll(e, "view-new-category");
                }}>
                    <div className="view-info">
                        <div className="row gap-4">
                            <div className="col-md-12">
                                <h4>{t("label_category_name")} (English):</h4>
                                <p>{editForm.values.name}</p>
                            </div>
                            <div className="col-md-12">
                                <h4>{t("label_category_name")} (Japanese):</h4>
                                <p>{editForm.values.name_ja}</p>
                            </div>
                            {/* <div className="col-md-12">
                                <h4>{t("label_category_name")} (Japanese):</h4>
                                <p>{editForm.values.name_ja}</p>
                            </div> */}
                            <div className="col-md-12">
                                <h4>{t("label_type_tax")}:</h4>
                                <p className='text-capitalize'>{editForm.values.tax_type}</p>
                            </div>
                            <div className="col-md-12">
                                <h4>{t("color_heading")}:</h4>
                                <div className="d-flex">
                                    <span className={'badge rounded-circle d-inline-block me-2 align-items-center'} 
                                    style={{height:"20px", width:"20px", backgroundColor:editForm?.values?.color,
                                    border:"1px solid",
                                    borderColor:(getColorType(editForm?.values?.color) === "white" ? "#ccc" : editForm?.values?.color)}}></span>
                                    <p>{editForm.values.color}</p>
                                </div>
                                
                                
                            </div>
                            <div className="col-md-12">
                                <h4>{t("label_short_description")}:</h4>
                                <p>{editForm.values.short_description}</p>
                            </div>
                            <div className="col-md-12">
                                <h4>{t("label_short_description_ja")}:</h4>
                                <p>{editForm.values.short_description_ja}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
