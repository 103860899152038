import { PHONE_NO_LENGTH } from "./Constants";
import * as Yup from "yup";


export const PHONE_VALIDATION= Yup.string()
.test('phone_required', 'label_phone_number_required_error', (value) => {
        const phoneNumber = (value && value.length ? value.split(" ")[1]:"");
        return phoneNumber && phoneNumber.length;
    }
  )
.test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
        const phoneNumber = (value && value.length ? value.split(" ")[1]:"");
        return phoneNumber && /^\d+$/.test(phoneNumber);
    }
  )
.test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
        const phoneNumber = (value && value.length ? value.split(" ")[1]:"");
        return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
    }
  )
.test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
        const phoneNumber = (value && value.length ? value.split(" ")[1]:"");
        return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
    }
  );