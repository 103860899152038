import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/receipts";
const options = {role: ROLE.RESTAURANT};


export const getReceipt = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const editReceipt = async (values) => {
  return await axiosInstance(options).put(`${path}/edit`, values);
}

export const receiptDetail = async (slug) => {
return await axiosInstance(options).get(`${path}/details/?slug=${slug}`);
}

export const setDefaultReceipt = async (values) => {
  return await axiosInstance(options).patch(`${path}/set-default-receipt` , values);
  }

