import { Navigate, createBrowserRouter } from "react-router-dom";

/**ADMIN ROUTE START */
import AdminLayout from "./modules/admin/shared/adminlayout";
import Login from "./modules/admin/pages/login";
import PrivateRoute from "./modules/admin/shared/privateRoute";
import PublicRoute from "./modules/admin/shared/publicRoute";
import Profile from "./modules/admin/pages/profile";
import EditProfile from "./modules/admin/pages/editprofile";
import Changepassword from "./modules/admin/pages/changepassword";
import OtpVerificationPage from "./modules/admin/pages/otpverification";
import ForgotPwdPage from "./modules/admin/pages/forgotpassword";
import ResetPwdPage from "./modules/admin/pages/resetpwd";
import Error404 from "./modules/admin/pages/404";
import Dashboard from "./modules/admin/pages/dashboard";
import EmailTemplatesTable from "./modules/admin/pages/cms/automaticemailtemplates/list";
import EmailTempAdd from "./modules/admin/pages/cms/automaticemailtemplates/add";
import EmailTempEdit from "./modules/admin/pages/cms/automaticemailtemplates/edit";
import ViewEmailTemp from "./modules/admin/pages/cms/automaticemailtemplates/view";
import PageTable from "./modules/admin/pages/cms/pages/list";
import PageAdd from "./modules/admin/pages/cms/pages/add";
import PageEdit from "./modules/admin/pages/cms/pages/edit";
import PageView from "./modules/admin/pages/cms/pages/view";
import GlobalSettings from "./modules/admin/pages/cms/settings/settings";

import BannerTable from "./modules/admin/pages/cms/banners/list";
import BannerAdd from "./modules/admin/pages/cms/banners/add";
import BannerEdit from "./modules/admin/pages/cms/banners/edit";
import BannerView from "./modules/admin/pages/cms/banners/view";
import UsersTable from "./modules/admin/pages/usermgmt/list";
import UsersView from "./modules/admin/pages/usermgmt/view";
import UsersAdd from "./modules/admin/pages/usermgmt/add";
import UserEdit from "./modules/admin/pages/usermgmt/edit";




import TestimonialTable from "./modules/admin/pages/testimonial/list";
import TestimonialAdd from "./modules/admin/pages/testimonial/add";
import TestimonialView from "./modules/admin/pages/testimonial/view";
import TestimonialEdit from "./modules/admin/pages/testimonial/edit";
import EnrollRestaurant from "./modules/admin/pages/usermgmt/enroll";
// import Enquire from "./modules/admin/pages/usermgmt/enquire";
import InquiryTable from "./modules/admin/pages/inquiry/list";
import ViewInquiry from "./modules/admin/pages/inquiry/view";
import AdminUnAuthLayout from "./modules/admin/shared/adminUnAuthLayout";
import RestaurantView from "./modules/admin/pages/usermgmt/restaurantView";
import TransactionsTable from "./modules/admin/pages/usermgmt/transactionDetails";
import RenewEnrollPlan from "./modules/admin/pages/usermgmt/renewEnrollPlan";
import EditEnrollDetails from "./modules/admin/pages/usermgmt/editEnrollDetails";
import ViewEnrollDetails from "./modules/admin/pages/usermgmt/viewEnrollDetails";
import ViewTransaction from "./modules/admin/pages/usermgmt/viewTransaction";
import ViewEnrollments from "./modules/admin/pages/usermgmt/viewEnroll";
import RestaurantPublicRoute from "./modules/restaurant/shared/publicRoute";
import Verifyotp from "./modules/restaurant/pages/auth/verifyotp";



/** ----------------- restaurant --------------- */

import RestaurantAuthLayout from "./modules/restaurant/shared/restaurantAuthLayout";
import RestaurantPrivateRoute from "./modules/restaurant/shared/restaurantPrivateRoute";
import RestaurantLayout from "./modules/restaurant/shared/restaurantLayout";
import RestaurantLogin from "./modules/restaurant/pages/auth/login";
import RestaurantForgotPwdPage from "./modules/restaurant/pages/auth/forgot_password";
import RestaurantResetPassword from "./modules/restaurant/pages/auth/resetPassword";
import RestaurantMyProfileLayout from "./modules/restaurant/pages/profile/shared/restaurantMyProfileLayout";
import RestMyProfile from "./modules/restaurant/pages/profile/pages/RestMyProfile";
import RestaurantHomeLayout from "./modules/restaurant/pages/dashboard/shared/restauranHomeLayout";
import RestDashboard from "./modules/restaurant/pages/dashboard/pages/RestDashboard";
import ProfileChangePassword from "./modules/restaurant/pages/profile/pages/ProfileChangePassword";
import RestaurantProfile from "./modules/restaurant/pages/profile/pages/RestaurantProfile";
import ContractInformation from "./modules/restaurant/pages/profile/pages/ContractInformation";
import MailNotificationSettings from "./modules/restaurant/pages/profile/pages/MailNotificationSettings";
import RestaurantSalesLayout from "./modules/restaurant/pages/sales/shared/restaurantMyProfileLayout";
import SalesReportStatusByDay from "./modules/restaurant/pages/sales/pages/SalesReportStatusByDay";
import SalesReportStatusByMonth from "./modules/restaurant/pages/sales/pages/SalesReportStatusByMonth";
import SalesReportStatusByWeek from "./modules/restaurant/pages/sales/pages/SalesReportStatusByWeek";
import AbcAnalysis from "./modules/restaurant/pages/sales/pages/AbcAnalysis";
import AbcAnalysisDayBasis from "./modules/restaurant/pages/sales/pages/AbcAnalysisDayBasis";
import AbcAnalysisCategoryBasis from "./modules/restaurant/pages/sales/pages/AbcAnalysisCategoryBasis";
import SalesTransactionHistory from "./modules/restaurant/pages/sales/pages/SalesTransactionHistory";
import SalesSlipChanges from "./modules/restaurant/pages/sales/pages/SalesSlipChanges";
import RestaurantMenuLayout from "./modules/restaurant/pages/menu/shared/restaurantMenuLayout";
import CategoriesListing from "./modules/restaurant/pages/menu/pages/CategoriesListing";
import RestaurantTableManagementLayout from "./modules/restaurant/pages/tableManagement/shared/restaurantTableManagementLayout"
import Tables from "./modules/restaurant/pages/tableManagement/pages/Tables";
import RestaurantSettingsLayout from "./modules/restaurant/pages/settings/shared/restaurantSettingsLayout"
import Staff from "./modules/restaurant/pages/settings/pages/Staff";
import Coupon from "./modules/restaurant/pages/settings/pages/Coupon";


import WebsiteLayout from "./modules/website/shared/layout";
import WebsiteHomePage from "./modules/website/pages/homePage";
import WebsiteManualGuide from "./modules/website/pages/manualGuide/manualGuide";
import WebsitePricing from "./modules/website/pages/pricing";
import PrivacyPolicy from "./modules/website/pages/PrivacyPolicy";
import TermsAndCondition from "./modules/website/pages/TermsAndCondition";
import Faq from "./modules/website/pages/Faq";
import MenuItemListing from "./modules/restaurant/pages/menu/pages/MenuItemListing";
import OptionsListing from "./modules/restaurant/pages/menu/pages/OptionsListing";
import Reservations from "./modules/restaurant/pages/tableManagement/pages/Reversations";
import ReceiptAndInvoice from "./modules/restaurant/pages/settings/pages/ReceiptInvoice";
import DynamicPages from "./modules/website/pages/DynamicPage";
import Page404 from "./modules/website/pages/Page404";
import BulkImport from "./modules/restaurant/pages/settings/pages/bulkImport";
import Tax from "./modules/restaurant/pages/settings/pages/tax";
import ServiceCharge from "./modules/restaurant/pages/settings/pages/serviceCharge";
import CreditCardPayment from "./modules/restaurant/pages/settings/pages/creditCardPayment";
import OtherPaymentMethod from "./modules/restaurant/pages/settings/pages/otherPaymentMethod";
import DownloadReport from "./modules/restaurant/pages/settings/pages/downloadReport";
import GlobalLayout from "./modules/GlobalLayout";
import Support from "./modules/website/pages/support";
import CashierPrivetRoute from "./modules/cashier/shared/CashierPrivetRoute";
import CashierLayout from "./modules/cashier/shared/CashierLayout";
import CashierDashboard from "./modules/cashier/pages/dashboard/pages/CashierDashboard";
import CashierAuthLayout from "./modules/cashier/shared/CashierAuthLayout";
import CashierLogin from "./modules/cashier/pages/auth/login";
import CashierPublicRoute from "./modules/cashier/shared/CashierPublicRoute";
import CashierCategory from "./modules/cashier/pages/dashboard/pages/CashierCategory";
import CashierMenu from "./modules/cashier/pages/dashboard/pages/CashierMenu";
import OpenCashRegister from "./modules/cashier/pages/OpenCashRegister";
import PaymentViaCash from "./modules/cashier/pages/PaymentViaCash";
import PaymentSuccess from "./modules/cashier/pages/PaymentSuccess";
import PaymentSuccessInfo from "./modules/cashier/pages/PaymentSuccessInfo";
import CashierSettingsLayout from "./modules/cashier/pages/settings/shared/cashierSettingsLayout"
import Sales from "./modules/cashier/pages/settings/pages/sales";
import PenominationDetails from "./modules/cashier/pages/PenominationDetails";
import TodaySales from "./modules/cashier/pages/TodaySales";
import QrOrderLayout from "./modules/qrOrder/shared/qrLayout";
import AddMenu from "./modules/qrOrder/pages/addMenu";
import ReviewOrder from "./modules/qrOrder/pages/reviewOrder";
import PlaceOrder from "./modules/qrOrder/pages/placeOrder";
import OrderSuccess from "./modules/qrOrder/pages/orderSuccess";
import CashierHomeLayout from "./modules/cashier/pages/dashboard/shared/CashierHomeLayout";


const router = createBrowserRouter([

  {
    path: "/", element: <GlobalLayout />,
    children: [

      {
        path: "/",
        element: <WebsiteLayout />,
        children: [
          { path: "", element: <WebsiteHomePage /> },
          { path: "/manual-guide", element: <WebsiteManualGuide /> },
          { path: "/pricing", element: <WebsitePricing /> },
          { path: "/privacy-policy", element: <PrivacyPolicy /> },
          { path: "/terms-and-condition", element: <TermsAndCondition /> },
          { path: "/faq", element: <Faq /> },
          { path: "/org-info/:slug", element: <DynamicPages /> },
          { path: "/support/:support_type", element: <Support /> }
        ],
      },
    
      /**ADMIN ROUTE START */
      {
        path: "admin",
        element: <PrivateRoute component={AdminLayout} />,
        children: [
          {
            path: "",
            element: <Navigate to={"/admin/dashboard"} />,
          },
          {
            path: "dashboard",
            element: <PrivateRoute component={Dashboard} />,
          },
          {
            path: "profile",
            // element: <Profile />,
            element: <PrivateRoute component={Profile} />,
            children: [
              {
                path: "edit/:id",
                // element: <EditProfile />,
                element: <PrivateRoute component={EditProfile} />,
              },
              {
                path: "change-password/:id",
                // element: <Changepassword />,
                element: <PrivateRoute component={Changepassword} />,
              },
            ],
          },
    
          {
            /**restaurant management route start */
          },
          {
            path: "restaurant-management/restaurant/list/:pgno",
            // element: <UsersTable />,
            element: <PrivateRoute component={UsersTable} />,
          },
          {
            path: "restaurant-management/restaurant/list/:id/enroll-restaurant/renew-plan",
            element: <PrivateRoute component={RenewEnrollPlan} />,
          },
          {
            path: "restaurant-management/restaurant/view/",
            element: <PrivateRoute component={RestaurantView} />,
    
            children: [
              {
                path: ":id/generals",
                // element: <UsersView />
                element: <PrivateRoute component={UsersView} />,
              },
              {
                path: ":id/enroll-restaurant/details/:pgno",
                // element: <ViewEnrollments />
                element: <PrivateRoute component={ViewEnrollments} />,
              },
              {
                path: ":id/enroll-restaurant/renew-plan",
                element: <PrivateRoute component={RenewEnrollPlan} />,
              },
              {
                path: ":id/enroll-restaurant/edit",
                element: <PrivateRoute component={EditEnrollDetails} />,
              },
              {
                path: ":id/enroll-restaurant/view",
                element: <PrivateRoute component={ViewEnrollDetails} />,
              },
              {
                path: ":id/transition-details/:pgno",
                element: <PrivateRoute component={TransactionsTable} />,
              },
              {
                path: ":id/transition-details/view",
                element: <PrivateRoute component={ViewTransaction} />,
              },
            ],
          },
    
          {
            path: "restaurant-management/restaurant/add",
            element: <PrivateRoute component={UsersAdd} />,
          },
          {
            path: "restaurant-management/restaurant/:pgno/edit/:id",
            element: <PrivateRoute component={UserEdit} />,
          },
          {
            path: "/admin/restaurant-management/restaurant/enroll-restaurant/:userId",
            element: <PrivateRoute component={EnrollRestaurant} />,
          },
          {
            /**restaurant management route end */
          },
    
          {
            /**testimonial management route start */
          },
          {
            path: "testimonial-management/testimonial/list/:pgno",
            element: <TestimonialTable />,
          },
          {
            path: "testimonial-management/testimonial/view/:id",
            element: <TestimonialView />,
          },
          {
            path: "testimonial-management/testimonial/add",
            element: <TestimonialAdd />,
          },
          {
            path: "testimonial-management/testimonial/:pgno/edit/:id",
            element: <TestimonialEdit />,
          },
          {
            /**testimonial management route end */
          },
    
          {
            /**inquiries route start */
          },
          {
            path: "inquiries/list/:pgno",
            element: <PrivateRoute component={InquiryTable} />,
          },
          {
            path: "inquiries/view/:id",
            element: <PrivateRoute component={ViewInquiry} />,
          },
          {
            /**testimonial management route end */
          },
    
          {
            path: "cms/pages/list/:pgno",
            element: <PrivateRoute component={PageTable} />,
          },
          {
            path: "cms/pages/add",
            element: <PrivateRoute component={PageAdd} />,
          },
          {
            path: "cms/pages/:pgno/edit/:id",
            element: <PrivateRoute component={PageEdit} />,
          },
          {
            path: "cms/pages/view/:id",
            element: <PrivateRoute component={PageView} />,
          },
    
          {
            path: "cms/banner/list/:pgno",
            element: <PrivateRoute component={BannerTable} />,
          },
          {
            path: "cms/banner/add",
            element: <PrivateRoute component={BannerAdd} />,
          },
          {
            path: "cms/banner/:pgno/edit/:id",
            element: <PrivateRoute component={BannerEdit} />,
          },
          {
            path: "cms/banner/view/:id",
            element: <PrivateRoute component={BannerView} />,
          },
    
          {
            path: "cms/settings",
            element: <PrivateRoute component={GlobalSettings} />,
          },
    
          {
            path: "cms/default-email-template/list/:pgno",
            element: <PrivateRoute component={EmailTemplatesTable} />,
          },
          {
            path: "cms/default-email-template/add",
            element: <PrivateRoute component={EmailTempAdd} />,
          },
          {
            path: "cms/default-email-template/:pgno/edit/:id",
            element: <PrivateRoute component={EmailTempEdit} />,
          },
          {
            path: "cms/default-email-template/view/:id",
            element: <PrivateRoute component={ViewEmailTemp} />,
          },
    
          /** Email Logs Routing End*/
        ],
      },
      {
        path: "admin",
        element: <AdminUnAuthLayout />,
        children: [
          { path: "login", element: <PublicRoute component={Login} /> },
          {
            path: "forget-password",
            element: <PublicRoute component={ForgotPwdPage} />,
          },
          {
            path: "otp-verification/:email",
            element: <PublicRoute component={OtpVerificationPage} />,
          },
          {
            path: "reset-password",
            // element: localStorage.getItem("validate_string") ? <PrivateRoute component={ResetPwdPage} /> : <Navigate to="/admin/login" />
            element: <PublicRoute component={ResetPwdPage} />,
          },
        ],
      },
    
      { path: "admin/*", element: <Error404 /> },
      /**ADMIN ROUTE END */
    
      /** RESTAURANT ROUTE START */
      {
        path: "restaurant",
        element: <RestaurantPrivateRoute component={RestaurantLayout} />,
        children: [
          {
            path: "",
            element: <Navigate to={"/restaurant/dashboard"} />,
          },
          {
            path: "dashboard",
            element: <RestaurantPrivateRoute component={RestaurantHomeLayout} />,
            children: [
              {
                path: "",
                element: <RestaurantPrivateRoute component={RestDashboard} />,
              },
            ],
          },
          {
            path: "profile",
            element: (
              <RestaurantPrivateRoute component={RestaurantMyProfileLayout} />
            ),
            children: [
              {
                path: "",
                element: <RestaurantPrivateRoute component={RestMyProfile} />,
              },
              {
                path: "change-password",
                element: (
                  <RestaurantPrivateRoute component={ProfileChangePassword} />
                ),
              },
              {
                path: "restaurant-profile",
                element: <RestaurantPrivateRoute component={RestaurantProfile} />,
              },
              {
                path: "contract-information",
                element: <RestaurantPrivateRoute component={ContractInformation} /> 
              },
              {
                path: "mail-notification-settings/:page",
                element: <RestaurantPrivateRoute component={MailNotificationSettings} /> 
              },
              { path: "*", element: <Page404 homePath="/restaurant/profile" /> },
            ],
          },
          {
            path: "sales",
            element: <RestaurantPrivateRoute component={RestaurantSalesLayout} />,
            children:[
              {
                path: "",
                element: <RestaurantPrivateRoute component={SalesReportStatusByDay} /> 
              },
              {
                path: "sales-report-status-month",
                element: <RestaurantPrivateRoute component={SalesReportStatusByMonth} /> 
              },
              {
                path: "sales-report-status-week",
                element: <RestaurantPrivateRoute component={SalesReportStatusByWeek} /> 
              },
              {
                path: "abc-analysis",
                element: <RestaurantPrivateRoute component={AbcAnalysis} /> 
              },
              {
                path: "abc-analysis-category-basis",
                element: <RestaurantPrivateRoute component={AbcAnalysisCategoryBasis} /> 
              },
              {
                path: "abc-analysis-day-basis",
                element: <RestaurantPrivateRoute component={AbcAnalysisDayBasis} /> 
              },
              {
                path: "sales-transaction-history",
                element: <RestaurantPrivateRoute component={SalesTransactionHistory} /> 
              },
              {
                path: "sales-slip-changes",
                element: <RestaurantPrivateRoute component={SalesSlipChanges} /> 
              },
              { path: "*", element: <Page404 homePath="/restaurant/sales" /> },
            ],
          },
          {
            path: "menu",
            element: <RestaurantPrivateRoute component={RestaurantMenuLayout} />,
            children:[
              {
                path: "",
                element: <CategoriesListing />,
              },
              {
                path:"menu-item/list/:id",
                element: <MenuItemListing />,
              },
              {
                path:"menu-item/list",
                element: <MenuItemListing />,
              },
              {
                path:"options/list/:active_option?",
                element: <OptionsListing />,
              },
              { path: "*", element: <Page404 homePath="/restaurant/menu" /> },
            ],
          },
          {
            path: "table-management",
            element: <RestaurantTableManagementLayout />,
            children: [
              {
                path: "table/:page",
                element: <Tables />,
              },
              {
                path: "reservations/list/:page",
                element: <Reservations />,
              },
              { path: "*", element: <Page404 homePath="/restaurant/table-management" /> },
            ],
          },
          {
            path: "settings",
            element: <RestaurantSettingsLayout />,
            children: [
              {
                path: "staff/:page",
                element: <Staff />,
              },
              {
                path: "staff-attendance/:page",
                element: <Staff />,
              },
              {
                path: "coupon",
                element: <Coupon />,
              },
              {
                path: "receipt-invoice",
                element: <ReceiptAndInvoice />,
              },
              {
                path: "bulk-import",
                element: <BulkImport />,
              },
              {
                path: "tax",
                element: <Tax />,
              },
              {
                path: "service-charges",
                element: <ServiceCharge />,
              },
              {
                path: "credit-card-payment",
                element: <CreditCardPayment/>,
              },
              {
                path: "other-payment",
                element: <OtherPaymentMethod />,
              },
              {
                path: "download-report",
                element: <DownloadReport />,
              },
              { path: "*", element: <Page404 homePath="/restaurant/settings" /> },
            ],
          },
          { path: "*", element: <Page404 homePath="/restaurant/dashboard" /> },
        ],
      },
      /** RESTAURANT ROUTE END */
    
      /** RESTAURANT AUTH ROUTE START */
      {
        path: "restaurant",
        element: <RestaurantPublicRoute component={RestaurantAuthLayout} />,
        children: [
          {
            path: "",
            element: <Navigate to={"/restaurant/dashboard"} />
          },
          {
            path: "login",
            element: <RestaurantPublicRoute component={RestaurantLogin} />,
          },
          {
            path: "forget-password",
            element: <RestaurantPublicRoute component={RestaurantForgotPwdPage} />,
          },
          {
            path: "reset-password",
            element: <RestaurantPublicRoute component={RestaurantResetPassword} />,
          },
          {
            path: "otp-verification",
            element: <RestaurantPublicRoute component={Verifyotp} />,
          },
          { path: "*", element: <Page404 homePath="/restaurant" /> },
        ],
      },

      /** CASHIER ROUTE START */
      {
        path: "cashier",
        element: <CashierPrivetRoute component={CashierLayout} />,
        children: [
          {
            path: "",
            element: <Navigate to={"/cashier/dashboard"} />,
          },
          {
            path: "dashboard",
            element: <CashierHomeLayout />,
            children:[
              {
                path: "",
                element: <CashierPrivetRoute component={CashierDashboard} />
              },
              {
                path: ":tableId",
                element: <CashierPrivetRoute component={CashierDashboard} />
              },
              {
                path: "categories/:tableId",
                element: <CashierPrivetRoute component={CashierCategory} />
              },
              {
                path: "menu/:tableId/:category",
                element: <CashierPrivetRoute component={CashierMenu} />
              },
            ]
          },
          // {
          //   path: "dashboard",
          //   element: <CashierPrivetRoute component={CashierDashboard} />
          // },
          {
            path: "open-cash-register",
            element: <CashierPrivetRoute component={OpenCashRegister} />
          },
          {
            path: "penomination-details",
            element: <CashierPrivetRoute component={PenominationDetails} />
          },
          
         
          {
            path: "payment-via-cash/:tableId",
            element: <CashierPrivetRoute component={PaymentViaCash} />
          },
          {
            path: "payment-success/:orderId",
            element: <CashierPrivetRoute component={PaymentSuccess} />
          },
          {
            path: "payment-success-info",
            element: <CashierPrivetRoute component={PaymentSuccessInfo} />
          },
          {
            path: "settings",
            element: <CashierSettingsLayout />,
            children: [
              {
                path: "",
                element: <Sales />,
              },
              
              { path: "*", element: <Page404 homePath="/cashier/settings" /> },
            ],
          },
          {
            path: "today-sales",
            element: <CashierPrivetRoute component={TodaySales} />
          },
  
          { path: "*", element: <Page404 homePath="/cashier/dashboard" /> },
        ],
      },
      /** CASHIER ROUTE END */
    
      /** CASHIER AUTH ROUTE START */
      {
        path: "cashier",
        element: <CashierPublicRoute component={CashierAuthLayout} />,
        children: [
          {
            path: "",
            element: <Navigate to={"/cashier/dashboard"} />
          },
          {
            path: "login",
            element: <CashierPublicRoute component={CashierLogin} />,
          },
          { path: "*", element: <Page404 homePath="/cashier" /> },
        ],
      },
      {
        path: "qr-order",
        element: <QrOrderLayout />,
        children: [
          { path: "", element: <AddMenu /> },
          { path: "review", element: <ReviewOrder /> },
          { path: "place", element: <PlaceOrder /> },
          { path: "success", element: <OrderSuccess /> },
        ],
      },
      /** CASHIER AUTH ROUTE END */
      { path: "*", element: <Page404 homePath="/" /> },
    ],
    
  },

  
  /** 404 page for website >>>>  */
  {
    path: "/",
    element: <WebsiteLayout />,
    children: [
      { path: "*", element: <Page404 /> },
    ],
  },
  /** 404 page for website <<<<  */
]);

export default router;
