import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../common/custompagination";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";

import {
    
    formateDateWithMonth,
    removeSpecialCharacter,
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import { RestTransactionList } from "../../services/restaurant.services"

const TransactionsTable = () => {
    // -------

    const navigate = useNavigate();
    const { t } = useTranslation();

    const params = useParams()


    const location = useLocation();
    const [list, setList] = useState([]);
    const [datalength, setDataLength] = useState("");
    const [itemperpage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);


    useEffect(() => {
        const getData = setTimeout(() => {
            if (search) {
                setLoader(true);
                const formData = new FormData();
                formData.append("restaurant_id", params.id);
                formData.append("page", params.pgno);
                formData.append("per_page", itemperpage);
                formData.append("sort", JSON.stringify(sorting));
                formData.append("search", JSON.stringify(search));
                formData.append("global_search", globalsearch);
                RestTransactionList(formData)
                    .then((data) => {
                        setDataLength(data.data.total_records);
                        // setSerialNo(data.data.offset);
                        setList(data && data.data && data.data.list ? data.data.list : []);
                        // console.log("data----->", data.data.list);

                        setPage(data && data.data && data.data.page ? data.data.page : 1);
                        setLoader(false);
                    })
                    .catch((error) => {
                        console.log("error ====> ", error);
                    });
            }
        }, 300);
        return () => clearTimeout(getData)
    }, [
        location,
        sorting,
        search,
        globalsearch,
        itemperpage,
        params.pgno,
    ]);

    const viewfunction = (row) => {
        navigate(
            `/admin/restaurant-management/restaurant/view/${row.restaurant_id}/transition-details/view?id=${row._id}`
        );
    };

    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    };
    // sorting end
    
    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };

    const resetFilter = (e) => {
        e.preventDefault();
        setGlobalSearch("");
        prepareSearch();
        setSearch({});
        setResetDate(!resetdate);
    };

    return (
        <>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card"></div>


                    <div className="">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h6 className="main-content-label">
                                {t("title_transactions")}
                            </h6>
                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <CustomRangepicker
                                        GetDateRange={(e) => {
                                            prepareSearch("createdAt", e);
                                        }}
                                        resetdate={resetdate}
                                    />
                                </div>

                                <button
                                    type="reset"
                                    value="Reset"
                                    onClick={resetFilter}
                                    className="btn btn-warning float-right mr-2"
                                >
                                    {t("btn_reset_filter")}
                                </button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className="position-relative select_head">
                                            <span>{t("list_heading_sno")}</span>
                                        </th>

                                        <th className="position-relative select_head">
                                            <span>Enrollment ID</span>
                                        </th>

                                        <th>
                                            <div className="sorting_column">
                                                <span>{t("label_company_name")}</span>
                                                <Sorting
                                                    sort={sorting}
                                                    handleSort={handleSort}
                                                    column="company_name"
                                                />
                                            </div>
                                        </th>
                                        <th>
                                            <div className="sorting_column">
                                                <span>{t("label_representative_name")}</span>
                                                <Sorting
                                                    sort={sorting}
                                                    handleSort={handleSort}
                                                    column="representative_name"
                                                />
                                            </div>
                                        </th>
                                        <th className="position-relative select_head">
                                            <span>{t("label_plan_name")}</span>
                                        </th>
                                        <th className="position-relative select_head">
                                            <span>{t("contract_plan_duration_type")}</span>
                                        </th>
                                        <th className="position-relative select_head">
                                            <span>{t("title_amount")}</span>
                                        </th>

                                        {/* <th className="status_head">SUBSCRIBE</th> */}
                                        <th className="created_head">
                                            <div className="sorting_column">
                                                <span>Transaction date</span>
                                                <Sorting
                                                    sort={sorting}
                                                    handleSort={handleSort}
                                                    defaultSorting={defaultSorting}
                                                    column="createdAt"
                                                />
                                            </div>
                                        </th>
                                        {/* <th>Created By</th>
                                            <th>Updated By</th> */}
                                        <th className="action_head">{t("list_heading_action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ? (
                                        <tr>
                                            <td colSpan={10}>
                                                <Loader />
                                            </td>
                                        </tr>
                                    ) : (
                                        <>
                                            {list.length ? (
                                                list.map((row, i) => {
                                                    return (
                                                        <tr
                                                            key={i}
                                                        >
                                                            <td>
                                                                {row ? ((page - 1) * itemperpage) + i + 1 : null}
                                                            </td>

                                                            <td>
                                                                {row?.enrollment_id ? (
                                                                    <div className="d-flex">
                                                                        {row?.enrollment_id ? row.enrollment_id : "N/A"}
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex">N/A</div>
                                                                )}
                                                            </td>
                                                            <td>
                                                                {row?.restaurent_info ? (
                                                                    <div className="d-flex">
                                                                        {row?.restaurent_info[0]?.company_name ? row.restaurent_info[0].company_name : "N/A"}
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex">N/A</div>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {row?.restaurent_info ? (
                                                                    <div className="d-flex">
                                                                        {row?.restaurent_info[0]?.company_name ? row.restaurent_info[0].representative_name : "N/A"}
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex">N/A</div>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {row?.restaurent_info ? (
                                                                    <div className="d-flex">
                                                                        {row?.restaurent_info[0]?.plan_name ? removeSpecialCharacter(row.restaurent_info[0].plan_name)  : "N/A"}
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex">N/A</div>
                                                                )}
                                                            </td>

                                                            <td>
                                                                {row?.restaurent_info ? (
                                                                    <div className="d-flex">
                                                                        {row?.restaurent_info[0]?.contract_plan_duration_type ? row.restaurent_info[0].contract_plan_duration_type : "N/A"}
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex">N/A</div>
                                                                )}
                                                            </td>


                                                            <td>
                                                                {row?.amount ? (
                                                                    <div className="d-flex">
                                                                        ¥ {row?.amount ? row.amount : "N/A"}
                                                                    </div>
                                                                ) : (
                                                                    <div className="d-flex">N/A</div>
                                                                )}
                                                            </td>


                                                            <td>
                                                                {row.createdAt
                                                                    ? formateDateWithMonth(row.createdAt)
                                                                    : "N/A"}
                                                            </td>

                                                            <td>
                                                                <div className="d-flex">
                                                                    <button
                                                                        className="btn ripple btn-main-primary signbtn"
                                                                        onClick={() => {
                                                                            viewfunction(row);
                                                                        }}
                                                                    >
                                                                        {t("btn_view")}
                                                                    </button>

                                                                    
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan={10} className="text-center">
                                                        {t("msg_no_records")}
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div
                            className=""
                            id="example1_info"
                            role="status"
                            aria-live="polite"
                        >
                            <b>
                                {t("msg_total_records")} : {datalength ? datalength : "0"}
                            </b>
                        </div>
                        {datalength && datalength > 0 ? (
                            <CustomPagination
                                datalength={datalength}
                                itemperpage={itemperpage}
                                currentPage={page}
                                setPage={setPage}
                                pageRoute={[
                                    {
                                        name: "Reataurant",
                                        path: `/admin/restaurant-management/restaurant/view/${params?.id}/transition-details`,
                                    },
                                ]}
                            />
                        ) : (
                            ""
                        )}
                    </div>

                    <div className="mt-5 back-button mx-0">
                        <button
                            className="btn ripple btn-dark"
                            type="button"
                            onClick={() =>
                                navigate("/admin/restaurant-management/restaurant/list/1")
                            }
                        >
                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                            {t("btn_back")}
                        </button>
                    </div>
                </div>
            </div>


        </>
    );
};

export default TransactionsTable;
