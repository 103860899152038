import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import { useDispatch, useSelector } from "react-redux";
import GlobalLoader from "./globalLoader";
import { setUserType } from "../../../redux/slices/userType";
import { GlobalSettingsDetails } from "../../website/services/website.services";
import { addData } from "../../../redux/slices/globalDetails";

const RestaurantAuthLayout = () => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const navigate = useNavigate();
    window.navigate_ = navigate;
    useEffect(()=>{
        const body = document.querySelector('body');
        if(body){
            body.classList.remove('restaurant_inner_body');
        }
    },[]);
    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            import("../../../assets/restaurant/css/style.css"),
            // import("rsuite/dist/rsuite.min.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);

    return (
        <>
            {loaderState ? (
                <GlobalLoader />
            ) : (
                <div className="page">
                    <Outlet />
                </div>
            )}
        </>
    );
};
export default RestaurantAuthLayout;
