import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'; 
import { logOutRestaurant} from '../../../../../utils/commonfunction';




export default function Sidebar() {

  const {t} = useTranslation();

  return (
    <div className='sidebar'>
      <div className='sidebar-menu'>
        <ul>
          <li>
            <NavLink end to={'/restaurant/profile'}>
              <i className='ti ti-user'></i>
              <span>{t("link_my_profile")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/profile/restaurant-profile'}>
              <i className='ti ti-building-store'></i>
              <span>{t("link_restaurant_profile")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink end to={'/restaurant/profile/contract-information'}>
              <i className='ti ti-notes'></i>
              <span>{t("link_contract_information")}</span>
            </NavLink>
          </li>
          
          <li>
            <NavLink to={'/restaurant/profile/mail-notification-settings/1'}>
              <i className='ti ti-mail-opened'></i>
              <span>{t("mail_notification_setting")}</span>
           </NavLink>
          </li>
          <li onClick={()=>{logOutRestaurant()}}>
            <a href='/' onClick={(e)=> e.preventDefault()}>
              <i className='ti ti-logout'></i>
              <span>{t("label_logout")}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
