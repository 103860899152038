import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import { SWAL_SETTINGS, PHONE_NO_LENGTH, INPUT_MAX_25, TEXTAREA_MAX_LENGTH } from "../../../../utils/Constants";
import {
  handleServerValidations,
  DT,
  globalLoader
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../../../utils/customError";
import { useTranslation } from "react-i18next";
import { Details, Edit } from "../../services/usermgmt.services";
import { cuisineOptions } from "../../../../utils/Constants";
import { TagPicker, Tooltip, Whisper } from "rsuite";
import PhoneInput from "../../../../utils/PhoneInput";
import { PHONE_VALIDATION } from "../../../../utils/commonValidations";


const UserEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const [showdefault, setShowDefault] = useState({});
  const [previewimage, setPreviewImage] = useState("");
  // const [previewimage, setPreviewImage] = useState("");
  // const [previewResImage, setPreviewResImage] = useState([]);
  const [saveType, setSaveType] = useState("");
  const breadcrumbs = [
    { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
    {
      title: t("sidebar_link_restaurant"),
      url: "/admin/restaurant-management/restaurant/list/1",
    },
    { title: t("btn_edit"), url: "" },
  ];

  useEffect(() => {
    Details(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setPreviewImage(response.data.restaurant_logo);
        // setPreviewResImage(response.data.images);
        // console.log("response showDefault edit---",response.data);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);




  // function getImageUrl(image) {
  //   if (typeof image === "string") {
  //     return image;
  //   } else {
  //     return URL.createObjectURL(image);
  //   }
  // }

  // function handleDeleteImage(ind, image) {
  //   let images = [...formik.values.images];
  //   images.splice(ind, 1);
  //   formik.setFieldValue("images", images);
  //   if (typeof image === "string") {
  //     let dFiles = [...deletedFiles];
  //     dFiles.push(image);
  //     setDeletedFiles(dFiles);
  //   }
  // } 

  const validationSchema = Yup.object().shape({
    // name: Yup.string().trim().max(INPUT_LENGTH_15,DT(t("validation_max_input_characters"), [INPUT_LENGTH_15])).required(t("label_name_error")),
    restaurant_name: Yup.string().trim().max(100,"max_length_error").required(("label_restaurant_name_error")),
    email: Yup.string()
      .trim()
      .email(("label_email_invalid_format_error"))
      .test('no-uppercase', ("label_email_uppercase_error"), function (value) {
        if (value) {
          return !/[A-Z]/.test(value);
        }
        return true;
      })
      .required(("label_email_required_error")),
    phone_number: PHONE_VALIDATION,
    address: Yup.string().max(TEXTAREA_MAX_LENGTH,"validation_max_input_characters").trim().required(("label_location_error")),
    restaurant_logo: Yup.mixed()
    .nullable()
    .test(
      'fileOrUrl',
      'supported_file_error',
      value => {
        if (!value) return true; 
        if (typeof value === 'string') return /^(http|https):\/\/[^\s$.?#].[^\s]*$/gm.test(value);
        return ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type);
      }
    )
    .test(
      'fileSize',
      'image_max_size_error',
      value => {
        if (!value || typeof value === 'string') return true;
        return value.size <= 1.5 * 1024 * 1024;
      }
    ),
    cuisine_type:Yup.array().min(1, "At least one cuisine type is required").required("label_cuisine_type_error")
,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // name:  showdefault && showdefault.name ? showdefault.name : "",
      restaurant_name: showdefault && showdefault.restaurant_name ? showdefault.restaurant_name : "",
      email: showdefault && showdefault.email ? showdefault.email : "",
      phone_number: showdefault && showdefault?.phone_number ? showdefault?.phone_number : "",
      address: showdefault && showdefault.address ? showdefault.address : "",
      restaurant_logo: showdefault && showdefault.restaurant_logo ? showdefault.restaurant_logo : "",
      cuisine_type: showdefault && showdefault.cuisine_type && Array.isArray(showdefault.cuisine_type) ? showdefault.cuisine_type : [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      globalLoader(true);
      let formData = new FormData();
      formData.append("o_id", params.id);
      // formData.append("name", values.name);
      formData.append("restaurant_name", values.restaurant_name);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone_number);
      formData.append("address", values.address);
      formData.append("cuisine_type", JSON.stringify(values.cuisine_type));
      formData.append("restaurant_logo", values.restaurant_logo);

      // formData.append("deletedFiles", JSON.stringify(deletedFiles));
      // if (values.images && values.images.length) {
      //   values.images.forEach((file) => {
      //     if (typeof file === "object") {
      //       formData.append("images", file);
      //     }
      //   });
      // }
      // if (values.phone_number && values.dialCodePhone) {
      //   formData.append(
      //     "phone_number",
      //     JSON.stringify({
      //       code: values.dialCodePhone,
      //       number: values.phone_number,
      //       countryCode: values.countryCodePhone,
      //     })
      //   );
      // }
console.log("formikformik",formik.values, formik.errors);
      Edit(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            globalLoader(false);
            if (saveType !== "Save") {
              navigate(`/admin/restaurant-management/restaurant/list/1`);
            }
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
          globalLoader(false);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
          console.log("error ====> ", error);
        });
    },
  });
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="col-lg-12 col-md-12 animation_fade">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h6 className="main-content-label mb-3">
                    {t("edit_restaurants")}
                  </h6>
                </div>
                <div className="row row-sm">

                  <div className="col-md-6 text-center form-group">
                    <label htmlFor="restaurant_name" className="text-left d-flex">
                      {t("label_restaurants_name")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      name="restaurant_name"
                      id="restaurant_name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.restaurant_name}
                      className="form-control"
                      placeholder={t("web_inquiry_form_placeholder_restaurant_name")}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError shortCodes={[INPUT_MAX_25]} name="restaurant_name" form={formik} />
                    </span>
                  </div>
                  <div className="col-lg-6 text-center form-group">
                    <label htmlFor="email" className="text-left d-flex">
                      {t("label_email")}:<span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="form-control"
                      placeholder={t("placeholder_email")}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError name="email" form={formik} />
                    </span>
                  </div>
                  <div className="col-md-6 text-center form-group">
                    <label htmlFor="address" className="text-left d-flex">
                      {t("label_address")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      name="address"
                      id="address"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className="form-control"
                      placeholder={t("web_inquiry_form_placeholder_address")}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError shortCodes={[TEXTAREA_MAX_LENGTH]} name="address" form={formik} />
                    </span>
                  </div>
                  <div className="col-md-6 text-center form-group">
                    <label className="text-left d-flex" htmlFor="phone_number">
                      {t("label_phone_number")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <PhoneInput
                      name="phone_number"
                      id="phone_number"
                      placeholder={t('phone_number_placeholder')}
                      onChange={(e) => { formik.setFieldValue('phone_number', e?.phone_number) }}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_number}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError name="phone_number" form={formik} shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                    </span>
                  </div>
                  

                  <div className="col-md-6  form-group">
                    <label htmlFor="cuisine_type" className="text-left d-flex">
                      {t("label_cuisine_type")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <div className="">
                    <TagPicker
                    className="cp"
                      data={cuisineOptions}
                      value={formik?.values?.cuisine_type}
                      onChange={(selectedValues) => {
                        formik.setFieldValue("cuisine_type", selectedValues);
                      }}
                      renderValue={(value, items) => {
                        const visibleOptions = 6;
                        const displayItems = items.slice(0, visibleOptions); // Show only the first 2 items
                        const hiddenCount = items.length - displayItems.length;
                        const removeTag = (i)=>{
                          let allValues = [...formik?.values?.cuisine_type];
                          allValues.splice(i,1);
                          formik.setFieldValue("cuisine_type", allValues);
                        };
                        return <>
                          {displayItems.map((item,i) => {
                            return (
                              <span className="rs-tag-text" key={i}>
                                <div role="option" title="Indian" className="rs-tag rs-tag-md rs-tag-default rs-tag-closable">
                                  <span className="rs-tag-text">{item?.label}</span>
                                  <button type="button" className="rs-tag-icon-close rs-btn-close" aria-label="Remove" onClick={()=>{removeTag(i)}}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" className="rs-icon" aria-label="close" data-category="application"><path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path></svg>
                                  </button>
                                </div>
                              </span>
                            )
                          })}
                          { items.length > visibleOptions ? <span> {hiddenCount} more ...</span> :null }
                        </>
                      }}
                      cleanable={false}
                    />
                    </div>
                    <span className="text-danger d-flex text-left">
                      <CustomError name="cuisine_type" form={formik} />
                    </span>
                  </div>

                  <div className="col-lg-6 text-center form-group">
                    <label htmlFor="restaurant_logo" className="text-left d-flex">
                      {t("label_logo")}:
                      {" "}
                      <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("image_support_tooltip")}
                                </Tooltip>
                              }
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                    </label>
                    <input
                      className="form-control imgInput"
                      id="restaurant_logo"
                      name="restaurant_logo"
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "restaurant_logo",
                          event.target.files[0] || ""
                        );
                        event.target.files.length === 1
                          ? setPreviewImage(
                            URL.createObjectURL(event.target.files[0])
                          )
                          : setPreviewImage("");
                      }}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError name="restaurant_logo" form={formik} />
                    </span>
                    {previewimage ? (
                      <ul className="question-image-preview questions-ul">
                        <li className="pr_img_box">
                          <img
                            src={previewimage}
                            style={{ height: "100px" }}
                            alt={"profileImg"}
                          />
                          {/* <div className="img_options">
                            <button
                              type="button"
                              onClick={() => {
                                setPreviewImage("");
                                formik.setFieldValue("restaurant_logo", null);
                              }}
                              className="text-danger"
                            >
                              <i className="ri-delete-bin-6-fill"></i>
                            </button>
                          </div> */}
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="col-lg-6 text-center form-group">
                    <label htmlFor="images" className="text-left d-flex">
                      Restaurant Images:
                    </label>
                    <input
                      className="form-control imgInput"
                      id="files"
                      name="images"
                      accept="image/*"
                      type="file"
                      multiple={true}
                      onChange={handleFilesSelection}
                    />
                    {formik.values.images.length > 0 ? (
                      <ul className="question-image-preview questions-ul">
                        {formik.values.images.map((image, index) => (
                          <li key={index} className="pr_img_box">
                            <img
                              src={getImageUrl(image)}
                              style={{ height: "100px" }}
                              alt={`Preview ${index}`}
                            />
                            <div className="img_options">
                              <button
                                type="button"
                                onClick={() => {
                                  handleDeleteImage(index, image);
                                }}
                                className="text-danger"
                              >
                                <i className="ri-delete-bin-6-fill"></i>
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div> */}

                  {/* <div className="col-lg-6 text-center form-group">
                    <label className="text-left d-flex" htmlFor="phone_number">
                      {t("label_phone_number")}:
                    </label>
                    <div className="form-control d-flex">
                     
                      
                      <input
                        className={"phoneInput"}
                        name="phone_number"
                        style={{ border: 0 }}
                        onChange={(event) => {
                          formik.setFieldValue(
                            `phone_number`,
                            event.target.value
                          );
                        }}
                        value={`${formik.values.phone_number}`}
                        onKeyDown={blockInvalidChar}
                      />
                    </div>
                  </div> */}



                  {/* <div className="col-md-6 text-center form-group">
                    <label
                      htmlFor="branch_details"
                      className="text-left d-flex"
                    >
                      Branch Details:{
" "}
                    </label>
                    <textarea
                      name="branch_details"
                      id="branch_details"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.branch_details}
                      className="form-control"
                      style={{ height: "82px" }}
                    />
                  </div> */}

                  <div className="mt-5">
                    <button
                      onClick={() => {
                        setSaveType("Save");
                      }}
                      className="btn btn-info mr-2"
                      type="submit"
                    >
                      <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                      {t("btn_save")}
                    </button>
                    <button
                      onClick={() => {
                        setSaveType("");
                      }}
                      className="btn btn-success mr-2"
                      type="submit"
                    >
                      <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                      {t("btn_save_exit")}
                    </button>
                    <button
                      className="btn ripple btn-secondary"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                      {t("btn_cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserEdit;