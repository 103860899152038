import React, { useEffect, useState, forwardRef } from 'react';
import { detail, orderDetail, receiptDetail, transactionDetail } from '../services/table.services';
import { useSelector } from 'react-redux';
import { getDate, getReciptDate, getTranslatedKey, showPrice, TrimText } from '../../../utils/commonfunction';
import { CONSUMPTION_TAX_RATES, DISPLAY_METHOD_TAX_OPTIONS } from '../../../utils/Constants';
import { useTranslation } from 'react-i18next';

const OrderReceipt = forwardRef(({ orderId }, ref) => {
    const { t } = useTranslation();
    const [receiptData, setReceiptData] = useState(null);
    const [orderItems, setOrderItems] = useState([]);
    const [orderTransactionDetail, setOrderTransactionDetail] = useState({});
    const restSettingDetails = useSelector((state) => state?.restSettings?.data);


    const getTransactionDetail = (orderId) => {
        transactionDetail({ o_id: orderId })
            .then((response) => {
                handelOrderCalculations(response?.data?.orderDetail);
                setOrderTransactionDetail(response?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const handelOrderCalculations = (orderDetail) => {
        const combinedOrderedItems = [...orderDetail?.ordered_items];
        orderDetail?.subOrders.forEach((subOrder) => {
            combinedOrderedItems.push(...subOrder.ordered_items);
        });
        const itemCounts = combinedOrderedItems.reduce((acc, item) => {
            acc[item.item_name] = acc[item.item_name] || {
                quantity: 0,
                consumption_tax_rate: item.consumption_tax_rate,
                price: item.price,
                tax_price: item.tax_price,
                total_price: item.total_price,
                item_name_ja: item.item_name_ja,
            };
            acc[item.item_name].quantity += 1;
            return acc;
        }, {});

        const result = Object.keys(itemCounts).map((itemName) => ({
            item_name: itemName,
            quantity: itemCounts[itemName].quantity,
            consumption_tax_rate: itemCounts[itemName].consumption_tax_rate,
            price: itemCounts[itemName].price,
            tax_price: itemCounts[itemName].tax_price,
            total_price: itemCounts[itemName].total_price,
            item_name_ja: itemCounts[itemName].item_name_ja,
        }));

        setOrderItems(result);
    };

    const getReceiptDetail = () => {
        receiptDetail()
            .then((response) => {
                setReceiptData(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    useEffect(() => {
        if (orderId) {
            getTransactionDetail(orderId);
            getReceiptDetail();
        }
    }, [orderId]);


    const handleAsterisk = (item) => {
        let result = null;
        if (
            item?.consumption_tax_rate === CONSUMPTION_TAX_RATES.TAKE_OUT && restSettingDetails?.icon_display_eligible_for_reduced_tax_rate
        ) {
            result = <span className="fw-bold">*</span>;
        }
        return result;
    };


    return (
        <table
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
                width: "100%",
                margin: "auto",
                fontSize: 16,
                fontFamily: "Arial, Helvetica, sans-serif"
            }}
        >
            <tbody>
                <tr>
                    <td colSpan={2} style={{ textAlign: "center", padding: "30px 0px" }}>

                        {(receiptData?.slug === 'receipt_style_1' && receiptData?.header_image) && (
                            <div style={{ textAlign: "center" }}>
                                <img src={receiptData?.header_image} alt="Logo" style={{width:'70px', height:'70px', objectFit:'cover',}}/>
                            </div>
                        )}
                        {receiptData?.header_text && (
                            <div style={{ fontSize: 16, color: "#000", paddingTop: 15 }}>
                                <p style={{ margin: 0, padding: "0 0 10px 0" }} dangerouslySetInnerHTML={{ __html: receiptData.header_text }}></p>
                                <p style={{ margin: 0, padding: 0 }}>{getReciptDate()}</p>
                            </div>
                        )}
                    </td>
                </tr>

                {orderItems.map((item, index) => (
                    <tr key={index}>
                        <td style={{ padding: "8px 0px" }}>{TrimText(getTranslatedKey("item_name", item), 20)}{handleAsterisk(item)}{" "}</td>
                        <td style={{ padding: "8px 0px", textAlign: "right" }}>
                            {restSettingDetails?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ? showPrice(item?.price) : showPrice(item?.total_price)}
                            {item.quantity > 1 ? ' × ' : ''}
                            <span style={{ margin: 0, padding: "0 0 0 25px" }}>{item.quantity > 1 ? item.quantity : ''} ¥{restSettingDetails?.display_method_product_price === DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ? showPrice(item?.price * item.quantity) : showPrice(item?.total_price * item?.quantity)}
                            </span>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td style={{ padding: "8px 0px", fontWeight: "bold" }}>
                        {t("item_total_excl_tax")}
                    </td>
                    <td style={{ padding: "8px 0px", fontWeight: "bold", textAlign: "right" }}>
                        {showPrice(orderTransactionDetail?.subtotal)}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ padding: "10px 0px", textAlign: "right" }}>
                        {t("reduced_tax_message")}
                    </td>
                </tr>
                <tr>
                    <td style={{ padding: "8px 0px", fontWeight: "bold" }}>
                        {t("subtotal_excl_tax")}
                    </td>
                    <td
                        style={{ padding: "8px 0px", textAlign: "right", fontWeight: "bold" }}
                    >
                        ¥{showPrice(orderTransactionDetail?.subtotal)}
                    </td>
                </tr>
                <tr>
                    <td style={{ padding: "8px 0px" }}>{t("tax")}</td>
                    <td style={{ padding: "8px 0px", textAlign: "right" }}>¥{showPrice(orderTransactionDetail?.tax_price)}</td>
                </tr>
                <tr>
                    <td style={{ padding: "8px 0px", fontWeight: "bold", fontSize: 24 }}>
                        {t("total")}
                    </td>
                    <td
                        style={{
                            padding: "8px 0px",
                            textAlign: "right",
                            fontWeight: "bold",
                            fontSize: 24
                        }}
                    >
                        ¥{showPrice(orderTransactionDetail?.grand_total)}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2} style={{ padding: "10px 0px", textAlign: "right" }}>
                        {orderTransactionDetail?.total_tax_8 && (
                            <>
                                {`(8% ${t("tax_on_text")} ¥ ${showPrice(orderTransactionDetail.total_tax_8)})`} <br />
                            </>
                        )}
                        {orderTransactionDetail?.total_tax_10 && (
                            <>
                                {`(10% ${t("tax_on_text")} ¥ ${showPrice(orderTransactionDetail.total_tax_10)})`}
                            </>
                        )}
                    </td>
                </tr>
                <tr>
                    <td style={{ padding: "8px 0px" }}>{t("payment")}</td>
                    <td style={{ padding: "8px 0px", textAlign: "right" }}>¥{showPrice(orderTransactionDetail?.received_amount)}</td>
                </tr>
                <tr>
                    <td style={{ padding: "8px 0px" }}>{t("change")}</td>
                    <td style={{ padding: "8px 0px", textAlign: "right" }}>¥{showPrice(orderTransactionDetail?.returned_amount)}</td>
                </tr>
                <tr>
                    <td style={{ padding: "8px 0px" }}>{t("gift_card")}</td>
                    <td style={{ padding: "8px 0px", textAlign: "right" }}>¥{showPrice(orderTransactionDetail?.gift_card_amount)}</td>
                </tr>
                {(receiptData?.registered_number && receiptData?.registered_number !== 'null') && (
                    <tr>
                        <td
                            colSpan={3}
                            style={{
                                paddingTop:10,
                                textAlign: "center",
                                fontWeight: "bold",
                                justifyContent:"center"
                            }}
                        >
                         <table style={{width:'100%',}}>
                          <tr>
                           <td>
                           <div style={{display:'flex',}}>
                            {t("registered_number")}:<div  dangerouslySetInnerHTML={{ __html: receiptData.registered_number}}></div></div>
                            </td> 
                          </tr>
                         </table>   

                        </td>
                    </tr>
                )}
                {(receiptData?.footer_title && receiptData?.footer_title !== 'null') && (
                    <tr>
                        <td
                            colSpan={2}
                            style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 22,
                                paddingBottom: 10
                            }}
                        >
                            {TrimText(receiptData?.footer_title, 40)}
                        </td>
                    </tr>
                )}
                {(receiptData?.phone_number && receiptData?.phone_number !== 'null') && (
                    <tr>
                        <td colSpan={2} style={{ textAlign: "center", paddingBottom: 10 }}>
                            {t("tel")}: {receiptData?.phone_number}
                        </td>
                    </tr>
                )}
                {(receiptData?.address && receiptData?.address !== 'null') && (
                    <tr>
                        <td
                            style={{ textAlign: "center", paddingBottom: 30 }}
                            colSpan={2}
                        >
                            {receiptData?.address}
                        </td>
                    </tr>
                )}

                {receiptData?.slug === 'receipt_style_1' && (
                    <tr>
                        <td colSpan={2}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        
                                        {(receiptData?.qr_code_msg && receiptData?.qr_code_msg !== 'null') && (
                                            <td colSpan={2}
                                                style={{
                                                    textAlign: "left",
                                                    verticalAlign: "middle",
                                                    fontWeight: "bold",
                                                    paddingLeft: 15,
                                                    fontSize: 20,

                                                }}
                                               
                                            >
                                              <div style={{display:'flex', alignItems:'center',justifyContent:"center"}}>
                                                <img src={receiptData?.qr_code_image} alt="QR Code" style={{width:'70px',height:'70px', objectFit:'cover', marginRight:'10px',}}/>
                                                <div  dangerouslySetInnerHTML={{ __html: receiptData.qr_code_msg}}></div>
                                                </div>   
                                            </td>
                                        )}
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );


{/* <table
  border={0}
  cellPadding={0}
  cellSpacing={0}
  style={{
    width: "100%",
    margin: "auto",
    fontSize: 16,
    fontFamily: "Arial, Helvetica, sans-serif"
  }}
>
  <tbody>
    <tr>
      <td colSpan={2} style={{ textAlign: "center", padding: "30px 0px" }}>
        <div style={{ textAlign: "center" }}>
          <img src="img/logo.png" />
        </div>
        <div style={{ fontSize: 16, color: "#000", paddingTop: 15 }}>
          <p style={{ margin: 0, padding: "0 0 10px 0" }}>
            ★ Thank you for visiting! We hope to see you again! ★
          </p>
          <p style={{ margin: 0, padding: 0 }}>2019-10-01 (Tue) 00:00:00</p>
        </div>
      </td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px" }}>Coffee</td>
      <td style={{ padding: "8px 0px", textAlign: "right" }}>
        ¥350 × <span style={{ margin: 0, padding: "0 0 0 25px" }}>2 ¥700</span>
      </td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px" }}>Tea</td>
      <td style={{ padding: "8px 0px", textAlign: "right" }}>¥350</td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px" }}>Hot Sandwich</td>
      <td style={{ padding: "8px 0px", textAlign: "right" }}>
        ¥400 × <span style={{ margin: 0, padding: "0 0 0 25px" }}>2 ¥800</span>
      </td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px", fontWeight: "bold" }}>
        Item Total (excl. tax)
      </td>
      <td
        style={{ padding: "8px 0px", fontWeight: "bold", textAlign: "right" }}
      >
        ¥1,850
      </td>
    </tr>
    <tr>
      <td colSpan={2} style={{ padding: "30px 0px", textAlign: "right" }}>
        ★ indicates items subject to reduced tax <br />
        rate of 8%
      </td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px", fontWeight: "bold" }}>
        Subtotal (excl. tax)
      </td>
      <td
        style={{ padding: "8px 0px", textAlign: "right", fontWeight: "bold" }}
      >
        ¥1,850
      </td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px" }}>Tax</td>
      <td style={{ padding: "8px 0px", textAlign: "right" }}>¥171</td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px", fontWeight: "bold", fontSize: 24 }}>
        Total
      </td>
      <td
        style={{
          padding: "8px 0px",
          textAlign: "right",
          fontWeight: "bold",
          fontSize: 24
        }}
      >
        ¥2,021
      </td>
    </tr>
    <tr>
      <td
        colSpan={2}
        style={{ padding: "30px 0px", textAlign: "right", }}
      >
        (8% tax on ¥abc) <br />
        (10% tax on ¥xyz)
      </td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px" }}>Payment</td>
      <td style={{ padding: "8px 0px", textAlign: "right" }}>¥2,100</td>
    </tr>
    <tr>
      <td style={{ padding: "8px 0px" }}>Change</td>
      <td style={{ padding: "8px 0px", textAlign: "right" }}>¥79</td>
    </tr>
    <tr>
      <td
        colSpan={2}
        style={{
          padding: "30px 0 40px 0px",
          textAlign: "left",
          fontWeight: "bold"
        }}
      >
        Registered Number: 152451148515747899999554
      </td>
    </tr>
    <tr>
      <td
        colSpan={2}
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 22,
          paddingBottom: 30
        }}
      >
        Blayn Cafe
      </td>
    </tr>
    <tr>
      <td colSpan={2} style={{ textAlign: "center", paddingBottom: 10 }}>
        TEL: 03-1234-5678
      </td>
    </tr>
    <tr>
      <td
        style={{ textAlign: "center", paddingBottom: 30 }}
        colSpan={2}
      >
        Address: Roppongi Hills Mori Tower, 17F, 6-10-1 <br />
        Roppongi, Minato-ku, Tokyo
      </td>
    </tr>
    <tr>
      <td colSpan={2}>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <img src="img/qr.png" />
              </td>
              <td
                style={{
                  textAlign: "left",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  paddingLeft: 15,
                  fontSize: 20,
                }}
              >
                Get a great coupon!! Register here for membership
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table> */}
});

export default OrderReceipt;
{/* <div style={{ backgroundColor: 'white', width: '350px', padding: '20px', margin: '30px auto', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>

<div style={{ textAlign: 'center', marginBottom: '10px' }}>
    <img src="https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg" alt="Food Catering Logo" style={{ width: '80px', height: '80px', borderRadius: '50%', marginBottom: '10px' }} />
</div>

<div style={{ textAlign: 'center' }}>
    <p style={{ fontSize: '12px', color: '#333', marginBottom: '5px' }}>★ Thank you for visiting! We hope to see you again! ★</p>
    <p style={{ fontSize: '12px', color: '#333' }}>2019-10-01 (Tue) 00:00:00</p>
</div>

<table style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}>
    <tbody>
        <tr>
            <td>Coffee</td>
            <td style={{ textAlign: 'right' }}>¥350 × 2</td>
            <td style={{ textAlign: 'right' }}>¥700</td>
        </tr>
        <tr>
            <td>Tea</td>
            <td></td>
            <td style={{ textAlign: 'right' }}>¥350</td>
        </tr>
        <tr>
            <td>Hot Sandwich</td>
            <td style={{ textAlign: 'right' }}>¥400 × 2</td>
            <td style={{ textAlign: 'right' }}>¥800</td>
        </tr>
        <tr style={{ fontWeight: 600, }}>
            <td style={{ paddingTop: '10px' }}>Item Total (excl. tax)</td>
            <td></td>
            <td style={{ textAlign: 'right', paddingTop: '10px' }}>¥1,850</td>
        </tr>
    </tbody>
</table>
<p style={{ fontSize: '10px', color: '#888', marginTop: '5px', marginBottom: '10px', textAlign: 'left' }}>★ indicates items subject to reduced tax rate of 8%</p>

<table style={{ width: '100%', marginBottom: '10px' }}>
    <tbody>
        <tr style={{ fontWeight: 600 }}>
            <td>Subtotal (excl. tax)</td>
            <td></td>
            <td style={{ textAlign: 'right' }}>¥1,850</td>
        </tr>
        <tr>
            <td>Tax</td>
            <td></td>
            <td style={{ textAlign: 'right' }}>¥171</td>
        </tr>
        <tr style={{ fontWeight: 600, fontSize: '18px' }}>
            <td>Total</td>
            <td></td>
            <td style={{ textAlign: 'right', fontWeight: 600 }}>¥2,021</td>
        </tr>
    </tbody>
</table>
<p style={{ fontSize: '12px', color: '#888', textAlign: 'right', margin: 0 }}>(8% tax on ¥ abc)</p>
<p style={{ fontSize: '12px', color: '#888', textAlign: 'right', margin: 0 }}>(10% tax on ¥ xyz)</p>
<table style={{ width: '100%', marginTop: '10px' }}>
    <tbody>
        <tr>
            <td>Payment</td>
            <td></td>
            <td style={{ textAlign: 'right' }}>¥2,100</td>
        </tr>
        <tr>
            <td>Change</td>
            <td></td>
            <td style={{ textAlign: 'right' }}>¥79</td>
        </tr>
    </tbody>
</table>
<div style={{ textAlign: 'center', fontSize: '12px', marginTop: '10px', color: '#888' }}>
    <p>Registered Number: 152451148515747899999954</p>
</div>
<div style={{ textAlign: 'center', marginTop: '10px' }}>
    <p style={{ fontWeight: 600, fontSize: '14px', margin: 0 }}>Blayn Cafe</p>
    <p style={{ margin: 0 }}>TEL: 03-1234-5678</p>
    <p style={{ margin: 0 }}>Address: Roppongi Hills Mori Tower, 17F, 6-10-1, Roppongi, Minato-ku, Tokyo</p>
</div>
<div style={{ textAlign: 'center', marginTop: '20px' }}>
    <img src="your-qr-code-link.png" alt="QR Code" style={{ width: '80px' }} />
    <p style={{ fontSize: '14px', margin: '5px 0' }}>Get a great coupon!!</p>
    <p style={{ fontSize: '14px', margin: 0 }}>Register here for membership</p>
</div>
</div > */}