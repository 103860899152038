import axiosInstance from "../../../utils/axios";


const path = "/waiter/menu";



export const categoryList = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).post(`${path}/categories/list`, values);
}


export const categoryDetail = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).post(`${path}/categories/details` , values);
}

export const menuItemList = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).post(`${path}/items/list`, values);
}


export const menuItemDetail = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).post(`${path}/items/details` , values);
}

