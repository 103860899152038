import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/addons";
const options = {role: ROLE.RESTAURANT};


export const list = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const add = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const edit = async (values) => {
  return await axiosInstance(options).put(`${path}/edit`, values);
}

export const deleteRecord = async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?o_id=${values.o_id}`);
}

export const details = async (id) => {
  return await axiosInstance(options).get(`${path}/details?o_id=${id}`);
}

export const Options = async () => {
  return await axiosInstance(options).get(`${path}/option-list`);
}

export const assignItems = async (values) => {
  return await axiosInstance(options).patch(`${path}/assign-items`, values);
}

export const deleteAssignedItem = async (values) => {
  return await axiosInstance(options).patch(`${path}/delete-assigned-item`, values);
}