import axiosInstance from "../../../utils/axios";


const path = "/waiter/order";

const authPath = "/waiter/auth"



export const createOrder = async (values , option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).post(`${path}/save`, values);
}

export const generateToken = async (values) => {
  return await axiosInstance().post(`${authPath}/QR-CODE/token`, values);
}

export const detail = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).get(`${path}/details?o_id=${values.o_id}`, values);
}

export const checkoutOrder = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).get(`${path}/checkout?o_id=${values.o_id}`, values);
}









