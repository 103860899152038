import React from 'react';
import card1 from '../../../assets/cashier/images/card-img.svg';
import card2 from '../../../assets/cashier/images/card2-img.svg';
import HomeIcon from '../../../assets/cashier/images/home-img.svg';
import InvoiceIcon from '../../../assets/cashier/images/invoice-img.svg';
import PayIcon from '../../../assets/cashier/images/pay-img.svg';
import ReprintIcon from '../../../assets/cashier/images/reprint-img.svg';
import AmericanCard from '../../../assets/cashier/images/american-card.png';
import amex from '../../../assets/cashier/images/amex.png';
import diners from '../../../assets/cashier/images/diners.png';
import JcbCard from '../../../assets/cashier/images/jcb-card.png';
import visa from '../../../assets/cashier/images/visa.png';
import Mastercard from '../../../assets/cashier/images/mastercard.png';
import aupay from '../../../assets/cashier/images/au-pay.png';
import linepay from '../../../assets/cashier/images/line-pay.png';
import merupay from '../../../assets/cashier/images/meru-pay.png';
import paypay from '../../../assets/cashier/images/pay-pay.png';
import rakutenpay from '../../../assets/cashier/images/rakuten-pay.png';
import dbarai from '../../../assets/cashier/images/d-barai.png';

export default function TodaySales() {
  return (
    <div className='dashboard-cashier-wrap'>
      <div className='dashboard-cashier-container d-flex'>
        <div className='cashier-left-part'>
          <ul className='cashier-menus'>
            <li>
              <a href='#' className='active'><i className='ti ti-chart-dots'></i> Today’s Sales</a>
            </li>
            <li>
              <a href='#'><i className='ti ti-chart-dots-3'></i> Sales History</a>
            </li>
            <li>
              <a href='#'><i className='ti ti-brand-netbeans'></i> Check Network</a>
            </li>
            <li>
              <a href='#'><i className='ti ti-sun'></i> Settlement</a>
            </li>
          </ul>
        </div>
        <div className='cashier-right-part'>
          <div className='cashier-heading-innner mb-4'>
            <h4>Today’s Sales</h4>
          </div>
          <div className='card'>
            <div className='card-body'>
              Add chart here
            </div>
          </div>

          <div className='card mt-4'>
            <div className='card-body'>
              <div className='today-statics-info'>
                <div className='row'>
                  <div className='col-md-4'>
                    <div className='today-statics-box'>
                      <h4 className='today-static-headings'>Sales</h4>
                      <div className='today-static-row'>
                          <span>Sales</span>
                          <h5>¥ 69,319</h5>
                          <p>¥ 63,089 (Excluding tax)</p>
                      </div>
                      <div className='today-static-row'>
                          <span>Settled</span>
                          <h5>¥ 69,319</h5>
                          <p>¥ 63,089 (Excluding tax)</p>
                      </div>
                      <div className='today-static-row'>
                          <span>Not yet settled</span>
                          <h5>¥ 0</h5>
                          <p>¥ 0 (Excluding tax)</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='today-statics-box'>
                      <h4 className='today-static-headings'>Number of Customers</h4>
                      <div className='today-static-row'>
                          <span>persons</span>
                          <h5>52</h5>
                      </div>
                      <div className='today-static-row'>
                          <span>Settled</span>
                          <h5>52</h5>
                      </div>
                      <div className='today-static-row'>
                          <span>Not yet settled</span>
                          <h5>0</h5>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='today-statics-box'>
                      <h4 className='today-static-headings'>Number of Transactions</h4>
                      <div className='today-static-row'>
                          <span>transactions</span>
                          <h5>40</h5>
                      </div>
                      <div className='today-static-row'>
                          <span>Settled</span>
                          <h5>35</h5>
                      </div>
                      <div className='today-static-row'>
                          <span>Not yet settled</span>
                          <h5>5</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
