import React, { useEffect, useRef, useState } from "react";
import { Drawer } from "rsuite";
import { setDefaultReceipt, receiptDetail, editReceipt, getReceipt } from "../../../services/receipt.services";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { LOADER_TIMEOUT_TIME, RECEIPT_TYPES, SWAL_SETTINGS } from "../../../../../utils/Constants";
import { getReciptDate, handleServerValidations } from "../../../../../utils/commonfunction";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import Skeleton from "react-loading-skeleton";
import { useFormik } from "formik";
import PhoneInput from "../../../../../utils/PhoneInput";
import CustomCkeditor from "../../../../admin/common/customeditor";
import * as Yup from 'yup';
import CustomError from "../../../../../utils/customError";

export default function ReceiptAndInvoice() {
  const [editHeader, setEditHeader] = useState(false);
  const [editFooter, setEditFooter] = useState(false);
  const [editRegistrationNumber, setRegistrationNumber] = useState(false);
  const [editQrFooter, setEditQrFooter] = useState(false);
  const { t } = useTranslation();
  const [receiptList, setReceiptList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [defaultReceiptItem, setDefaultReceiptItem] = useState("");
  const [receipt, setReceipt] = useState({});
  const fileSelectInput = useRef();
  const [hideCkeditor, setHideckeditor] = useState(true);
  const [refresh, setRefresh] = useState(false)

  const customToolbarConfig = {
    toolbar: [
      { name: 'source', items: ['Source'] },
      { name: 'basicstyles', items: ['Bold', 'Italic'] },
      { name: 'styles', items: ['FontSize'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight'] },
    ]
  };

  const validationSchemaHeader = Yup.object().shape({
    header_image: Yup.mixed()
      .test(
        "fileSize",
        "image_support_tooltip",
        (value) => {
          if (typeof value === 'string') {
            return value
          }
          else {
            const validFormats = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
            if (!validFormats.includes(value.type)) {
              return false;
            }
            else if (value.size >= 1.5 * 1024 * 1024) {
              console.log("here2")
              return false;
            }
            else {
              return value;
            }
          }

        }
      )
      .nullable(),
  });

  const validationSchemaQR = Yup.object().shape({
    qr_code_image: Yup.mixed()
      .test(
        "fileSize",
        "image_support_tooltip",
        (value) => {
          if (typeof value === 'string') {
            return value
          }
          else {
            const validFormats = ['image/jpeg', 'image/png', 'image/webp', 'image/gif'];
            if (!validFormats.includes(value.type)) {
              return false;
            }
            else if (value.size >= 1.5 * 1024 * 1024) {
              return false;
            }
            else {
              return value;
            }
          }
        }
      )
      .nullable(),
  });



  const formRegistrationNumber = useFormik({
    initialValues: { registered_number: receipt.registered_number },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("slug", receipt.slug);
      formData.append("registered_number", values.registered_number);
      setRefresh(false);
      editReceipt(formData)
        .then((response) => {
          if (response.success) {
            formRegistrationNumber.resetForm();
            setRegistrationNumber(false);
            setRefresh(true);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating receipt", error);
        });
    },
  });

  const formFooter = useFormik({
    initialValues: { address: receipt.address, phone_number: receipt.phone_number, footer_title: receipt.footer_title },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("slug", receipt.slug);
      formData.append("footer_title", values.footer_title);
      formData.append("address", values.address);
      formData.append("phone_number", values.phone_number);
      setRefresh(false);
      editReceipt(formData)
        .then((response) => {
          if (response.success) {
            formFooter.resetForm();
            setEditFooter(false);
            setRefresh(true);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating receipt", error);
        });
    },
  });

  const formQR = useFormik({
    initialValues: { qr_code_msg: receipt.qr_code_msg, qr_code_image: receipt.qr_code_image },
    enableReinitialize: true,
    validationSchema: validationSchemaQR,
    onSubmit: (values) => {
      const formData = new FormData();
      setRefresh(false);
      formData.append("slug", receipt.slug);
      formData.append("qr_code_msg", values.qr_code_msg && values.qr_code_msg !== 'null' ? values.qr_code_msg : '');
      formData.append("qr_code_image", values.qr_code_image);
      editReceipt(formData)
        .then((response) => {
          if (response.success) {
            formQR.resetForm();
            setEditQrFooter(false);
            setRefresh(true);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating receipt", error);
        });
    },
  });

  const formHeader = useFormik({
    initialValues: { header_text: receipt.header_text, header_image: receipt.header_image || "" },
    enableReinitialize: true,
    validationSchema: validationSchemaHeader,
    onSubmit: (values) => {
      const formData = new FormData();
      console.log(receipt);
      formData.append("slug", receipt.slug);
      formData.append("header_text", values.header_text && values.header_text !== 'null' ? values.header_text : '');
      formData.append("header_image", values.header_image);
      setRefresh(false);
      editReceipt(formData)
        .then((response) => {
          if (response.success) {
            formHeader.resetForm();
            setEditHeader(false);
            setRefresh(true);
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating receipt", error);
        });
    },
  });

  const updateDefaultReceipt = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("receipt_text"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t('change_label'),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        setRefresh(false);
        setDefaultReceipt(item)
          .then((response) => {
            if (response.success) {
              setDefaultReceiptItem(item);
              setRefresh(true);
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
      }
    });
  };

  useEffect(() => {
    setLoader(true);
    getReceipt()
      .then((response) => {
        setReceiptList(response.data);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);

        //    console.log(response.data)
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refresh]);

  const getReceiptDetail = (item) => {
    setReceipt({});
    setHideckeditor(true);
    receiptDetail(item.slug)
      .then((response) => {
        setReceipt({ ...item, ...response?.data });
        setHideckeditor(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFileSelection = (event) => {
    if (event.target.files.length) {
      formHeader.setFieldValue("header_image", event.target.files[0]);
    }
  };
  const handleFilePreview = (file) => {
    if (typeof file === "string") {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  return (
    <main id="main-content">
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">{t('receipt_label')}</h4>
        </div>
        <div className="receipt-section">
          {loader ? (
            <div className="row">
              <div className="col-md-4">
                {" "}
                <Skeleton height={400} />
              </div>
              <div className="col-md-4">
                {" "}
                <Skeleton height={400} />
              </div>
              <div className="col-md-4">
                {" "}
                <Skeleton height={400} />
              </div>
            </div>
          ) : (
            <div className="row">
              {/* receipts body */}

              {receiptList && receiptList.length > 0
                ? receiptList.map((item, index) => {
                  return (
                    <div className="col-md-4" key={index}>
                      <div className="d-flex justify-content-center mb-3">
                        <div
                          className="form-check cp e_none"
                          onClick={() => {
                            if (!item?.is_default) {
                              updateDefaultReceipt({ slug: item?.slug });
                            }

                          }}
                        >
                          <input className="form-check-input" name="type" type="radio" id={item?.slug} value="item" defaultChecked={item?.is_default} />
                          <label className="form-check-label cp" htmlFor={item?.slug}>
                            {t(item?.slug)}
                          </label>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">

                          <div className="p-3 logo-part border-dot-orange" style={{ minHeight: "75px" }}>
                            <button
                              className="btn btn-icon"
                              onClick={() => {
                                setEditHeader(true);
                                getReceiptDetail(item);
                              }}
                            >
                              <i className="ti ti-edit"></i>
                            </button>
                            <div className="profile-photo-upload mb-3 mx-auto">
                              {item.slug === RECEIPT_TYPES.TYPE_1 && (
                                <>
                                  <div className="avatar avatar-xl">
                                    {/* src={item?.header_image ? item?.header_image : DummyRecipt} */}
                                    <img alt="profile-img" src={(item?.header_image)} />
                                  </div>
                                  <button
                                    className="cp e_none"
                                    type="button"
                                    onClick={() => {
                                      setEditHeader(true);
                                      getReceiptDetail(item);
                                    }}
                                  >
                                    <input id="profile_image" name="profile_image" accept="image/*" type="file" />
                                    <i className="ti ti-upload"></i>
                                  </button>
                                </>
                              )}
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: item?.header_text }} />
                            {/* {getReciptDate() && (
                             <div>{getReciptDate()}</div>
                            )} */}
                            <p>{getReciptDate()}</p>

                          </div>


                          <div className="receipt-info my-3">
                            <table className="receipt-table">
                              <tbody>
                                <tr>
                                  <td width={"45%"}>{t('coffee_label')} </td>
                                  <td className="text-end">¥350 × 2</td>
                                  <td className="text-end">¥700</td>
                                </tr>
                                <tr>
                                  <td width={"45%"}>{t('tea_label')} </td>
                                  <td className="text-end"></td>
                                  <td className="text-end">¥350</td>
                                </tr>
                                <tr>
                                  <td width={"45%"}>{t('hot_sandwich_label')} </td>
                                  <td className="text-end">¥400 × 2</td>
                                  <td className="text-end">¥800</td>
                                </tr>
                                <tr>
                                  <td width={"45%"}>
                                    <b>{t('item_total_text')}</b>
                                  </td>
                                  <td className="text-end"></td>
                                  <td className="text-end">
                                    <b>¥1,850</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td width={"45%"}></td>
                                  <td className="text-end" colSpan="2">
                                    {t('tax_rate_text')}
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <table className="receipt-table">
                              <tbody>
                                <tr>
                                  <td width={"45%"}>{t('subtotal_text')} </td>
                                  <td className="text-end">¥1,850</td>
                                </tr>
                                <tr>
                                  <td width={"45%"}>{t('tax')} </td>
                                  <td className="text-end">¥171</td>
                                </tr>
                                <tr>
                                  <td width={"45%"}>
                                    <h4>{t('total_label')}</h4>
                                  </td>
                                  <td className="text-end">
                                    <h4>¥2,021</h4>
                                  </td>
                                </tr>
                                <tr>
                                  <td width={"45%"}></td>
                                  <td className="text-end">
                                    {" "}
                                    {t('8_percent_tax_text')}<br></br>{t('10_percent_tax_text')}
                                  </td>
                                </tr>
                                <tr>
                                  <td width={"45%"}>{t('payment_label')} </td>
                                  <td className="text-end">¥2,100</td>
                                </tr>
                                <tr>
                                  <td width={"45%"}>{t('change_label')} </td>
                                  <td className="text-end">¥79</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="p-3 registered-number-box border-dot-orange d-flex align-items-center justify-content-between flex-wrap">

                            <h4 className="m-0" >{t('registered_number_label')}:</h4>


                            <div className="col reg_num" dangerouslySetInnerHTML={{ __html: item?.registered_number }} />
                            <button
                              className="btn btn-icon"
                              onClick={() => {
                                setRegistrationNumber(true);
                                getReceiptDetail(item);
                              }}
                            >
                              <i className="ti ti-edit"></i>
                            </button>
                          </div>
                          <div className="p-3 resto-bill-info text-center border-dot-orange mt-3">
                            <h4>{item?.footer_title}</h4>
                            <p>{t('tel_label')}: {item?.phone_number}</p>
                            <p>{t('list_heading_address')}: {item?.address}</p>
                            <button
                              className="btn btn-icon"
                              onClick={() => {
                                setEditFooter(true);
                                getReceiptDetail(item);
                              }}
                            >
                              <i className="ti ti-edit"></i>
                            </button>
                          </div>
                          {item.slug === RECEIPT_TYPES.TYPE_1 && (
                            <div className="qr-code-info border-dot-orange mt-3 p-3 d-flex align-items-center justify-content-between gap-3" style={{ minHeight: "75px" }}>
                              <figure>
                                <img src={item?.qr_code_image} />
                              </figure>
                              <button
                                className="btn btn-icon"
                                onClick={() => {
                                  setEditQrFooter(true);
                                  getReceiptDetail(item);
                                }}
                              >
                                <i className="ti ti-edit"></i>
                              </button>
                              <div className="col" dangerouslySetInnerHTML={{ __html: item?.qr_code_msg }} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
                : ""}
            </div>
          )}
        </div>
      </div>

      {/* modal for header */}

      <Drawer open={editHeader}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t('edit_header_label')}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setEditHeader(false);
                formHeader.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <form onSubmit={formHeader.handleSubmit}>
            {receipt.slug === RECEIPT_TYPES.TYPE_1 && (<div className="profile-photo-upload mb-3" style={{ margin: "auto" }}>
              <div className="avatar avatar-xl">
                <img alt="header_image" src={handleFilePreview(formHeader?.values?.header_image)} />
              </div>
              <button
                className="cp e_none"
                type="button"
                onClick={() => {
                  fileSelectInput?.current?.click();
                }}
              >
                <input ref={fileSelectInput} id="header_image" name="header_image" accept="image/*" type="file" onChange={handleFileSelection} />
                <i className="ti ti-upload"></i>
              </button>
              <CustomError className="text-danger" name="header_image" form={formHeader} />
            </div>)}

            <div className="mb-3">
              <label className="form-label">{t('header_text')}</label>
              {
                !hideCkeditor && (
                  <CustomCkeditor
                    fieldname={"header_text"}
                    setFieldValue={formHeader.setFieldValue}
                    value={formHeader?.values?.header_text}
                    setFieldTouched={formHeader.setFieldTouched}
                    config={customToolbarConfig}
                  />

                )
              }

            </div>

            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_submit")}
              </button>
            </div>
          </form>

        </Drawer.Body>
      </Drawer>

      {/* modal for footer  */}

      <Drawer open={editFooter}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t('menu_of_page_footer')}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setEditFooter(false);
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <form onSubmit={formFooter.handleSubmit}>
            <div className="mb-3">
              <label className="form-label">{t('input_placeholder_title')}</label>
              <input
                type="text"
                className="form-control"
                placeholder={t('title_placeholder')}
                name="footer_title"
                value={formFooter?.values?.footer_title}
                onChange={formFooter.handleChange}
                onBlur={formFooter.handleBlur}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">{t('label_phone_number')}</label>
              <PhoneInput
                value={formFooter?.values?.phone_number}
                name={"phone_number"}
                placeholder={t('placeholder_phone_number')}
                onChange={(e) => {
                  formFooter.setFieldValue("phone_number", e?.phone_number);
                }}
                onBlur={formFooter.handleBlur}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">{t('label_address')}</label>

              <textarea
                className="form-control"
                style={{ height: "120px" }}
                name="address"
                value={formFooter?.values?.address}
                onChange={formFooter.handleChange}
                onBlur={formFooter.handleBlur}
              ></textarea>
            </div>

            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      {/* modal for registration number  */}

      <Drawer open={editRegistrationNumber}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t('registered_number_label')}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setRegistrationNumber(false);
                setReceipt({});
                formRegistrationNumber.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <form onSubmit={formRegistrationNumber.handleSubmit}>
            <div className="mb-3">
              <label className="form-label">{t('registered_number_label')}</label>
              {
                !hideCkeditor && (
                  <CustomCkeditor
                    fieldname={"registered_number"}
                    setFieldValue={formRegistrationNumber.setFieldValue}
                    value={formRegistrationNumber?.values?.registered_number}
                    setFieldTouched={formRegistrationNumber.setFieldTouched}
                    config={customToolbarConfig}
                  />

                )
              }
            </div>

            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      {/* modal for qr code footer  */}

      <Drawer open={editQrFooter}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t('label_QR_Code_Message')}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setEditQrFooter(false);
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body>
          <form onSubmit={formQR.handleSubmit}>
            <div className="mb-3">
              <label className="form-label">{t('QR_Code_Message')}</label>

              {
                !hideCkeditor && (<CustomCkeditor
                  fieldname={"qr_code_msg"}
                  setFieldValue={formQR.setFieldValue}
                  value={formQR?.values?.qr_code_msg}
                  setFieldTouched={formQR.setFieldTouched}
                  config={customToolbarConfig}
                />)}
            </div>

            <div className="mb-3">
              <label className="form-label">
                {t("upload_QR_Code_image")}
                <span>*</span>
              </label>
              <input
                className="form-control imgInput"
                id="qr_code_image"
                name="qr_code_image"
                accept="image/*"
                type="file"
                // value={addItemForm?.values?.image}
                onChange={(event) => {
                  formQR.setFieldValue("qr_code_image", event.target.files[0] || "");
                }}
              />
              <span className="mt-2 d-block">{t("file_supported_label")}</span>
              {formQR.values.qr_code_image && (
                <ul className="question-image-preview questions-ul">
                  <li className="pr_img_box">
                    <img
                      src={formQR.values.qr_code_image instanceof File ? URL.createObjectURL(formQR.values.qr_code_image) : formQR.values.qr_code_image}
                      style={{ height: "100px" }}
                      alt={"qr_code_image"}
                    />
                    <button
                      type="button"
                      className="btn-icon"
                      style={{ position: "absolute", right: "5px", top: "5px", height: "30px", width: "30px", minWidth: "30px" }}
                      onClick={() => {
                        formQR.setFieldValue("qr_code_image", null);
                        document.getElementById("qr_code_image").value = "";
                      }}
                    >
                      <i className="ti ti-x"></i>
                    </button>
                  </li>
                </ul>

              )}
              <CustomError className="text-danger" name="qr_code_image" form={formQR} />
            </div>

            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>
    </main>
  );
}
