import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: null,
  cashRegister:null
};

const cashierSlice = createSlice({
  name: 'cashierDetails',
  initialState,
  reducers: {
    setCashierDetail(state, action) {
      state.data = action.payload;
    },
    setCashRegister(state, action) {
      state.cashRegister = action.payload;
    }
  }
});

export const { setCashierDetail,setCashRegister } = cashierSlice.actions;
export default cashierSlice.reducer;