import React, { useEffect, useRef, useState } from 'react';
import card1 from '../../../assets/cashier/images/card-img.svg';
import card2 from '../../../assets/cashier/images/card2-img.svg';
import HomeIcon from '../../../assets/cashier/images/home-img.svg';
import InvoiceIcon from '../../../assets/cashier/images/invoice-img.svg';
import PayIcon from '../../../assets/cashier/images/pay-img.svg';
import ReprintIcon from '../../../assets/cashier/images/reprint-img.svg';
import AmericanCard from '../../../assets/cashier/images/american-card.png';
import amex from '../../../assets/cashier/images/amex.png';
import diners from '../../../assets/cashier/images/diners.png';
import JcbCard from '../../../assets/cashier/images/jcb-card.png';
import visa from '../../../assets/cashier/images/visa.png';
import Mastercard from '../../../assets/cashier/images/mastercard.png';
import aupay from '../../../assets/cashier/images/au-pay.png';
import linepay from '../../../assets/cashier/images/line-pay.png';
import merupay from '../../../assets/cashier/images/meru-pay.png';
import paypay from '../../../assets/cashier/images/pay-pay.png';
import rakutenpay from '../../../assets/cashier/images/rakuten-pay.png';
import dbarai from '../../../assets/cashier/images/d-barai.png';
import { useNavigate } from 'react-router-dom';
import { PaymentMethodList } from '../services/table.services';
import { LOADER_TIMEOUT_TIME, PAYMENT_METHOD_TYPES } from '../../../utils/Constants';
import RecordNotFound from '../../restaurant/shared/recordNotFound';
import { useTranslation } from 'react-i18next';

const keypadButtons = [
  '1', '2', '3',
  '4', '5', '6',
  '7', '8', '9',
  '0', '00', 'C'
];

export default function PaymentViaCash() {
  const [total, setTotal] = useState(420);
  const [amountReceived, setAmountReceived] = useState(0);
  const [creditCardPaymentMethod, setCreditCardPaymentMethod] = useState([]);
  const [otherPaymentMethod, setOtherPaymentMethod] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const amountReceivedRef = useRef(null);

  const handleKeypadClick = (value) => {
    if (value === 'C') {
      setAmountReceived(0);
    } else {
      setAmountReceived((prev) => { if (prev == 0) { return value } else { return prev + value } });
    }
  };


  useEffect(() => {
    PaymentMethodList({ global_search: PAYMENT_METHOD_TYPES.CREDIT_CARD }).then((response) => {
      setCreditCardPaymentMethod(response.data.list);
    }).catch((err) => {
      console.log(err)
    })
    PaymentMethodList({ global_search: PAYMENT_METHOD_TYPES.QR_PAYMENTS }).then((response) => {
      setOtherPaymentMethod(response.data.list);
    }).catch((err) => {
      console.log(err)
    })
  },[]);

  useEffect(() => {
    const element = amountReceivedRef.current;

    if (element) {
      const adjustFontSize = () => {
        element.classList.remove(
          'font-size-30px',
          'font-size-25px',
          'font-size-20px',
          'font-size-15px',
          'font-size-12px'
        );
        if (element.scrollWidth > 200) {
          element.classList.add('font-size-12px');
        } else if (element.scrollWidth > 150) {
          element.classList.add('font-size-15px');
        } else if (element.scrollWidth > 100) {
          element.classList.add('font-size-20px');
        } else if (element.scrollWidth > 50) {
          element.classList.add('font-size-25px');
        } else {
          element.classList.add('font-size-30px');
        }
      };

      adjustFontSize(); 
      window.addEventListener('resize', adjustFontSize);
      return () => {
        window.removeEventListener('resize', adjustFontSize);
      };
    }
  }, [amountReceived]); 



  return (
    <div className='dashboard-cashier-wrap'>
      {/* <div className='loader-box'>
        <div className='loader-in'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#FFFFFF"></stop><stop offset=".3" stop-color="#FFFFFF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#FFFFFF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#FFFFFF" stop-opacity=".3"></stop><stop offset="1" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
          <span>Waiting for payment received </span>
        </div>
      </div> */}
      <div className="cashier-head text-center">
        <h4>{t('payment_label')}</h4>
        <div className="btns-head-in-left" onClick={()=>navigate(-1)}>
          <button className="btn btn-icon"><i className="ti ti-arrow-left"></i></button>
        </div>
      </div>
      <div className='payment-wraper'>
        <div className='payment-wraper-in'>
          <div className='paymnet-typing shadow-box'>
            <span>{t('label_total')}</span>
            <h4>¥ {total}</h4>
          </div>
          <div className='payment-keypad-box shadow-box'>
            <div className='amount-received-box d-flex align-items-center justify-content-between'>
              <h4>{t('label_amt_recieved')}</h4>
              <h5 ref={amountReceivedRef}>¥ {amountReceived}</h5>
            </div>
            <div className='payment-keypad'>
              <div className='row'>
                {keypadButtons.map((button, index) => (
                  <div className='col-md-4' key={index}>
                    <button onClick={() => handleKeypadClick(button)}>{button}</button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='keypad-action-btn-left gap-3'>
            <button className='keypad-action-btns' data-bs-toggle="modal" data-bs-target="#pay-with-other">
              <img src={card1} />
              <span>{t('label_pay_via_other')}</span>
            </button>
            <button className='keypad-action-btns' data-bs-toggle="modal" data-bs-target="#pay-card-modal">
              <img src={card2} />
              <span>{t('label_pay_via_card')}</span>
            </button>
          </div>
          <div className='keypad-action-btn-right gap-3'>
            <button className='keypad-action-btns' onClick={() => navigate('/cashier/payment-success')}>
              <img src={PayIcon} />
              <span>{t('label_pay_via_cash')}</span>
            </button>
          </div>

          <div className="modal fade modal-cashier" id="pay-card-modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-arrow-left'></i>
                  </button>
                  <h1 className="modal-title fs-5" id="exampleModalLabel">{t("label_choose_credit_card")}</h1>
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-x'></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className='payment-radio'>
                    {
                      creditCardPaymentMethod.length ?
                        <div className='row'>
                          {creditCardPaymentMethod.map((item, index) => (
                            <div className='col-md-3' key={index}>
                              <div className='payment-radio-card'>
                                <input type='radio' id={item.payment_type} name={item.payment_type} />
                                <img src={visa} alt="card" />
                                <span>{item.payment_type}</span>
                              </div>
                            </div>
                          ))}

                        </div>
                        :
                        <RecordNotFound heading='no_staff_found' />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade modal-cashier" id="pay-with-other" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-arrow-left'></i>
                  </button>
                  <h1 className="modal-title fs-5" id="exampleModalLabel">{t("label_choose_payment_method")}</h1>
                  <button type="button" className="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                    <i className='ti ti-x'></i>
                  </button>
                </div>
                <div className="modal-body">
                  <div className='payment-radio payment-method-radio'>
                    <div className='payment-radio'>
                      {
                        otherPaymentMethod.length ?
                          <div className='row'>
                            {otherPaymentMethod.map((item, index) => (
                              <div className='col-md-3' key={index}>
                                <div className='payment-radio-card'>
                                  <input type='radio' id={item.payment_type} name={item.payment_type} />
                                  <img src={linepay} alt="card" />
                                  <span>{item.payment_type}</span>
                                </div>
                              </div>
                            ))}

                          </div>
                          :
                          <RecordNotFound heading='no_staff_found' />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
