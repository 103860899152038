import React  from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EnquiryForm } from "../../website/services/website.services"
import {
    SWAL_SETTINGS,
    PHONE_NO_LENGTH,
    USER_ENQUIRY_TYPES
} from "../../../utils/Constants";
import {
    DT,
    globalLoader,
    handleServerValidations,
} from "../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../../utils/customError";
import PhoneInput from "../../../utils/PhoneInput";
import { PHONE_VALIDATION } from "../../../utils/commonValidations";

const Inquiry = ({id, className="image-inquiry", heading="web_inquiry_form_heading", msgFieldLabel="web_inquiry_form_label_more_info", type=USER_ENQUIRY_TYPES.SIMPLE_INQUIRY, errorType=null}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required("web_inquiry_form_validation_name_required"),
        email: Yup.string()
            .trim()
            .email("web_inquiry_form_validation_email_invalid_format")
            .test('no-uppercase', "web_inquiry_form_validation_email_not_contain_uppercase", function (value) {
                if (value) {
                    return !/[A-Z]/.test(value);
            }})
            .required("web_inquiry_form_validation_email_required"),
        restaurant_name: Yup.string().trim().required("web_inquiry_form_validation_restaurant_name_required"),
        phone_number: PHONE_VALIDATION,
        address: Yup.string().trim().required("web_inquiry_form_validation_address_required"),
        // more_info: Yup.string().trim().required("More info is required")
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            email: "",
            restaurant_name: "",
            phone_number: "",
            address: "",
            more_info: "",
        },
        validateOnBlur: false,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            globalLoader(true);
            let formData = new FormData();
            formData.append("name", values.name)
            formData.append("restaurant_name", values.restaurant_name);
            formData.append("phone_number", values.phone_number);
            formData.append("email", values.email);
            formData.append("address", values.address);
            formData.append("more_info", values.more_info);
            formData.append("type", type);
            if(errorType){
                formData.append("error_type", errorType);
            }
            EnquiryForm(formData)
                .then((response) => {
                    globalLoader(false);
                    setSubmitting(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm({ values: "" });
                        navigate(`/`);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    globalLoader(false);
                    console.log("error ====> ", error);
                });
        },
    });


    return (
        <section id={id} className={"contact-sec section-padding "+className}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="contact-form">
                                <div className="headings text-center mb-4">
                                    <h2>{t(heading)}</h2>
                                </div>
                                <div className="contact-form-box">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label ">
                                                    {t("web_inquiry_form_label_name")} <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("web_inquiry_form_placeholder_name")}
                                                    name="name"
                                                    id="name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.name}
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="name" form={formik} />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="restaurant_name" className="form-label ">
                                                    {t("web_inquiry_form_label_restaurant_name")} <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("web_inquiry_form_placeholder_restaurant_name")}
                                                    name="restaurant_name"
                                                    id="restaurant_name"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.restaurant_name}
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="restaurant_name" form={formik} />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label htmlFor="phone_number" className="form-label ">
                                                    {t("web_inquiry_form_label_contact_number")} <span>*</span>
                                                </label>
                                                <PhoneInput
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("web_inquiry_form_placeholder_contact_number")}
                                                    name="phone_number"
                                                    id="phone_number"
                                                    // onChange={formik.handleChange}
                                                    onChange={(e) => { formik.setFieldValue('phone_number', e?.phone_number) }}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.phone_number}
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="phone_number" form={formik} shortCodes={{PHONE_MIN_LENGTH:PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH:PHONE_NO_LENGTH.max}}/>
                                                </span>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="email">
                                                    {t("web_inquiry_form_label_email")} <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("web_inquiry_form_placeholder_email")}
                                                    name="email"
                                                    id="email"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="email" form={formik} />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="address">
                                                    {t("web_inquiry_form_label_address")} <span>*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={t("web_inquiry_form_placeholder_address")}
                                                    name="address"
                                                    id="address"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.address}
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="address" form={formik} />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label className="form-label" htmlFor="more_info">{t(msgFieldLabel)}</label>
                                                <textarea
                                                    className="form-control"
                                                    placeholder={t("web_inquiry_form_placeholder_more_info")}
                                                    style={{ height: "120px" }}
                                                    name="more_info"
                                                    id="more_info"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.more_info}
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-primary w-100 justify-content-center">
                                                {t("web_inquiry_form_btn_submit")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Inquiry;