import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/destination-email-settings";
const options = {role: ROLE.RESTAURANT};

// {{base_url}}/restaurant/destination-email-settings/list


export const getEmailList = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const addEmail = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const editEmail= async (values) => {
  return await axiosInstance(options).put(`${path}/edit`, values);
}

export const deleteEmail= async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?_id=${values._id}`);
}

export const emailDetail = async (values) => {
  return await axiosInstance(options).get(`${path}/details?_id=${values._id}`);
}