import React from 'react'

function BulkImport() {
  return (
    <main id='main-content'>
    <div className='inner-container'>
        <div className='page-header d-flex align-items-center'>
            <h4 className='heading-title-text'>Bulk import </h4>
        </div>
        <div className='dashboard-content-wraper'>
            <div className='row'>
                <div className='col-md-12 col-lg-10 col-sm-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='mb-3'>
                                <label class="form-label">Choose upload type</label>
                                <div class="d-flex gap-5">
                                    <div class="form-check">
                                        <input class="form-check-input cp" name="type" type="radio" id="item" value="item" />
                                        <label class="form-check-label cp" for="item">Category</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input cp" name="type" type="radio" id="item" value="item" />
                                        <label class="form-check-label cp" for="item">Menu Items</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input cp" name="type" type="radio" id="item" value="item" />
                                        <label class="form-check-label cp" for="item">Options</label>
                                    </div>
                                </div>
                            </div>
                            <div className='csv-box'>
                                <h4>Inport CSV file</h4>
                                <div className='csv-input-ui d-flex align-items-center'>
                                    <input type='file'/>
                                    <button className='btn btn-primary'>Submit</button>
                                </div>
                                <p className='mt-3 mb-0'>Download sample <a href='#' className='link-text'>sample_file_demo_import.csv</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
  )
}

export default BulkImport
