import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { categoryList, detail, list } from "../../../services/table.services";
import { LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants';
import { TrimText, getColorType } from '../../../../../utils/commonfunction';
import GridLoader from '../../../../restaurant/shared/gridLoader';
import RecordNotFound from '../../../../restaurant/shared/recordNotFound';


export default function CashierCategory() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [categoriesList, setCategoriesList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableDetail, setTableDetail] = useState({});


  useEffect(() => {
    if (params.tableId) {
      detail({ o_id: params.tableId })
        .then((response) => {
          setTableDetail(response?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      navigate('/cashier/dashboard')
    }
  }, [params.tableId]);

  useEffect(() => {
    setLoader(true);
    categoryList()
      .then((data) => {
        if (data && data.data) {
          setCategoriesList(data.data.list || []);
        } else {
          setCategoriesList([]);
        }

        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [refreshList]);

  const handleRefreshList = () => {
    setRefreshList(!refreshList)
  }
  const handleCategoryClick = (categoryId) => {
    navigate(`/cashier/dashboard/menu/${params.tableId}/${categoryId}`); // Navigate to categories page with table ID
  };

  return (
    <>
      <div className='dashboard-cashier-left d-flex flex-column'>
        <div className='cashier-head text-center'>
          <div className='btns-head-in-left gap-2 d-flex'>
            <button className='btn btn-icon' onClick={() => { navigate(-1); }}>
              <i className='ti ti-arrow-left'></i>
            </button>
          </div>
          <h4>{TrimText(tableDetail.table_number, 20)}</h4>
          <div className='btns-head-in gap-2 d-flex'>
            <button className='btn btn-icon' data-bs-toggle="modal" data-bs-target="#exampleModal">
              <i className='ti ti-user'></i>
            </button>
            <button className='btn btn-icon'>
              <i className='ti ti-printer'></i>
            </button>
          </div>
        </div>

        <div class="modal fade modal-cashier" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header d-flex justify-content-between">
                <button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                  <i className='ti ti-arrow-left'></i>
                </button>
                <h1 class="modal-title fs-5" id="exampleModalLabel">Customer info</h1>
                <button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
                  <i className='ti ti-x'></i>
                </button>
              </div>
              <div class="modal-body">
                <div className='card-list'>
                  <ul>
                    <li>
                      <h4>Name</h4>
                      <h3>Lissa watson</h3>
                    </li>
                    <li>
                      <h4>Phone number</h4>
                      <h3>+81 45124 45124</h3>
                    </li>
                    <li>
                      <h4>Number of persons </h4>
                      <h3>5</h3>
                    </li>
                    <li>
                      <h4>Tag</h4>
                      <h3>VIP</h3>
                    </li>
                  </ul>
                </div>
                <button className='btn btn-primary w-100 mt-3'>Edit</button>
              </div>
            </div>
          </div>
        </div>
        {
          loader ?
            <GridLoader />
            :
            <div className='cashier-table-wrap'>
              {categoriesList.length ?
              <div className='category-list-grid'>
                {categoriesList.map((item, index) => {
                  return (<div key={index} className={'category-box cp ' + (`category_bg_${getColorType(item.color)}`)} style={{ background: item.color }} onClick={() => handleCategoryClick(item?._id)}>
                    <div class="category-name">
                      <span>{item.name}</span>
                    </div>
                    <div class="category-item-info">
                      <a class="text-white" href="#">{item.items_count} {t('label_items')}</a>
                    </div>
                  </div>)
                })}
              </div>
              :
              <RecordNotFound heading="no_category_found_text" text="no_category_found_text_para" />
            }
            </div>
        }
      </div>
    </>
  )
}
