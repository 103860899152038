import React, { useEffect, useMemo, useRef, useState } from 'react';
import { categoryList, menuItemList, menuItemDetail } from '../services/menu.service';
import Skeleton from 'react-loading-skeleton';
import { ITEM_TYPES, LOADER_TIMEOUT_TIME, SPICE_LEVELS } from '../../../utils/Constants';
import { useSelector } from 'react-redux';
import { useFormik } from "formik";
import combosImage from "../../../assets/qrOrder/images/combos-image.png";
import { getSessionKey, getSubTotalQRApp, showPrice } from '../../../utils/commonfunction';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';



export default function AddMenu() {
  const queryParams = new URLSearchParams(window.location.search);
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const [categoriesList, setCategoriesList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [catLoader, setCatLoader] = useState(true);
  const [itemLoader, setItemLoader] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(queryParams.get('category_id')||'');
  const [currentItem, setCurrentItem] = useState({});
  const addFormCloseButton = useRef();
  const addFormOpenButton = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const [langChanged, setLangChanged] = useState(false);
  const {t} = useTranslation();
  const [selectedItems, setSelectedItems] = useState([...(location?.state?.selectedItems || [])]);
  const [customerDetails, setCustomerDetails] = useState(location?.state?.customerDetails || null);

  const newToken = useSelector((state)=>{return state.QrOrderToken.qrPanelToken});
  useEffect(()=>{
    setToken(newToken);
  },[newToken]);


  const addForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      _id: '',
      type: '',
      image: '',
      item_name: '',
      addons: [],
      spice_level: '',
      spice_level_needed: null,
      price: '',
      tax_price: '',
      total_price: '',
      short_description: '',
      consumption_tax_rate: '',
    },
    validationSchema: Yup.object({
      addons: Yup.array().of(
        Yup.object({
          item_id: Yup.string().required('Please select an item')
        })
      )
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let data = [...selectedItems];
      let index = data.findIndex(item => item._id === values._id);
      if (index >= 0) {
        data[index] = values;
      } else {
        data.push(values);
      }
      setSelectedItems(data);
      addFormCloseButton?.current?.click();
    },
  });

  /** If user comes to edit an item */
  useEffect(() => {
    const editableId = queryParams.get('openEdit');
    if (editableId && editableId.length) {
      const data = selectedItems.filter(item => item?._id === editableId)[0];
      if(data){
        fetchMenuItemDetail(editableId).then(()=>{
          addForm.setValues(data);
          addFormOpenButton?.current?.click();
        }).catch((err)=>{
          console.error("Error while getting info", err);
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (token && token.length) {
      setCatLoader(true);
      categoryList({}, { token: token })
        .then((response) => {
          setCategoriesList(response.data.list);
          if(!selectedCategory){
            setSelectedCategory(response.data.list[0]?._id);
          }
          setTimeout(() => { setCatLoader(false) }, LOADER_TIMEOUT_TIME);
        })
        .catch((err) => { console.error(err) });
    }
  }, [token, langChanged]);

  useEffect(() => {
    if (token && token.length) {
      setItemLoader(true);
      let filterCategory = {};
      if (selectedCategory) {
        filterCategory.category_id = selectedCategory;
      }
      menuItemList(filterCategory, { token: token })
        .then((response) => {
          setItemList(response.data.list);
          setTimeout(() => { setItemLoader(false) }, LOADER_TIMEOUT_TIME)
        })
        .catch((err) => { console.error(err) });
    }
  }, [token, selectedCategory, langChanged]);

  const fetchMenuItemDetail = (_id) => {
    return new Promise((resolve, reject)=>{
      menuItemDetail({ o_id: _id }, { token: token }).then((response) => {
        setCurrentItem(response.data);
        let ifNotExists = selectedItems.findIndex(record => record?._id === _id) === -1;
        if (ifNotExists) {
          if (response.data.type === ITEM_TYPES.ITEM) {
            addForm.setValues({
              ...response.data,
              spice_level: response?.data?.spice_level_needed ? SPICE_LEVELS.LOW : null,
              spice_level_needed: response?.data?.spice_level_needed || false,
            });
          } else {
            let data = {
              ...response.data, addons: response?.data?.addons?.map((addon) => {
                return {
                  item_id: "",
                  item_name: "",
                  spice_level: null,
                  spice_level_needed: false,
                  addon_id: addon?._id,
                  addon_name: addon.addon_name,
                }
              })
            };
            addForm.setValues(data);
          }
        }
        setTimeout(() => {
          resolve(response);
        }, 100);
      }).catch((err) => { console.error(err); reject(err); })
    })
  }

  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setLangChanged(!langChanged);
    })
  });
  const isItemSelected = (item)=>{
    return selectedItems?.findIndex(record=>record?._id === item?._id) !== -1;
  };

  return (
    <>
      <div className='qr-body-wrap'>
        <div className='menu-qr-main-wrap'>
          <div className='qr-menu-left'>
            {!catLoader ? (<div className='qr-menu-list'>
              {categoriesList.length > 0 ? categoriesList.map((item, index) => {
                return (
                <button className={'menu-btn' + (selectedCategory === item._id ? ` active` : "")} 
                key={index} onClick={() => { 
                  setSelectedCategory(item?._id);
                  const searchParams = new URLSearchParams(window.location.search);
                  searchParams.set('category_id', item?._id);
                  navigate({
                    pathname: location.pathname,
                    search: searchParams.toString()
                  });
                }}>{item?.name} </button>
              )

              }) : ""}
              {/* <button className='menu-btn active'>Fries </button> */}
            </div>) : (<div className='qr-menu-list'>
              <Skeleton style={{ height: "37px" }} />
              <Skeleton style={{ height: "37px" }} />
              <Skeleton style={{ height: "37px" }} />
              <Skeleton style={{ height: "37px" }} />
              <Skeleton style={{ height: "37px" }} />
            </div>)
            }

          </div>
          <div className='qr-menu-right'>
            {!itemLoader ? (<div className='qr-menu-item-list'>
              {itemList.length > 0 ? itemList.map((item, index) => {
                return (
                  <div className={'qr-menu-item-col'+(isItemSelected(item)?" item-selected":"")} key={index}>
                    <figure>
                      <img src={item.type === ITEM_TYPES.ITEM ? item?.image : combosImage} alt={item?.item_name} />
                    </figure>
                    <figcaption>
                      <span>{item?.item_name}</span>
                      <span>¥ {showPrice(item?.price)}</span>
                      <button className='btn btn-sm btn-primary' data-bs-toggle="offcanvas" 
                      data-bs-target="#itemModifyCanvas" aria-controls="itemModifyCanvas" 
                      onClick={() => { fetchMenuItemDetail(item?._id) }}>
                        {isItemSelected(item)?"Update":t('link_add')}
                        </button>
                    </figcaption>
                  </div>
                )

              }) : ""}
            </div>) : (
              <div className='qr-menu-item-list'>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
                <div className='qr-menu-item-col'>
                  <Skeleton style={{ height: "70px" }} className='rounded-3 mb-3' />
                  <figcaption>
                    <Skeleton containerClassName='d-flex' />
                    <Skeleton containerClassName='d-flex' inline={true} style={{ height: "10px" }} />
                    <Skeleton containerClassName='d-flex' style={{ height: "33px" }} />
                  </figcaption>
                </div>
              </div>)}
          </div>
        </div>
      </div>
      {
        selectedItems.length ?
          <div className='cart-bottom p-3'>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <span>{selectedItems.length} {t('label_items')}</span>
              <span>¥  {showPrice(getSubTotalQRApp(selectedItems)?.grand_total)}</span>
            </div>
            <button className='btn btn-primary w-100' onClick={() => {
              const searchParams = location.search;
              navigate(`/qr-order/review${searchParams}`, {
                state: {
                  selectedItems: selectedItems,
                  customerDetails:customerDetails
                }
              });

            }}>{t('review_place_order_text')}</button>
          </div>
          : null
      }
      <button className='btn btn-sm btn-primary d-none' ref={addFormOpenButton} data-bs-toggle="offcanvas" data-bs-target="#itemModifyCanvas" aria-controls="itemModifyCanvas"></button>
      <div className="offcanvas offcanvas-bottom qr-offcanvas" data-bs-backdrop="static" tabIndex="-1" id="itemModifyCanvas" aria-labelledby="itemModifyCanvasLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="itemModifyCanvasLabel">{addForm?.values?.item_name}</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ref={addFormCloseButton} onClick={()=>{
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('openEdit');
            navigate({
              pathname: location.pathname,
              search: searchParams.toString()
            });
          }}></button>
        </div>

        <div className="offcanvas-body small" >
          <small className='mb-3 d-block'>{addForm?.values?.short_description}</small>
          <form onSubmit={addForm.handleSubmit}>


            {
              addForm?.values?.type === ITEM_TYPES.ITEM ?
                <>
                  {
                    addForm?.values?.spice_level_needed ?
                      <>
                        <div className='card card-2'>
                          <div className='p-3'>
                            <div className="row-veg-card">
                              <div className="head-veg-card d-flex align-items-center justify-content-between mb-2">
                                <h4>{t('spice_level_text')}</h4>
                              </div>
                              <div className="head-veg-card d-flex align-items-center justify-content-between mb-3">
                                <h4>{addForm?.values?.item_name || addForm?.values?.addon_name}</h4>
                              </div>
                              <div className="veg-tags">
                                <ul>
                                  <li>
                                    <div className="check-input-tick">
                                      <input type="radio" className='cp' onChange={addForm.handleChange} name="spice_level" id={"spice_level_low" + addForm?.values?._id} checked={addForm?.values?.spice_level === SPICE_LEVELS.LOW} value={SPICE_LEVELS.LOW} />
                                      <label htmlFor={"spice_level_low" + addForm?.values?._id} className='cp'>
                                        <i className="ti ti-circle-check"></i> {t('label_low')}
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="check-input-tick">
                                      <input type="radio" className='cp' onChange={addForm.handleChange} name="spice_level" id={"spice_level_medium" + addForm?.values?._id} checked={addForm?.values?.spice_level === SPICE_LEVELS.MEDIUM} value={SPICE_LEVELS.MEDIUM} />
                                      <label htmlFor={"spice_level_medium" + addForm?.values?._id} className='cp'>
                                        <i className="ti ti-circle-check"></i> {t('label_medium')}
                                      </label>
                                    </div>
                                  </li>
                                  <li>
                                    <div className="check-input-tick">
                                      <input type="radio" className='cp' onChange={addForm.handleChange} name="spice_level" id={"spice_level_high" + addForm?.values?._id} checked={addForm?.values?.spice_level === SPICE_LEVELS.HIGHT} value={SPICE_LEVELS.HIGHT} />
                                      <label htmlFor={"spice_level_high" + addForm?.values?._id} className='cp'>
                                        <i className="ti ti-circle-check"></i> {t('label_high')}
                                      </label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                      : null
                  }
                </>
                :
                <>
                  <h4 className="mb-3">{t('label_choose_option')}</h4>
                  <div className='card card-2'>
                    <div className='p-3'>
                      {
                        currentItem?.addons?.map((addon, index) => {
                          return (
                            <div key={index}>
                              <div className="row-veg-card">
                                <div className="head-veg-card d-flex align-items-center justify-content-between mb-3">
                                  <h4>{addon?.addon_name}</h4>
                                  <div className="form-group">
                                    <select className="form-control w-200px cp" onBlur={addForm.handleBlur} name={`addons[${index}].item_id`} value={addForm?.values?.addons[index]?.item_id} onChange={(e) => {
                                      addForm.handleChange(e);
                                      let selectedItemData = addon?.items.filter((item) => item?._id === e?.target?.value)[0];
                                      if (selectedItemData) {
                                        addForm.setFieldValue(`addons[${index}].item_name`, selectedItemData?.item_name);
                                        addForm.setFieldValue(`addons[${index}].spice_level_needed`, selectedItemData?.spice_level_needed);
                                        if (selectedItemData?.spice_level_needed) {
                                          if (addForm?.values?.addons[index]?.spice_level) {
                                            /** Already selected by user */
                                          } else {
                                            addForm.setFieldValue(`addons[${index}].spice_level`, SPICE_LEVELS.LOW);
                                          }
                                        } else {
                                          addForm.setFieldValue(`addons[${index}].spice_level`, null);
                                        }
                                      }
                                      setTimeout(() => {
                                        addForm.handleBlur(e);
                                      }, 100);
                                    }}>
                                      <option value={""}>{t('input_select_text')}</option>
                                      {
                                        addon?.items?.map((option, opIndex) => {
                                          return <option key={opIndex} value={option?._id}>{option?.item_name}</option>
                                        })
                                      }
                                    </select>
                                    {
                                      addForm?.touched && addForm?.touched.addons && addForm?.touched.addons[index]?.item_id && !addForm?.values?.addons[index]?.item_id ? 
                                      <span className='validation-error text-danger fs-14'>{t('select_item_text')}</span>
                                      :null
                                    }
                                  </div>
                                </div>
                                {
                                  (function () {
                                    let selectedItemData = addon?.items.filter((item) => item?._id === addForm?.values?.addons[index]?.item_id)[0];
                                    if (selectedItemData && selectedItemData?.spice_level_needed) {
                                      return (
                                        <>
                                          <div className="head-veg-card d-flex align-items-center justify-content-between mb-2">
                                            <h4>{t('spice_level_text')}</h4>
                                          </div>
                                          <div className="veg-tags">
                                            <ul>
                                              <li>
                                                <div className="check-input-tick">
                                                  <input type="radio" className='cp' onChange={addForm.handleChange} name={`addons[${index}].spice_level`} id={"spice_level_low" + addon?._id} checked={addForm?.values?.addons[index]?.spice_level === SPICE_LEVELS.LOW} value={SPICE_LEVELS.LOW} />
                                                  <label htmlFor={"spice_level_low" + addon?._id} className='cp'>
                                                    <i className="ti ti-circle-check"></i> {t('label_low')}
                                                  </label>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="check-input-tick">
                                                  <input type="radio" className='cp' onChange={addForm.handleChange} name={`addons[${index}].spice_level`} id={"spice_level_medium" + addon?._id} checked={addForm?.values?.addons[index]?.spice_level === SPICE_LEVELS.MEDIUM} value={SPICE_LEVELS.MEDIUM} />
                                                  <label htmlFor={"spice_level_medium" + addon?._id} className='cp'>
                                                    <i className="ti ti-circle-check"></i> {t('label_medium')}
                                                  </label>
                                                </div>
                                              </li>
                                              <li>
                                                <div className="check-input-tick">
                                                  <input type="radio" className='cp' onChange={addForm.handleChange} name={`addons[${index}].spice_level`} id={"spice_level_high" + addon?._id} checked={addForm?.values?.addons[index]?.spice_level === SPICE_LEVELS.HIGHT} value={SPICE_LEVELS.HIGHT} />
                                                  <label htmlFor={"spice_level_high" + addon?._id} className='cp'>
                                                    <i className="ti ti-circle-check"></i> {t('label_high')}
                                                  </label>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </>
                                      )
                                    }
                                    return null;
                                  })()
                                }

                              </div>
                              {index + 1 < currentItem?.addons.length
                                ?
                                <hr className='my-3' />
                                : null}
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </>
            }
            <button className='btn btn-primary w-100 mt-4' type='submit'>{isItemSelected(addForm?.values)?"Update":t('link_add')}</button>
          </form>
        </div>
      </div>
    </>
  )
}
