import axiosInstance from "../../../utils/axios";

const path = "/website" ; 
const commonPath= "common";

export const Banners = async (values) => {
    return await axiosInstance().get(`${path}/banners`, values);
}
export const FeaturedRestaurant = async (values) => {
    return await axiosInstance().get(`${path}/featured-restaurant`, values);
}

export const EnquiryForm = async (values) => {
    return await axiosInstance().post(`${path}/enquiry-form`, values);
}


export const Testimonials = async (values) => {
    return await axiosInstance().get(`${path}/testimonials`, values);
}

export const CMSOptions = async (values) => {
    return await axiosInstance().get(`${path}/cms-options?menu_location=${values}`);
}

    // ------------------------------------common-------------------------------------- //

export const GlobalSettingsDetails = async () => {
  return await axiosInstance().get(`${commonPath}/global-settings`);
};

export const CMSDetails = async (value) => {
  return await axiosInstance().get(`${commonPath}/cms-page?slug=${value}`);
};

