import React from 'react'

function ServiceCharge() {
  return (
    <main id='main-content'>
        <div className='inner-container'>
            <div className='page-header d-flex align-items-center'>
                <h4 className='heading-title-text'>Service Charge</h4>
            </div>
            <div className='service-charges-box'>
                <div className='card'>
                    <div className='card-body'>
                        <div className='d-flex gap-3 justify-content-between'>
                            <div className='w-75 d-flex gap-3'>
                                <div className='form-group w-100'>
                                    <input type='text' className='form-control' placeholder='Service Charges'/>
                                </div>
                                <div className='form-group'>
                                    <div class="input-group">
                                        <input type="text" class="form-control no_spring_input" placeholder="Enter Cost Price" aria-label="Username" aria-describedby="basic-addon1" name="cost_price" value="0"/>
                                        <span class="input-group-text" id="basic-addon1">%</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-check form-switch">
                                <input class="form-check-input cp" type="checkbox"/>
                            </div>
                        </div>
                        <hr></hr>
                        <div className='d-inline-flex gap-5'>
                            <div class="form-check">
                                <input class="form-check-input cp" name="type" type="radio" id="item" value="item"/>
                                <label class="form-check-label cp" for="item">Always</label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input cp" name="type" type="radio" id="item" value="item"/>
                                <label class="form-check-label cp" for="item">At the time of checkout</label>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='btn btn-primary mt-4'>Update</button>
            </div>
        </div>
    </main>
  )
}

export default ServiceCharge
