import React,{useState} from 'react'
import { Link } from 'react-router-dom'
import ReactApexChart from 'react-apexcharts';    

export default function SalesReportStatusByWeek() {

    const [options] = useState({
        chart: {
          type: 'area',
          height: 350,
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        // colors: ['#EB4034', '#EB8C34', '#EBE234', '#34EB61', '#34EBEB', '#3484EB', '#7D34EB'],
        colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56'], 

        labels: [
          "2024-01-01",
          "2024-01-02",
          "2024-01-03",
          "2024-01-04",
          "2024-01-05",
          "2024-01-06",
          "2024-01-07",
        ],
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          opposite: false
        },
        legend: {
            horizontalAlign: 'left',
            onItemHover: {
                toggleDataSeries: true // Enable toggling of series visibility via the legend
            }
        }
      });
      
      const [seriesData] = useState([
        {
          name: "Monday",
          data: [120.5, 127.0, 121.5, 129.0, 124.5, 126.0, 125.5]
        },
        {
          name: "Tuesday",
          data: [130.5, 132.0, 131.5, 133.0, 134.5, 136.0, 135.5]
        },
        {
          name: "Wednesday",
          data: [140.5, 142.0, 141.5, 143.0, 144.5, 146.0, 145.5]
        },
        {
          name: "Thursday",
          data: [150.5, 152.0, 151.5, 153.0, 154.5, 156.0, 155.5]
        },
        {
          name: "Friday",
          data: [160.5, 162.0, 161.5, 163.0, 164.5, 166.0, 165.5]
        },
        {
          name: "Saturday",
          data: [170.5, 172.0, 171.5, 173.0, 174.5, 176.0, 175.5]
        },
        {
          name: "Sunday",
          data: [180.5, 182.0, 181.5, 183.0, 184.5, 186.0, 185.5]
        }
      ]);
      
  return (
    <main id='main-content'>
        <div className='inner-container'>
            <div className='d-flex tabs-with-etc justify-content-between align-items-center mb-4'>
                <ul className="nav nav-pills gap-3">
                    <li className="nav-item">
                        <Link className="nav-link " to={'/restaurant/sales'}>By day</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link " to={'/restaurant/sales/sales-report-status-month'}>By month</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active" to={'/restaurant/sales/sales-report-status-week'}>By day of the week</Link>
                    </li>
                </ul>
                <div className='tabls-dowpdown'>
                    <select className='form-control cp'>
                        <option selected disabled>Choose payment method type</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                    </select>
                </div>
            </div>
            <div className='card'>
                <div className='card-header border-0'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <button className='btn btn-date'><i className='ti ti-calendar'></i> 14 May 2024 <i className='ti ti-chevron-down'></i></button>
                        <div className='card-head-group d-flex align-items-center gap-2'>
                            <select className="form-control cp">
                                <option>With tax</option>
                                <option>2</option>
                                <option>3</option>
                            </select>
                            <button className='btn btn-black'><i className='ti ti-download'></i> Download</button>
                            <button className='btn btn-black'><i className='ti ti-printer'></i> Print</button>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                          <ReactApexChart options={options} series={seriesData} type="area" height={350} />
                </div>
            </div>
        </div>
    </main>
  )
}