import React from 'react'
// import { Link } from 'react-router-dom'

export default function Coupon() {
  return (
    <main id='main-content'>
        <div className='inner-container'>
            <div className='page-header d-flex align-items-center'>
                <h4 className='heading-title-text'>Coupon Settings </h4>
            </div>
            <div className='card-outer-header d-flex justify-content-between align-items-center mb-4'>
                <div className='search-box'>
                    <input type='text' className='form-control search-input' placeholder='Search'/>
                </div>
                <div className='card-head-group d-flex align-items-center gap-2'>
                    <div className="tabls-dowpdown">
                        <select className="form-control cp">
                            <option disabled="">Status</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                        </select>
                    </div>
                    <button className='btn btn-primary'><i className='ti ti-plus'></i> Create Coupon</button>
                </div>
            </div>
        </div>
    </main>
  )
}

