import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/menu-management/menu-item"
const options = {role: ROLE.RESTAURANT};



export const menuItemList = async (values ) => {
    return await axiosInstance(options).post(`${path}/list` , values);
}

export const addMenuItem = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const editMenuItem = async (values) => {
  return await axiosInstance(options).put(`${path}/edit`, values);
}

export const menuItemDetails = async (values) => {
  return await axiosInstance(options).get(`${path}/details?o_id=${values.o_id}`);
}

export const deleteMenuItem = async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?o_id=${values.o_id}`);
}

export const sortMenuItems = async (values) => {
  return await axiosInstance(options).patch(`${path}/sort`, values);
}

export const Options = async (type) => {
  return await axiosInstance(options).get(`${path}/options?type=${type}`);
}

export const latest_menu_item_code = async () => {
  return await axiosInstance(options).get(`${path}/item-code`);
}