import React, { useEffect, useState } from 'react';
import { contractDetails } from '../../../services/profile.services';
import { useTranslation } from 'react-i18next';
import { LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants';
import Skeleton from 'react-loading-skeleton';
import { formateDate, removeSpecialCharacter, capitalizeFirstLetter } from '../../../../../utils/commonfunction';

export default function ContractInformation() {

    const [ContractDetail, setContractDetail] = useState({});
    const { t } = useTranslation();
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setLoader(true)
        contractDetails().then((response) => {
            setContractDetail(response?.data || {});
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
        }).catch((err) => {
            setTimeout(() => { setLoader(false); }, LOADER_TIMEOUT_TIME);
            console.log(err);
        })
    }, []);

    return (
        <main id='main-content'>
            <div className='inner-container'>
                <div className='page-header d-flex align-items-center'>
                    <h4 className='heading-title-text'>{t("link_contract_information")}</h4>
                </div>
                {loader ? <div className='w-100'>
                    <Skeleton className='mb-4' height={150} />
                    <Skeleton className='mb-2' height={40} count={6} />
                </div> : <>
                    <div className='row'>
                        <div className='col-lg-12 col-xl-12 col-xxl-8'>
                            <div className='card'>
                                <div className='list-info'>
                                    <ul>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("restaurant_id")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.id}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("contract_expiry")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.enrollment_details?.expiry_date ? formateDate(ContractDetail?.enrollment_details?.expiry_date):""}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("plan")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.enrollment_details?.plan_name ? capitalizeFirstLetter(removeSpecialCharacter(ContractDetail?.enrollment_details?.plan_name)):""}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-lg-12 col-xl-12 col-xxl-8'>
                            <div className='card'>
                                <div className='list-info'>
                                    <ul>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("label_company_name")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.enrollment_details?.company_name ? capitalizeFirstLetter(ContractDetail?.enrollment_details?.company_name):""}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("label_restaurants_name")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.restaurant_name ? capitalizeFirstLetter(ContractDetail?.restaurant_name) :""}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("address")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.address}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("label_shipping_address")} </span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.enrollment_details?.shipping_address}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("store_rep_name")} </span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.enrollment_details?.representative_name}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("email_address")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.email}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("tel_number")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.phone_number}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='list-info-left'>
                                                <span>{t("contractor")}</span>
                                            </div>
                                            <div className='list-info-right'>
                                                <span>{ContractDetail?.enrollment_details?.contractor_name}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
        </main>
    )
}
