import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import {
    globalLoader,
    handleServerValidations,
} from "../../../../utils/commonfunction";
import { forgetPassword } from "../../services/auth.services";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import CustomError from "../../../../utils/customError";
import { useValidationSchemas } from "../../validator/validation"
import logo from '../../../../assets/restaurant/images/logo.svg';

const ForgetPassword = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { forgetPasswordValidationSchema } = useValidationSchemas()

    const formik = useFormik({
        initialValues: { email: "" },
        validationSchema: forgetPasswordValidationSchema,
        onSubmit: (values, { resetForm }) => {
            globalLoader(true);
            const formData = new FormData();
            formData.append("email", values.email);

            forgetPassword(formData)
                .then((response) => {
                    globalLoader(false)
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm();
                        navigate(
                            `/restaurant/otp-verification`, { state: { email: values.email } }
                        );
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    globalLoader(false);
                    console.log("error--->", error)
                });
        }
    });

    return (
        <div className="login-wrap">
            <div className="login-wrap-in">
                <div className="login-logo">

                    <img src={logo} alt="" />

                </div>
                <div className="login-form-wrap">
                    <div className="login-form-header">
                        <h4>{t("forgot_password")}</h4>
                        <p>{t("paragraph_enter_email_we_will_send_you_OTP")}</p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="login-form">
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">{t("label_email_address")}<span>*</span></label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    placeholder={t("placeholder_email_address")}
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik?.values?.email}

                                />
                                <span className="text-danger d-flex text-left">
                                    <CustomError name="email" form={formik} />
                                </span>

                            </div>
                            <button type="submit" className={"btn btn-primary w-100 justify-content-center"}>
                                {t("btn_submit")}
                            </button>

                        </div>
                    </form>
                    <div className="login-cta-bottom text-center mt-3">
                        <p className="mb-2">
                            {t("did_you_remembered_your_password")}?{" "}
                            {t("label_try_to")} <Link className="" to="/restaurant/login">{t("link_login")}</Link>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;
