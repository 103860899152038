import React from 'react';
import { Outlet, useParams, Link, useLocation } from 'react-router-dom';
import Breadcrums from '../../common/breadcrumbs';
import { useTranslation } from "react-i18next";
const RestaurantView = (props) => {
    const {t} = useTranslation();
    const params = useParams();
    const loc = useLocation();

    const breadcrumbs = [
        { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
        {
            title: t("sidebar_link_restaurant"),
            url: "/admin/restaurant-management/restaurant/list/1",
        },
        { title: t("btn_view"), url: "" },
    ];



    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                {/* <h6 className="main-content-label">{eventTitle}</h6> */}
                                <div className="">
                                    <h6 className="main-content-label mb-3">
                                        {t("label_restaurant_details")}
                                    </h6>
                                </div>
                            </div>
                            <div className="tabs-wrap">
                                <div className="profile-tab tab-menu-heading mb-4">
                                    <nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">

                                        <Link to={`/admin/restaurant-management/restaurant/view/${params?.id}/generals`}
                                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/restaurant-management/restaurant/view/${params?.id}/generals`) ? " active" : "")}>
                                            {t("title_generals")}
                                        </Link>
                                        <Link to={`/admin/restaurant-management/restaurant/view/${params?.id}/enroll-restaurant/details/1`} 
                                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/restaurant-management/restaurant/view/${params?.id}/enroll-restaurant`) ? " active" : "")}>
                                            {t("title_enrollments")}
                                        </Link>
                                        <Link to={`/admin/restaurant-management/restaurant/view/${params?.id}/transition-details/1`} 
                                        className={"nav-link linkactive" + (loc.pathname.includes(`/admin/restaurant-management/restaurant/view/${params?.id}/transition-details`) ? " active" : "")}>
                                            {t("title_transactions")}
                                        </Link>

                                    </nav>
                                </div>


                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RestaurantView;