import React, { useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import { useTranslation } from "react-i18next";
import {
  INPUT_LENGTH_15,
  PHONE_NO_LENGTH,
  SWAL_SETTINGS,
  TEXTAREA_MAX_LENGTH,
} from "../../../../utils/Constants";
import {
  blockInvalidChar,
  DT,
  globalLoader,
  handleServerValidations,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../../../utils/customError";
import { Add } from "../../services/usermgmt.services";
import { cuisineOptions } from "../../../../utils/Constants";
import { TagPicker, Tooltip, Whisper } from "rsuite";
import PhoneInput from "../../../../utils/PhoneInput";
import { PHONE_VALIDATION } from "../../../../utils/commonValidations";

const UsersAdd = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const breadcrumbs = [
    { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
    {
      title: t("sidebar_link_restaurant"),
      url: "/admin/restaurant-management/restaurant/list/1",
    },
    { title: t("link_add"), url: "" },
  ];

  const validationSchema = Yup.object().shape({
    restaurant_name: Yup.string().trim().max(100, "max_length_error").required("label_restaurant_name_error"),
    email: Yup.string()
      .trim()
      .email("label_email_invalid_format_error")
      .test('no-uppercase', "label_email_uppercase_error", function (value) {
        if (value) {
          return !/[A-Z]/.test(value);
        }
        return true;
      })
      .required("label_email_required_error"),
    phone_number: PHONE_VALIDATION,
    address: Yup.string().trim().max(TEXTAREA_MAX_LENGTH, "validation_max_input_characters").required("label_location_error"),
    profile_image: Yup.mixed()
    .nullable()
    .test(
      'fileType',
      'supported_file_error',
      value => !value || ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type)
    )
    .test(
      'fileSize',
      'image_max_size_error',
      value => !value || value.size <= 1.5 * 1024 * 1024
    ), 
    cuisine_type:Yup.array()
    .of(Yup.string().trim().required("label_cuisine_type_error"))
    .required("label_cuisine_type_error")
    .min(1, "At least one cuisine type is required")
,
  });



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      restaurant_name: "",
      email: "",
      address: "",
      phone_number: "",
      profile_image: "",
      cuisine_type: [],
    },
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      globalLoader(true);
      setSubmitted(true);
      let formData = new FormData();
      formData.append("restaurant_name", values.restaurant_name);
      formData.append("email", values.email);
      formData.append("address", values.address);
      formData.append("phone_number", values.phone_number);
      formData.append("restaurant_logo", values.profile_image);
      formData.append("cuisine_type", JSON.stringify(values.cuisine_type));

      Add(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            globalLoader(false);
            resetForm({ values: "" });
            navigate(`/admin/restaurant-management/restaurant/list/1`);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            globalLoader(false);
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    },
  });

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="col-lg-12 col-md-12 animation_fade">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h6 className="main-content-label mb-3">
                    {t("add_restaurants")}
                  </h6>
                </div>
                <div className="row row-sm">


                  <div className="col-md-6 text-center form-group">
                    <label htmlFor="restaurant_name" className="text-left d-flex">
                      {t("label_restaurants_name")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      name="restaurant_name"
                      id="restaurant_name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.restaurant_name}
                      className="form-control"
                      placeholder={t("web_inquiry_form_placeholder_restaurant_name")}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError shortCodes={[INPUT_LENGTH_15]} name="restaurant_name" form={formik} />
                    </span>
                    {/* <span>DT(t(form.errror), [])</span> */}
                  </div>

                  <div className="col-lg-6 text-center form-group">
                    <label htmlFor="email" className="text-left d-flex">
                      {t("label_email")}:<span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="form-control"
                      placeholder={t("placeholder_email")}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError name="email" form={formik} />
                    </span>
                  </div>

                  <div className="col-md-6 text-center form-group">
                    <label htmlFor="address" className="text-left d-flex">
                      {t("label_address")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      name="address"
                      id="address"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className="form-control"
                      placeholder={t("web_inquiry_form_placeholder_address")}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError shortCodes={[TEXTAREA_MAX_LENGTH]} name="address" form={formik} />
                    </span>
                  </div>

                  <div className="col-md-6 text-center form-group">
                    <label className="text-left d-flex" htmlFor="phone_number">
                      {t("label_phone_number")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <PhoneInput
                      name="phone_number"
                      id="phone_number"
                     
                      onChange={(e) => { formik.setFieldValue('phone_number', e?.phone_number) }}
                  
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_number}
                      className="form-control"
                      placeholder={t('phone_number_placeholder')}
                    
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError name="phone_number" form={formik} shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                    </span>
                  </div>

                  <div className="col-md-6 form-group">
                    <label htmlFor="cuisine_type" className="text-left d-flex">
                      {t("label_cuisine_type")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <div className="">
                       <TagPicker
                       className="cp"
                      data={cuisineOptions}
                      value={formik?.values?.cuisine_type}
                      onChange={(selectedValues) => {
                        formik.setFieldValue("cuisine_type", selectedValues);
                      }}
                      renderValue={(value, items) => {
                        const visibleOptions = 6;
                        const displayItems = items.slice(0, visibleOptions); // Show only the first 2 items
                        const hiddenCount = items.length - displayItems.length;
                        const removeTag = (i)=>{
                          let allValues = [...formik?.values?.cuisine_type];
                          allValues.splice(i,1);
                          formik.setFieldValue("cuisine_type", allValues);
                        };
                        return <>
                          {displayItems.map((item,i) => {
                            return (
                              <span className="rs-tag-text" key={i}>
                                <div role="option" title="Indian" className="rs-tag rs-tag-md rs-tag-default rs-tag-closable">
                                  <span className="rs-tag-text">{item?.label}</span>
                                  <button type="button" className="rs-tag-icon-close rs-btn-close" aria-label="Remove" onClick={()=>{removeTag(i)}}>
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" className="rs-icon" aria-label="close" data-category="application"><path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path></svg>
                                  </button>
                                </div>
                              </span>
                            )
                          })}
                          { items.length > visibleOptions ? <span> {hiddenCount} more ...</span> :null }
                        </>
                      }}
                      cleanable={false}
                    />
                    </div>
                    <span className="text-danger d-flex text-left">
                      <CustomError name="cuisine_type" form={formik} />
                    </span>
                  </div>

                  <div className="col-lg-6 text-center form-group">
                    <label htmlFor="profile_image" className="text-left d-flex">
                      {t("label_logo")}:
                    {" "}
                    <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("image_support_tooltip")}
                                </Tooltip>
                              }
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                    </label>
                    <input
                      className="form-control imgInput"
                      id="profile_image"
                      name="profile_image"
                      accept="image/*"
                      type="file"
                      onChange={(event) => {
                        formik.setFieldValue(
                          "profile_image",
                          event.target.files[0] || ""
                        );
                        event.target.files.length === 1
                          ? setPreviewImage(
                            URL.createObjectURL(event.target.files[0])
                          )
                          : setPreviewImage("");
                      }}
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError name="profile_image" form={formik} />
                    </span>

                    {previewimage ? (
                      <ul className="question-image-preview questions-ul">
                        <li className="pr_img_box">
                          <img
                            src={previewimage}
                            style={{ height: "100px" }}
                            alt={"Profile_Img"}
                          />
                          <div className="img_options">
                            <button type="button" className="text-danger"
                              onClick={() => {
                                // console.log(previewResImage , previewimage)
                                setPreviewImage("");
                                formik.setFieldValue("profile_image", null);
                                document.getElementById("profile_image").value = ""
                              }}>
                              <i className="ri-delete-bin-6-fill"></i>
                            </button>
                          </div>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* <div className="col-lg-6 text-center form-group">
                    <label htmlFor="images" className="text-left d-flex">
                      {t("label_restaurant_images")}:
                    </label>
                    <input
                      className="form-control imgInput"
                      id="files"
                      name="images"
                      accept="image/*"
                      type="file"
                      multiple={true}
                      onChange={(event) => {
                        if (
                          event &&
                          event.target &&
                          event.target.files &&
                          event.target.files.length
                        ) {
                          const existingFiles = Array.from(event.target.files);
                          formik.setFieldValue("images", existingFiles);
                        }
                        //-------------
                        // setFieldValue("images", event.target.files[0]);
                        if (
                          event &&
                          event.target &&
                          event.target.files &&
                          event.target.files.length >= 1
                        ) {
                          const previewImages = Array.from(event.target.files);
                          setPreviewResImage(previewImages);
                        } else {
                          setPreviewResImage([]);
                        }
                      }}
                    />

                    {previewResImage && previewResImage.length > 0 ? (
                      <ul className="question-image-preview questions-ul">
                        {previewResImage.map((image, index) => (
                          <li className="pr_img_box">
                            <img
                              // src={image}
                              src={URL.createObjectURL(image)}
                              style={{ height: "100px" }}
                              alt={`Preview ${index}`}
                            />
                            <div className="img_options">
                              <button type="button" className="text-danger">
                                <i className="ri-delete-bin-6-fill"></i>
                              </button>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : null}

                  
                  </div> */}



                  {/* <div className="col-md-6 text-center form-group">
                    <label
                      htmlFor="branch_details"
                      className="text-left d-flex"
                    >
                      Branch Details:{
" "}
                    </label>
                    <textarea
                      name="branch_details"
                      id="branch_details"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.branch_details}
                      className="form-control"
                      style={{ height: "82px" }}
                    />
                  </div> */}

                  <div className="mt-5">
                    <button
                      className="btn btn-info mr-2"
                      type="submit"
                      disabled={submitted ? true : null}
                    >
                      <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                      {t("btn_submit")}
                    </button>
                    <button
                      className="btn ripple btn-secondary"
                      type="button"
                      disabled={submitted ? true : null}
                      onClick={() => navigate(-1)}
                    >
                      <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                      {t("btn_cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UsersAdd;