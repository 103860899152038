import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toHtml from "html-react-parser";
import Breadcrums from "../../../common/breadcrumbs";
import Loader from "../../../common/loader";
import { breakWord, formateDate, showStatus } from "../../../../../utils/commonfunction";
import * as pageServices from "../../../services/pages.services";
import { useTranslation } from "react-i18next";

const PageView = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const breadcrumbs = [
    { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
    { title: t("sidebar_link_pages"), url: "/admin/cms/pages/list/1" },
    { title: t("btn_view"), url: "" },
  ];
 
  // const selected_language = localStorage.getItem("system_language")
  // console.log(selected_language)

  useEffect(() => {
    pageServices
      .Details(params.id)
      .then((data) => {
        setShowDefault(data && data.data ? data.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">{t("view_cms")}</h6>
              </div>
              {showdefault && Object.keys(showdefault)?.length > 0 ? (
                <div className="row">
                  <div className="col-lg-12 form-group">
                    <table
                      id="simple-table"
                      className="table  table-bordered table-hover"
                    >
                      <tbody>
                        
                        <tr>
                          <th>{t("label_page_title")} </th>
                          <td className="text-capitalize">
                            {showdefault && showdefault?.title
                              ? showdefault.title
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("label_page_title_ja")} </th>
                          <td className="text-capitalize">
                            {showdefault && showdefault?.title_ja
                              ? showdefault.title_ja
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("label_slug_of_page")}</th>
                          <td>
                            {showdefault && showdefault.slug
                              ? showdefault.slug
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("menu_location")}</th>
                          <td>
                            {showdefault && showdefault.menu_location
                              ? showdefault.menu_location
                              : "N/A"}
                          </td>
                        </tr>
                        
                        <tr>
                          <th>{t("label_short_description")}</th>
                          <td>
                            {showdefault && showdefault?.short_description
                              ? breakWord(showdefault.short_description)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("label_short_description_ja")}</th>
                          <td>
                            {showdefault && showdefault?.short_description_ja
                              ? breakWord(showdefault.short_description_ja)
                              : "N/A"}
                          </td>
                        </tr>
                        
                        <tr>
                          <th>{t("list_heading_status")}</th>
                          <td>
                            {showdefault && showdefault?.status === 0
                              ? toHtml(showStatus(showdefault.status))
                              : toHtml(showStatus(showdefault.status))}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("list_heading_created_date")}</th>
                          <td>
                            {showdefault && showdefault.createdAt
                              ? formateDate(showdefault.createdAt)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("label_modified_date")}</th>
                          <td>
                            {showdefault && showdefault?.updatedAt
                              ? formateDate(showdefault.updatedAt)
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h6 className="main-content-label mb-3">{t("label_long_description")}:</h6>
                    {/* <div className="">{toHtml(showdefault && showdefault.description ? showdefault.description : "N/A")}</div> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          showdefault && showdefault?.description
                            ? showdefault.description
                            : "N/A",
                      }}
                    ></div>
                    <h6 className="main-content-label mb-3">
                      {t("description_japanese")}:
                    </h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          showdefault && showdefault?.description_ja
                            ? showdefault.description_ja
                            : "N/A",
                      }}
                    ></div>
                    <div className="mt-5">
                      <button
                        className="btn ripple btn-dark"
                        type="button"
                        onClick={() => navigate(-1)}
                      >
                        <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                        {t("btn_back")}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageView;
