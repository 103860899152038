import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey } from '../../../utils/commonfunction';
import { setGlobalLoader } from '../../../redux/slices/globalLoader';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLoader from './globalLoader';
import {ROLE} from '../../../utils/Constants'

const RestaurantPublicRoute = ({ component: Component }) => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const userData = getLocalKey(ROLE.RESTAURANT) ? JSON.parse(getLocalKey(ROLE.RESTAURANT)) : {};

    useEffect(() => {
        Promise.all([import('rsuite/dist/rsuite.min.css'),
        import('bootstrap/dist/css/bootstrap.min.css'),
        import("../../../assets/admin/plugins/fancyuploder/fancy_fileupload.css")]).then(() => {
            dispatch(setGlobalLoader(false));
        })

    }, []);


    return (
        <>
            {
                userData && userData.token ?
                    <Navigate to={{ pathname: "/restaurant" }} />

                    :
                    loaderState ?
                        <GlobalLoader /> :
                        <Component />
            }
        </>
    )

}

export default RestaurantPublicRoute;