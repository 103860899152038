import { createSlice } from "@reduxjs/toolkit";

export const QrCodeSettingsSlice = createSlice({
  name: "QrOrderToken",
  initialState: {
    qrPanelToken: "",
    tableDetails: ""
  },
  reducers: {
    addQrPanelToken: (state, action) => {
      state.qrPanelToken = action.payload;
    },
    addTableDetails: (state, action) => {
      state.tableDetails = action.payload;
    },
}
});

export const { addQrPanelToken, addTableDetails } = QrCodeSettingsSlice.actions;
export default QrCodeSettingsSlice.reducer;
