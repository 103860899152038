import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { restaurantProfileDetails, updateRestaurantProfile, cashierPasswordUpdate, setFiscalYearSetting } from "../../../services/profile.services";
import Swal from "sweetalert2";
import { LOADER_TIMEOUT_TIME, ROLE, SWAL_SETTINGS } from "../../../../../utils/Constants";
import { useValidationSchemas } from "../../../validator/validation";
import { getUser, handleServerValidations } from "../../../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
import { DatePicker, TagPicker } from "rsuite";
import { cuisineOptions } from "../../../../../utils/Constants";
import CustomError from "../../../../../utils/customError";
import Skeleton from "react-loading-skeleton";
import { handlePicker } from "../../../../../utils/commonfunction";

export default function RestaurantProfile() {
  const [showPassword, setShowPassword] = useState(false);
  const { restaurantProfileValidationSchema, CashierPasswordValidationSchema } = useValidationSchemas();
  const fileSelectInput = useRef();
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);

  const toggleEyeIcon = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const profileForm = useFormik({
    initialValues: {
      restaurantId: "",
      restaurantName: "",
      restaurantAddress: "",
      city: "",
      zipCode: "",
      website: "",
      cuisineType: "",
      openingTime: "",
      closingTime: "",
      restaurant_logo: "",
    },
    enableReinitialize: true,
    validationSchema: restaurantProfileValidationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("restaurant_name", values.restaurantName);
      formData.append("address", values.restaurantAddress);
      formData.append("restaurant_city", values.city);
      formData.append("restaurant_zip_code", values.zipCode);
      formData.append("restaurant_website", values.website);
      formData.append("cuisine_type", JSON.stringify(values.cuisineType));
      formData.append("restaurant_opening_time", values.openingTime);
      formData.append("restaurant_closing_time", values.closingTime);
      formData.append("restaurant_logo", values.restaurant_logo);
      updateRestaurantProfile(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });

  const formCashier = useFormik({
    initialValues: { cashier_password: "", is_cashier_password_updated:false },
    enableReinitialize: true,
    validationSchema: CashierPasswordValidationSchema, // Add this line
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("password", values.cashier_password);
      cashierPasswordUpdate(formData)
        .then((response) => {
          if (response.success) {
            formCashier.resetForm();
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            formCashier.setValues({
              is_cashier_password_updated: true,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
    },
  });

  const formFiscalSettings = useFormik({
    initialValues: {
      fiscal_year_setting: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("fiscal_year_setting", values.fiscal_year_setting);
      setFiscalYearSetting(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });

  useEffect(() => {
    const userId = getUser(ROLE.RESTAURANT)?._id;
    setLoader(true);
    restaurantProfileDetails({ o_id: userId }, userId)
      .then((data) => {
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        profileForm.setValues({
          restaurantId: data?.data?.id || "",
          restaurantName: data?.data?.restaurant_name || "",
          restaurantAddress: data?.data?.address || "",
          city: data?.data?.restaurant_city || "",
          zipCode: data?.data?.restaurant_zip_code || "",
          website: data?.data?.restaurant_website || "",
          cuisineType: data?.data?.cuisine_type || "",
          openingTime: data?.data?.restaurant_opening_time || "",
          closingTime: data?.data?.restaurant_closing_time || "",
          restaurant_logo: data?.data?.restaurant_logo || "",
        });
        formFiscalSettings.setValues({
          fiscal_year_setting: data?.data?.restaurant_settings?.fiscal_year_setting || "",
        });
        formCashier.setValues({
          is_cashier_password_updated: data?.data?.is_cashier_password_updated,
        });
      })
      .catch((err) => {
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        console.log(err);
      });
  }, []);

  const handleFileSelection = (event) => {
    if (event.target.files.length) {
      profileForm.setFieldValue("restaurant_logo", event.target.files[0]);
    }
  };
  const handleFilePreview = (file) => {
    if (typeof file === "string") {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  return (
    <main id="main-content">
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">{t("link_restaurant_profile")}</h4>
        </div>
        <div className="card">
          <div className="card-body">
            {loader ? (
              <div className="">
                <Skeleton circle={true} style={{ width: "96px", height: "96px" }} />
                <Skeleton className="mt-4" style={{ height: "30px" }} />
                <Skeleton className="mt-4" style={{ height: "30px" }} />
                <Skeleton className="mt-4" style={{ height: "30px" }} />
                <Skeleton className="mt-4" style={{ height: "30px" }} />
                <Skeleton className="mt-4" style={{ height: "30px" }} />
                <Skeleton className="mt-4" style={{ height: "30px" }} />
              </div>
            ) : (
              <form onSubmit={profileForm.handleSubmit}>
                <div className="profile-photo-upload mb-3">
                  <div className="avatar avatar-xl">
                    <img alt="restaurant_logo" src={handleFilePreview(profileForm?.values?.restaurant_logo)} />
                  </div>
                  <button
                    className="cp e_none"
                    type="button"
                    onClick={() => {
                      fileSelectInput?.current?.click();
                    }}
                  >
                    <input ref={fileSelectInput} id="restaurant_logo" name="restaurant_logo" accept="image/*" type="file" onChange={handleFileSelection} />
                    <i className="ti ti-upload"></i>
                  </button>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        {t("restaurant_id")}
                        <span>*</span>
                      </label>
                      <input type="text" className="form-control" disabled value={profileForm.values.restaurantId} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        {t("label_restaurant_name")}
                        <span>*</span>
                      </label>
                      <input type="text" className="form-control" name="restaurantName" onChange={profileForm.handleChange} value={profileForm.values.restaurantName} />
                      {/* {profileForm.errors.restaurantName ? <div className="text-danger">{profileForm.errors.restaurantName}</div> : null} */}
                      <CustomError name="restaurantName" form={profileForm} className="text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3 input-address">
                      <label className="form-label">
                        {t("label_restaurant_address")}
                        <span>*</span>
                      </label>
                      <input type="text" className="form-control" name="restaurantAddress" onChange={profileForm.handleChange} value={profileForm.values.restaurantAddress} />
                      {/* {profileForm.errors.restaurantAddress ? <div className="text-danger">{profileForm.errors.restaurantAddress}</div> : null} */}
                      <CustomError name="restaurantAddress" form={profileForm} className="text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            {t("label_city")}
                            <span>*</span>
                          </label>
                          <input type="text" className="form-control" name="city" onChange={profileForm.handleChange} value={profileForm.values.city} />
                          {/* {profileForm.errors.city ? <div className="text-danger">{profileForm.errors.city}</div> : null} */}
                          <CustomError name="city" form={profileForm} className="text-danger" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label">{t("zip_code")}<span>*</span></label>
                          <input type="number" className="form-control no_spring_input" name="zipCode" onChange={profileForm.handleChange} value={profileForm.values.zipCode} />
                          {/* {profileForm.errors.zipCode ? <div className="text-danger">{profileForm.errors.zipCode}</div> : null} */}
                          <CustomError name="zipCode" form={profileForm} className="text-danger" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">
                        {t("label_website")}
                        <span></span>
                      </label>
                      <input type="text" className="form-control" name="website" onChange={profileForm.handleChange} value={profileForm.values.website} />
                      {/* {profileForm.errors.website ? <div className="text-danger">{profileForm.errors.website}</div> : null} */}
                      <CustomError name="website" form={profileForm} className="text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="form-label">{t("label_cuisine_type")}</label>
                      <TagPicker
                        data={cuisineOptions}
                        menuClassName="rest_multi_select_menu"
                        className="d-block rest_multi_select cp"
                        value={profileForm?.values?.cuisineType}
                        onChange={(selectedValues) => {
                          profileForm.setFieldValue("cuisineType", selectedValues);
                        }}
                        renderValue={(value, items) => {
                          const visibleOptions = 6;
                          const displayItems = items.slice(0, visibleOptions); // Show only the first 2 items
                          const hiddenCount = items.length - displayItems.length;
                          const removeTag = (i)=>{
                            let allValues = [...profileForm?.values?.cuisineType];
                            allValues.splice(i,1);
                            profileForm.setFieldValue("cuisineType", allValues);
                          };
                          return <>
                            {displayItems.map((item,i) => {
                              return (
                                <span className="rs-tag-text" key={i}>
                                  <div role="option" title="Indian" className="rs-tag rs-tag-md rs-tag-default rs-tag-closable">
                                    <span className="rs-tag-text">{item?.label}</span>
                                    <button type="button" className="rs-tag-icon-close rs-btn-close" aria-label="Remove" onClick={()=>{removeTag(i)}}>
                                      <svg width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" focusable="false" className="rs-icon" aria-label="close" data-category="application"><path d="M2.784 2.089l.069.058 5.146 5.147 5.146-5.147a.5.5 0 01.765.638l-.058.069L8.705 8l5.147 5.146a.5.5 0 01-.638.765l-.069-.058-5.146-5.147-5.146 5.147a.5.5 0 01-.765-.638l.058-.069L7.293 8 2.146 2.854a.5.5 0 01.638-.765z"></path></svg>
                                    </button>
                                  </div>
                                </span>
                              )
                            })}
                            { items.length > visibleOptions ? <span> {hiddenCount} more ...</span> :null }
                          </>
                        }}
                        cleanable={false}
                      />
                      <CustomError name="cuisineType" form={profileForm} className="text-danger" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="form-label">
                          {t("restaurant_time")}
                          <span>*</span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                          {/* <input type="time" className="form-control cp" onFocus={(e)=>{
                            handlePicker(e)
                          }} name="openingTime" onChange={profileForm.handleChange} value={profileForm.values.openingTime}
                          onBlur={profileForm.handleBlur} /> */}
                          <DatePicker classPrefix="restaurant" placement="autoVertical" format="HH:mm" cleanable={false} editable={true} name="openingTime" onChange={(e)=>{
                          profileForm.setFieldTouched('openingTime', true);
                          profileForm.setFieldValue("openingTime" , e);
                        }} value={new Date(profileForm.values.openingTime)} 
                          onBlur={profileForm.handleBlur}/>
                          <CustomError name="openingTime" form={profileForm} className="text-danger" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3">
                        <DatePicker classPrefix="restaurant" placement="autoVertical" format="HH:mm" cleanable={false} editable={true} name="closingTime" onChange={(e)=>{
                          profileForm.setFieldTouched('closingTime', true);
                          profileForm.setFieldValue("closingTime" , e);
                        }} value={new Date(profileForm.values.closingTime)} 
                          />
                          {/* <input type="time" className="form-control cp"
                           onFocus={(e)=>{
                            handlePicker(e)
                            e?.target?.showPicker();
                          }} name="closingTime" onChange={profileForm.handleChange} value={profileForm.values.closingTime} 
                          onBlur={profileForm.handleBlur}
                          /> */}
                          <CustomError name="closingTime" form={profileForm} className="text-danger" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-form">
                  <button type="submit" className="btn btn-primary">
                    {t("btn_update_profile")}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
        {loader ? <div className="card">
         <div className="card-body">
         <Skeleton className="mt-3" style={{ height: "30px" }} />
         <Skeleton className="mt-3" style={{ height: "30px" }} />

         </div>

        </div> :   
         <div className="row mt-4">
          <div className="col-md-6">
            <div className="profile-set-row">
              <h4 className="heading-title-text mb-3">{t(formCashier?.values?.is_cashier_password_updated ? "rest_update_cashier_password" : "rest_set_cashier_password")}</h4>
              <form onSubmit={formCashier.handleSubmit}>
                <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                      <label className="form-label d-flex" htmlFor="cashier_password">{t("cashier_password")}<span>*</span></label>
                        <div className="ms-auto">
                          <div className="input-group">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control"
                              id="cashier_password"
                              name="cashier_password"
                              value={formCashier.values.cashier_password}
                              onChange={formCashier.handleChange}
                              onBlur={formCashier.handleBlur}
                            />
                            <button
                              className="input-group-text"
                              type="button"
                              onClick={() => {
                                toggleEyeIcon();
                              }}
                            >
                              {showPassword ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}
                            </button>
                          </div>
                          {formCashier.touched.cashier_password && formCashier.errors.cashier_password ? <div className="text-danger">{t(formCashier.errors.cashier_password)}</div> : null}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" className="btn btn-primary">
                        {t(formCashier?.values?.is_cashier_password_updated?"btn_text_update":"btn_create")}
                      </button>
                    </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="profile-set-row">
              <h4 className="heading-title-text mb-3">{t("fiscal_year")}</h4>
              <form onSubmit={formFiscalSettings.handleSubmit}>
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <label className="form-label d-flex" htmlFor="fiscal_year_setting">{t("starting_month")}</label>
                      <div className="ms-auto">
                        <select className="form-control cp" id="fiscal_year_setting" name="fiscal_year_setting" onChange={formFiscalSettings.handleChange} value={formFiscalSettings.values.fiscal_year_setting}>
                          <option value="January">{t("month_jan")}</option>
                          <option value="February">{t("month_feb")}</option>
                          <option value="March">{t("month_mar")}</option>
                          <option value="April">{t("month_apr")}</option>
                          <option value="May">{t("month_may")}</option>
                          <option value="June">{t("month_jun")}</option>
                          <option value="July">{t("month_jul")}</option>
                          <option value="August">{t("month_aug")}</option>
                          <option value="September">{t("month_sep")}</option>
                          <option value="October">{t("month_oct")}</option>
                          <option value="November">{t("month_nov")}</option>
                          <option value="December">{t("month_dec")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      {t("btn_update")}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>}
    
      </div>
    </main>
  );
}
