import React from "react";
import { Outlet } from "react-router-dom";
// import GlobalLoader from "../../../shared/globalLoader";
import Sidebar from "./sidebar";

const cashierSettingsLayout = () => {
  return (
    <>
      <div className='dashboard-cashier-wrap'>
        <div className='dashboard-cashier-container d-flex'>
          <Sidebar />
          <div className='cashier-right-part'>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default cashierSettingsLayout;
