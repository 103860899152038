import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './slices/profileslice';
import globalLoaderSlice from './slices/globalLoader';
import userTypeSlice from './slices/userType';
import GlobalDetailsSlice from './slices/globalDetails';
import RestSettingsSlice from './slices/restSettings';
import CashierDetailSlice  from './slices/cashierDetail';
import QrCodeSettingsSlice  from './slices/qrcodeDetails';

const store = configureStore({
    reducer: {
        profile: profileReducer,
        user:userTypeSlice,
        globalLoader: globalLoaderSlice,
        globalData: GlobalDetailsSlice,
        restSettings: RestSettingsSlice,
        cashierDetails:CashierDetailSlice , 
        QrOrderToken : QrCodeSettingsSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default store;

