import React from 'react'
import { useTranslation } from 'react-i18next';
// import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

export default function Sidebar() {
  const location = useLocation();
  const { t } = useTranslation();
  // Determine if the link should be active
  const isActive = (path) => {
    const currentPath = location.pathname;
    return currentPath.startsWith(path);
  };

  return (
    <div className='cashier-left-part'>
      <ul className='cashier-menus'>
        <li>
          <NavLink
            to={'/cashier/settings'}
            className={isActive('/cashier/settings') ? 'active' : ''}
          >
            <i className='ti ti-chart-dots'></i>
            <span>Today’s Sales</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={'/cashier/settings'}>
            <i className='ti ti-chart-dots-3'></i>
            <span>Sales History</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={'/cashier/settings'}>
            <i className='ti ti-brand-netbeans'></i>
            <span>Check Network</span>
          </NavLink>
        </li>
        <li>

          <NavLink to={'/cashier/settings'}>
            <i className='ti ti-sun'></i>
            <span>Settlement</span>
          </NavLink>
        </li>
      </ul>
    </div>
  )
}
