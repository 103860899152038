import React from 'react'
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom'

export default function Reservations() {
const { t } = useTranslation();
  return (
    <main id='main-content'>
        <div className='inner-container'>
            <div className='page-header d-flex align-items-center'>
                <h4 className='heading-title-text'>{t("label_reservations")} </h4>
            </div>

            <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
                        <div className="search-box">
                            <input type="text" className="form-control search-input"  placeholder={t("input_placeholder_search")} />
                        </div>
                        <div className="card-head-group d-flex align-items-center gap-2">
                            <div className="tabls-dowpdown">
                                <select className="form-control cp" >
                                    <option disabled="" value="">   {t("label_select_status")} </option>
                                    <option value="1"> {t("btn_active")}</option>
                                    <option value="0">{t("btn_inactive")}</option>
                                </select>
                            </div>
                            <button className="btn btn-primary" >
                                <i className="ti ti-plus"></i> {t("label_make_reservation")}
                            </button>
                        </div>
                    </div>
            
        </div>
    </main>
  )
}
