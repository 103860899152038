import React, { useEffect, useRef, useState } from "react";
import { globalLoader, handleOffcanvasScroll, handleServerValidations } from "../../../../../utils/commonfunction";
import { getRestaurantSettings, UpdateEmailNotification } from "../../../services/profile.services";
import { LOADER_TIMEOUT_TIME, SWAL_SETTINGS } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import { getEmailList, addEmail, deleteEmail, editEmail } from "../../../services/mail-notification-setting.services";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../../validator/validation";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import RecordNotFound from "../../../shared/recordNotFound";
import CustomPagination from "../../../../admin/common/custompagination";
import { useLocation, useParams } from "react-router-dom";
import TableLoader from "../../../../../utils/TableLoader";
import trash from "../../../../../assets/restaurant/images/trash.svg";

export default function MailNotificationSettings() {
  const params = useParams();
  const location = useLocation();
  const [mailNotificationDetails, setMailNotificationDetails] = useState({});
  const [emailList, setEmailList] = useState([]);
  const { profileValidationSchema } = useValidationSchemas();
  const [emailItem, setEmailItem] = useState({});
  const [page, setPage] = useState(1);
  const [offset, setOffsetPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage] = useState(10);
  const { t } = useTranslation();
  const addEmailCloseBtn = useRef();
  const editEmailCloseBtn = useRef();
  const [detailsLoader, setDetailsLoader] = useState(true);
  const [listLoader, setListLoader] = useState(true);
  const [refreshList, setRefreshList] = useState(true);
  const [emailData, setEmailData] = useState({});

  const addDestinationEmail = useFormik({
    initialValues: {
      email: "",
      opening: 0,
      settlement: 0,
      cancel_sales: 0,
      edit_sales: 0,
    },
    enableReinitialize: true,
    validationSchema: profileValidationSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("opening", values.opening);
      formData.append("settlement", values.settlement);
      formData.append("cancel_sales", values.cancel_sales);
      formData.append("edit_sales", values.edit_sales);
      addEmail(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setRefreshList(!refreshList);
            resetForm();
            addEmailCloseBtn?.current?.click();
          } else {
            Swal.fire({
              icon: "error",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });

  const editDestinationEmail = useFormik({
    initialValues: {
      email: emailItem.email || "",
      opening: emailItem.opening || 0,
      settlement: emailItem.settlement || 0,
      cancel_sales: emailItem.cancel_sales || 0,
      edit_sales: emailItem.edit_sales || 0,
    },
    enableReinitialize: true,
    validationSchema: profileValidationSchema,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("_id", emailItem._id);
      formData.append("email", values.email);
      formData.append("opening", values.opening);
      formData.append("settlement", values.settlement);
      formData.append("cancel_sales", values.cancel_sales);
      formData.append("edit_sales", values.edit_sales);
      //   console.log(values)
      editEmail(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setRefreshList(!refreshList);
            setEmailItem({
              email: values.email,
              opening: values.opening,
              settlement: values.settlement,
              cancel_sales: values.cancel_sales,
              edit_sales: values.edit_sales,
            });
            resetForm();
            editEmailCloseBtn?.current?.click();
          } else {
            Swal.fire({
              icon: "error",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });

  useEffect(() => {
    setDetailsLoader(true);
    getRestaurantSettings()
      .then((response) => {
        setTimeout(() => {
          setDetailsLoader(false);
        }, LOADER_TIMEOUT_TIME);
        setMailNotificationDetails(response && response?.data ? response?.data : {});
      })
      .catch((error) => {
        setTimeout(() => {
          setDetailsLoader(false);
        }, LOADER_TIMEOUT_TIME);
        console.log("Error===>", error);
      });
  }, []);

  useEffect(() => {
    setListLoader(true);
    getEmailList({ page: params.page, per_page: itemPerPage })
      .then((response) => {
        setEmailList(response.data.list);
        setOffsetPage(response.data.offset);
        setTotalRecords(response.data.total_records);
        setTimeout(() => {
          setListLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((err) => {
        console.error(err);
        setTimeout(() => {
          setListLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [location, refreshList]);

  const handelEmailNotificationToggle = (e, key) => {
    let data = {};
    const action = e?.target?.checked ? true : false;
    data[key] = action;
    // console.log("data",data);
    UpdateEmailNotification(data)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: response.message,
          ...SWAL_SETTINGS,
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: handleServerValidations(error),
          ...SWAL_SETTINGS,
        });
      });
  };

  const deleteEmailItem = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        deleteEmail({ _id: item._id })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setRefreshList(!refreshList);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            console.log("deleteError", error);
          });
      }
    });
  };

  return (
    <main id="main-content">
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">{t("link_mail_notification_settings")}</h4>
        </div>
        {detailsLoader ? (
          <div className="row">
            <div className="col-md-3">
              <Skeleton className="rounded-3" style={{ height: "113px" }} />
            </div>
            <div className="col-md-3">
              <Skeleton className="rounded-3" style={{ height: "113px" }} />
            </div>
            <div className="col-md-3">
              <Skeleton className="rounded-3" style={{ height: "113px" }} />
            </div>
            <div className="col-md-3">
              <Skeleton className="rounded-3" style={{ height: "113px" }} />
            </div>
          </div>
        ) : (
          <div className="row mail-notification-box">
            <div className="col-md-3">
              <div className="card mail-notification-card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="mail-card-left">
                      <h4>{t("mail_opening")}</h4>
                      <p>{t("opening_sales_text")}</p>
                    </div>
                    <div className="mai-card-right">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input cp"
                          type="checkbox"
                          defaultChecked={mailNotificationDetails?.openings_emails}
                          onInput={(e) => handelEmailNotificationToggle(e, "openings_emails")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mail-notification-card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="mail-card-left">
                      <h4>{t("mail_settlement")}</h4>
                      <p>{t("enable_mail_text_settlement")}</p>
                    </div>
                    <div className="mai-card-right">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input cp"
                          type="checkbox"
                          defaultChecked={mailNotificationDetails?.settlements_emails}
                          onInput={(e) => handelEmailNotificationToggle(e, "settlements_emails")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mail-notification-card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="mail-card-left">
                      <h4>{t("mail_cancel")}</h4>
                      <p>{t("cancel_sales_text")}</p>
                    </div>
                    <div className="mai-card-right">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input cp"
                          type="checkbox"
                          defaultChecked={mailNotificationDetails?.cancel_sales_emails}
                          onInput={(e) => handelEmailNotificationToggle(e, "cancel_sales_emails")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="card mail-notification-card">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="mail-card-left">
                      <h4>{t("mail_edit")}</h4>
                      <p>{t("edit_sales_text")}</p>
                    </div>
                    <div className="mai-card-right">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input cp"
                          type="checkbox"
                          defaultChecked={mailNotificationDetails?.edit_sales_emails}
                          onInput={(e) => handelEmailNotificationToggle(e, "edit_sales_emails")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="page-header d-flex align-items-center mt-5">
          <h4 className="heading-title-text">{t("email_list_text")}</h4>
          <button className="btn btn-primary ms-auto" data-bs-toggle="offcanvas" data-bs-target="#addNewEmail" aria-controls="staticBackdrop">
            {t("add_new_email")}
          </button>
        </div>
        <div className="card">
          <div className="card-body card-body-table">
            {listLoader ? (
              <TableLoader />
            ) : (
              <>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>{t("list_heading_sno")}</th>
                        <th>{t("label_email_address")}</th>
                        <th>{t("link_mail_notification_settings")}</th>
                        <th>{t("label_action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {emailList.length > 0 ? (
                        emailList.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1 + offset}</td>
                              <td>{item.email}</td>
                              <td>
                                <div className="checkbox-table d-flex gap-4">
                                  <div className="form-check e_none">
                                    <input className="form-check-input" type="checkbox" id={"main-permission-opening" + index} defaultChecked={item.opening === 1 ? true : false} />
                                    <label className="form-check-label" htmlFor={"main-permission-opening" + index}>
                                      {t("mail_opening")}
                                    </label>
                                  </div>
                                  <div className="form-check e_none">
                                    <input className="form-check-input" type="checkbox" id={"main-permission-settlement" + index} defaultChecked={item.settlement === 1 ? true : false} />
                                    <label className="form-check-label" htmlFor={"main-permission-settlement" + index}>
                                      {t("mail_settlement")}
                                    </label>
                                  </div>
                                  <div className="form-check e_none">
                                    <input className="form-check-input" type="checkbox" id={"main-permission-cancel_sales" + index} defaultChecked={item.cancel_sales === 1 ? true : false} />
                                    <label className="form-check-label" htmlFor={"main-permission-cancel_sales" + index}>
                                      {t("mail_cancel")}
                                    </label>
                                  </div>
                                  <div className="form-check e_none">
                                    <input className="form-check-input" type="checkbox" id={"main-permission-edit_sales" + index} defaultChecked={item.edit_sales === 1 ? true : false} />
                                    <label className="form-check-label" htmlFor={"main-permission-edit_sales" + index}>
                                      {t("mail_edit")}
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="dropdown">
                                  <button className="btn btn-icon dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="ti ti-dots-vertical"></i>
                                  </button>
                                  <ul
                                    onClick={() => {
                                      setEmailItem(item);
                                      // console.log(item);
                                    }}
                                    className="dropdown-menu"
                                  >
                                    <li data-bs-toggle="offcanvas" data-bs-target="#viewNewEmail">
                                      <a
                                        className="dropdown-item"
                                        href="/"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          let data = {...item, permissions:[]};
                                          if(item.settlement === 1){
                                            data.permissions.push("mail_settlement");
                                          }
                                          if(item.opening === 1){
                                            data.permissions.push("mail_opening");
                                          }
                                          if(item.cancel_sales === 1){
                                            data.permissions.push("mail_cancel");
                                          }
                                          if(item.edit_sales === 1){
                                            data.permissions.push("mail_edit");
                                          }
                                          
                                          setEmailData(data);
                                        }}
                                      >
                                        <i className="ti ti-eye"></i> {t("btn_view")}
                                      </a>
                                    </li>
                                    <li data-bs-toggle="offcanvas" data-bs-target="#editNewEmail">
                                      <a className="dropdown-item" href="/" onClick={(e) => e.preventDefault()}>
                                        <i className="ti ti-edit"></i> {t("btn_edit")}
                                      </a>
                                    </li>
                                    <li
                                      onClick={() => {
                                        deleteEmailItem(item);
                                      }}
                                    >
                                      <a className="dropdown-item" href="/" onClick={(e) => e.preventDefault()}>
                                        <i className="ti ti-trash"></i> {t("btn_delete")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4}>
                            <RecordNotFound heading="lbl_record_not_found" text="paragraph_record_not_found" />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="table-status-info d-flex justify-content-between align-items-center">
                  <span>
                    {t("label_showing")} {offset + emailList.length} of {totalRecords} {t("label_entries")}
                  </span>
                  <nav aria-label="Page navigation example">
                    {totalRecords && totalRecords > 0 ? (
                      <CustomPagination
                        datalength={totalRecords}
                        itemperpage={itemPerPage}
                        currentPage={page}
                        custompath="page"
                        setPage={setPage}
                        pageRoute={[{ name: "mail-notification-settings", path: "/restaurant/profile/mail-notification-settings" }]}
                      />
                    ) : (
                      ""
                    )}
                  </nav>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* modal for adding a new email  */}
      <div className="offcanvas offcanvas-end right-modal-w" id="addNewEmail" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("form_heading_add_new_email")}
          </h5>
          <button
            ref={addEmailCloseBtn}
            onClick={() => {
              addDestinationEmail.resetForm();
            }}
            type="button"
            className="btn btn-icon ms-auto"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="ti ti-x"></i>
          </button>
        </div>
        <div
          className="offcanvas-body"
          onScroll={(e) => {
            handleOffcanvasScroll(e, "addNewEmail");
          }}
        >
          <form onSubmit={addDestinationEmail.handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="email">
                {t("label_email")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_email_address")}
                name="email"
                id="email"
                value={addDestinationEmail.values.email}
                onChange={addDestinationEmail.handleChange}
                onBlur={addDestinationEmail.handleBlur}
              />
              {addDestinationEmail.errors.email && addDestinationEmail.touched.email ? <div className="error text-danger">{t(addDestinationEmail.errors.email)}</div> : null}
            </div>
            <div className="mb-3">
              <h6 className="mb-3">{t("select_mail_settings")}</h6>
              <div className="checkbox-list d-flex flex-column gap-3">
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="opening_check"
                    name="opening"
                    checked={addDestinationEmail.values.opening === 1}
                    onChange={() => {
                      addDestinationEmail.setFieldValue("opening", addDestinationEmail.values.opening === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="opening_check">
                    {t("mail_opening")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="settlement"
                    name="settlement"
                    checked={addDestinationEmail.values.settlement === 1}
                    onChange={() => {
                      addDestinationEmail.setFieldValue("settlement", addDestinationEmail.values.settlement === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="settlement">
                    {t("mail_settlement")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="cancel_sales"
                    name="cancel_sales"
                    checked={addDestinationEmail.values.cancel_sales === 1}
                    onChange={() => {
                      addDestinationEmail.setFieldValue("cancel_sales", addDestinationEmail.values.cancel_sales === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="cancel_sales">
                    {t("mail_cancel")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="edit_sales"
                    name="edit_sales"
                    checked={addDestinationEmail.values.edit_sales === 1}
                    onChange={() => {
                      addDestinationEmail.setFieldValue("edit_sales", addDestinationEmail.values.edit_sales === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="edit_sales">
                    {t("mail_edit")}
                  </label>
                </div>
              </div>
            </div>
            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("link_add")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* modal for edit a email setting  */}

      <div className="offcanvas offcanvas-end right-modal-w" id="editNewEmail" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("form_heading_edit_email")}
          </h5>
          <button
            ref={editEmailCloseBtn}
            onClick={() => {
              editDestinationEmail.resetForm();
            }}
            type="button"
            className="btn btn-icon ms-auto"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="ti ti-x"></i>
          </button>
        </div>
        <div
          className="offcanvas-body"
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editNewEmail");
          }}
        >
          <form onSubmit={editDestinationEmail.handleSubmit}>
            <div className="mb-3">
              <label className="form-label" htmlFor="edit_email">
                {t("label_email")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_email_address")}
                name="email"
                id="edit_email"
                value={editDestinationEmail.values.email}
                onChange={editDestinationEmail.handleChange}
                onBlur={editDestinationEmail.handleBlur}
              />
              {editDestinationEmail.errors.email && editDestinationEmail.touched.email ? <div className="error text-danger">{t(editDestinationEmail.errors.email)}</div> : null}
            </div>
            <div className="mb-3">
              <h6 className="mb-3">{t("select_mail_settings")}</h6>
              <div className="checkbox-list d-flex flex-column gap-3">
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="opening"
                    name="opening"
                    checked={editDestinationEmail.values.opening === 1}
                    onChange={() => {
                      editDestinationEmail.setFieldValue("opening", editDestinationEmail.values.opening === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="opening">
                    {t("mail_opening")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="flexCheckDefault6"
                    name="settlement"
                    checked={editDestinationEmail.values.settlement === 1}
                    onChange={() => {
                      editDestinationEmail.setFieldValue("settlement", editDestinationEmail.values.settlement === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="flexCheckDefault6">
                    {t("mail_settlement")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="flexCheckDefault7"
                    name="cancel_sales"
                    checked={editDestinationEmail.values.cancel_sales === 1}
                    onChange={() => {
                      editDestinationEmail.setFieldValue("cancel_sales", editDestinationEmail.values.cancel_sales === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="flexCheckDefault7">
                    {t("mail_cancel")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    type="checkbox"
                    id="flexCheckDefault8"
                    name="edit_sales"
                    checked={editDestinationEmail.values.edit_sales === 1}
                    onChange={() => {
                      editDestinationEmail.setFieldValue("edit_sales", editDestinationEmail.values.edit_sales === 1 ? 0 : 1);
                    }}
                  />
                  <label className="form-check-label cp" htmlFor="flexCheckDefault8">
                    {t("mail_edit")}
                  </label>
                </div>
              </div>
            </div>
            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_edit")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* modal for view a email setting  */}

      <div className="offcanvas offcanvas-end right-modal-w" id="viewNewEmail" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("view_email_setting")}
          </h5>
          <button
            type="button"
            className="btn btn-icon ms-auto"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="ti ti-x"></i>
          </button>
        </div>
        <div
          className="offcanvas-body"
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editNewEmail");
          }}
        >
          <div className="view-info">
            <div className="row gap-4">
              <div className="col-md-12">
                <h4>{t("label_email")}:</h4>
                <p>{emailData.email}</p>
              </div>

              <div className="col-md-12">
                <h4>{t("link_mail_notification_settings")}:</h4>
                <p>
                  {emailData?.permissions?.map((key, i)=>{
                    return <>{t(key)}{(i < emailData?.permissions.length-1 ? ", ":"")}</>
                  })}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
