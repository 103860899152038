import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { SWAL_SETTINGS, INPUT_LENGTH_15, PHONE_NO_LENGTH, TEXTAREA_MAX_LENGTH, PLAN_TYPES, } from "../../../../utils/Constants";
import {
    handleServerValidations,
    DT,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import { DatePicker } from 'rsuite';
import CustomError from "../../../../utils/customError";
import { useTranslation } from "react-i18next";
import { RestEnrollList, RestEnrollEdit } from "../../services/restaurant.services";
import PhoneInput from "../../../../utils/PhoneInput";
import { PHONE_VALIDATION } from "../../../../utils/commonValidations";

const EditEnrollDetails = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation();
    const [showdefault, setShowDefault] = useState({});
    // const [previewimage, setPreviewImage] = useState("");
    // const [previewResImage, setPreviewResImage] = useState([]);
    const [saveType, setSaveType] = useState("");
    

    useEffect(() => {
        RestEnrollList({ restaurant_id: params.id })
            .then((response) => {
                console.log("Response--->", response.data.list[0]);
                setShowDefault(response && response.data.list ? response.data.list[0] : []);
                // setPreviewImage(response.data.profile_image);
                // setPreviewResImage(response.data.images);
            })
            .catch((error) => {
                console.log("error=====>", error);
            });
    }, [params.id]);




    
    // function getImageUrl(image) {
    //     if (typeof image === "string") {
    //         return image;
    //     } else {
    //         return URL.createObjectURL(image);
    //     }
    // }



    const validationSchema = Yup.object().shape({
 
        company_name: Yup.string().trim().max(INPUT_LENGTH_15, DT(t("validation_max_input_characters"), [INPUT_LENGTH_15])).required(t("label_company_name_error")),
        company_address: Yup.string().trim().max(TEXTAREA_MAX_LENGTH, DT(t("validation_max_input_characters"), [TEXTAREA_MAX_LENGTH])).required(t("label_company_address_error")),
        shipping_address: Yup.string().trim().max(TEXTAREA_MAX_LENGTH, DT(t("validation_max_input_characters"), [TEXTAREA_MAX_LENGTH])).required(t("label_shipping_address_error")),
        representative_name: Yup.string().trim().max(INPUT_LENGTH_15, DT(t("validation_max_input_characters"), [INPUT_LENGTH_15])).required(t("label_representative_name_error")),
        contractor_name: Yup.string().trim().max(INPUT_LENGTH_15, DT(t("validation_max_input_characters"), [INPUT_LENGTH_15])).required(t("label_contractor_name_error")),
        phone_number:PHONE_VALIDATION,
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            company_name: showdefault && showdefault.company_name ? showdefault.company_name : "",
            representative_name: showdefault && showdefault.representative_name ? showdefault.representative_name : "",
            contractor_name: showdefault && showdefault.contractor_name ? showdefault.contractor_name : "",
            // email: showdefault && showdefault.email ? showdefault.email : "",
            phone_number: showdefault && showdefault?.phone_number ? showdefault?.phone_number : "",
            membership_cost: showdefault && showdefault?.membership_cost ? showdefault?.membership_cost : "",
            plan_name: showdefault && showdefault?.plan_name ? showdefault?.plan_name : "",
            contract_plan_duration_type: showdefault && showdefault?.contract_plan_duration_type ? showdefault?.contract_plan_duration_type : "",
            expiry_date: showdefault && showdefault?.expiry_date ? showdefault?.expiry_date : "",

            company_address: showdefault && showdefault.company_address ? showdefault.company_address : "",
            shipping_address: showdefault && showdefault.shipping_address ? showdefault.shipping_address : "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            console.log(values.profile_image)
            let formData = new FormData();
            formData.append("_id", showdefault._id);
            formData.append("company_name", values.company_name);
            formData.append("representative_name", values.representative_name);
            formData.append("phone_number", values.phone_number);
            formData.append("contractor_name", values.contractor_name);
            formData.append("company_address", values.company_address);
            formData.append("shipping_address", values.shipping_address);

            RestEnrollEdit(formData)
                .then((response) => {
                    setSubmitting(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        if (saveType !== "Save") {
                            navigate(`/admin/restaurant-management/restaurant/view/${params.id}/rest-enrol-details`);
                        }
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(error),
                        ...SWAL_SETTINGS,
                    });
                    console.log("error ====> ", error);
                });
        },
    });
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="">
                                <div>
                                    <h6 className="main-content-label mb-3">
                                        Edit Enrollments
                                    </h6>
                                </div>
                                <div className="row row-sm">

                                    {/* <div className="col-md-6 text-center form-group">
                    <label htmlFor="rest_Id" className="text-left d-flex">
                      {t("label_id")}:
                      <span className="requirestar">*</span>{" "}
                    </label>
                    <input
                      name="rest_Id"
                      id="rest_Id"
                      type="text"
                      disabled={true}
                      // onBlur={formik.handleBlur}
                      value={restData.id}
                      className="form-control"
                    />
                    <span className="text-danger d-flex text-left">
                      <CustomError name="rest_Id" form={formik} />
                    </span>
                  </div> */}

                                    <div className="col-md-6 text-center form-group">
                                        <label className="text-left d-flex" htmlFor="phone_number">
                                            {t("label_phone_number")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <PhoneInput
                                            name="phone_number"
                                            id="phone_number"
                                            
                                            onChange={(e) => { formik.setFieldValue('phone_number', e?.phone_number) }}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.phone_number}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="phone_number" form={formik} shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="company_name" className="text-left d-flex">
                                            {t("label_company")} {t("list_heading_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="company_name"
                                            id="company_name"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.company_name}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="company_name" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="representative_name" className="text-left d-flex">
                                            {t("label_representative_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="representative_name"
                                            id="representative_name"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.representative_name}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="representative_name" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="contractor_name" className="text-left d-flex">
                                            {t("label_contractor_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="contractor_name"
                                            id="contractor_name"
                                            type="text"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.contractor_name}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="contractor_name" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="plan_name" className="text-left d-flex">
                                            {t("label_plan_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <select
                                            name="plan_name"
                                            id="plan_name"
                                            disabled={true}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.plan_name}
                                            className="form-control cp" >
                                            <option value="">{t("input_select_text")}</option>
                                            {
                                                PLAN_TYPES.map((item , index)=>{return(<option key={index} value={item.value}>{item.label}</option>)})
                                            }
                                            <option value="demo">{t("input_select_option_demo")}</option>
                                            <option value="Premium plan">{t("label_premium_plan")}</option>
                                        </select>
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="plan_name" form={formik} />
                                        </span>
                                    </div>

                                    {formik.values.plan_name === "demo" ?
                                        (<div className="col-md-6 text-center form-group">
                                            <label htmlFor="expiry_date" className="text-left d-flex">
                                                {t("label_expiry_date")}:
                                                <span className="requirestar">*</span>{" "}
                                            </label>
                                            <DatePicker
                                                format="dd.MM.yyyy"
                                                name="expiry_date"
                                                id="expiry_date"
                                                type="date"
                                                disabled={true}
                                                // onChange={formik.handleChange}
                                                onChange={value => formik.setFieldValue('expiry_date', new Date(value))}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.expiry_date ? new Date(formik.values.expiry_date) : null}
                                            />
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="expiry_date" form={formik} />
                                            </span>
                                        </div>) :
                                        null}

                                    {formik.values.plan_name === "Premium plan" ?
                                        <>
                                            <div className="col-md-6 text-center form-group">
                                                <label htmlFor="contract_plan_duration_type" className="text-left d-flex">
                                                    {t("contract_plan_duration_type")}:
                                                    <span className="requirestar">*</span>{" "}
                                                </label>
                                                <div className="col-md-6 text-left form-group mt-3" style={{ margin: 0, padding: 0 }}>
                                                    <label className="radio-inline mr-2">
                                                        <input
                                                            type="radio"
                                                            name="contract_plan_duration_type"
                                                            value="monthly"
                                                            disabled={true}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            checked={formik.values.contract_plan_duration_type === "monthly"}
                                                        /> {t("input_radio_monthly")}
                                                    </label>
                                                    <label className="radio-inline mr-2">
                                                        <input
                                                            type="radio"
                                                            name="contract_plan_duration_type"
                                                            value="yearly"
                                                            disabled={true}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            checked={formik.values.contract_plan_duration_type === "yearly"}
                                                        />  {t("input_radio_yearly")}
                                                    </label>
                                                </div>
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="contract_plan_duration_type" form={formik} />
                                                </span>
                                            </div>


                                            <div className="col-md-6 text-center form-group">
                                                <label htmlFor="membership_cost" className="text-left d-flex">
                                                    {t("label_membership_cost")}:
                                                    <span className="requirestar">*</span>{" "}
                                                </label>
                                                <input
                                                    name="membership_cost"
                                                    id="membership_cost"
                                                    type="text"
                                                    disabled={formik.values.plan_name === "Premium plan" ? true : false}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.membership_cost}
                                                    //   value={formik.values.plan_name === "Premium plan" ?
                                                    //     formik.values.contract_plan_duration_type === "yearly" ? yearlyEnrollCost : monthlyEnrollCost
                                                    //     : formik.values.membership_cost}

                                                    className="form-control"
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="membership_cost" form={formik} />
                                                </span>
                                            </div>
                                        </>
                                        :
                                        null}

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="company_address" className="text-left d-flex">
                                            {t("label_company")} {t("label_address")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <textarea
                                            name="company_address"
                                            id="company_address"
                                            onChange={formik.handleChange}
                                            handleBlur={formik.handleBlur}
                                            value={formik.values.company_address}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="company_address" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="shipping_address" className="text-left d-flex">
                                            {t("label_shipping")} {t("label_address")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <textarea
                                            name="shipping_address"
                                            id="shipping_address"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.shipping_address}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="shipping_address" form={formik} />
                                        </span>
                                    </div>

                                    <div className="mt-5">
                                        <button
                                            onClick={() => {
                                                setSaveType("Save");
                                            }}
                                            className="btn btn-info mr-2"
                                            type="submit"
                                        >
                                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                            {t("btn_save")}
                                        </button>
                                        <button
                                            onClick={() => {
                                                setSaveType("");
                                            }}
                                            className="btn btn-success mr-2"
                                            type="submit"
                                        >
                                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                            {t("btn_save_exit")}
                                        </button>
                                        <button
                                            className="btn ripple btn-secondary"
                                            type="button"
                                            onClick={() => navigate(-1)}
                                        >
                                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                            {t("btn_cancel")}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default EditEnrollDetails;