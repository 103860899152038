import React, { useEffect, useRef } from 'react'
import { createSlider } from '../../../../utils/commonfunction';
import icon1 from '../../../../assets/website/images/icon-1.svg';
import icon2 from '../../../../assets/website/images/icon-2.svg';
import icon3 from '../../../../assets/website/images/icon-3.svg';
import icon4 from '../../../../assets/website/images/icon-4.svg';
import WebsiteTestimonials from '../../common/websitetestimonials';
import Inquiry from '../../common/inquiry';
import { useTranslation } from 'react-i18next';



function WebsiteManualGuide() {
  const homeSlider3 = useRef();
  const {t} = useTranslation();
  useEffect(()=>{
    if(homeSlider3?.current){
      createSlider('#homeSlider3',{
        loop: true,
        margin: 20,
        nav: true,
        item:2,
        dots:false,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
        });
      }
  },[homeSlider3]);
  return (
    <div className='manual-guide-page'>
      <section className="how-work-sec manual-guide-sec section-padding">
        <div className="container">
          <div className="headings  mb-5 text-center">
            <h4>{t("web_manual_guide")}</h4>
            <h2>{t('web_manual_guide_heading_text')}</h2>
          </div>
          <div className="row how-box">
            <div className="col-md-3">
              <article>
                <figure>
                  <span><img src={icon1} alt=''/></span>
                </figure>
                <figcaption>
                  <h4>{t('web_restaurant_admin_panel')}</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </figcaption>
              </article>
            </div>
            <div className="col-md-3">
              <article>
                <figure>
                  <span><img src={icon2} alt=''/></span>
                </figure>
                <figcaption>
                  <h4>{t('web_waiter_panel')}</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </figcaption>
              </article>
            </div>
            <div className="col-md-3">
              <article>
                <figure>
                  <span><img src={icon3} alt=''/></span>
                </figure>
                <figcaption>
                  <h4>{t('web_cashier_panel')}</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </figcaption>
              </article>
            </div>
            <div className="col-md-3">
              <article>
                <figure>
                  <span><img src={icon4} alt=''/></span>
                </figure>
                <figcaption>
                  <h4>{t('web_qr_code')}</h4>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.{" "}
                  </p>
                </figcaption>
              </article>
            </div>
          </div>
        </div>
      </section>
  
      <section className="testimonial-section section-padding pt-0">
       
       <WebsiteTestimonials id="testimonial-2" />
     </section>
     <Inquiry id="inquiry-2" />
     
    </div>
  )
}

export default WebsiteManualGuide
