import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUser, setReturnURL} from '../../../utils/commonfunction';
import { ROLE } from "../../../utils/Constants";

const CashierPrivetRoute = ({ component: Component }) => {
    const userData = getUser(ROLE.CASHIER);
    return (
        <>
            {(function(){
                if(Object.keys(userData).length && userData.token){
                    return <Component/>
                }else{
                    setReturnURL(ROLE.CASHIER);
                    return <Navigate to="/cashier/login" />;
                }
            })()}
        </>
    )
    // return (
    //     <>
    //         {(function(){
    //             if(true){
    //             // if(Object.keys(userData).length && userData.token){
    //                 return <Component/>
    //             }else{
    //                 setReturnURL(ROLE.STAFF);
    //                 return <Navigate to="/cashier/login" />;
    //             }
    //         })()}
    //     </>
    // )
}

export default CashierPrivetRoute;
