import React from 'react'
import Skeleton from 'react-loading-skeleton'

export default function TableLoader({ pagination = false }) {
    return (
        <>
            <div className='w-100'>
                <Skeleton className="rounded" style={{ height: "60px" }} />
                <Skeleton className="rounded" style={{ height: "40px" }} />
                <Skeleton className="rounded" style={{ height: "40px" }} />
                <Skeleton className="rounded" style={{ height: "40px" }} />
                <Skeleton className="rounded" style={{ height: "40px" }} />
                <Skeleton className="rounded" style={{ height: "40px" }} />
            </div>
            {
                pagination ?
                    <div className="table-status-info d-flex justify-content-between mt-2">
                        <Skeleton className="rounded" style={{ height: "36px", width:"300px" }} />
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <Skeleton className="rounded" style={{ height: "36px", width:"36px" }} />
                                </li>
                                <li class="page-item">
                                    <Skeleton className="rounded" style={{ height: "36px", width:"36px" }} />
                                </li>
                                <li class="page-item">
                                    <Skeleton className="rounded" style={{ height: "36px", width:"36px" }} />
                                </li>
                                <li class="page-item">
                                    <Skeleton className="rounded" style={{ height: "36px", width:"36px" }} />
                                </li>
                            </ul>
                        </nav>
                    </div>
                : null
            }
        </>
    )
}
