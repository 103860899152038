import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ReactApexChart from 'react-apexcharts';

export default function Sale() {
    const [options] = useState({
        chart: {
            type: 'area',
            height: 350,
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        colors: ['#eb8c34'],
        // title: {
        //   text: 'Fundamental Analysis of Stocks',
        //   align: 'left'
        // },
        // subtitle: {
        //   text: 'Price Movements',
        //   align: 'left'
        // },
        labels: [
            "2024-01-01",
            "2024-01-02",
            "2024-01-03",
            "2024-01-04",
            "2024-01-05",
            "2024-01-06",
            "2024-01-07"
        ],
        xaxis: {
            type: 'datetime',
        },
        yaxis: {
            opposite: false
        },
        legend: {
            horizontalAlign: 'left'
        }
    });

    const [seriesData] = useState([{
        name: "STOCK ABC",
        data: [
            120.5,
            122.0,
            121.5,
            123.0,
            124.5,
            126.0,
            125.5,
            // Add corresponding prices as needed
        ]
    }]);
    return (
<>
        <div className='cashier-heading-innner mb-4'>
        <h4>Today’s Sales</h4>
      </div>
      <div className='card'>
        <div className='card-body'>
            <ReactApexChart options={options} series={seriesData} type="area" height={350} />
        </div>
      </div>

      <div className='card mt-4'>
        <div className='card-body'>
          <div className='today-statics-info'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='today-statics-box'>
                  <h4 className='today-static-headings'>Sales</h4>
                  <div className='today-static-row'>
                      <span>Sales</span>
                      <h5>¥ 69,319</h5>
                      <p>¥ 63,089 (Excluding tax)</p>
                  </div>
                  <div className='today-static-row'>
                      <span>Settled</span>
                      <h5>¥ 69,319</h5>
                      <p>¥ 63,089 (Excluding tax)</p>
                  </div>
                  <div className='today-static-row'>
                      <span>Not yet settled</span>
                      <h5>¥ 0</h5>
                      <p>¥ 0 (Excluding tax)</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='today-statics-box'>
                  <h4 className='today-static-headings'>Number of Customers</h4>
                  <div className='today-static-row'>
                      <span>persons</span>
                      <h5>52</h5>
                  </div>
                  <div className='today-static-row'>
                      <span>Settled</span>
                      <h5>52</h5>
                  </div>
                  <div className='today-static-row'>
                      <span>Not yet settled</span>
                      <h5>0</h5>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='today-statics-box'>
                  <h4 className='today-static-headings'>Number of Transactions</h4>
                  <div className='today-static-row'>
                      <span>transactions</span>
                      <h5>40</h5>
                  </div>
                  <div className='today-static-row'>
                      <span>Settled</span>
                      <h5>35</h5>
                  </div>
                  <div className='today-static-row'>
                      <span>Not yet settled</span>
                      <h5>5</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
}
