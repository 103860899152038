import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { DatePicker } from "rsuite";
import { useLocation, useNavigate } from "react-router-dom";
import * as restaurantService from "../../services/restaurant.services";
import Breadcrums from "../../common/breadcrumbs";
import { useTranslation } from "react-i18next";
import { INPUT_LENGTH_15, PHONE_NO_LENGTH, PLAN_DURATION_TYPES, PLAN_TYPES, SWAL_SETTINGS, TEXTAREA_MAX_LENGTH } from "../../../../utils/Constants";
import { DT, handleServerValidations } from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../../../utils/customError";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PhoneInput from "../../../../utils/PhoneInput";
import { PHONE_VALIDATION } from "../../../../utils/commonValidations";
import { Details } from "../../services/usermgmt.services";

const EnrollRestaurant = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [restData, setRestData] = useState([]);
  const location = useLocation().pathname.split("/");

  const breadcrumbs = [
    { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
    {
      title: t("sidebar_link_restaurant"),
      url: "/admin/restaurant-management/restaurant/list/1",
    },
    { title: t("btn_enroll_restaurant"), url: "" },
  ];

  const globalDetails = useSelector((state) => state?.globalData?.data);
  const yearlyEnrollCost = globalDetails?.yearly_restaurant_enrollment_cost;
  const monthlyEnrollCost = globalDetails?.monthly_restaurant_enrollment_cost;

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().trim().max(100, "validation_err_reached_maximum_length").required("label_company_name_error"),
    company_address: Yup.string().trim().max(TEXTAREA_MAX_LENGTH, "validation_max_input_characters").required("label_company_address_error"),
    shipping_address: Yup.string().trim().max(TEXTAREA_MAX_LENGTH, "validation_max_input_characters").required("label_shipping_address_error"),
    representative_name: Yup.string().trim().max(100, "validation_err_reached_maximum_length").required("label_representative_name_error"),
    contractor_name: Yup.string().trim().max(100, "validation_err_reached_maximum_length").required("label_contractor_name_error"),
    plan_name: Yup.string().trim().required("label_plan_name_error"),
    contract_plan_duration_type: Yup.string().test("plan_name", "label_contract_plan_type_error", (value) => {
      if (formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    expiry_date: Yup.string().test("plan_name", "label_expiry_date_error", (value) => {
      if (formik.values.plan_name === "demo") {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    phone_number: PHONE_VALIDATION,
  });

  useEffect(() => {
    const getUserDetails = () => {
      Details(params.userId)
        .then((data) => {
          setRestData(data && data.data ? data?.data : []);
        })
        .catch((error) => {
          console.log("error====>", error);
        });
    };
    getUserDetails();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rest_Id: restData.id,
      email: restData.email,
      restaurant_name: restData.name,
      phone_number: "",
      company_name: "",
      company_address: "",
      shipping_address: "",
      representative_name: "",
      contractor_name: "",
      plan_name: "",
      expiry_date: "",
      contract_plan_duration_type: PLAN_DURATION_TYPES.MONTHLY,
      membership_cost: 0,
    },
    validateOnBlur: false,
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitted(true);
      let formData = new FormData();
      formData.append("rest_Id", values.id);
      formData.append("primary_id", values.userId);
      formData.append("email", values.email);
      formData.append("restaurant_name", values.restaurant_name);
      formData.append("phone_number", values.phone_number);
      formData.append("company_address", values.company_address);
      formData.append("company_name", values.company_name);
      formData.append("shipping_address", values.shipping_address);
      formData.append("representative_name", values.representative_name);
      formData.append("contractor_name", values.contractor_name);
      formData.append("plan_name", values.plan_name);
      formData.append("restaurant_id", location[5]);
      if (values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
        formData.append("contract_plan_duration_type", values.contract_plan_duration_type);
      } else {
        formData.append("expiry_date", values.expiry_date);
      }
      if (values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
        if (values?.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY) {
          formData.append("membership_cost", yearlyEnrollCost);
        } else {
          formData.append("membership_cost", monthlyEnrollCost);
        }
      } else {
        formData.append("membership_cost", values?.membership_cost);
      }
      restaurantService
        .RestEnrollAdd(formData)
        .then((response) => {
          setSubmitting(false);
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            resetForm({ values: "" });
            navigate(`/admin/restaurant-management/restaurant/list/1`);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    },
  });

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="col-lg-12 col-md-12 animation_fade">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h6 className="main-content-label mb-3">{t("btn_enroll_restaurant")}</h6>
                </div>
                {restData?.enrollment_details ? (
                  <div>Already Enrolled</div>
                ) : (
                  <div className="row row-sm">
                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="rest_Id" className="text-left d-flex">
                        {t("label_id")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <input
                        name="rest_Id"
                        id="rest_Id"
                        type="text"
                        disabled={true}
                        // onBlur={formik.handleBlur}
                        value={restData.id}
                        className="form-control"
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError name="rest_Id" form={formik} />
                      </span>
                    </div>

                    <div className="col-lg-6 text-center form-group">
                      <label htmlFor="email" className="text-left d-flex">
                        {t("label_email")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <input name="email" id="email" type="text" disabled={true} value={restData.email} className="form-control" />
                      <span className="text-danger d-flex text-left">
                        <CustomError name="email" form={formik} />
                      </span>
                    </div>

                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="restaurant_name" className="text-left d-flex">
                        {t("label_restaurant_name")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <input
                        name="restaurant_name"
                        id="restaurant_name"
                        disabled={true}
                        type="text"
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={restData.restaurant_name}
                        className="form-control"
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError name="restaurant_name" form={formik} />
                      </span>
                    </div>

                    <div className="col-md-6 text-center form-group">
                      <label className="text-left d-flex" htmlFor="phone_number">
                        {t("label_phone_number")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <PhoneInput
                        name="phone_number"
                        id="phone_number"
                        onChange={(e) => {
                          formik.setFieldValue("phone_number", e?.phone_number);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone_number}
                        className="form-control"
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError name="phone_number" form={formik} shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                      </span>
                    </div>

                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="company_name" className="text-left d-flex">
                        {t("label_company")} {t("list_heading_name")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <input
                        name="company_name"
                        id="company_name"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_name}
                        className="form-control"
                        placeholder={t("company_name_placeholder")}
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError shortCodes={[INPUT_LENGTH_15]} name="company_name" form={formik} />
                      </span>
                    </div>

                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="representative_name" className="text-left d-flex">
                        {t("label_representative_name")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <input
                        name="representative_name"
                        id="representative_name"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.representative_name}
                        className="form-control"
                        placeholder={t("representative_name_placeholder")}
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError shortCodes={[INPUT_LENGTH_15]} name="representative_name" form={formik} />
                      </span>
                    </div>

                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="contractor_name" className="text-left d-flex">
                        {t("label_contractor_name")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <input
                        name="contractor_name"
                        id="contractor_name"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contractor_name}
                        className="form-control"
                        placeholder={t("contractor_name_placeholder")}
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError shortCodes={[INPUT_LENGTH_15]} name="contractor_name" form={formik} />
                      </span>
                    </div>

                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="plan_name" className="text-left d-flex">
                        {t("label_plan_name")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <div className=" select-down-arrow">
                        <select name="plan_name" id="plan_name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.plan_name} className="form-control cp">
                          <option className="cp" value="">
                            {t("input_select_text")}
                          </option>
                          {Object.keys(PLAN_TYPES).map((item, index) => {
                            return (
                              <option className="cp" key={index} value={PLAN_TYPES[item]}>
                                {t(`plan_type_${item.toLowerCase()}`)}
                              </option>
                            );
                          })}
                          {/* <option className="cp" value="demo">
                            {t("input_select_option_demo")}
                          </option>
                          <option className="cp" value=PLAN_TYPES.PREMIUM_PLAN>
                            {t("label_premium_plan")}
                          </option> */}
                        </select>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <CustomError name="plan_name" form={formik} />
                      </span>
                    </div>

                    {formik.values.plan_name === PLAN_TYPES.DEMO ? (
                      <div className="col-md-6 text-center form-group">
                        <label htmlFor="expiry_date" className="text-left d-flex">
                          {t("label_expiry_date")}:<span className="requirestar">*</span>{" "}
                        </label>
                        {/* <input
                        name="expiry_date"
                        id="expiry_date"
                        type="text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.expiry_date}
                        className="form-control"
                      /> */}
                        <DatePicker
                          format="dd.MM.yyyy"
                          name="expiry_date"
                          id="expiry_date"
                          type="date"
                          shouldDisableDate={(date) => date < new Date()}
                          // onChange={formik.handleChange}
                          onChange={(value) => formik.setFieldValue("expiry_date", new Date(value))}
                          // onBlur={formik.handleBlur}
                          value={formik.values.expiry_date ? new Date(formik.values.expiry_date) : null}
                        />
                        <span className="text-danger d-flex text-left">
                          <CustomError name="expiry_date" form={formik} />
                        </span>
                      </div>
                    ) : null}

                    {formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN ? (
                      <>
                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="contract_plan_duration_type" className="text-left d-flex">
                            {t("contract_plan_duration_type")}:<span className="requirestar">*</span>{" "}
                          </label>
                          <div className="col-md-6 text-left form-group mt-3" style={{ margin: 0, padding: 0 }}>
                            <label className="radio-inline mr-2 cp">
                              <input
                                type="radio"
                                name="contract_plan_duration_type"
                                value={PLAN_DURATION_TYPES.MONTHLY}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.MONTHLY}
                              />{" "}
                              {t("input_radio_monthly")}
                            </label>
                            <label className="radio-inline mr-2 cp">
                              <input
                                type="radio"
                                name="contract_plan_duration_type"
                                value={PLAN_DURATION_TYPES.YEARLY}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY}
                              />{" "}
                              {t("input_radio_yearly")}
                            </label>
                          </div>
                          <span className="text-danger d-flex text-left">
                            <CustomError name="contract_plan_duration_type" form={formik} />
                          </span>
                        </div>

                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="membership_cost" className="text-left d-flex">
                            {t("label_membership_cost")}:<span className="requirestar">*</span>{" "}
                          </label>

                          <div className="d-flex">
                            <div className="input-group-prepend  rounded-end-0">
                              <span className="input-group-text rounded-end-0">¥</span>
                            </div>
                            <input
                              name="membership_cost"
                              id="membership_cost"
                              type="text"
                              disabled={formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN ? true : false}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={
                                formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN
                                  ? formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY
                                    ? yearlyEnrollCost
                                    : monthlyEnrollCost
                                  : formik.values.membership_cost
                              }
                              className="form-control rounded-start-0"
                            />
                          </div>

                          <span className="text-danger d-flex text-left">
                            <CustomError name="membership_cost" form={formik} />
                          </span>
                        </div>
                      </>
                    ) : null}

                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="company_address" className="text-left d-flex">
                        {t("label_company")} {t("label_address")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <textarea
                        name="company_address"
                        id="company_address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.company_address}
                        className="form-control"
                        placeholder={t("company_address_placeholder")}
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError shortCodes={[TEXTAREA_MAX_LENGTH]} name="company_address" form={formik} />
                      </span>
                    </div>

                    <div className="col-md-6 text-center form-group">
                      <label htmlFor="shipping_address" className="text-left d-flex">
                        {t("label_shipping")} {t("label_address")}:<span className="requirestar">*</span>{" "}
                      </label>
                      <textarea
                        name="shipping_address"
                        id="shipping_address"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.shipping_address}
                        className="form-control"
                        placeholder={t("shipping_address_placeholder")}
                      />
                      <span className="text-danger d-flex text-left">
                        <CustomError shortCodes={[TEXTAREA_MAX_LENGTH]} name="shipping_address" form={formik} />
                      </span>
                    </div>

                    <div className="mt-5">
                      <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                        <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                        {t("btn_submit")}
                      </button>
                      <button className="btn ripple btn-secondary" type="button" disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                        <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                        {t("btn_cancel")}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EnrollRestaurant;
