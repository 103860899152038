import { createSlice } from "@reduxjs/toolkit";

export const RestSettingsSlice = createSlice({
  name: "restSettings",
  initialState: {
    data: null
  },
  reducers: {
    addRestData: (state, action) => {
      state.data = action.payload;
    },
}
});

export const { addRestData } = RestSettingsSlice.actions;
export default RestSettingsSlice.reducer;
