import React, { useEffect, useMemo, useState } from "react";
import english from "../../../assets/restaurant/images/english.png";
import japanese from "../../../assets/restaurant/images/japanese.png";
import Skeleton from "react-loading-skeleton";
import { LOADER_TIMEOUT_TIME, ORDER_STATUS } from "../../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { tableDetail } from "../services/table.service";
import { getLocalKey, setLocalKey, TrimText, getSessionKey } from "../../../utils/commonfunction";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { addTableDetails } from "../../../redux/slices/qrcodeDetails";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tableDetailsLoader, setTableDetailsLoader] = useState(true);
  const [tableData , setTableData] = useState({});
  const [token, setToken] = useState(getSessionKey("qr_user_token"));
  const [langChanged, setLangChanged] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const table_id = queryParams.get('table_id');
  const {t} = useTranslation();


  const newToken = useSelector((state)=>{return state.QrOrderToken.qrPanelToken});
  useEffect(()=>{
    setToken(newToken);
  },[newToken]);

  const [flag, setFlag] = useState(getLocalKey("system_language_flag") || english);
 

  useEffect(() => {
    if (token && token.length) {
      setTableDetailsLoader(true);
      tableDetail({o_id : table_id}, { token: token })
        .then((response) => {
          setTableData(response.data);
          dispatch(addTableDetails(response.data));
          if(response.data.tableOrders.order_status===ORDER_STATUS.CHECKED_OUT){
            const mySearchParams = new URLSearchParams(window.location.search);
            mySearchParams.set("restaurant_id", response.data.restaurant_id)
            mySearchParams.set("table_id", response.data.tableOrders?.table_id)
            mySearchParams.set("type",'table')
            mySearchParams.set("base", 'web')
            mySearchParams.set("order_id", response.data.tableOrders?._id)
            navigate({
              pathname: "/qr-order/success",
              search: mySearchParams.toString()
            });
          }
          setTimeout(() => { 
            setTableDetailsLoader(false);
          }, LOADER_TIMEOUT_TIME);
        })
        .catch((err) => {
          setTimeout(() => { 
            setTableDetailsLoader(false);
          }, LOADER_TIMEOUT_TIME);
          console.log(err);
        });
    }
  }, [token, langChanged]);

  const changeLanguage = (lang, flag) => {
    i18next.changeLanguage(lang);
    setLocalKey("system_language", lang);
    setLocalKey("system_language_flag", flag);
    setFlag(flag);
    const evt = new CustomEvent("language_change");
    document.dispatchEvent(evt);
  }
  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setLangChanged(!langChanged);
    })
  });
  return (
    <>
      {!tableDetailsLoader ? (
        <div className="qr-header d-flex align-items-center justify-content-between">
          <h4 className="m-0">Guest</h4>
          <div className="qr-head-right d-flex align-items-center gap-2">
            <button className="btn btn-icon">
              <i className="ti ti-shopping-cart"></i>
            </button>
            <div className="qr-table-number">
              {t('label_table1')} <span>{TrimText(tableData?.table_number , 4)}</span>
            </div>
            <div className="dropdown profile-dropdown lang-dropdown">
              <button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <div className="avatar avatar-xs">
                  <img src={flag} alt="Japanese-flag" />
                </div>
              </button>
              <ul className="dropdown-menu">
                <li onClick={(e) => {e.preventDefault(); changeLanguage("ja", japanese)}}>
                  <Link className="dropdown-item" >
                    <figure className="avatar avatar-xxs">
                      <img src={japanese} alt="Japanese-flag" />
                    </figure>
                    <span>Japanese</span>
                  </Link>
                </li>
                <li onClick={(e) => {e.preventDefault(); changeLanguage("en", english)}}>
                  <Link className="dropdown-item" >
                    <figure className="avatar avatar-xxs">
                      <img src={english} alt="UK-flag" />
                    </figure>
                    <span>English</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="qr-header d-flex align-items-center justify-content-between">
          <Skeleton containerClassName="flex-fill me-3" style={{ height: "37px" }} />
          <div className="qr-head-right d-flex align-items-center gap-2">
            <Skeleton style={{ height: "37px", width: "37px" }} />
            <Skeleton style={{ height: "37px", width: "86px" }} />
            <Skeleton style={{ height: "37px", width: "37px" }} className="rounded-circle" />
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
