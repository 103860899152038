import React, { useEffect, useRef, useState } from "react";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import { list, add, deleteStaff, edit, sortStaff, detail, changeStatus, staffAttendance, latest_employee_id } from "../../../services/staff.service";
import { ACTIVE, DEACTIVE, LOADER_TIMEOUT_TIME, PHONE_NO_LENGTH, SORT_A, SORT_D, SWAL_SETTINGS } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../../validator/validation";
import { useTranslation } from "react-i18next";
import { formatePickerDate, getColorType, getDayDiff, globalLoader, handleOffcanvasScroll, handleServerValidations, TrimText, useDebouncing } from "../../../../../utils/commonfunction";
import { CATEGORIES_TAX_TYPES } from "../../../../../utils/Constants";
import { ReactSortable } from "react-sortablejs";
import CustomError from "../../../../../utils/customError";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import GridLoader from "../../../shared/gridLoader";
import RecordNotFound from "../../../shared/recordNotFound";
import PhoneInput from "../../../../../utils/PhoneInput";
import { DatePicker, Drawer } from "rsuite";
import Skeleton from "react-loading-skeleton";
import TableLoader from "../../../../../utils/TableLoader";
import CustomPagination from "../../../../admin/common/custompagination";
import DefaultProfileImg from '../../../../../assets/restaurant/images/default_user_profile.png';


export default function Staff() {
  const navigate = useNavigate()
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation();
  const [List, setList] = useState([]);
  const { staffValidationSchema, editStaffValidationSchema } = useValidationSchemas();
  const addCategoryCloseBtn = useRef();
  const [refreshList, setRefreshList] = useState(false);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [openWithHeaderEdit, setOpenWithHeaderEdit] = useState(false);
  const [prevGlobalSearch, setPrevGlobalSearch] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [search, setSearch] = useState({});
  const [loader, setLoader] = useState(true);
  const [staffDetail, setStaffDetail] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [attendanceLoader, setAttendanceLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [offset, setOffsetPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [itemPerPage] = useState(10);
  const [employeeId, setEmployeeId] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const fileSelectInput = useRef();
  const [attendancePage, setAttendancePage] = useState(1);
  const [attendanceOffset, setAttendanceOffset] = useState(0);
  const [attendanceTotalRecords, setAttendanceTotalRecords] = useState(0);
  const toggleEyeIcon = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  // State for search input and select dropdown
  const [searchValue, setSearchValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [sortConfig, setSortConfig] = useState({ column: 'createdAt', order: SORT_D });
  const [sortHistoryConfig, setSortHistoryConfig] = useState({ column: 'date', order: SORT_D });

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const prepareGlobalSearch = (value) => {
    setPrevGlobalSearch(globalSearch)
    setGlobalSearch(value);
  };
  const searchDebounce = useDebouncing(prepareGlobalSearch);

  const handleFilterStatusChange = (e) => {
    if (e.target.value) {
      prepareSearch("status", Number(e.target.value));
    } else {
      prepareSearch("status", "");
    }
  };




  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    searchDebounce(value);
  };

  // Handle select dropdown change
  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedStatus(value);
    handleFilterStatusChange(e);
  };

  // Reset filter values
  const resetFilter = (e) => {
    setSearchValue('');
    setSelectedStatus('');
    e.preventDefault()
    setGlobalSearch("")
    prepareSearch()
    setSearch({})
  };


  const handleSort = (key) => {
    let direction = SORT_A;
    if (sortConfig.column === key && sortConfig.order === SORT_A) {
      direction = SORT_D;
    }
    setSortConfig({ column: key, order: direction });
  };


  const get_employee_id = () => {
    latest_employee_id()
      .then((response) => {
        console.log(setEmployeeId(response.data.employee_id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {

    if (!location.pathname.includes("staff-attendance") || !selectedEmployeeId) {
      setLoader(true);
      const formData = new FormData();
      if (Object.keys(search).length) {
        formData.append("search", JSON.stringify(search));
      }
      formData.append("global_search", globalSearch);
      // Determine the page number
      let page = params.page;
      if (globalSearch) {
        if (prevGlobalSearch !== globalSearch || !prevGlobalSearch) {
          page = 1; // Reset to the first page if globalSearch changes
        }
        setPage(page);
      }
      formData.append("page", page);
      formData.append("per_page", itemPerPage);

      console.log("sortConfig", sortConfig)
      if (sortConfig) {
        formData.append("sort", JSON.stringify(sortConfig));
      }
      list(formData)
        .then((data) => {
          if (data && data.data) {
            setList(data.data.list || []);
            setPage(data.data.page);
            navigate(`/restaurant/settings/staff/${data.data.page}`);
            setOffsetPage(data.data.offset);
            setTotalRecords(data.data.total_records || 0);
          } else {
            setList([]);
            setOffsetPage(0);
            setTotalRecords(0);
          }
          setPrevGlobalSearch(globalSearch);
          setTimeout(() => {
            setLoader(false);
          }, LOADER_TIMEOUT_TIME);
        })
        .catch((error) => {
          console.log("error ====> ", error);
          setTimeout(() => {
            setLoader(false);
          }, LOADER_TIMEOUT_TIME);
        });
    }
    else {
      setAttendanceLoader(true);
      const formData = new FormData();
      let page = params.page;
      setAttendancePage(page);
      formData.append("page", page);
      formData.append("per_page", itemPerPage);
      staffAttendance(selectedEmployeeId, formData)
        .then((response) => {
          setTimeout(() => { setAttendanceLoader(false) }, LOADER_TIMEOUT_TIME);
          setAttendance(response?.data?.list);
          setAttendancePage(response?.data?.page);
          setAttendanceOffset(response.data.offset);
          setAttendanceTotalRecords(response.data.total_records || 0);
          navigate(`/restaurant/settings/staff-attendance/${response.data.page}`);
        })
        .catch((err) => {
          setTimeout(() => { setAttendanceLoader(false) }, LOADER_TIMEOUT_TIME);
          console.log(err);
        });
    }
  }, [search, refreshList, globalSearch, params.page, sortConfig]);


  const getStaffDetail = (item) => {
    detail({ o_id: item._id })
      .then((response) => {
        editForm.setValues({
          o_id: response?.data?._id || "",
          first_name: response?.data?.first_name || "",
          last_name: response?.data?.last_name || "",
          email: response?.data?.email || "",
          employee_id: response?.data?.employee_id || "",
          phone_number: response?.data?.phone_number || "",
          staff_state: response?.data?.staff_state || "",
          staff_zip_code: response?.data?.staff_zip_code || "",
          staff_city: response?.data?.staff_city || "",
          staff_joining_date: response?.data?.staff_joining_date || "",
          address: response?.data?.address || "",
          profile_image: response?.data?.profile_image || "",
        });
        setStaffDetail(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const handleFileSelection = (event, form) => {
    if (event.target.files.length) {
      form.setFieldValue("profile_image", event.target.files[0]);
    }
  };


  const handleFilePreview = (file) => {
    if (typeof file === "string") {
      return file;
    } else {
      return URL.createObjectURL(file);
    }
  };

  const addForm = useFormik({
    initialValues: {
      profile_image: "",
      employee_id: employeeId,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      staff_state: "",
      staff_zip_code: "",
      staff_city: "",
      address: "",
      staff_joining_date: ""
    },
    validationSchema: staffValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("employee_id", values.employee_id);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone_number);
      formData.append("password", values.password);
      formData.append("staff_state", values.staff_state);
      formData.append("staff_zip_code", values.staff_zip_code);
      formData.append("staff_city", values.staff_city);
      formData.append("staff_joining_date", values.staff_joining_date);
      formData.append("address", values.address);
      if (values.profile_image) {
        formData.append("profile_image", values.profile_image);
      }
      //   console.log(values)
      add(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            resetForm();
            setOpenWithHeader(false);
            setRefreshList(!refreshList);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });

  const editForm = useFormik({
    initialValues: {
      o_id: "",
      employee_id: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      staff_state: "",
      staff_zip_code: "",
      staff_city: "",
      address: "",
      staff_joining_date: ""
    },
    validationSchema: editStaffValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("o_id", staffDetail._id);
      formData.append("first_name", values.first_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone_number);
      formData.append("staff_state", values.staff_state);
      formData.append("staff_zip_code", values.staff_zip_code);
      formData.append("staff_city", values.staff_city);
      formData.append("address", values.address);
      formData.append("profile_image", values.profile_image);
      formData.append("staff_joining_date", values.staff_joining_date);
      if (values.password) {
        formData.append("password", values.password);
      }
      edit(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            resetForm();
            setOpenWithHeaderEdit(false);
            setRefreshList(!refreshList);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      console.log(values);
    },
  });


  const deleteRecord = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        deleteStaff({ o_id: item._id })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setRefreshList(!refreshList);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            console.log("deleteError");
          });
      } else {
        // setSortableCategories(List);
      }
    });
  };

  const changeStatusToggle = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: item.status ? t("btn_deactive_status_text") : t("btn_active_status_text"),
      // imageUrl: trash,
      icon:'question',
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: item.status ? t("btn_inactive") : t("btn_active"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        changeStatus({ o_id: item._id, status: item.status ? DEACTIVE : ACTIVE })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setRefreshList(!refreshList);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            console.log("deleteError");
          });
      } else {
        // setSortableCategories(List);
      }
    });
  };

  const getStaffAttendance = (id, values = null) => {
    setAttendanceLoader(true);
    staffAttendance(id)
      .then((response) => {
        setTimeout(() => { setAttendanceLoader(false) }, LOADER_TIMEOUT_TIME);
        setAttendance(response?.data?.list);
        setAttendanceOffset(response.data.offset);
        setAttendanceTotalRecords(response.data.total_records || 0);
        navigate(`/restaurant/settings/staff-attendance/${response.data.page}`);
      })
      .catch((err) => {

        setTimeout(() => { setAttendanceLoader(false) }, LOADER_TIMEOUT_TIME);
        console.log(err);
      });
  };

  useEffect(() => {
    if (selectedEmployeeId) {
      const formData = new FormData()
      if (sortHistoryConfig) {
        formData.append("sort", JSON.stringify(sortHistoryConfig));
      }
      getStaffAttendance(selectedEmployeeId, formData)
    }
  }, [sortHistoryConfig, selectedEmployeeId])


  const handleHistorySort = (key,item=null) => {
    let direction = SORT_A;
    if (sortHistoryConfig.column === key && sortHistoryConfig.order === SORT_A) {
      direction = SORT_D;
    }
    setSortHistoryConfig({ column: key, order: direction });
  };

  return (
    <main id="main-content">
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">{t("label_staff")}  ({totalRecords})</h4>
        </div>
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
          <div className="search-box">
            <input
              type="text"
              value={searchValue} // Bind state value
              className="form-control search-input"
              placeholder={t("input_placeholder_search")}
              onChange={handleSearchChange} // Use onChange instead of onInput for better performance
            />
          </div>
          <div className="card-head-group d-flex align-items-center gap-2">
            <div className="tabls-dowpdown">
              <select
                className="form-control cp"
                value={selectedStatus} // Bind state value
                onChange={handleSelectChange}
              >
                <option disabled="" value="">
                  {t("label_select_status")}
                </option>
                <option value="1"> {t("btn_active")}</option>
                <option value="0">{t("btn_inactive")}</option>
              </select>
            </div>
            {/* <button
          type="button"
          onClick={resetFilter}
          className="btn btn-warning float-right mr-2"
        >
          {t("btn_reset_filter")}
        </button> */}
            <button
              className="btn btn-primary"
              onClick={() => {
                setOpenWithHeader(true);
                get_employee_id();
              }}
            >
              <i className="ti ti-plus"></i> {t("label_create_new")}
            </button>
          </div>
        </div>

        <div className="dashboard-content-wraper">
          <div className="category-list-wrap">
            {loader ? (
              <div className="w-100">
                <Skeleton className="mb-2" height={50} count={5} />
              </div>
            ) : List?.length ? (
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>{t("s_no")}</th>
                          <th>{t("employee_id")}
                            <span style={{ cursor: 'pointer' }} onClick={() => handleSort('employee_id')}>

                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'employee_id' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '5px' }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'employee_id' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '-8px' }} />
                            </span>
                          </th>
                          <th>{t("staff_name")}
                            <span style={{ cursor: 'pointer' }} onClick={() => handleSort('name')}>

                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'name' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '5px' }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'name' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '-8px' }} />
                            </span>
                          </th>
                          <th>{t("staff_email")}
                            <span style={{ cursor: 'pointer' }} onClick={() => handleSort('email')}>

                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'email' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '5px' }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'email' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '-8px' }} />
                            </span>
                          </th>
                          <th>{t("contact_no")}
                            <span style={{ cursor: 'pointer' }} onClick={() => handleSort('phone_number')}>

                              <i className={`ti ti-arrow-narrow-up ${sortConfig.column === 'phone_number' && sortConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '5px' }}></i>
                              <i className={`ti ti-arrow-narrow-down ${sortConfig.column === 'phone_number' && sortConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                                style={{ marginLeft: '-8px' }} />
                            </span></th>
                          <th>{t("list_heading_status")}</th>
                          <th style={{ width: "150px" }}>{t("label_action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {List.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{offset + index + 1}</td>
                              <td>{item?.employee_id}</td>
                              <td>{TrimText(item?.name, 30)}</td>
                              <td>{item?.email}</td>
                              <td>{item?.phone_number}</td>
                              <td>
                                <div className="mai-card-right">
                                  <div className="form-check form-switch">
                                    <input className="form-check-input cp" type="checkbox" checked={item?.status} onChange={(e) => changeStatusToggle(item)} />
                                  </div>
                                </div>
                              </td>
                              <td className="d-flex">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setSelectedEmployeeId(item._id);
                                    getStaffAttendance(item._id);
                                  }}
                                  style={{ width: "max-content" }}
                                  data-bs-toggle="modal"
                                  data-bs-target="#shift-history"
                                >
                                  {t("view_shift_history")}
                                </button>
                                <div className="dropdown ms-2">
                                  <button className="btn btn-icon dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="ti ti-dots-vertical"></i>
                                  </button>
                                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <li>
                                      <a
                                        className="dropdown-item cp"
                                        onClick={() => {
                                          getStaffDetail(item);
                                        }}
                                        data-bs-toggle="offcanvas"
                                        data-bs-target="#view-staff"
                                      >
                                        <i className="ti ti-eye"></i>
                                        {t("btn_view")}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item cp"
                                        onClick={() => {
                                          getStaffDetail(item);
                                          setOpenWithHeaderEdit(true);
                                        }}
                                      >
                                        <i className="ti ti-edit"></i>
                                        {t("btn_edit")}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item cp"
                                        onClick={() => {
                                          deleteRecord(item);
                                        }}
                                      >
                                        <i className="ti ti-trash"></i>
                                        {t("btn_delete")}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="table-status-info d-flex justify-content-between align-items-center">
                    <span>{t("label_showing")} {offset + List.length} of {totalRecords} {t("label_entries")}</span>
                    <nav aria-label="Page navigation example" title={page}>
                      {totalRecords && totalRecords > 0 ?
                        <CustomPagination
                          datalength={totalRecords}
                          itemperpage={itemPerPage}
                          currentPage={page}
                          custompath="page"
                          setPage={setPage}
                          pageRoute={[{ name: "settings", path: "/restaurant/settings/staff" }]} /> : ""
                      }
                    </nav>
                  </div>
                </div>
              </div>
            ) : (
              <RecordNotFound heading="no_staff_found" />
            )}
          </div>
        </div>
        <div>
          {/* <button className='btn btn-success' data-bs-toggle="offcanvas" data-bs-target="#view-staff">view staff details</button>
                    <button className='btn btn-danger ms-2' data-bs-toggle="modal" data-bs-target="#shift-history">view staff details</button> */}
        </div>
      </div>

      {/* add item modal  */}
      <Drawer open={openWithHeader}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t("create_staff")}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setOpenWithHeader(false);
                addForm.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editNewEmail", "rsuite");
          }}
        >
          <form onSubmit={addForm.handleSubmit}>
            {/* Employee ID */}
            <div className="profile-photo-upload mb-3" style={{ margin: "auto" }}>
              <div className="avatar avatar-xl">
                <img alt="profile_image" src={addForm?.values?.profile_image ? handleFilePreview(addForm?.values?.profile_image) : DefaultProfileImg} />
              </div>
              <button
                className="cp e_none"
                type="button"
                onClick={() => {
                  fileSelectInput?.current?.click();
                }}
              >
                <input ref={fileSelectInput} id="profile_image" name="profile_image" accept="image/*" type="file" onChange={(e) => handleFileSelection(e, addForm)} />
                <i className="ti ti-upload"></i>
              </button>
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_employee_id")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_employee_id")}
                name="employee_id"
                value={addForm?.values?.employee_id}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
                disabled
              />
              <CustomError name="employee_id" form={addForm} className="text-danger" />
            </div>

            {/* First Name */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_first_name")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_first_name")}
                name="first_name"
                value={addForm?.values?.first_name}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="first_name" form={addForm} className="text-danger" />
            </div>

            {/* Last Name */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_last_name")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_last_name")}
                name="last_name"
                value={addForm?.values?.last_name}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="last_name" form={addForm} className="text-danger" />
            </div>

            {/* Email */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_email")}
                <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder={t("placeholder_email_address")}
                name="email"
                value={addForm?.values?.email}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="email" form={addForm} className="text-danger" />
            </div>

            {/* Phone Number */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_phone_number")}
                <span>*</span>
              </label>
              <PhoneInput
                value={addForm?.values?.phone_number}
                name={"phone_number"}
                onChange={(e) => {
                  addForm.setFieldValue("phone_number", e?.phone_number);
                }}
                placeholder={t("web_inquiry_form_placeholder_contact_number")}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="phone_number" form={addForm} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
            </div>

            {/* Password */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_password")}
                <span>*</span>
              </label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control border-end-0"
                  placeholder={t("placeholder_password1")}
                  name="password"
                  value={addForm?.values?.password}
                  onChange={addForm.handleChange}
                  onBlur={addForm.handleBlur}
                />
                <button
                  className="input-group-text restaurant-password-toggle"
                  type="button"
                  onClick={() => {
                    toggleEyeIcon();
                  }}
                >
                  {showPassword ? <i className="ti ti-eye"></i> : <i className="ti ti-eye-off"></i>}
                </button>
              </div>
              <CustomError name="password" form={addForm} className="text-danger" />
            </div>
            {/* Staff Joining Date */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_staff_joining_date")}
                <span>*</span>
              </label>
              <DatePicker oneTap={true} classPrefix="restaurant" placeholder={t("placeholder_staff_joining_date")} placement="autoVertical" format="dd.MM.yyyy" cleanable={false} editable={false} name="staff_joining_date" onChange={(e) => {
                addForm.setFieldTouched('staff_joining_date', true);
                addForm.setFieldValue("staff_joining_date", e);
              }} value={new Date(addForm.values.staff_joining_date)}
                onBlur={addForm.handleBlur} />
              <CustomError name="staff_joining_date" form={addForm} className="text-danger" />
            </div>

            {/* Address */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_address")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_address")}
                name="address"
                value={addForm?.values?.address}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="address" form={addForm} className="text-danger" />
            </div>

            {/* Staff City */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_city")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("city_placeholder")}
                name="staff_city"
                value={addForm?.values?.staff_city}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="staff_city" form={addForm} className="text-danger" />
            </div>

            {/* Staff State */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_state")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("state_placeholder")}
                name="staff_state"
                value={addForm?.values?.staff_state}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="staff_state" form={addForm} className="text-danger" />
            </div>

            {/* Staff Zip Code */}
            <div className="mb-3">
              <label className="form-label">
                {t("zip_code")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("zipcode_placeholder")}
                name="staff_zip_code"
                value={addForm?.values?.staff_zip_code}
                onChange={addForm.handleChange}
                onBlur={addForm.handleBlur}
              />
              <CustomError name="staff_zip_code" form={addForm} className="text-danger" />
            </div>

            {/* Submit Button */}
            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>


      <Drawer open={openWithHeaderEdit}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t("heading_edit_staff")}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setOpenWithHeaderEdit(false);
                editForm.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editEmail", "rsuite");
          }}
        >
          <form onSubmit={editForm.handleSubmit}>
            {/* Employee ID */}
            <div className="profile-photo-upload mb-3" style={{ margin: "auto" }}>
              <div className="avatar avatar-xl">
                <img alt="profile_image" src={editForm?.values?.profile_image ? handleFilePreview(editForm?.values?.profile_image) : DefaultProfileImg} />
              </div>
              <button
                className="cp e_none"
                type="button"
                onClick={() => {
                  fileSelectInput?.current?.click();
                }}
              >
                <input ref={fileSelectInput} id="profile_image" name="profile_image" accept="image/*" type="file" onChange={(e) => handleFileSelection(e, editForm)} />
                <i className="ti ti-upload"></i>
              </button>
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_employee_id")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_employee_id")}
                name="employee_id"
                value={editForm?.values?.employee_id}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
                disabled
              />
              <CustomError name="employee_id" form={editForm} className="text-danger" />
            </div>

            {/* Name */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_first_name")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_first_name")}
                name="first_name"
                value={editForm?.values?.first_name}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="first_name" form={editForm} className="text-danger" />
            </div>

            {/* Last Name */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_last_name")}
                <span>*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_last_name")}
                name="last_name"
                value={editForm?.values?.last_name}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="last_name" form={editForm} className="text-danger" />
            </div>
            {/* Email */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_email")}
                <span>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder={t("placeholder_email_address")}
                name="email"
                value={editForm?.values?.email}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="email" form={editForm} className="text-danger" />
            </div>

            {/* Phone Number */}

            <div className="mb-3">
              <label htmlFor="phone_number" className="form-label">
                {t("label_phone_number")}
                <span>*</span>
              </label>
              <PhoneInput
                value={editForm?.values?.phone_number}
                name={"phone_number"}
                onChange={(e) => {
                  editForm.setFieldValue("phone_number", e?.phone_number);
                }}
                onBlur={editForm.handleBlur}
                placeholder={t("web_inquiry_form_placeholder_contact_number")}
              />
              <CustomError name="phone_number" form={editForm} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_password")}
                <span></span>
              </label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control border-end-0"
                  placeholder={t("placeholder_password1")}
                  name="password"
                  value={editForm?.values?.password}
                  onChange={editForm.handleChange}
                  onBlur={editForm.handleBlur}
                />
                <button
                  className="input-group-text restaurant-password-toggle"
                  type="button"
                  onClick={() => {
                    toggleEyeIcon();
                  }}
                >
                  {showPassword ? <i className="ti ti-eye"></i> : <i className="ti ti-eye-off"></i>}
                </button>
              </div>

              <CustomError name="password" form={editForm} className="text-danger" />
              <span className="mt-2 d-block">{t("password_blank_label")}</span>
            </div>


            {/* Staff Joining Date */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_staff_joining_date")}
                <span>*</span>
              </label>
              <DatePicker oneTap={true} classPrefix="restaurant" placeholder={t("placeholder_staff_joining_date")} placement="autoVertical" format="dd.MM.yyyy" cleanable={false} editable={false} name="staff_joining_date" onChange={(e) => {
                editForm.setFieldTouched('staff_joining_date', true);
                editForm.setFieldValue("staff_joining_date", e);
              }} value={new Date(editForm.values.staff_joining_date)}
                onBlur={editForm.handleBlur} />
              <CustomError name="staff_joining_date" form={editForm} className="text-danger" />
            </div>

            {/* Address */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_address")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_address")}
                name="address"
                value={editForm?.values?.address}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="address" form={editForm} className="text-danger" />
            </div>

            {/* Staff City */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_city")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("city_placeholder")}
                name="staff_city"
                value={editForm?.values?.staff_city}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="staff_city" form={editForm} className="text-danger" />
            </div>

            {/* Staff State */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_state")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("state_placeholder")}
                name="staff_state"
                value={editForm?.values?.staff_state}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="staff_state" form={editForm} className="text-danger" />
            </div>

            {/* Staff Zip Code */}
            <div className="mb-3">
              <label className="form-label">
                {t("zip_code")}
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("zipcode_placeholder")}
                name="staff_zip_code"
                value={editForm?.values?.staff_zip_code}
                onChange={editForm.handleChange}
                onBlur={editForm.handleBlur}
              />
              <CustomError name="staff_zip_code" form={editForm} className="text-danger" />
            </div>

            {/* Submit Button */}
            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      <div className="offcanvas offcanvas-end right-modal-w" id="view-staff" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("view_staff_detail")}
          </h5>
          <button type="button" className="btn btn-icon ms-auto" data-bs-dismiss="offcanvas" aria-label="Close">
            <i className="ti ti-x"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="view-staff-info">
            <div className="row gap-4">
              {staffDetail?.profile_image ?
                <div className="profile-photo-upload mb-3" style={{ margin: "auto" }}>
                  <div className="avatar avatar-xl">
                    <img alt="profile_image" src={staffDetail?.profile_image ? staffDetail?.profile_image : DefaultProfileImg} />
                  </div>
                </div> : null}
              <div className="col-md-12">
                <h4>{t("employee_id")}</h4>
                <p>{staffDetail?.employee_id}</p>
              </div>
              <div className="col-md-12">
                <h4>{t("staff_name")}</h4>
                <p>{staffDetail?.name}</p>
              </div>
              <div className="col-md-12">
                <h4>{t("staff_email")}</h4>
                <p>{staffDetail?.email}</p>
              </div>
              <div className="col-md-12">
                <h4>{t("contact_no")}</h4>
                <p>{staffDetail?.phone_number}</p>
              </div>
              <div className='col-md-12'>
                <h4>{t("label_staff_joining_date")}</h4>
                <p>{formatePickerDate(staffDetail.staff_joining_date)} </p>
              </div>
              {staffDetail && staffDetail.address ?
                <div className='col-md-12'>
                  <h4>{t("list_heading_address")}</h4>
                  <p>{staffDetail.address} </p>
                </div> : null}

              {staffDetail && staffDetail.staff_city ?
                <div className='col-md-12'>
                  <h4>{t("label_city")}</h4>
                  <p>{staffDetail.staff_city} </p>
                </div> : null}

              {staffDetail && staffDetail.staff_state ?
                <div className='col-md-12'>
                  <h4>{t("label_state")}</h4>
                  <p>{staffDetail.staff_state} </p>
                </div> : null}

              {staffDetail && staffDetail.staff_zip_code ?
                <div className='col-md-12'>
                  <h4>{t("zip_code")}</h4>
                  <p>{staffDetail.staff_zip_code} </p>
                </div> : null}
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade sort-modal" data-bs-backdrop="static" id="shift-history" tabIndex="-1" aria-labelledby="shift-historyLabel" aria-modal="true" role="dialog">
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                {t('shift_history_label')}
              </h1>
              <button type="button" className="btn-close btn-icon" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setEmployeeId(""); navigate(`/restaurant/settings/staff/${page}`); setAttendancePage(1) }}>
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                {attendance.length > 0 ?

                  (<div className="card-body">
                    <div className="table-responsive">
                      {attendanceLoader ? <TableLoader /> : (<table className="table">
                        <thead>
                          <tr>
                            <th>{t('s_no')}</th>
                            <th>{t('employee_id')}</th>
                            <th>{t('staff_name')}</th>
                            <th>{t('input_placeholder_date')}
                              <span style={{ cursor: 'pointer' }} onClick={() => handleHistorySort('date')}>

                                <i className={`ti ti-arrow-narrow-up ${sortHistoryConfig.column === 'date' && sortHistoryConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                                  style={{ marginLeft: '5px' }}></i>
                                <i className={`ti ti-arrow-narrow-down ${sortHistoryConfig.column === 'date' && sortHistoryConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                                  style={{ marginLeft: '-8px' }} />
                              </span>
                            </th>
                            <th>{t('shift_start_time')}
                              <span style={{ cursor: 'pointer' }} onClick={() => handleHistorySort('start_time')}>

                                <i className={`ti ti-arrow-narrow-up ${sortHistoryConfig.column === 'start_time' && sortHistoryConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                                  style={{ marginLeft: '5px' }}></i>
                                <i className={`ti ti-arrow-narrow-down ${sortHistoryConfig.column === 'start_time' && sortHistoryConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                                  style={{ marginLeft: '-8px' }} />
                              </span>
                            </th>
                            <th>{t('shift_break_time')}</th>
                            <th>{t('shift_end_time')}

                              <span style={{ cursor: 'pointer' }} onClick={() => handleHistorySort('end_time')}>
                                <i className={`ti ti-arrow-narrow-up ${sortHistoryConfig.column === 'end_time' && sortHistoryConfig.order === SORT_D ? 'sort-disabled' : ''}`}
                                  style={{ marginLeft: '5px' }}></i>
                                <i className={`ti ti-arrow-narrow-down ${sortHistoryConfig.column === 'end_time' && sortHistoryConfig.order === SORT_A ? 'sort-disabled' : ''}`}
                                  style={{ marginLeft: '-8px' }} />
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {attendance
                            ? attendance.map((item, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item?.staff_info[0]?.employee_id}</td>
                                  <td>{item?.staff_info[0]?.name}</td>
                                  <td>{new Date(item?.date).toLocaleDateString("en-US", { weekday: "long", day: "2-digit", month: "2-digit", year: "numeric" })}</td>
                                  <td>{new Date(item?.start_time).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true })} </td>
                                  <td>
                                    {(item.break_time_hours && item.break_time_hours !== null ? item.break_time_hours : 0) +
                                      "h " +
                                      (item.break_time_minutes && item.break_time_minutes !== null ? item.break_time_minutes : 0) +
                                      "m"}
                                  </td>

                                  <td>{item?.end_time ? new Date(item?.end_time).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true }) + getDayDiff(item?.start_time, item?.end_time) : t('label_not_entered')}
                                  </td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>)}

                    </div>

                    <div className="table-status-info d-flex justify-content-between align-items-center">
                      <span>{t("label_showing")} {attendanceOffset + attendance.length} of {attendanceTotalRecords} {t("label_entries")}</span>
                      <nav aria-label="Page navigation example" title={attendancePage}>
                        {attendanceTotalRecords && attendanceTotalRecords > 0 ?
                          <CustomPagination
                            datalength={attendanceTotalRecords}
                            itemperpage={itemPerPage}
                            currentPage={attendancePage}
                            custompath="page"
                            setPage={setAttendancePage}
                            pageRoute={[{ name: "settings", path: "/restaurant/settings/staff-attendance" }]} /> : ""
                        }
                      </nav>
                    </div>
                  </div>) :
                  <RecordNotFound heading="lbl_record_not_found" />}

              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
