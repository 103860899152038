import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { menuList, detail, categoryDetail } from "../../../services/table.services";
import { LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants';
import { TrimText, getColorType } from '../../../../../utils/commonfunction';
import GridLoader from '../../../../restaurant/shared/gridLoader';
import RecordNotFound from '../../../../restaurant/shared/recordNotFound';


export default function CashierMenu() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [menuItemList, setMenuItemList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableDetail, setTableDetail] = useState({});
  const [category, setCategory] = useState({});


  const getTableDetail = (tableId) => {
    detail({ o_id: tableId })
      .then((response) => {
        setTableDetail(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getCategoryDetail = (categoryId) => {
    categoryDetail({ o_id: categoryId })
      .then((response) => {
        setCategory(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (params.tableId && params.category) {
      getTableDetail(params.tableId);
      getCategoryDetail(params.category)
    }
    else {
      navigate('/cashier/dashboard')
    }
  }, [params.tableId, params.category]);

  useEffect(() => {
    setLoader(true);
    menuList()
      .then((data) => {
        if (data && data.data) {
          setMenuItemList(data.data.list || []);
        } else {
          setMenuItemList([]);
        }

        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [refreshList]);

  const handleRefreshList = () => {
    setRefreshList(!refreshList)
  }

  return (
    <>

      <div className='dashboard-cashier-left d-flex flex-column'>
        <div className='cashier-head text-center'>
          <div className='btns-head-in-left gap-2 d-flex'>
            <button className='btn btn-icon' onClick={()=>{navigate(-1);}}>
              <i className='ti ti-arrow-left'></i>
            </button>
          </div>
          <h4>{TrimText(tableDetail.table_number, 20)}</h4>
          <div className='btns-head-in gap-2 d-flex'>
            <button className='btn btn-icon'>
              <i className='ti ti-user'></i>
            </button>
            <button className='btn btn-icon'>
              <i className='ti ti-printer'></i>
            </button>
          </div>
        </div>


        <div className='cashier-category-wrap'>
          <h4 className='mb-3'>{TrimText(category.name, 20)}</h4>
          {
            loader ?
              <GridLoader />
              :
              menuItemList.length ?
                <div className='category-list-grid menu-list-grid'>
                  {menuItemList.map((item, index) => (
                    <div className='category-box cp category-menu-box' key={index}>
                      <div className="category-name menu-img">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="menu-item-info">
                        <h4>{item.item_name}</h4>
                        <span>¥ {item.total_price?.toFixed(2)}</span>
                      </div>
                    </div>
                  ))}

                </div>
                :
                <RecordNotFound heading="no_assigned_item_text" text="no_category_found_text_para" />
          }
        </div>
      </div>
    </>
  )
}




{/* <div class="modal fade modal-cashier" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
        <i className='ti ti-arrow-left'></i>
      </button>
      <h1 class="modal-title fs-5" id="exampleModalLabel">Customer info</h1>
      <button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
        <i className='ti ti-x'></i>
      </button>
    </div>
    <div class="modal-body">
      <div className='card-list'>
        <ul>
          <li>
            <h4>Name</h4>
            <h3>Lissa watson</h3>
          </li>
          <li>
            <h4>Phone number</h4>
            <h3>+81 45124 45124</h3>
          </li>
          <li>
            <h4>Number of persons </h4>
            <h3>5</h3>
          </li>
          <li>
            <h4>Tag</h4>
            <h3>VIP</h3>
          </li>
        </ul>
      </div>
      <button className='btn btn-primary w-100 mt-3'>Edit</button>
    </div>
  </div>
</div>
</div> */}