import React, { useEffect, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import * as bannerService from "../../../services/banner.services";
import Breadcrums from "../../../common/breadcrumbs";
// import CustomCkeditor from "../../../common/customeditor";
import { SWAL_SETTINGS } from "../../../../../utils/Constants";
import { globalLoader, handleServerValidations } from "../../../../../utils/commonfunction";
import * as Yup from "yup";
import { Tooltip, Whisper, Loader } from "rsuite";
import { useTranslation } from "react-i18next";

const BannerAdd = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [previewimage, setPreviewImage] = useState("");
  const [initialValues, setInitialValues] = useState({
    title: "",
    title_ja: "",
    link: "",
    // richtext: "",
    // richtext_de: "",
    files: "",
  });

  const breadcrumbs = [
    { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
    { title: t("sidebar_link_banners"), url: "/admin/cms/banner/list/1" },
    { title: t("link_add"), url: "" },
  ];

  useEffect(() => {
    setInitialValues({
      title: state && state?.title ? state.title : "",
      title_ja: state && state?.title_ja ? state.title_ja : "",
      link: state && state?.link ? state.link : "",
      // richtext: state && state?.description ? state.description : "",
      // richtext_de: state && state?.description_ja ? state.description_ja : "",
      files: "",
    });
    setDataLoaded(true);
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(("label_title_error")).max(15, ("max_length_label"))
      .test("no-spaces", ("label_title_error"), (value) => value.trim()),
    title_ja: Yup.string()
      .max(15, ("max_length_label")),
    // link: Yup.string()
    //   .max(50, t("label_title_length_error_50")),
    // richtext: Yup.string()
    //   .required(t("label_richtext_error"))
    //   .test("no-spaces", t("label_richtext_error"), (value) => value.trim()),
    files: Yup.mixed()
      .required(("label_image_error"))
    .test(
      'fileType',
      'supported_file_error',
      value => !value || ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type)
    )
    .test(
      'fileSize',
      'image_max_size_error',
      value => !value || value.size <= 1.5 * 1024 * 1024
    ), 
  });

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnBlur={false}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            globalLoader(true);
            setSubmitted(true);
            let formData = new FormData();
            formData.append("title", values.title);
            formData.append("title_ja", values.title_ja.trim());
            formData.append("link", values.link.trim());
            // formData.append("description", values.richtext);
            // formData.append("description_ja", values.richtext_de);
            formData.append("image", values.files);
            bannerService
              .Add(formData)
              .then((response) => {
                setSubmitting(false);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  globalLoader(false);
                  setTimeout(() => {
                    resetForm({ values: "" });
                    navigate(`/admin/cms/banner/list/1`);
                  }, 2000);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                  globalLoader(false);
                  setSubmitted(false);
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">{t("link_add")} {t("btn_banner")}</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            {t("label_Banner_title")}: <span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="title"
                            id="title"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                            placeholder={t("banner_title_placeholder")}
                          />
                          {/* <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"title"} />
                          </span> */}
                            <span className="text-danger d-flex text-left">
                            {errors.title && touched.title && t(errors.title)}
                          </span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            {t("label_Banner_title_ja")}:
                          </label>
                          <input
                            name="title_ja"
                            id="title_ja"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title_ja}
                            className="form-control"
                            placeholder={t("banner_title_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.title_ja && touched.title_ja && errors.title_ja}
                          </span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="link" className="text-left d-flex">
                            {t("label_link")}:
                          </label>
                          <input
                            name="link"
                            id="link"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.link}
                            className="form-control"
                            placeholder={t("banner_link_placeholder")}
                          />
                          {/* <span className="text-danger d-flex text-left">
                            {errors.link && touched.link && errors.link}
                          </span> */}
                        </div>

                        {/* <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            {t("label_description")}:<span className="requirestar">*</span>
                          </label>
                          <CustomCkeditor
                            fieldname={"richtext"}
                            setFieldValue={setFieldValue}
                            value={values.richtext}
                            setFieldTouched={setFieldTouched}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.richtext &&
                              touched.richtext &&
                              errors.richtext}
                          </span>
                        </div>
                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            {t("description_japanese")}:
                          </label>
                          <CustomCkeditor
                            fieldname={"richtext_de"}
                            setFieldValue={setFieldValue}
                            value={values.richtext_de}
                            setFieldTouched={setFieldTouched}
                          />
                        </div> */}
                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="files" className="text-left d-flex">
                            {t("label_image")}:<span className="requirestar">*</span>
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("image_support_tooltip")}
                                </Tooltip>
                              }
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                          </label>
                          <input
                            className="form-control imgInput"
                            id="files"
                            name="files"
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "files",
                                event.currentTarget.files[0] || ""
                              );
                              event.currentTarget.files.length === 1
                                ? setPreviewImage(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  )
                                )
                                : setPreviewImage("");
                            }}
                          />
                          <span
                            className="text-danger d-flex text-left"
                            id="errortext"
                          >
                            {errors.files && touched.files && t(errors.files)}
                          </span>
                        </div>
                        {/* {previewimage ? (
                          <div className="mb-4">
                            <img
                              src={previewimage}
                              alt="Preview"
                              style={{ height: "100px" }}
                            />
                          </div>
                        ) : (
                          ""
                        )} */}

                        {previewimage ? (
                          <ul className="question-image-preview questions-ul">
                            <li className="pr_img_box">
                              <img
                                src={previewimage}
                                style={{ height: "100px" }}
                                alt={t("banner-image")}
                              />
                              <div className="img_options">
                                <button type="button" className="text-danger"
                                  onClick={() => {
                                    // console.log(previewResImage , previewimage)
                                    setPreviewImage("");
                                    setFieldValue("files", "");
                                    document.getElementById("files").value = ""
                                  }}>
                                  <i className="ri-delete-bin-6-fill"></i>
                                </button>
                              </div>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                        <div className="">
                          <button
                            className="btn btn-main-primary signbtn mr-2"
                            type="submit"
                            disabled={submitted ? true : null}
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            {t("btn_submit")}
                          </button>
                          <button
                            className="btn ripple btn-secondary"
                            type="button"
                            disabled={submitted ? true : null}
                            onClick={() => navigate(-1)}
                          >
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            {t("btn_cancel")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default BannerAdd;
