import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalKey } from '../../../utils/commonfunction';
import { setGlobalLoader } from '../../../redux/slices/globalLoader';
import { useDispatch, useSelector } from 'react-redux';
import GlobalLoader from './globalLoader';
import {ROLE} from '../../../utils/Constants'

const CashierPublicRoute = ({ component: Component }) => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const userData = getLocalKey(ROLE.STAFF) ? JSON.parse(getLocalKey(ROLE.STAFF)) : {};


    return (
        <>
            {
                userData && userData.token ?
                    <Navigate to={{ pathname: "/cashier" }} />
                    :
                    <Component />
            }
        </>
    )

}

export default CashierPublicRoute;