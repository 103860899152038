import React, { useEffect, useMemo, useRef, useState } from "react";
import { TrimText, capitalizeFirstLetter, createSlider } from "../../../utils/commonfunction";
import Skeleton from "react-loading-skeleton";
import { Testimonials } from "../services/website.services";
import { useTranslation } from "react-i18next";

const WebsiteTestimonials = ({ id = "TestimonialsSlider" }) => {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [apiLoaded, setApiLoaded] = useState(false);
  const TestimonialsHomeSliderRef = useRef();
  const [ModalData, setModalData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    Testimonials()
      .then((response) => {
        setApiLoaded(true);
        setTestimonialsData(response?.data?.list||[]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    if (TestimonialsHomeSliderRef?.current) {
      createSlider(`#${id}`, {
        loop: false,
        margin: 20,
        nav: true,
        item: 2,
        dots: false,
        responsive: {
          0: {
            items: 1,
          },
          600: {
            items: 3,
          },
          1000: {
            items: 4,
          },
        },
      });
    }
    setTimeout(() => {
      const elements = document.querySelectorAll('.testimonials_read_more');
      elements.forEach((elem) => {
        elem.setAttribute('onclick', 'readMoreTestimonials(event)');
      });
    }, 100);
  }, [TestimonialsHomeSliderRef, apiLoaded]);


  useMemo(() => {
    document.addEventListener('set_testimonial_data', function (e) {
      const { source } = e.detail;
      setModalData(JSON.parse(source));
    });
  }, []);

  return (
    <>
      {apiLoaded ? (
        testimonialsData.length?
          <section className="testimonial-section section-padding-bottom">
            <div className="container">
              <div className="headings text-center mb-5">
                <h4>{t("web_our_testimonials")}</h4>
                <h2>{t("web_our_testimonials_text")}</h2>
              </div>
              <>
                {testimonialsData ? (
                  <div className="owl-carousel owl-theme enable-nav-flex" id={id} ref={TestimonialsHomeSliderRef}>
                    {testimonialsData &&
                      testimonialsData.map((item, index) => (
                        <div className="item" key={index}>
                          <article className="testimonial-box">
                            <figure>
                              <img src={item?.profile_image} alt="" />
                            </figure>
                            <figcaption>
                              <p>
                                {item?.testimonial_text.length > 90 ? (
                                  <>
                                    {capitalizeFirstLetter(TrimText(item?.testimonial_text, 90))}
                                    <span className="link-text cp testimonials_read_more" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" data-source={JSON.stringify(item)}>Read more</span>
                                  </>
                                ) : (
                                  capitalizeFirstLetter(TrimText(item?.testimonial_text, 90))
                                )}
                              </p>

                              <h4>{item?.name}</h4>
                              <span>{item?.address}</span>
                            </figcaption>
                          </article>
                        </div>
                      ))}
                  </div>
                ) : null}
              </>
            </div>
          </section>
        :null
      ) : (
        <div className="row">
          <div className="col-lg-3 col-md-4 ">
            <Skeleton className="rounded-4" height={200} />
          </div>
          <div className="col-lg-3 col-md-4  d-none d-md-block">
            <Skeleton className="rounded-4" height={200} />
          </div>
          <div className="col-lg-3 col-md-4  d-none d-md-block">
            <Skeleton className="rounded-4" height={200} />
          </div>
          <div className="col-lg-3 col-md-4  d-none d-lg-block">
            <Skeleton className="rounded-4" height={200} />
          </div>
        </div>
      )}
      <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel">{ModalData?.name}</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {ModalData?.testimonial_text}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteTestimonials;
