import React, { useEffect, useRef, useState} from 'react';
import { createSlider } from '../../../utils/commonfunction';
import Skeleton from 'react-loading-skeleton'
import { FeaturedRestaurant } from '../services/website.services';
import { useTranslation } from 'react-i18next';



const WebsiteFeaturedRestaurant = ({ id = "featuredSlider" }) => {

    const [featuredRestaurantData, setFeaturedRestaurantData] = useState([]);
    const [apiLoaded, setApiLoaded] = useState(false);
    const FeaturedRestaurantSliderRef = useRef();
    const {t} = useTranslation();


    useEffect(() => {
        FeaturedRestaurant().then((response) => {
            setApiLoaded(true);
            setFeaturedRestaurantData(response?.data?.list||[]);
        }).catch((err) => {
            console.log("err", err);
        })
    }, []);

    useEffect(() => {
        if (FeaturedRestaurantSliderRef?.current) {
            createSlider(`#${id}`, {
                loop: false,
                margin: 20,
                nav: true,
                item: 2,
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 3
                    },
                    1000: {
                        items: 4
                    }
                }
            });
        }
    }, [FeaturedRestaurantSliderRef, apiLoaded]);

    return (
        <>
            {apiLoaded ?
            featuredRestaurantData && featuredRestaurantData.length ? 
        <section className="featured-restaurants-section section-padding-top">
                <div className="container" style={{ minHeight: '351.8px' }}>
                    <div className='headings text-center mb-5'>
                        <h2>{t('web_featured_restaurants')}</h2>
                    </div>
                    <>
                        {featuredRestaurantData ?
                            <div className="owl-carousel owl-theme enable-nav-flex" id={id} ref={FeaturedRestaurantSliderRef}>
                                {featuredRestaurantData && featuredRestaurantData.map((item, index) => (
                                    <div className="item" key={index}>
                                        <article className='featured-restaurants-box'>
                                            <figure>
                                                <img src={item?.restaurant_logo} alt="" />
                                            </figure>
                                            <figcaption>
                                                <h4>{item?.restaurant_name}</h4>
                                                <span className='location-info'><i className='ti ti-map-pin'></i> {item?.address}</span>
                                            </figcaption>
                                        </article>
                                    </div>
                                ))}
                            </div>
                            : null}
                    </>
                </div>
                </section>
            :null
                :
                <section className="featured-restaurants-section section-padding-top">
                    <div className="container" style={{ minHeight: '351.8px' }}>
                        <div className="row">
                            <div className="col-lg-3 col-md-4 ">
                                <Skeleton className='rounded-4' height={200} />
                            </div>
                            <div className="col-lg-3 col-md-4  d-none d-md-block">
                                <Skeleton className='rounded-4' height={200} />
                            </div>
                            <div className="col-lg-3 col-md-4  d-none d-md-block">
                                <Skeleton className='rounded-4' height={200} />
                            </div>
                            <div className="col-lg-3 col-md-4  d-none d-lg-block">
                                <Skeleton className='rounded-4' height={200} />
                            </div>
                        </div>
                    </div>
                </section>
            }
        
        </>
    )
}

export default WebsiteFeaturedRestaurant
