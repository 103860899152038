import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function GridLoader({ parentClass="row", className="col-xxl-2 col-xl-3 col-md-6 mb-4", innerClass="category-box", height=165}) {
    return (
        <div className={parentClass}>
            <div className={className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={"d-none d-md-block "+className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={"d-none d-md-block "+className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={"d-none d-md-block "+className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={"d-none d-md-block "+className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={"d-none d-md-block "+className}>
                <Skeleton className={innerClass} height={height} />
            </div>
            <div className={"d-none d-md-block "+className}>
                <Skeleton className={innerClass} height={height} />
            </div>
        </div>
    )
}
