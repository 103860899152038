import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/payment-method"
const options = {role: ROLE.RESTAURANT};



export const PaymentMethodList = async (values ) => {
    return await axiosInstance(options).post(`${path}/list` , values);
}

export const addPaymentMethod = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const editPaymentMethod = async (values) => {
  return await axiosInstance(options).put(`${path}/edit`, values);
}

export const paymentMethodDetails = async (o_id) => {
  return await axiosInstance(options).get(`${path}/details/?o_id=${o_id}`);
}

export const deletePaymentMethod = async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?o_id=${values.o_id}`);
};

export const changePaymentTypeStatus = async (values) => {
  return await axiosInstance(options).patch(`${path}/update-status`, values);
};

export const changePaymentMethodStatus = async (values) => {
  return await axiosInstance(options).patch(`${path}/update-payment-method-status`, values);
};