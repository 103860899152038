import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Breadcrums from "../../../common/breadcrumbs";
import CustomCkeditor from "../../../common/customeditor";
import * as pageServices from "../../../services/pages.services";
import { SWAL_SETTINGS, MENU_LOCATION, showFilterlist, INPUT_LENGTH_50 } from "../../../../../utils/Constants";
import {
  globalLoader,
  handleServerValidations,
} from "../../../../../utils/commonfunction";
import { Tooltip, Whisper } from "rsuite";
import { useTranslation } from "react-i18next";
const PageAdd = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation()
  const [submitted, setSubmitted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    title: "",
    title_ja: "",
    name: "",
    slug: "",
    short_description: "",
    short_description_ja: "",
    meta_tags: "",
    meta_keyword: "",
    richtext: "",
    title_de: "",
    richtext_ja: "",
  });

  const breadcrumbs = [
    { title: t("sidebar_link_dashboard"), url: "/admin/dashboard" },
    { title: t("sidebar_link_pages"), url: "/admin/cms/pages/list/1" },
    { title: t("link_add"), url: "" },
  ];

  useEffect(() => {
    setInitialValues({
      title: state && state?.title ? state?.title : "",
      title_ja: state && state?.title_ja ? state?.title_ja : "",
      slug: state && state?.slug ? state?.slug : "",
      status: state && state?.status ? state.status : "",
      menu_location: state && state?.menu_location ? state.menu_location : "",
      short_description:
        state && state?.short_description ? state?.short_description : "",
      short_description_ja:
        state && state?.short_description_ja ? state?.short_description_ja : "",
      richtext: state && state?.description ? state?.description : "",
      richtext_ja: state && state?.richtext_ja ? state?.richtext_ja : "",

    });
    setDataLoaded(true);
  }, []);

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          validate={(values) => {
            const error = {};
            if (!values.title || !values.title.trim())
              error.title = "label_title_error";
            if (values.title.length > INPUT_LENGTH_50)
              error.title = "validation_err_reached_maximum_length";
            // if (!values.title_ja || !values.title.trim())
            //   error.title_ja = t("label_title_error");
            // if (values.title_ja.length > INPUT_LENGTH_50)
            //   error.title_ja = t("validation_err_reached_maximum_length");
            if (!values.slug || !values.slug.trim())
              error.slug = "label_slug_error";
            if (values.slug.length > INPUT_LENGTH_50)
              error.slug = "validation_err_reached_maximum_length";
            if (!values.status)
              error.status = "validation_err_status_required";
            if (!values.menu_location)
              error.menu_location = "validation_err_menu_location";
            if (!values.short_description || !values.short_description.trim())
              error.short_description = "label_short_description_error";
            // if (!values.short_description_ja || !values.short_description_ja.trim())
            //   error.short_description_ja = t("short_description_ja");
            if (!values.richtext || !values.richtext.trim())
              error.richtext = "label_long_description_error";

            return error;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitted(true);
            globalLoader(true);
            console.log(values);
            let formData = new FormData();
            formData.append("title", values.title);
            formData.append("title_ja", values.title_ja);
            formData.append("slug", values.slug);
            formData.append("status", Number(values.status));
            formData.append("menu_location", values.menu_location);
            formData.append("short_description", values.short_description);
            formData.append("short_description_ja", values.short_description_ja);
            formData.append("description", values.richtext);
            formData.append("description_ja", values.richtext_ja);
            // formData.append("type", values.type);
            pageServices
              .Add(formData)
              .then((response) => {
                setSubmitting(false);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  setTimeout(() => {
                    resetForm({ values: "" });
                    globalLoader(false);
                    navigate(`/admin/cms/pages/list/1`);
                  }, 1000);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                  setSubmitted(false);
                  globalLoader(false);
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">{t("add_cms")}</h6>
                      </div>
                      <div className="row row-sm">
                        {/* <div className="col-lg-6 text-center form-group">
                          <label htmlFor="name" className="text-left d-flex">
                          {t("label_name")}:
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={
                                <Tooltip>Page Name - Backend Only</Tooltip>
                              }
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                          </label>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.name}
                            className="form-control"
                          />
                        </div> */}
                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            {t("label_page_title")}:<span className="requirestar">*</span>
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("tooltip_page_title")}
                                </Tooltip>
                              }
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                          </label>
                          <input
                            name="title"
                            id="title"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                            placeholder={t("page_title_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.title && touched.title && t(errors.title)}
                          </span>
                        </div>


                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="title_ja" className="text-left d-flex">
                            {t("label_page_title_ja")}:
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("tooltip_page_title")}
                                </Tooltip>
                              }
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                          </label>
                          <input
                            name="title_ja"
                            id="title_ja"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title_ja}
                            className="form-control"
                            placeholder={t("page_title_placeholder")}
                          />
                          {/* <span className="text-danger d-flex text-left">
                            {errors.title_ja && touched.title_ja && errors.title_ja}
                          </span> */}
                        </div>
                        <div className="col-lg-6 text-center form-group">
                          <label htmlFor="slug" className="text-left d-flex">
                            {t("label_slug_of_page")}:<span className="requirestar">*</span>
                          </label>
                          <input
                            name="slug"
                            id="slug"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.slug}
                            className="form-control"
                            placeholder={t("slug_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.slug && touched.slug && t(errors.slug)}
                          </span>
                        </div>

                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="status" className="text-left d-flex">
                            {t("list_heading_status")}:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          <div className=" select-down-arrow">
                            <select
                              name="status"
                              id="status"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.status}
                              className="form-control cp" >

                              {/* <option value="">{t("input_select_text")}</option> */}
                              {(showFilterlist).map((value, i) => {
                                return (
                                  <option key={i} value={value.status__id}>{t(`${value.name}`)}</option>
                                )
                              })}
                            </select>
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.status &&
                              touched.status &&
                             t(errors.status)}
                          </span>
                        </div>

                        <div className="col-md-6 text-center form-group">
                          <label htmlFor="menu_location" className="text-left d-flex">
                            {t("label_menu_of_the_page")}:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          <div className=" select-down-arrow">
                            <select
                              name="menu_location"
                              id="menu_location"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.menu_location}
                              className="form-control cp" >

                              <option value="">{t("input_select_text")}</option>
                              {Object.keys(MENU_LOCATION).map((key, i) => {
                                return (
                                  <option key={i} value={MENU_LOCATION[key]}>{t(`${key}`)}</option>
                                )
                              })}
                            </select>
                          </div>
                          <span className="text-danger d-flex text-left">
                            {errors.menu_location &&
                              touched.menu_location &&
                             t(errors.menu_location)}
                          </span>
                        </div>

                        {/* <div className="col-lg-12 text-center form-group required">
                          <label
                            htmlFor="meta_tags"
                            className="text-left d-flex"
                          >
                             {t("meta_tags")}:{" "}
                          </label>
                          <textarea
                            name="meta_tags"
                            id="meta_tags"
                            rows="2"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.meta_tags}
                            className="form-control"
                          />
                        </div> */}
                        {/* <div className="col-lg-12 text-center form-group required">
                          <label
                            htmlFor="meta_keyword"
                            className="text-left d-flex"
                          >
                             {t("meta_keywords")}:{" "}
                          </label>
                          <textarea
                            name="meta_keyword"
                            id="meta_keyword"
                            rows="2"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.meta_keyword}
                            className="form-control"
                          />
                        </div> */}
                        <div className="col-lg-12 text-center form-group required">
                          <label
                            htmlFor="short_description"
                            className="text-left d-flex"
                          >
                            {t("label_short_description")}:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          <textarea
                            name="short_description"
                            id="short_description"
                            rows="2"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.short_description}
                            className="form-control"
                            placeholder={t("short_description_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.short_description &&
                              touched.short_description &&
                             t(errors.short_description)}
                          </span>
                        </div>
                        <div className="col-lg-12 text-center form-group required">
                          <label
                            htmlFor="short_description_ja"
                            className="text-left d-flex"
                          >
                            {t("label_short_description_ja")}:
                            {" "}
                          </label>
                          <textarea
                            name="short_description_ja"
                            id="short_description_ja"
                            rows="2"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.short_description_ja}
                            className="form-control"
                            placeholder={t("short_description_placeholder")}
                          />
                          {/* <span className="text-danger d-flex text-left">
                            {errors.short_description_ja &&
                              touched.short_description_ja &&
                              errors.short_description_ja}
                          </span> */}
                        </div>
                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            {t("label_long_description")}:<span className="requirestar">*</span>
                          </label>
                          <CustomCkeditor
                            fieldname={"richtext"}
                            setFieldValue={setFieldValue}
                            value={values.richtext}
                            setFieldTouched={setFieldTouched}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.richtext &&
                              touched.richtext &&
                             t(errors.richtext)}
                          </span>
                        </div>

                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            {t("description_japanese")}:
                          </label>
                          <CustomCkeditor
                            fieldname={"richtext_ja"}
                            setFieldValue={setFieldValue}
                            value={values.richtext_ja}
                            setFieldTouched={setFieldTouched}
                          />
                          <span className='text-danger d-flex text-left'>{errors.richtext_ja && touched.richtext_ja && t(errors.richtext_ja)}</span>
                        </div>

                        <div className="">
                          <button
                            className="btn btn-main-primary signbtn mr-2"
                            type="submit"
                            disabled={submitted ? true : null}
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            {t("btn_submit")}
                          </button>
                          <button
                            className="btn ripple btn-secondary"
                            type="button"
                            disabled={submitted ? true : null}
                            onClick={() => navigate(-1)}
                          >
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            {t("btn_cancel")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};

export default PageAdd;
