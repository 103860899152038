import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { detail, list } from "../../../services/table.services";
import { LOADER_TIMEOUT_TIME, SOCKET_EVENTS, SWAL_SETTINGS } from '../../../../../utils/Constants';
import { getLocalKey, getSubTotalQRApp, showPrice, TrimText } from '../../../../../utils/commonfunction';
import GridLoader from '../../../../restaurant/shared/gridLoader';
import RecordNotFound from '../../../../restaurant/shared/recordNotFound';
import Swal from 'sweetalert2';
import { SOCKET_CLIENT } from '../../../../../utils/socket';
export default function CashierDashboard() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [List, setList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [openWithHeaderEdit, setOpenWithHeaderEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableDetail, setTableDetail] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const isCashRegisterDone = useSelector((state) => state?.cashierDetails?.cashRegister);

  useEffect(() => {
    try {
      if (isCashRegisterDone === false && getLocalKey('cashRegisterEntry') === 'false') {
        navigate('/cashier/open-cash-register')
      }

    } catch (error) {
      console.error("Error parsing or handling cashier data:", error);
    }
  }, [isCashRegisterDone]);


  const getTableList = ()=>{
    setLoader(true);
    list()
      .then((data) => {
        if (data && data.data) {
          setList(data.data.list || []);
          setTotalRecords(data.data.total_records || 0);
        } else {
          setList([]);
          setTotalRecords(0);
        }

        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.error("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }
  useEffect(() => {
    getTableList();
  }, [refreshList]);

  const handleRefreshList = () => {
    setRefreshList(!refreshList)
  }
  const handleTableClick = (tableId) => {
    detail({ o_id: tableId })
      .then((response) => {
        if (response?.data?.is_available === false) {
          navigate(`/cashier/dashboard/categories/${tableId}`);
        }
        else{
          Swal.fire({
            icon: "error",
            text: "Order has not been placed for this table",
            ...SWAL_SETTINGS,
          });
       
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };


  useMemo(()=>{
    SOCKET_CLIENT.on('GET-DATA', (response) => {
        const eventData = response.data;
     
       if(response.event===SOCKET_EVENTS.ORDER_PLACED){
          getTableList();
       }
       if(response.event===SOCKET_EVENTS.ORDER_BILLED){
        getTableList();
     }
    });
} , [List]);

  return (
    <>
      <div className='dashboard-cashier-left d-flex flex-column'>
        <div className='cashier-head text-center'>
          <h4>{t('nav_link_home')}</h4>
          <div className='btns-head-in'>
            <button className='btn btn-icon' onClick={handleRefreshList}>
              <i className='ti ti-refresh'></i>
            </button>
          </div>
        </div>
        {loader ?
          <GridLoader parentClass='cashier-table-wrap' className="w-100" /> :
          <>
            {List.length ?
              <div className='cashier-table-wrap'>
                {List.map((item, index) => {
                  return (
                    <div className={item?.is_available ? 'cashier-table-box cp' : 'cashier-table-box active cp'} onClick={() => handleTableClick(item?._id)} key={index}>
                      <div className='d-flex'>
                        <h4 className='px-2'>{TrimText(item?.table_number, 12)}</h4>
                      </div>
                      {item?.is_available ?
                        <div className='cashier-table-info'>
                          <span>¥0</span>
                        </div>
                        : <div className='cashier-table-info'>
                          <h6>{TrimText(item?.activeOrder?.customerDetails?.customer_name , 10) || t('label_not_entered')}</h6>
                          <p>{t('label_no_of_person')} {item?.activeOrder?.number_of_persons || t('label_not_entered')}</p>
                          <span>¥ {item?.activeOrder ? showPrice(item?.activeOrder?.great_grand_total): 0}</span>
                          {console.log(item)}
                        </div>
                      }
                    </div>
                  )
                }
                )}
              </div> : <RecordNotFound heading="no_table_found" />
            }

          </>
        }
      </div>
    </>
  )
}


