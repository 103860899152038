import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { list } from "../../../services/table.services";
import { LOADER_TIMEOUT_TIME } from '../../../../../utils/Constants';
import { TrimText } from '../../../../../utils/commonfunction';
export default function CashierDashboard() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [List, setList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [openWithHeaderEdit, setOpenWithHeaderEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableDetail, setTableDetail] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const isCashRegisterDone = useSelector((state) => state?.cashierDetails?.cashRegister);
  console.log("isCashRegisterDone", isCashRegisterDone)
  useEffect(() => {
    try {
      if (isCashRegisterDone === false) {
        navigate('/cashier/open-cash-register')
      }

    } catch (error) {
      console.error("Error parsing or handling cashier data:", error);
    }
  }, [isCashRegisterDone]);


  useEffect(() => {
    setLoader(true);
    list()
      .then((data) => {
        if (data && data.data) {
          setList(data.data.list || []);
          setTotalRecords(data.data.total_records || 0);
        } else {
          setList([]);
          setTotalRecords(0);
        }

        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [refreshList]);

  const handleRefreshList = () => {
    setRefreshList(!refreshList)
  }
  const handleTableClick = (tableId) => {
    navigate(`/cashier/dashboard/categories/${tableId}`); // Navigate to categories page with table ID
  };

  return (
      <>
        <div className='dashboard-cashier-left d-flex flex-column'>
        <div className='cashier-head text-center'>
          <h4>{t('nav_link_home')}</h4>
          <div className='btns-head-in'>
            <button className='btn btn-icon' onClick={handleRefreshList}>
              <i className='ti ti-refresh'></i>
            </button>
          </div>
        </div>
        <div className='cashier-table-wrap'>
          {List.map((item, index) => {
            return (
              <div className={item?.is_availabble ? 'cashier-table-box cp' : 'cashier-table-box active cp'} onClick={() => handleTableClick(item?._id)}>
                <div className='d-flex'>
                  <h4 className='px-2'>{TrimText(item?.table_number,12)}</h4>
                </div>
                <div className='cashier-table-info'>
                  <h6>Lissa watson</h6>
                  <p>{t('label_no_of_person')} 10</p>
                  <span>¥400</span>
                </div>
              </div>
            )
          }
          )}
        </div>
        </div>
      </>
  )
}


