import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import { DatePicker } from 'rsuite';
import { useNavigate } from "react-router-dom";
import * as restaurantService from "../../services/restaurant.services";
import { useTranslation } from "react-i18next";
import {
    PLAN_DURATION_TYPES,
    PLAN_TYPES,
    SWAL_SETTINGS,
} from "../../../../utils/Constants";
import {
    handleServerValidations,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomError from "../../../../utils/customError";
import { useParams } from 'react-router-dom';
import { useSelector } from "react-redux";

const RenewEnrollPlan = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const [restEnrollData, setRestEnrollData] = useState([]);


    const globalDetails = useSelector((state) => state?.globalData?.data)

    const yearlyEnrollCost = globalDetails?.yearly_restaurant_enrollment_cost;
    const monthlyEnrollCost = globalDetails?.monthly_restaurant_enrollment_cost;


    const validationSchema = Yup.object().shape({
        plan_name: Yup.string().trim().required("label_plan_name_error"),
        contract_plan_duration_type: Yup.string().test('plan_name', 'label_contract_plan_type_error', (value) => {
            if (formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
                return Boolean(value);
            } else {
                return true;
            }
        }),
        expiry_date: Yup.string().test('plan_name', "label_expiry_date_error", (value) => {
            if (formik.values.plan_name === PLAN_TYPES.DEMO) {
                return Boolean(value);
            } else {
                return true;
            }
        }),
    });

    useEffect(() => {

        const getUserDetails = () => {
            const formData = new FormData();
            formData.append("restaurant_id", params.id)
            restaurantService
                .RestEnrollList(formData)
                .then((data) => {
                    // console.log("data ---", data.data.list[0]);
                    setRestEnrollData(data && data?.data && data?.data?.list ? data?.data?.list[0] : [])
                }).catch((error) => {
                    console.log("error====>", error);
                })
        }
        getUserDetails()
    }, [])

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            plan_name: "",
            expiry_date: "",
            contract_plan_duration_type: PLAN_DURATION_TYPES.MONTHLY,
            membership_cost: 0
        },
        validateOnBlur: false,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setSubmitted(true);
            let formData = new FormData();
            // formData.append("rest_Id", restEnrollData.rest_Id)
            // formData.append("primary_id", params.userId)
            // formData.append("email", restEnrollData.email);
            // formData.append("restaurant_name", restEnrollData.restaurant_name);
            // formData.append("phone_number", values.phone_number);
            // formData.append("company_address", values.company_address);
            // formData.append("company_name", values.company_name);
            // formData.append("shipping_address", values.shipping_address);
            // formData.append("representative_name", values.representative_name);
            // formData.append("contractor_name", values.contractor_name);
            // formData.append("restaurant_id", location[5])
            formData.append("_id", restEnrollData._id);
            formData.append("restaurant_id", restEnrollData.restaurant_id);
            formData.append("plan_name", values.plan_name);
            if (values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
                formData.append("contract_plan_duration_type", values.contract_plan_duration_type);
                formData.append("days_in_expiry", restEnrollData.days_in_expiry)
            } else {
                const isoDate = values.expiry_date.toISOString();
                formData.append("expiry_date", isoDate);
                formData.append("days_in_expiry", 0)
            }
            if (values.plan_name === PLAN_TYPES.PREMIUM_PLAN) {
                if (values?.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY) {
                    formData.append("membership_cost", yearlyEnrollCost);
                }
                else {
                    formData.append("membership_cost", monthlyEnrollCost);
                }
            }
            else {
                formData.append("membership_cost", values?.membership_cost);
            }

            restaurantService
                .RestRenewEnroll(formData)
                .then((response) => {
                    setSubmitting(false);
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm({ values: "" });
                        // navigate(`/admin/restaurant-management/restaurant/list/1`);
                        navigate(-1);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                        setSubmitted(false);
                    }
                })
                .catch((error) => {
                    console.log("error ====> ", error);
                });
        },
    });

    let today = new Date();
    const addOnDate = formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.MONTHLY ?
        new Date((today.setMonth(today.getMonth() + 1))) :
        new Date(today.setFullYear(today.getFullYear() + 1));

    const remandingDay = restEnrollData.days_in_expiry
    const pre_expiry_date = new Date(addOnDate.setDate(addOnDate.getDate() + remandingDay))

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {/* {console.log("values--->", formik.values)} */}
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">
                                        {t("heading_renew_plan")}
                                    </h6>
                                </div>
                                <div className="row row-sm">
                                    {/* <div className="col-md-6 text-center form-group">
                                        <label htmlFor="rest_Id" className="text-left d-flex">
                                            {t("label_id")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="rest_Id"
                                            id="rest_Id"
                                            type="text"
                                            disabled={true}
                                            // onBlur={formik.handleBlur}
                                            value={restEnrollData.rest_Id}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="rest_Id" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-lg-6 text-center form-group">
                                        <label htmlFor="email" className="text-left d-flex">
                                            {t("label_email")}:<span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="email"
                                            id="email"
                                            type="text"
                                            disabled={true}
                                            value={restEnrollData.email}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="email" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="restaurant_name" className="text-left d-flex">
                                            {t("label_restaurant_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="restaurant_name"
                                            id="restaurant_name"
                                            disabled={true}
                                            type="text"
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            value={restEnrollData.restaurant_name}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="restaurant_name" form={formik} />
                                        </span>
                                    </div> */}

                                    <div className="col-md-6 text-center form-group">
                                        <label className="text-left d-flex" htmlFor="phone_number">
                                            {t("label_phone_number")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="phone_number"
                                            id="phone_number"
                                            type="text"
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            disabled={true}
                                            defaultValue={restEnrollData.phone_number}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="phone_number" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="company_name" className="text-left d-flex">
                                            {t("label_company")} {t("list_heading_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="company_name"
                                            id="company_name"
                                            type="text"
                                            disabled={true}
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            defaultValue={restEnrollData.company_name}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="company_name" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="representative_name" className="text-left d-flex">
                                            {t("label_representative_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="representative_name"
                                            id="representative_name"
                                            type="text"
                                            disabled={true}
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            defaultValue={restEnrollData.representative_name}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="representative_name" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="contractor_name" className="text-left d-flex">
                                            {t("label_contractor_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <input
                                            name="contractor_name"
                                            id="contractor_name"
                                            type="text"
                                            disabled={true}
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            defaultValue={restEnrollData.contractor_name}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="contractor_name" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="plan_name" className="text-left d-flex">
                                            {t("label_plan_name")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <div className=" select-down-arrow">
                                            <select
                                                name="plan_name"
                                                id="plan_name"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.plan_name}
                                                className="form-control cp" >
                                                <option value="">{t("input_select_text")}</option>
                                                <option value={PLAN_TYPES.DEMO}>{t("input_select_option_demo")}</option>
                                                <option value={PLAN_TYPES.PREMIUM_PLAN}>{t("label_premium_plan")}</option>
                                            </select>
                                        </div>
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="plan_name" form={formik} />
                                        </span>
                                    </div>

                                    {formik.values.plan_name === PLAN_TYPES.DEMO ?
                                        (<div className="col-md-6 text-center form-group">
                                            <label htmlFor="expiry_date" className="text-left d-flex">
                                                {t("label_expiry_date")}:
                                                <span className="requirestar">*</span>{" "}
                                            </label>
                                            <DatePicker
                                                format="dd.MM.yyyy"
                                                name="expiry_date"
                                                id="expiry_date"
                                                type="date"
                                                // onChange={formik.handleChange}
                                                onChange={value => formik.setFieldValue('expiry_date', new Date(value))}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.expiry_date ? new Date(formik.values.expiry_date) : null}
                                            />
                                            <span className="text-danger d-flex text-left">
                                                <CustomError name="expiry_date" form={formik} />
                                            </span>
                                        </div>) :
                                        null}

                                    {formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN ?
                                        <>
                                            <div className="col-md-6 text-center form-group">
                                                <label htmlFor="contract_plan_duration_type" className="text-left d-flex">
                                                    {t("contract_plan_duration_type")}:
                                                    <span className="requirestar">*</span>{" "}
                                                </label>
                                                <div className="col-md-6 text-left form-group mt-3" style={{ margin: 0, padding: 0 }}>
                                                    <label className="radio-inline mr-2">
                                                        <input
                                                            type="radio"
                                                            name="contract_plan_duration_type"
                                                            value={PLAN_DURATION_TYPES.MONTHLY}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            checked={formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.MONTHLY}
                                                        /> {t("input_radio_monthly")}
                                                    </label>
                                                    <label className="radio-inline mr-2">
                                                        <input
                                                            type="radio"
                                                            name="contract_plan_duration_type"
                                                            value={PLAN_DURATION_TYPES.YEARLY}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            checked={formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY}
                                                        />  {t("input_radio_yearly")}
                                                    </label>
                                                </div>
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="contract_plan_duration_type" form={formik} />
                                                </span>
                                            </div>

                                            <div className="col-md-6 text-center form-group">
                                                <label htmlFor="pre_expiry_date" className="text-left d-flex">
                                                    {t("label_expiry_date")}:
                                                    <span className="requirestar">*</span>{" "}
                                                </label>
                                                <DatePicker
                                                    format="dd.MM.yyyy"
                                                    name="pre_expiry_date"
                                                    id="pre_expiry_date"
                                                    type="date"
                                                    disabled={formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN ? true : false}
                                                    // onChange={formik.handleChange}
                                                    onChange={value => formik.setFieldValue('pre_expiry_date', new Date(value))}
                                                    onBlur={formik.handleBlur}
                                                    value={pre_expiry_date}
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="pre_expiry_date" form={formik} />
                                                </span>
                                            </div>
                                            {/* 
                                            <div className="col-md-6 text-center form-group">
                                                <label htmlFor="days_in_expiry" className="text-left d-flex">
                                                    days_in_expiry:
                                                    <span className="requirestar">*</span>{" "}
                                                </label>
                                                <input
                                                    name="days_in_expiry"
                                                    id="days_in_expiry"
                                                    type="text"
                                                    disabled={true} 
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={total_days_in_expiry}

                                                    className="form-control"
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="days_in_expiry" form={formik} />
                                                </span>
                                            </div> */}


                                            <div className="col-md-6 text-center form-group">
                                                <label htmlFor="membership_cost" className="text-left d-flex">
                                                    {t("label_membership_cost")}:
                                                    <span className="requirestar">*</span>{" "}
                                                </label>
                                                <input
                                                    name="membership_cost"
                                                    id="membership_cost"
                                                    type="text"
                                                    disabled={formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN ? true : false}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.plan_name === PLAN_TYPES.PREMIUM_PLAN?
                                                        formik.values.contract_plan_duration_type === PLAN_DURATION_TYPES.YEARLY ? yearlyEnrollCost : monthlyEnrollCost
                                                        : formik.values.membership_cost}

                                                    className="form-control"
                                                />
                                                <span className="text-danger d-flex text-left">
                                                    <CustomError name="membership_cost" form={formik} />
                                                </span>
                                            </div>
                                        </>
                                        :
                                        null}

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="company_address" className="text-left d-flex">
                                            {t("label_company")} {t("label_address")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <textarea
                                            name="company_address"
                                            id="company_address"
                                            // onChange={formik.handleChange}
                                            // handleBlur={formik.handleBlur}
                                            disabled={true}
                                            defaultValue={restEnrollData.company_address}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="company_address" form={formik} />
                                        </span>
                                    </div>

                                    <div className="col-md-6 text-center form-group">
                                        <label htmlFor="shipping_address" className="text-left d-flex">
                                            {t("label_shipping")} {t("label_address")}:
                                            <span className="requirestar">*</span>{" "}
                                        </label>
                                        <textarea
                                            name="shipping_address"
                                            id="shipping_address"
                                            disabled={true}
                                            // onChange={formik.handleChange}
                                            // onBlur={formik.handleBlur}
                                            defaultValue={restEnrollData.shipping_address}
                                            className="form-control"
                                        />
                                        <span className="text-danger d-flex text-left">
                                            <CustomError name="shipping_address" form={formik} />
                                        </span>
                                    </div>

                                    <div className="mt-5">
                                        <button
                                            className="btn btn-info mr-2"
                                            type="submit"
                                            disabled={submitted ? true : null}
                                        >
                                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                            {t("btn_submit")}
                                        </button>
                                        <button
                                            className="btn ripple btn-secondary"
                                            type="button"
                                            disabled={submitted ? true : null}
                                            onClick={() => navigate(-1)}
                                        >
                                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                            {t("btn_cancel")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default RenewEnrollPlan;