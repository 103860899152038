import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { COINS, NOTES, REST_LOGIN_ERROR_TYPES, ROLE,SWAL_SETTINGS } from '../../../utils/Constants';
import { useTranslation } from 'react-i18next';
import { add } from '../services/cash-register.service';
import { getLocalKey, handleNavigation, handleServerValidations, isValidJSON } from '../../../utils/commonfunction';
import { useSelector } from 'react-redux';
import Swal from "sweetalert2";
import { useDispatch } from 'react-redux';
import { setCashRegister } from '../../../redux/slices/cashierDetail';
import { useNavigate } from 'react-router-dom';

export default function PenominationDetails() {
  const { t } = useTranslation();
  const restaurantData = getLocalKey(ROLE.CASHIER) ? JSON.parse(getLocalKey(ROLE.CASHIER)) : {};
  const cashierDetails = useSelector((state) => state?.cashierDetails?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Function to calculate the total value of notes and coins

  const isCashRegisterDone = useSelector((state) => state?.cashierDetails?.cashRegister);

  useEffect(() => {
    try {
      if (isCashRegisterDone !== false) {
        navigate('/cashier/dashboard')
      }

    } catch (error) {
      console.error("Error parsing or handling cashier data:", error);
    }
  }, [isCashRegisterDone]);


  const calculateTotal = (values) => {
    const totalNotes = Object.keys(values.notes).reduce((sum, key) => {
      return sum + values.notes[key] * parseInt(key, 10);
    }, 0);

    const totalCoins = Object.keys(values.coins).reduce((sum, key) => {
      return sum + values.coins[key] * parseInt(key, 10);
    }, 0);

    return totalNotes + totalCoins;
  };

  const formik = useFormik({
    initialValues: {
      openingBalance: 0,
      notes: NOTES.reduce((acc, item) => {
        acc[item.value] = 0;
        return acc;
      }, {}),
      coins: COINS.reduce((acc, item) => {
        acc[item.value] = 0;
        return acc;
      }, {}),
    },
    validationSchema: Yup.object({
      openingBalance: Yup.number()
        .required('Opening Balance is required')
        .min(0, 'Opening Balance must be greater than or equal to 0'),
    }),
    validate: (values) => {
      const errors = {};
      const total = calculateTotal(values);
      if (values.openingBalance !== total) {
        errors.openingBalance = `Opening Balance must match the sum of notes and coins (${total})`;
      }
      const hasNotesValue = Object.values(values.notes).some(v => v > 0);
      const hasCoinsValue = Object.values(values.coins).some(v => v > 0);
    
      if (!hasNotesValue && !hasCoinsValue) {
        errors.notes = 'At least one note or one coin must have a value';
      }
    
      return errors;
    },
    onSubmit: (values) => {
      console.log('Form values:', values);
      let obj = {
        opening_balance: values.openingBalance,
        opening_notes: isValidJSON(values.notes) ? JSON.stringify(values.notes) : null,
        opening_coins:  isValidJSON(values.coins) ? JSON.stringify(values.coins) : null,
        cashier_id: cashierDetails._id
      }
      add(obj)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            dispatch(setCashRegister(true));
            handleNavigation({
              successURL: "/cashier/dashboard",
              role: ROLE.CASHIER
            });

          } else {
            let message = handleServerValidations(response);
            /** Handling different types of server errors */
            const supportUrlConditions = [REST_LOGIN_ERROR_TYPES.ACCOUNT_IS_BLOCKED, REST_LOGIN_ERROR_TYPES.SUBSCRIPTION_EXPIRED, REST_LOGIN_ERROR_TYPES.ACCOUNT_NOT_ENROLLED, REST_LOGIN_ERROR_TYPES.PASSWORD_NOT_SET_YET];
            if (supportUrlConditions.indexOf(response.error_type) !== -1) {
              message += `<a className="d-block mt-2 no-decoration link-text" href="/support/${response.error_type}">${t('btn_text_contact_us')}</a>`;
            }
            Swal.fire({
              icon: "error",
              html: message,
              ...SWAL_SETTINGS
            });
          }
        })
        .catch((error) => {
          console.log("error===>", error)
        })
    },
  });

  // Update the opening balance when notes or coins change
  useEffect(() => {
    const total = calculateTotal(formik.values);
    formik.setFieldValue('openingBalance', total);
  }, [formik.values.notes, formik.values.coins]);

  // Handling changes to input fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, Number(value));
  };

  // Function to generate table rows
  const generateTableRows = () => {
    const rows = [];
    const maxRows = Math.max(NOTES.length, COINS.length);
    for (let i = 0; i < maxRows; i++) {
      const note = NOTES[i] || { value: '', label: '' };
      const coin = COINS[i] || { value: '', label: '' };
      rows.push(
        <tr key={i}>
          <td>
            {note.label && (
              <div className='d-flex align-items-center justify-content-between'>
                <span>{note.label}</span>
                <input
                  className='form-control'
                  name={`notes.${note.value}`}
                  type="number"
                  value={formik.values.notes[note.value] || ''}
                  min={0}
                  onChange={handleInputChange}
                />
              </div>
            )}
          </td>
          <td>
            {coin.label && (
              <div className='d-flex align-items-center justify-content-between'>
                <span>{coin.label}</span>
                <input
                  className='form-control'
                  name={`coins.${coin.value}`}
                  type="number"
                  value={formik.values.coins[coin.value] || ''}
                  onChange={handleInputChange}
                />
              </div>
            )}
          </td>
        </tr>
      );
    }
    return rows;
  };

  // Function to format date as YYYY/MM/DD
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  return (
    <div className='dashboard-cashier-wrap'>
      {/* <div className='loader-box'>
        <div className='loader-in'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#FFFFFF"></stop><stop offset=".3" stop-color="#FFFFFF" stop-opacity=".9"></stop><stop offset=".6" stop-color="#FFFFFF" stop-opacity=".6"></stop><stop offset=".8" stop-color="#FFFFFF" stop-opacity=".3"></stop><stop offset="1" stop-color="#FFFFFF" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a12)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#FFFFFF" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
          <span>Waiting for payment received </span>
        </div>
      </div> */}

      <div className='penomination-details-wrap'>
        <div className='penomination-details-box'>
          <div className='card'>
            <div className='card-body'>
              <div className='card-header'>
                <div className='d-flex align-items-center justify-content-between'>
                  <span>{t('input_placeholder_date')}</span>
                  <span>{formatDate(new Date())}</span>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className='d-flex align-items-center justify-content-between p-3'>
                  <span>{t('label_opening_balance')}</span>
                  <div className='input-group w-50'>
                    <span className="input-group-text" id="basic-addon1">¥</span>
                    <input
                      type="number"
                      className="form-control no_spring_input"
                      placeholder="Enter Cost Price"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="openingBalance"
                      value={formik.values.openingBalance}
                      onChange={handleInputChange}
                      onBlur={formik.handleBlur}
                      disabled
                    />

                    {formik.touched.openingBalance && formik.errors.openingBalance ? (
                      <div className="text-danger ms-3">{formik.errors.openingBalance}</div>
                    ) : null}
                  </div>
                </div>

                <div className='penomination-table'>
                  <table className='w-100'>
                    <thead>
                      <tr>
                        <th width="50%">{t('label_jpy_notes')}</th>
                        <th width="50%">{t('label_jpy_coins')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {generateTableRows()}
                    </tbody>
                  </table>
                </div>
                      {(formik.touched.notes && formik.errors.notes) || (formik.touched.coins && formik.errors.coins) ? (
                      <div className="text-danger">{formik.errors.notes}</div>
                    ) : null}
                <div className='text-center mt-3'>
                  <button type="submit" className='btn btn-primary'>{t('label_confirm')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
