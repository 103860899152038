
import React, { useEffect, useMemo, useRef, useState } from "react";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import { list, add, deleteTable, edit, detail} from "../../../services/table.service";
import { LOADER_TIMEOUT_TIME, QR_CODE_TYPES, SOCKET_EVENTS, SWAL_SETTINGS } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { useValidationSchemas } from "../../../validator/validation";
import { useTranslation } from "react-i18next";
import { globalLoader, handleOffcanvasScroll, handleServerValidations, useDebouncing, capitalizeFirstLetter, TrimText, generateTablePdf, blockSpecialChar } from "../../../../../utils/commonfunction";
import { TABLE_SHAPE_TYPES } from "../../../../../utils/Constants";
import CustomError from "../../../../../utils/customError";
import { useNavigate, useParams } from "react-router-dom";
import RecordNotFound from "../../../shared/recordNotFound";
import { Drawer } from "rsuite";
import Skeleton from "react-loading-skeleton";
import ReactToPrint from 'react-to-print';
import { QRCodeSVG } from 'qrcode.react';
import { SOCKET_CLIENT } from '../../../../../utils/socket';

export default function Tables() {
    const params = useParams();
    const { t } = useTranslation();
    const [List, setList] = useState([]);
    const { tableValidationSchema } = useValidationSchemas();
    const [refreshList, setRefreshList] = useState(false);
    const [openWithHeader, setOpenWithHeader] = useState(false);
    const [openWithHeaderEdit, setOpenWithHeaderEdit] = useState(false);
    const [globalSearch, setGlobalSearch] = useState("");
    const [search, setSearch] = useState({});
    const [loader, setLoader] = useState(true);
    const [statusFilter, setStatusFilter] = useState(null);
    const [tableDetail, setTableDetail] = useState({});
    const [page, setPage] = useState(1);
    const [offset, setOffsetPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [itemPerPage] = useState(10);
    const [prevGlobalSearch, setPrevGlobalSearch] = useState("");
    const navigate = useNavigate();


    const prepareSearch = (key, value) => {
        console.log("values", value)
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };


    const handleFilterStatusChange = (e) => {
        if (e.target.value) {
            setStatusFilter(e.target.value);
        } else {
            setStatusFilter(null);
        }
    };

    useMemo(()=>{
        SOCKET_CLIENT.on('GET-DATA', (response) => {
            const eventData = response.data;
         
           if(response.event===SOCKET_EVENTS.ORDER_PLACED){
               // table_id
               let tableList = [...List];
               const index = tableList.findIndex(item=>item._id === eventData.table_id);
               if(index !== -1){
                tableList[index].is_available = false;
                setList(tableList);
            }
           }
           if(response.event===SOCKET_EVENTS.ORDER_BILLED){
            // table_id
            let tableList = [...List];
            const index = tableList.findIndex(item=>item._id === eventData.table_id);
            if(index !== -1){
             tableList[index].is_available = true;
             setList(tableList);
         }
        }
        });
    } , [List]);

    // Handle select dropdown change
    const handleSelectChange = (e) => {
        const value = e.target.value;
        handleFilterStatusChange(e);
    };

    const prepareGlobalSearch = (value) => {
        setGlobalSearch(value);
    };
    const searchDebounce = useDebouncing(prepareGlobalSearch);

    useEffect(() => {
        setLoader(true);
        const formData = new FormData();
        if (Object.keys(search).length) {
            formData.append("search", JSON.stringify(search));
        }
        if (statusFilter) {
            formData.append("is_available", statusFilter);
        }
        formData.append("global_search", globalSearch);
        // Determine the page number
        let page = params.page;
        if (globalSearch) {
            if (prevGlobalSearch !== globalSearch || !prevGlobalSearch) {
                page = 1; // Reset to the first page if globalSearch changes
            }
            setPage(page);
        }
        formData.append("page", page);
        formData.append("per_page", itemPerPage);

        list(formData)
            .then((data) => {
                if (data && data.data) {
                    setList(data.data.list || []);
                    setPage(data.data.page);
                    navigate(`/restaurant/table-management/table/${data.data.page}`);
                    setOffsetPage(data.data.offset);
                    setTotalRecords(data.data.total_records || 0);
                } else {
                    setList([]);
                    setOffsetPage(0);
                    setTotalRecords(0);
                }

                setPrevGlobalSearch(globalSearch);

                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIMEOUT_TIME);
            })
            .catch((error) => {
                console.log("error ====> ", error);
                setTimeout(() => {
                    setLoader(false);
                }, LOADER_TIMEOUT_TIME);
            });
    }, [search, refreshList, globalSearch, params.page, statusFilter]);



    const getTableDetail = (item) => {
        detail({ o_id: item._id })
            .then((response) => {
                editTableForm.setValues({
                    o_id: item._id,
                    table_number: item.table_number,
                    capacity: item.capacity,
                    shape: item.shape,
                });
                setTableDetail(response?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };


    const addForm = useFormik({
        initialValues: {
            table_number: "",
            capacity: "",
            shape: "",
        },
        validationSchema: tableValidationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("table_number", values.table_number);
            formData.append("capacity", values.capacity);
            formData.append("shape", values.shape);
            add(formData)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm();
                        setOpenWithHeader(false);
                        setRefreshList(!refreshList);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error adding table", error);
                });
            console.log(values);
        },
    });

    const editTableForm = useFormik({
        initialValues: {
            o_id: tableDetail._id,
            table_number: tableDetail.table_number,
            capacity: tableDetail.capacity,
            shape: tableDetail.shape,
        },
        validationSchema: tableValidationSchema,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            let obj = {
                o_id: tableDetail._id,
                table_number: values.table_number,
                capacity: values.capacity,
                shape: values.shape,
            };

            //   console.log(values)
            edit(obj)
                .then((response) => {
                    if (response.success) {
                        Swal.fire({
                            icon: "success",
                            text: response.message,
                            ...SWAL_SETTINGS,
                        });
                        resetForm();
                        setOpenWithHeaderEdit(false);
                        setRefreshList(!refreshList);
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error updating table", error);
                });
            console.log(values);
        },
    });

    const deleteRecord = (item) => {
        Swal.fire({
            title: t("msg_are_you_sure"),
            text: t("btn_delete_text"),
            imageUrl: trash,
            showCancelButton: true,
            confirmButtonColor: "#dc3545",
            cancelButtonColor: "#6c757d",
            confirmButtonText: t("btn_delete"),
            cancelButtonText: t("btn_cancel"),
            reverseButtons: true,
            customClass: {
                popup: "swal-restaurant",
                confirmButton: "btn btn-danger col",
                cancelButton: "btn btn-secondary col",
            },
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                globalLoader(true);
                deleteTable({ o_id: item._id })
                    .then((response) => {
                        globalLoader(false);
                        if (response.success) {
                            Swal.fire({
                                icon: "success",
                                text: response.message,
                                ...SWAL_SETTINGS,
                            });
                            setRefreshList(!refreshList);
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS,
                            });
                        }
                    })
                    .catch((error) => {
                        globalLoader(false);
                        console.log("deleteError");
                    });
            } else {
                // setSortableCategories(List);
            }
        });
    };

    const componentRef = useRef();

    const handelDownload = (fileName = "qrCode.pdf") => {
        generateTablePdf(fileName)
        // getPdfByHtml(html, fileName)
    }
    const getLabelByValue = (value) => {
        const item = TABLE_SHAPE_TYPES.find(shapeType => shapeType.value === value);
        return item ? item.label : '';
    };


    const generateQRUrl = (tableDetail) => {
        console.log(tableDetail,"tableDetail")
        let qrCodeData = {
            restaurant_id: tableDetail.restaurant_id,
            table_id: tableDetail._id,
            type: QR_CODE_TYPES.TABLE, 
            base : "web"
        }
        const queryString = new URLSearchParams(qrCodeData).toString();
        let url = `${process.env.REACT_APP_WEB_BASE_URL}/qr-order?${queryString}`;
        console.log("QR url", url);
        return url;

    }

    return (
      <>
        <main id="main-content">
          <div className="inner-container">
            <div className="page-header d-flex align-items-center">
              <h4 className="heading-title-text">{t("label_table")} </h4>
            </div>
            <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
              <div className="search-box">
                <input
                  type="text"
                  className="form-control search-input"
                  placeholder={t("input_placeholder_search")}
                  onInput={(e) => {
                    searchDebounce(e.target.value);
                  }}
                />
              </div>
              <div className="card-head-group d-flex align-items-center gap-2">
                <div className="tabls-dowpdown">
                  <select className="form-control cp" onChange={handleSelectChange}>
                    <option disabled="" value="">
                      {t("label_select_status")}
                    </option>
                    <option value="1"> {t("label_available")}</option>
                    <option value="0">{t("label_reserved")}</option>
                  </select>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setOpenWithHeader(true);
                  }}
                >
                  <i className="ti ti-plus"></i> {t("label_create_a_table")}
                </button>
              </div>
            </div>
            <div className="dashboard-content-wraper">
              <div className="category-list-wrap">
                {loader ? (
                  <div className="w-100">
                    <Skeleton className="mb-2" height={50} count={5} />
                  </div>
                ) : List?.length ? (
                  <>
                    <div className="table-manage-wrap">
                      {List.map((item, index) => {
                        return (
                          <div className="table-card" key={index}>
                            <div className="table-card-body">
                              <div className="table-main-box">
                                <div className="table-name px-3">{TrimText(item?.table_number, 15)}</div>
                                <span>
                                  {t(getLabelByValue(item.shape))} {t("label_shape")}
                                </span>
                                <p>
                                  {item.capacity} {t("label_guest")}
                                </p>
                              </div>
                              <div className="table-action-info d-flex align-items-center justify-content-between">
                                {item.is_available ? (
                                  <span className="status-badge status-badge-success">{t("label_available")}</span>
                                ) : (
                                  <span className="status-badge status-badge-danger">{t("label_reserved")}</span>
                                )}

                                <div className="table-action-btn d-flex gap-2">
                                  <a
                                    className="btn btn-icon"
                                    onClick={() => {
                                      getTableDetail(item);
                                    }}
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#view-table"
                                  >
                                    <i className="ti ti-eye"></i>
                                  </a>
                                  <a
                                    className="btn btn-icon"
                                    onClick={() => {
                                      getTableDetail(item);
                                      setOpenWithHeaderEdit(true);
                                    }}
                                  >
                                    <i className="ti ti-edit"></i>
                                  </a>
                                  <a
                                    className="btn btn-icon"
                                    onClick={() => {
                                      deleteRecord(item);
                                    }}
                                  >
                                    <i className="ti ti-trash"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {/* <div className="table-status-info d-flex justify-content-between align-items-center">
                                        <span>{t("label_showing")} {offset + List.length} of {totalRecords} {t("label_entries")}</span>
                                        <nav aria-label="Page navigation example">
                                            {totalRecords && totalRecords > 0 ?
                                                <CustomPagination
                                                    datalength={totalRecords}
                                                    itemperpage={itemPerPage}
                                                    currentPage={page}
                                                    custompath="page"
                                                    setPage={setPage}
                                                    pageRoute={[{ name: "table-management", path: "/restaurant/table-management/table" }]} /> : ""
                                            }
                                        </nav>
                                    </div> */}
                  </>
                ) : (
                  <RecordNotFound heading="no_table_found" />
                )}
              </div>
            </div>
          </div>

          <div className="offcanvas offcanvas-end right-modal-w" id="view-table" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasRightLabel">
                {t("label_view_details")}
              </h5>
              <button type="button" className="btn btn-icon ms-auto" data-bs-dismiss="offcanvas" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>
            <div
              className="offcanvas-body"
              onScroll={(e) => {
                handleOffcanvasScroll(e, "view-table");
              }}
            >
              <div className="card">
                <div className="card-body">
                  <div className="table-main-box">
                    <div className="table-name h-auto px-3">{tableDetail.table_number}</div>
                    <p>
                      {t("number_of_guests_text")} {tableDetail.capacity}
                    </p>
                  </div>
                </div>
              </div>
              <div className="view-staff-info mt-3">
                <div className="row gap-4">
                  {/* <div className="col-md-12">
                                    <h4>Table Capacity</h4>
                                    
                                </div> */}
                  <div className="col-md-12">
                    <h4>{t("table_shape_text")}</h4>
                    <p>{t(getLabelByValue(tableDetail.shape))}</p>
                  </div>
                  {tableDetail.qr_url ? (
                    <div className="col-md-12" id="qr-code">
                      <h4> {t("label_qr_code")}</h4>
                      <figure
                        className="qr-code-img"
                        onClick={(e) => {
                          if (e.ctrlKey|| e.metaKey) {
                            window.open(generateQRUrl(tableDetail), "_blank");
                          }
                        }}
                      >
                        <QRCodeSVG value={generateQRUrl(tableDetail)} id="qr-image" />
                      </figure>
                    </div>
                  ) : null}
                </div>
              </div>
              {tableDetail.qr_url ? (
                <div className="row mt-3">
                  <div className="col">
                    <ReactToPrint trigger={() => <button className="btn btn-primary w-100"> {t("label_print_QR_code")}</button>} content={() => componentRef.current} />
                  </div>
                  <div className="col">
                    <button className="btn btn-primary w-100" onClick={(e) => handelDownload(tableDetail.table_number)}>
                      {t("label_download")}
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <Drawer open={openWithHeader}>
            <Drawer.Header closeButton={false}>
              <div className="d-flex justify-content-between w-100 align-items-center">
                <h5 className="mb-0">{t("label_create_table")}</h5>
                <button
                  className="btn-icon"
                  onClick={() => {
                    setOpenWithHeader(false);
                    addForm.resetForm();
                  }}
                >
                  <i className="ri-close-fill"></i>
                </button>
              </div>
            </Drawer.Header>
            <Drawer.Body
              onScroll={(e) => {
                handleOffcanvasScroll(e, "editNewEmail", "rsuite");
              }}
            >
              <form onSubmit={addForm.handleSubmit}>
                {/* Table Number */}
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_table_number")}
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("placeholder_enter_table_number")}
                    name="table_number"
                    value={addForm?.values?.table_number}
                    onChange={addForm.handleChange}
                    onBlur={addForm.handleBlur}
                  />
                  <CustomError name="table_number" form={addForm} className="text-danger" />
                </div>

                {/* Capacity */}
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_table_capacity")}
                    <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control no_spring_input"
                    placeholder={t("placeholder_enter_table_capacity")}
                    name="capacity"
                    value={addForm?.values?.capacity}
                    onKeyDown={(e) => {
                      blockSpecialChar(e);
                    }}
                    onChange={addForm.handleChange}
                    onBlur={addForm.handleBlur}
                  />
                  <CustomError name="capacity" form={addForm} className="text-danger" />
                </div>

                {/* Shape */}
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_table_shape")}
                    <span>*</span>
                  </label>
                  <select className="form-control cp" name="shape" value={addForm.values.shape} onChange={addForm.handleChange} onBlur={addForm.handleBlur}>
                    <option value="" label={t("placeholder_select_table_shape")} />
                    {TABLE_SHAPE_TYPES.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {t(item.label)}
                        </option>
                      );
                    })}
                  </select>
                  {addForm.touched.shape && addForm.errors.shape ? <div className="text-danger">{t(addForm.errors.shape)}</div> : null}
                </div>

                {/* Submit Button */}
                <div className="button-form">
                  <button type="submit" className="btn btn-primary w-100" formNoValidate>
                    {t("btn_submit")}
                  </button>
                </div>
              </form>
            </Drawer.Body>
          </Drawer>

          <Drawer open={openWithHeaderEdit}>
            <Drawer.Header closeButton={false}>
              <div className="d-flex justify-content-between w-100 align-items-center">
                <h5 className="mb-0">{t("edit_table_label")}</h5>
                <button
                  className="btn-icon"
                  onClick={() => {
                    setOpenWithHeaderEdit(false);
                    editTableForm.resetForm();
                  }}
                >
                  <i className="ri-close-fill"></i>
                </button>
              </div>
            </Drawer.Header>
            <Drawer.Body
              onScroll={(e) => {
                handleOffcanvasScroll(e, "editEmail", "rsuite");
              }}
            >
              <form onSubmit={editTableForm.handleSubmit}>
                {/* Table Number */}
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_table_number")}
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("placeholder_enter_table_number")}
                    name="table_number"
                    value={editTableForm?.values?.table_number}
                    onChange={editTableForm.handleChange}
                    onBlur={editTableForm.handleBlur}
                  />
                  <CustomError name="table_number" form={editTableForm} className="text-danger" />
                </div>

                {/* Capacity */}
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_table_capacity")}
                    <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control no_spring_input"
                    placeholder={t("placeholder_enter_table_capacity")}
                    name="capacity"
                    value={editTableForm?.values?.capacity}
                    onKeyDown={(e) => {
                      blockSpecialChar(e);
                    }}
                    onChange={editTableForm.handleChange}
                    onBlur={editTableForm.handleBlur}
                  />
                  <CustomError name="capacity" form={editTableForm} className="text-danger" />
                </div>

                {/* Shape */}
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_table_shape")}
                    <span>*</span>
                  </label>
                  <select className="form-control cp" name="shape" value={editTableForm.values.shape} onChange={editTableForm.handleChange} onBlur={editTableForm.handleBlur}>
                    <option value="" label={t("placeholder_select_table_shape")} />
                    {TABLE_SHAPE_TYPES.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {t(item.label)}
                        </option>
                      );
                    })}
                  </select>
                  {editTableForm.touched.shape && editTableForm.errors.shape ? <div className="text-danger">{t(editTableForm.errors.shape)}</div> : null}
                </div>

                {/* Submit Button */}
                <div className="button-form">
                  <button type="submit" className="btn btn-primary w-100" formNoValidate>
                    {t("btn_submit")}
                  </button>
                </div>
              </form>
            </Drawer.Body>
          </Drawer>
        </main>

        <div style={{ display: "none" }}>
          <div ref={componentRef} className="container d-flex justify-content-center align-items-center vh-100">
            <div className="text-center">
              <QRCodeSVG value={tableDetail.qr_url} height={400} width={400} className="mt-4" />
            </div>
          </div>
        </div>
      </>
    );
}

