import React, { useEffect, useState } from 'react';
import success from '../../../assets/restaurant/images/success.png';
import { detail } from '../services/order.service';
import { getSessionKey } from '../../../utils/commonfunction';

export default function OrderSuccess() {
  const queryParams = new URLSearchParams(window.location.search);
  const order_id = queryParams.get('order_id');
  const [orderDetail, setOrderDetail] = useState(null);
  const [token, setToken] = useState(getSessionKey("qr_user_token"));

  useEffect(() => {
    detail({ o_id: order_id, }, { token: token })
      .then((response) => {
        setOrderDetail(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [order_id])
  return(
    <>
        <div className='qr-body-wrap'>
          <div className='order-success-wrap'>
            <article>
              <figure>
                <img src={success}/>
              </figure>
              <figcaption>
                <span>Order no. #{orderDetail?.order_id}</span>
                <h4>Thank you for visiting us.</h4>
                <p>Go to Cashier and complete your payment</p>
              </figcaption>
            </article>
          </div>
        </div>    
    </>
  )
}
