import React, { useEffect, useLayoutEffect } from "react";
import { Outlet } from "react-router-dom";
import { setGlobalLoader } from "../../../../../redux/slices/globalLoader";
import { useDispatch, useSelector } from "react-redux";
import GlobalLoader from "../../../shared/globalLoader";
import { setUserType } from "../../../../../redux/slices/userType";
const RestaurantHomeLayout = () => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);

    useEffect(()=>{
        const body = document.querySelector('body');
        if(body){
            body.classList.add('restaurant_inner_body');
        }
    },[]);

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            // import("rsuite/dist/rsuite.min.css"),
            import("../../../../../assets/restaurant/css/style.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);

    return (
        <>
            {loaderState ? (
                <GlobalLoader />
            ) : (
                <div>
                    <Outlet />
                </div>
            )}
        </>
    );
};
export default RestaurantHomeLayout;
