import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";
const options = {role: ROLE.SUPER_ADMIN};

const path = "admin/restaurant";

// ========  Restaurant Enroll  =====

export const RestEnrollAdd = async (values) => {
  return await axiosInstance(options).post(`${path}/rest-enroll/add`, values);
};

export const RestEnrollList = async (values) => {
  return await axiosInstance(options).post(`${path}/rest-enroll/enrollment-Details`, values);
};

//http://172.16.11.237:5500/webservice/api/v1/admin/restaurant/rest-enroll/edit
export const RestEnrollEdit = async (values) => {
  return await axiosInstance(options).put(`${path}/rest-enroll/edit`, values);
};

export const RestTransactionList = async (values) => {
  return await axiosInstance(options).post(`${path}/rest-enroll/transaction-details`, values);
};

//http://172.16.11.237:5500/webservice/api/v1/admin/restaurant/rest-enroll/renewenrollment
export const RestRenewEnroll = async (values) => {
  return await axiosInstance(options).put(`${path}/rest-enroll/renew-enrollment`, values);
};

//http://172.16.11.237:5500/webservice/api/v1/admin/restaurant/rest-enroll/delete
export const ResEnrollDelete = async (values) => {
  return await axiosInstance(options).delete(`${path}/rest-enroll/delete?o_id=${values[0]}`);
};

export const Details = async (id) => {
  return await axiosInstance(options).get(`${path}/details?o_id=${id}`);
};

// -------------------------------

