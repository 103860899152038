import React, { useEffect, useState, useRef, useMemo } from "react";
import { categoryOptions } from "../../../services/category.services";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { menuItemList, deleteMenuItem, addMenuItem, editMenuItem, menuItemDetails, sortMenuItems, latest_menu_item_code } from "../../../services/menuItem.services";
import { useValidationSchemas } from "../../../validator/validation";
import { useTranslation } from "react-i18next";
import { ITEM_TAX_OPTIONS, ITEM_TYPES, LOADER_TIMEOUT_TIME, SWAL_SETTINGS } from "../../../../../utils/Constants";
import Swal from "sweetalert2";
import { booleanMask, getTaxFromValue, globalLoader, handleOffcanvasScroll, handleServerValidations, roundFigurePrice, useDebouncing } from "../../../../../utils/commonfunction";
import trash from "../../../../../assets/restaurant/images/trash.svg";
import { useFormik } from "formik";
import CustomError from "../../../../../utils/customError";
import { ReactSortable } from "react-sortablejs";
import { Options } from "../../../services/addons.service";
import { Drawer, SelectPicker, Whisper, Tooltip } from "rsuite";
import GridLoader from "../../../shared/gridLoader";
import RecordNotFound from "../../../shared/recordNotFound";
import { useSelector } from "react-redux";

export default function MenuItemListing() {
  const [categoryList, setCategoryList] = useState([]);
  const [categoryFilterOptions, setCategoryFilterOptions] = useState([]);
  const params = useParams();
  const [sortableCategories, setSortableCategories] = useState([]);
  const [search, setSearch] = useState({});
  const [refreshList, setRefreshList] = useState(false);
  const [List, setList] = useState([]);
  const sortingCloseBtn = useRef();
  const [loader, setLoader] = useState(true);
  const { t } = useTranslation();
  const { menuItemsValidationSchema } = useValidationSchemas();
  const navigate = useNavigate();
  const location = useLocation();
  const [ItemDetail, setItemDetail] = useState({});
  const [ItemOption, setItemOption] = useState([]);
  const [OptionsData, setOptionsData] = useState([]);
  const [openWithHeader, setOpenWithHeader] = useState(false);
  const [openWithHeaderEdit, setOpenWithHeaderEdit] = useState(false);
  const [globalSearch, setGlobalSearch] = useState("");
  const [langChanged, setLangChanged] = useState(false);
  const [menuItemCode, setMenuItemCode] = useState("");
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);

  const addItemForm = useFormik({
    initialValues: {
      item_name: "",
      item_name_ja: "",
      category_id: "",
      item_code: menuItemCode,
      short_description: "",
      short_description_ja: "",
      selected_addon: "",
      addons: [],
      price: 0,
      cost_price: 0,
      consumption_tax_rate: 0,
      item_tax_type: ITEM_TAX_OPTIONS.INCLUSIVE,
      spice_level_needed: false,
      image: "",
      type: "",
    },
    validationSchema: menuItemsValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldValue }) => {
      const formData = new FormData();
      formData.append("item_name", values.item_name);
      formData.append("item_name_ja", values.item_name_ja);
      formData.append("category_id", values.category_id);
      formData.append("item_code", values.item_code);
      formData.append("short_description", values.short_description);
      formData.append("short_description_ja", values.short_description_ja);
      formData.append("price", findTax()?.price_without_tax);
      formData.append("total_price", findTax()?.price_with_tax);
      formData.append("tax_price", findTax()?.tax_price);
      formData.append("cost_price", values.cost_price);
      formData.append("consumption_tax_rate", findTax()?.tax_rate);
      formData.append("type", values.type);
      formData.append("spice_level_needed", values.spice_level_needed);
      formData.append("image", values.image);
      formData.append("item_tax_type", values.item_tax_type);
      formData.append("addons", JSON.stringify(values.addons));

      addMenuItem(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setFieldValue("image", "");
            resetForm();
            document.getElementById("image").value = "";
            setOpenWithHeader(false);
            setRefreshList(!refreshList);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      // console.log(values);
    },
  });

  const editItemForm = useFormik({
    initialValues: {
      o_id: "",
      item_name: "",
      item_name_ja: "",
      category_id: "",
      item_code: "",
      short_description: "",
      short_description_ja: "",
      selected_addon: "",
      addons: [],
      price: 0,
      cost_price: 0,
      consumption_tax_rate: 0,
      item_tax_type: "",
      spice_level_needed: "",
      image: "",
      type: "",
    },
    validationSchema: menuItemsValidationSchema,
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldValue }) => {
      const formData = new FormData();
      formData.append("o_id", values.o_id);
      formData.append("item_name", values.item_name);
      formData.append("item_name_ja", values.item_name_ja);
      formData.append("category_id", values.category_id);
      formData.append("item_code", values.item_code);
      formData.append("short_description", values.short_description);
      formData.append("short_description_ja", values.short_description_ja);
      formData.append("price", findTax(editItemForm, values.category_id)?.price_without_tax);
      formData.append("total_price", findTax(editItemForm, values.category_id)?.price_with_tax);
      formData.append("tax_price", findTax(editItemForm, values.category_id)?.tax_price);
      formData.append("cost_price", values.cost_price);
      formData.append("consumption_tax_rate", findTax(editItemForm, values.category_id)?.tax_rate);
      formData.append("type", values.type);
      formData.append("spice_level_needed", values.spice_level_needed);
      formData.append("image", values.image);
      formData.append("item_tax_type", values.item_tax_type);
      formData.append("addons", JSON.stringify(values.addons));

      editMenuItem(formData)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setFieldValue("image", "");
            resetForm();
            document.getElementById("image").value = "";
            setOpenWithHeaderEdit(false);
            setRefreshList(!refreshList);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
      // console.log(values);
    },
  });

  const findTax = (form = addItemForm, categoryId = null) => {
    const category = categoryList.find((obj) => obj?._id === (categoryId ? categoryId : form.values.category_id));
    let result = {
      tax_price: 0,
      price_without_tax: 0,
      price_with_tax: 0,
      tax_rate: 0,
    };
    if (category) {
      result.tax_rate = category.tax_type === "dine" ? 10 : 8;
      const price = Number(form.values.price);
      const price_tax_type = form.values.item_tax_type;
      if (price && price_tax_type) {
        if (price_tax_type === ITEM_TAX_OPTIONS.INCLUSIVE) {
          const tax_price = getTaxFromValue(price, result.tax_rate);
          const price_without_tax = roundFigurePrice((price - tax_price), restSettingDetails?.tax_when_register_product);
          const price_with_tax = price;
          result.tax_price = roundFigurePrice(tax_price, restSettingDetails?.tax_when_register_product);
          result.price_without_tax = price_without_tax;
          result.price_with_tax = price_with_tax;
        } else {
          const tax_price = (price * result.tax_rate) / 100;
          const price_without_tax = price;
          const price_with_tax = roundFigurePrice((price + tax_price), restSettingDetails?.tax_when_register_product);
          result.tax_price = roundFigurePrice(tax_price, restSettingDetails?.tax_when_register_product);
          result.price_without_tax = price_without_tax;
          result.price_with_tax = price_with_tax;
        }
      }
    }

    return result;
  };

  useEffect(() => {
    categoryOptions(true)
      .then((response) => {
        setCategoryList(response?.data ? response.data.filter((record) => !record?.is_unCategorized) : []);
        setCategoryFilterOptions(response?.data ? response.data : []);
      })
      .catch((err) => {
        console.log(err);
      });

    Options()
      .then((response) => {
        const formattedOptions = response.data.map((item, i) => ({
          label: item.addon_name,
          value: item._id,
        }));
        setItemOption(response.data);
        setOptionsData(formattedOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [langChanged]);

  // useEffect(()=>{
  //   setOptionsData((options)=>{
  //     return options.map((op, i)=>{
  //       return i===0? op: {disabled:true,...op}
  //     })
  //   });
  // },[addItemForm.values]);

  // const prepareSearch = (key, value) => {
  //   let sr = { ...search };
  //   if (String(value).length > 0) {
  //     sr[key] = value;
  //   } else {
  //     delete sr[key];
  //   }
  //   setSearch(sr);
  // };
  const prepareGlobalSearch = (value) => {
    setGlobalSearch(value);
  };
  const searchDebounce = useDebouncing(prepareGlobalSearch);

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    const o_id = params.id ? params.id : "";

    if (Object.keys(search).length) {
      formData.append("search", JSON.stringify(search));
    }
    formData.append("global_search", globalSearch);
    if (params.id) {
      formData.append("category_id", o_id);
    }
    menuItemList(formData)
      .then((data) => {
        setList(data && data.data && data.data.list ? data.data.list : []);
        setSortableCategories(data && data.data && data.data.list ? data.data.list : []);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
        console.log("error ====> ", error);
      });
  }, [search, refreshList, location, globalSearch, langChanged]);

  const deleteItem = (item) => {
    Swal.fire({
      title: t("msg_are_you_sure"),
      text: t("btn_delete_text"),
      imageUrl: trash,
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      cancelButtonColor: "#6c757d",
      confirmButtonText: t("btn_delete"),
      cancelButtonText: t("btn_cancel"),
      reverseButtons: true,
      customClass: {
        popup: "swal-restaurant",
        confirmButton: "btn btn-danger col",
        cancelButton: "btn btn-secondary col",
      },
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        globalLoader(true);
        deleteMenuItem({ o_id: item._id })
          .then((response) => {
            globalLoader(false);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setRefreshList(!refreshList);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            console.log("deleteError");
          });
      } else {
        setSortableCategories(List);
      }
    });
  };

  const get_menu_item_code = () => {
    latest_menu_item_code()
      .then((response) => {
        setMenuItemCode(response.data.item_code);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getItemDetail = (item) => {
    menuItemDetails({ o_id: item._id })
      .then((response) => {
        if(response.success){
          let activeAddons = response?.data?.addons.map((addon)=>{
            let assignedItems = getAddon(addon.option_id)?.assigned_items || [];
            let itemExists = assignedItems.filter(addonItem => addonItem?._id === addon.item_id)[0];
            return {
              item_id: itemExists ? addon.item_id: "",
              option_id: addon.option_id
            }
          });
          editItemForm.setValues({
            o_id: response?.data?._id || "",
            item_name: response?.data?.item_name || "",
            item_name_ja: response?.data?.item_name_ja || "",
            category_id: response?.data?.category_id || "",
            item_code: response?.data?.item_code || "",
            short_description: response?.data?.short_description || "",
            short_description_ja: response?.data?.short_description_ja || "",
            addons: activeAddons || [],
            price: response?.data?.total_price && response?.data?.item_tax_type === ITEM_TAX_OPTIONS.INCLUSIVE ? response?.data?.total_price : response?.data?.price,
            cost_price: response?.data?.cost_price || 0,
            consumption_tax_rate: response?.data?.consumption_tax_rate || 0,
            item_tax_type: response?.data?.item_tax_type || ITEM_TAX_OPTIONS.INCLUSIVE,
            spice_level_needed: response?.data?.spice_level_needed || false,
            image: response?.data?.image || "",
            type: response?.data?.type || "",
          });
          setItemDetail(response?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function handleSortCategories(closeModal = true) {
    sortMenuItems({ sorted_array: sortableCategories })
      .then((response) => {
        if (response.success) {
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
          if (closeModal) {
            sortingCloseBtn?.current?.click();
          }
          setRefreshList(!refreshList);
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        console.error("Error updating profile", error);
      });
  }
  function isSortingChanged(){
    let changed = false;
    let i = 0;
    for (i; i < List.length; i++) {
        if (List[i]?.order !== sortableCategories[i]?.order) {
            changed = true;
            break;
        } else {
            changed = false;
        }
    };
    return changed;
}
  const handleCloseSorting = () => {
    if (isSortingChanged()) {
      Swal.fire({
        title: t("question_to_save_data"),
        text: t("confirm_heading_found_unsaved_data_description"),
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#6c757d",
        cancelButtonColor: "#dc3545",
        confirmButtonText: t("btn_text_save_changes"),
        cancelButtonText: t("btn_text_discard"),
        customClass: {
          popup: "swal-restaurant",
          confirmButton: "btn btn-secondary col",
          cancelButton: "btn btn-danger col",
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          handleSortCategories(false);
        } else {
          setSortableCategories(List);
        }
      });
    }
  };

  const addAddon = (form) => {
    let addons = [...form?.values?.addons];
    if (form?.values?.selected_addon?.length > 0) {
      addons.push({ option_id: form?.values?.selected_addon, item_id: "" });
      form?.setFieldValue("addons", addons);
      form?.setFieldValue("selected_addon", "");
    }
  };

  const removeAddon = (item, form) => {
    const newAddon = form?.values?.addons.filter((items) => {
      return items !== item;
    });
    form?.setFieldValue("addons", newAddon);
  };

  const getAddon = (id) => {
    return ItemOption.find((item) => item._id === id) || {};
  };

  useMemo(() => {
    document.addEventListener("language_change", function () {
      setLangChanged(!langChanged);
    });
  });

  const disabledOptions = (values) => {
    // if (values?.addons?.length) {
    //   return values?.addons.map((op) => op.option_id);
    // }
    // return [];
    const disabledIds = [];
    ItemOption.forEach((addon)=>{
      let addonSelectedOptions = values?.addons.filter((op) => op.option_id === addon._id);
      if(addonSelectedOptions?.length === addon?.assigned_items?.length){
        disabledIds.push(addon?._id);
      }
    });
    return disabledIds;
  };

  const isOptionSelected = (item, option, addons=[]) => {
    console.log("***",item, option);
    if(addons.length){
      let result = addons.filter(record => record?.option_id === item?.option_id && record?.item_id === option?._id);
      return result.length > 0;
    }
    return false
  };

  return (
    <main id="main-content">
      {/* main container  */}
      <div className="inner-container">
        <div className="page-header d-flex align-items-center">
          <h4 className="heading-title-text">
            {t("menu_item_label")} ({List.length})
          </h4>
        </div>
        <div className="card-outer-header d-flex justify-content-between align-items-center mb-4">
          <div className="search-box">
            <input
              type="text"
              className="form-control search-input"
              placeholder={t("input_placeholder_search")}
              onInput={(e) => {
                searchDebounce(e.target.value);
              }}
            />
          </div>
          <div className="card-head-group d-flex align-items-center gap-2">
            <div className="tabls-dowpdown">
              <select
                className="form-control cp"
                value={params.id || ""}
                onChange={(e) => {
                  navigate(`/restaurant/menu/menu-item/list/${e.target.value}`);
                }}
              >
                <option disabled="" value="">
                  {t("input_select_text")}
                </option>
                {categoryFilterOptions.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#sort-modal">
              {t("sort_menu_item_label")}
            </button>
            <button
              onClick={() => {
                setOpenWithHeader(true);
                get_menu_item_code();
              }}
              className="btn btn-primary"
            >
              <i className="ti ti-plus"></i> {t("add_item_label")}
            </button>
          </div>
        </div>
        {loader ? (
          <GridLoader />
        ) : (
          <div className="dashboard-content-wraper">
            {List.length > 0 ? (
              <div className="category-list-wrap">
                <div className="category-list-grid menu-list-grid">
                  {List.map((item, index) => (
                    <div className="category-box category-menu-box" key={index}>
                      <div className="category-name menu-img">
                        <img src={item.image} alt="" />
                        <div className="category-name-action d-flex gap-2">
                          <button
                            className="btn-icon"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#view-new-item"
                            onClick={() => {
                              getItemDetail(item);
                            }}
                          >
                            <i className="ti ti-eye"></i>
                          </button>
                          <button
                            className="btn-icon"
                            onClick={() => {
                              getItemDetail(item);
                              setOpenWithHeaderEdit(true);
                            }}
                          >
                            <i className="ti ti-edit"></i>
                          </button>
                          <button
                            className="btn-icon"
                            onClick={() => {
                              deleteItem(item);
                            }}
                            type="button"
                          >
                            <i className="ti ti-trash"></i>
                          </button>
                        </div>
                      </div>
                      <div className="menu-item-info">
                        <h4>{item.item_name}</h4>
                        <span>¥ {item.total_price?.toFixed(2)}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <RecordNotFound heading="no_assigned_item_text" text="no_category_found_text_para" />
            )}
          </div>
        )}
      </div>

      {/* sorting modal */}

      <div className="modal fade sort-modal" data-bs-backdrop="static" id="sort-modal" tabIndex="-1" aria-labelledby="sort-modalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalToggleLabel">
                {t("menu_item_label")}{" "}
              </h1>

              <button type="button" className="btn-close btn-icon" onClick={handleCloseSorting} data-bs-dismiss="modal" aria-label="Close">
                <i className="ti ti-x"></i>
              </button>
            </div>

            <div className="modal-body">
              {sortableCategories && sortableCategories.length ? (
                <ReactSortable
                  list={sortableCategories}
                  setList={(newList) => setSortableCategories(newList)}
                  ghostClass="dropArea"
                  handle=".dragHandle"
                  filter=".ignoreDrag"
                  preventOnFilter={true}
                  className="grid-drag-container"
                >
                  <>
                    {sortableCategories.map((record, i) => (
                      <div className="category-box category-menu-box dragHandle" key={i} style={{ background: record?.color }}>
                        <div className="category-name menu-img">
                          <img src={record.image} alt="" />
                        </div>
                        <div className="menu-item-info">
                          <h4>{record.item_name}</h4>
                          <span>¥ {record.total_price?.toFixed(2)}</span>
                        </div>
                      </div>
                    ))}
                  </>
                </ReactSortable>
              ) : (
                <RecordNotFound heading="no_assigned_item_text" text="no_category_found_text_para" />
              )}
            </div>
            <div className="modal-footer justify-content-center">
              <button type="button" className="btn btn-danger me-2 d-none" data-bs-dismiss="modal" ref={sortingCloseBtn} aria-label="Close">
                Close
              </button>

              <button type="button" className="btn btn-primary" disabled={!isSortingChanged()} onClick={handleSortCategories}>
                {t("btn_save_changes")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* add item modal  */}
      <Drawer open={openWithHeader}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t("add_item_label")}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setOpenWithHeader(false);
                addItemForm.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editNewEmail", "rsuite");
          }}
        >
          <form onSubmit={addItemForm.handleSubmit}>
            <div className="mb-3">
              <label className="form-label">
                {t("choose_category_label")}
                <span>*</span>{" "}
              </label>
              <select
                className="form-control cp"
                name="category_id"
                value={addItemForm?.values?.category_id}
                onChange={(e) => {
                  addItemForm.handleChange(e);
                  addItemForm.setFieldValue("consumption_tax_rate", findTax(addItemForm, e.target.value)?.tax_rate);
                }}
                onBlur={addItemForm.handleBlur}
              >
                <option value={""}>{t("input_select_text")}</option>
                {categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              <CustomError name="category_id" form={addItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("menu_item_code_label")}
                <span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("menu_item_code_label") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("enter_code_placeholder")}
                name="item_code"
                value={addItemForm?.values?.item_code}
                onChange={addItemForm.handleChange}
                onBlur={addItemForm.handleBlur}
                disabled
              />
              <CustomError name="item_code" form={addItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("item_name_label")}(English)<span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("item_name_label") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_addon_option_name")}
                name="item_name"
                value={addItemForm?.values?.item_name}
                onChange={addItemForm.handleChange}
                onBlur={addItemForm.handleBlur}
              />
              <CustomError name="item_name" form={addItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("item_name_label")}(Japanese)<span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("item_name_label") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_addon_option_name")}
                name="item_name_ja"
                value={addItemForm?.values?.item_name_ja}
                onChange={addItemForm.handleChange}
                onBlur={addItemForm.handleBlur}
              />
              <CustomError name="item_name_ja" form={addItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("add_photo_label")}
                <span>*</span>
              </label>
              <input
                className="form-control imgInput"
                id="image"
                name="image"
                accept="image/*"
                type="file"
                // value={addItemForm?.values?.image}
                onChange={(event) => {
                  addItemForm.setFieldValue("image", event.target.files[0] || "");
                }}
              />

              <CustomError name="image" form={addItemForm} className="text-danger" />
              <span className="mt-2 d-block">{t("file_supported_label")}</span>
              {addItemForm.values.image && (
                <ul className="question-image-preview questions-ul">
                  <li className="pr_img_box">
                    <img src={URL.createObjectURL(addItemForm.values.image)} style={{ height: "100px" }} alt={"Profile_Img"} />
                    <button
                      type="button"
                      className="btn-icon"
                      style={{ position: "absolute", right: "5px", top: "5px", height: "30px", width: "30px", minWidth: "30px" }}
                      onClick={() => {
                        addItemForm.setFieldValue("image", null);
                        document.getElementById("image").value = "";
                      }}
                    >
                      <i className="ti ti-x"></i>
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("type_item_label")}
                <span>*</span>
              </label>
              <div className="d-flex gap-5">
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    name="type"
                    type="radio"
                    id={ITEM_TYPES.ITEM}
                    value={ITEM_TYPES.ITEM}
                    onChange={addItemForm.handleChange}
                    checked={addItemForm?.values?.type === ITEM_TYPES.ITEM}
                  />
                  <label className="form-check-label cp" htmlFor={ITEM_TYPES.ITEM}>
                    {t("label_item")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    name="type"
                    type="radio"
                    id={ITEM_TYPES.SET_OF_ITEMS}
                    value={ITEM_TYPES.SET_OF_ITEMS}
                    onChange={addItemForm.handleChange}
                    checked={addItemForm?.values?.type === ITEM_TYPES.SET_OF_ITEMS}
                  />
                  <label className="form-check-label cp" htmlFor={ITEM_TYPES.SET_OF_ITEMS}>
                    {t("set_items_label")}
                  </label>
                </div>
              </div>
              <CustomError name="type" form={addItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={addItemForm?.values?.spice_level_needed}
                  onChange={(e) => {
                    addItemForm?.setFieldValue("spice_level_needed", e.target.checked);
                  }}
                  id="spice_level_needed"
                />
                <label className="form-check-label" htmlFor="spice_level_needed">
                  {t("spice_level_label")}
                </label>
              </div>
            </div>

            {/* Dynamic addons >>>> */}
            {addItemForm?.values?.type === ITEM_TYPES.SET_OF_ITEMS ? (
              <>
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_options")}
                    <span>*</span>{" "}
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("label_options") + " " + t("tooltip_label")}</Tooltip>}>
                      <div
                        className="label-tooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-title="This top tooltip is themed via CSS variables."
                      >
                        <i className="ti ti-help-circle"></i>
                      </div>
                    </Whisper>
                  </label>
                  <div className="d-flex gap-3">
                    <SelectPicker
                      classPrefix="restaurant"
                      data={OptionsData}
                      height="40px"
                      style={{ width: 300 }}
                      name="selected_addon"
                      value={addItemForm?.values?.selected_addon}
                      placeholder={t("input_select_text")}
                      onChange={(e) => {
                        addItemForm.setFieldValue("selected_addon", e);
                      }}
                      cleanable={false}
                      disabledItemValues={disabledOptions(addItemForm?.values)}
                    />
                    <button
                      type="button"
                      className="btn btn-black"
                      onClick={() => {
                        addAddon(addItemForm);
                      }}
                    >
                      {t("link_add")}
                    </button>
                  </div>
                  {typeof addItemForm?.errors?.addons === "string" && addItemForm?.errors?.addons ? <span className="d-block text-danger">{t(addItemForm?.errors?.addons)}</span> : null}
                </div>
                <div className="mb-3">
                  {addItemForm?.values?.addons.map((item, index) => {
                    return (
                      <div className="d-flex gap-3 mb-3" key={index}>
                        <input className="form-control col" type="text" disabled value={getAddon(item?.option_id)?.addon_name} />
                        <div className="position-relative col">
                          <select
                            className="form-control cp"
                            name={`addons[${index}].item_id`}
                            value={addItemForm?.values?.addons[index].item_id}
                            onChange={addItemForm.handleChange}
                            onBlur={addItemForm.handleBlur}
                          >
                            <option value={""}>{t("label_select_item")}</option>
                            {getAddon(item?.option_id)?.assigned_items.map((option, optionIndex) => {
                              return (
                                <option key={optionIndex} value={option?._id}
                                disabled={isOptionSelected(item, option, addItemForm?.values?.addons)}
                                >
                                  {option?.item_name}
                                </option>
                              );
                            })}
                          </select>
                          {addItemForm?.errors?.addons?.length && addItemForm?.errors?.addons[index]?.item_id ? (
                            <span className="d-block text-danger">{t(addItemForm?.errors?.addons[index]?.item_id)}</span>
                          ) : null}
                        </div>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => {
                            removeAddon(item, addItemForm);
                          }}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            {/* Dynamic addons <<<< */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_price")}
                <span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("label_price") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <div className="row">
                <div className="col">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      ¥
                    </span>
                    <input
                      type="number"
                      className="form-control no_spring_input"
                      placeholder={t("placeholder_price")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="price"
                      value={addItemForm?.values?.price}
                      onChange={addItemForm.handleChange}
                      onBlur={addItemForm.handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <select className="form-control cp" value={addItemForm?.values?.item_tax_type} onChange={addItemForm.handleChange} name="item_tax_type">
                    {Object.keys(ITEM_TAX_OPTIONS).map((key, i) => (
                      <option key={i} value={ITEM_TAX_OPTIONS[key]}>
                        {t(`item_tax_option_${key.toLocaleLowerCase()}`)}
                      </option>
                    ))}
                  </select>
                </div>
                <CustomError name="price" form={addItemForm} className="text-danger" />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_cost_price")}
                <span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("label_cost_price") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  ¥
                </span>
                <input
                  type="number"
                  className="form-control no_spring_input"
                  placeholder={t("placeholder_cost_price")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="cost_price"
                  value={addItemForm?.values?.cost_price}
                  onChange={addItemForm.handleChange}
                  onBlur={addItemForm.handleBlur}
                />
              </div>
              <CustomError name="cost_price" form={addItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("consumption_tax_rate")}
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("consumption_tax_rate") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter cost price"
                value={`${addItemForm.values.consumption_tax_rate}% tax`}
                disabled
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              {/* <CustomError name="consumption_tax_rate" form={addItemForm} className="text-danger" /> */}
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <span>{t("price_with_tax")}</span>
                <span>¥ {roundFigurePrice(findTax()?.price_with_tax, restSettingDetails?.tax_when_register_product)}</span>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span>{t("price_without_tax")}</span>
                <span>¥ {roundFigurePrice(findTax()?.price_without_tax , restSettingDetails?.tax_when_register_product)}</span>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_short_description")}
                <span>*</span>
              </label>
              <textarea
                className="form-control"
                style={{ height: "120px" }}
                name="short_description"
                value={addItemForm?.values?.short_description}
                onChange={addItemForm.handleChange}
                onBlur={addItemForm.handleBlur}
              ></textarea>
              <CustomError name="short_description" form={addItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_short_description_ja")}
                <span>*</span>
              </label>
              <textarea
                className="form-control"
                style={{ height: "120px" }}
                name="short_description_ja"
                value={addItemForm?.values?.short_description_ja}
                onChange={addItemForm.handleChange}
                onBlur={addItemForm.handleBlur}
              ></textarea>
              <CustomError name="short_description_ja" form={addItemForm} className="text-danger" />
            </div>
            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_submit")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      {/* EDIT ITEM MODAL */}

      <Drawer open={openWithHeaderEdit}>
        <Drawer.Header closeButton={false}>
          <div className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="mb-0">{t("edit_item_label")}</h5>
            <button
              className="btn-icon"
              onClick={() => {
                setOpenWithHeaderEdit(false);
                editItemForm.resetForm();
              }}
            >
              <i className="ri-close-fill"></i>
            </button>
          </div>
        </Drawer.Header>
        <Drawer.Body
          onScroll={(e) => {
            handleOffcanvasScroll(e, "editEmail", "rsuite");
          }}
        >
          <form onSubmit={editItemForm.handleSubmit}>
            <div className="mb-3">
              <label className="form-label">
                {t("choose_category_label")}
                <span>*</span>{" "}
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("choose_category_label") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <select
                className="form-control cp"
                name="category_id"
                value={editItemForm?.values?.category_id}
                onChange={(e) => {
                  editItemForm.handleChange(e);
                  editItemForm.setFieldValue("consumption_tax_rate", findTax(editItemForm, e.target.value)?.tax_rate);
                }}
                onBlur={editItemForm.handleBlur}
              >
                <option value={""}>{t("input_select_text")}</option>
                {categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>
              <CustomError name="category_id" form={editItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("menu_item_code_label")}
                <span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("menu_item_code_label") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_code")}
                name="item_code"
                value={editItemForm?.values?.item_code}
                onChange={editItemForm.handleChange}
                onBlur={editItemForm.handleBlur}
                disabled
              />
              <CustomError name="item_code" form={editItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("item_name_label")}(English)<span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("item_name_label") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_addon_option_name")}
                name="item_name"
                value={editItemForm?.values?.item_name}
                onChange={editItemForm.handleChange}
                onBlur={editItemForm.handleBlur}
              />
              <CustomError name="item_name" form={editItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("item_name_label")}(Japanese)<span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("item_name_label") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_enter_addon_option_name")}
                name="item_name_ja"
                value={editItemForm?.values?.item_name_ja}
                onChange={editItemForm.handleChange}
                onBlur={editItemForm.handleBlur}
              />
              <CustomError name="item_name_ja" form={editItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("add_photo_label")}
                <span>*</span>
              </label>
              <input
                className="form-control imgInput"
                id="image"
                name="image"
                accept="image/*"
                type="file"
                // value={addItemForm?.values?.image}
                onChange={(event) => {
                  editItemForm.setFieldValue("image", event.target.files[0] || "");
                }}
              />

              <CustomError name="image" form={editItemForm} className="text-danger" />
              <span className="mt-2 d-block">{t("file_supported_label")}</span>
              {editItemForm.values.image && (
                <ul className="question-image-preview questions-ul">
                  <li className="pr_img_box">
                    <img src={editItemForm.values.image instanceof File ? URL.createObjectURL(editItemForm.values.image) : editItemForm.values.image} style={{ height: "100px" }} alt={"Profile_Img"} />
                    <button
                      type="button"
                      className="btn-icon"
                      style={{ position: "absolute", right: "5px", top: "5px", height: "30px", width: "30px", minWidth: "30px" }}
                      onClick={() => {
                        editItemForm.setFieldValue("image", null);
                        document.getElementById("image").value = "";
                      }}
                    >
                      <i className="ti ti-x"></i>
                    </button>
                  </li>
                </ul>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("type_item_label")}
                <span>*</span>
              </label>
              <div className="d-flex gap-5">
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    name="type"
                    type="radio"
                    id={ITEM_TYPES.ITEM}
                    value={ITEM_TYPES.ITEM}
                    onChange={editItemForm.handleChange}
                    checked={editItemForm?.values?.type === ITEM_TYPES.ITEM}
                  />
                  <label className="form-check-label cp" htmlFor={ITEM_TYPES.ITEM}>
                    {t("label_item")}
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input cp"
                    name="type"
                    type="radio"
                    id={ITEM_TYPES.SET_OF_ITEMS}
                    value={ITEM_TYPES.SET_OF_ITEMS}
                    onChange={editItemForm.handleChange}
                    checked={editItemForm?.values?.type === ITEM_TYPES.SET_OF_ITEMS}
                  />
                  <label className="form-check-label cp" htmlFor={ITEM_TYPES.SET_OF_ITEMS}>
                    {t("set_items_label")}
                  </label>
                </div>
              </div>
              <CustomError name="type" form={editItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={editItemForm?.values?.spice_level_needed}
                  onChange={(e) => {
                    editItemForm?.setFieldValue("spice_level_needed", e.target.checked);
                  }}
                  id="spice_level_needed"
                />
                <label className="form-check-label" htmlFor="spice_level_needed">
                  {t("spice_level_label")}
                </label>
              </div>
            </div>
            {/* Dynamic addons >>>> */}
            {editItemForm?.values?.type === ITEM_TYPES.SET_OF_ITEMS ? (
              <>
                <div className="mb-3">
                  <label className="form-label">
                    {t("label_options")}
                    <span>*</span>{" "}
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("label_options") + " " + t("tooltip_label")}</Tooltip>}>
                      <div
                        className="label-tooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-custom-class="custom-tooltip"
                        data-bs-title="This top tooltip is themed via CSS variables."
                      >
                        <i className="ti ti-help-circle"></i>
                      </div>
                    </Whisper>
                  </label>
                  <div className="d-flex gap-3">
                    <SelectPicker
                      classPrefix="restaurant"
                      data={OptionsData}
                      style={{ width: 300 }}
                      name="selected_addon"
                      placeholder={t("input_select_text")}
                      value={editItemForm?.values?.selected_addon}
                      onChange={(e) => {
                        editItemForm.setFieldValue("selected_addon", e);
                      }}
                      cleanable={false}
                      disabledItemValues={disabledOptions(editItemForm?.values)}
                    />
                    <button
                      type="button"
                      className="btn btn-black"
                      onClick={() => {
                        addAddon(editItemForm);
                      }}
                    >
                      {t("link_add")}
                    </button>
                  </div>
                  {typeof editItemForm?.errors?.addons === "string" && editItemForm?.errors?.addons ? <span className="d-block text-danger">{t(editItemForm?.errors?.addons)}</span> : null}
                </div>
                <div className="mb-3">
                  {editItemForm?.values?.addons.map((item, index) => {
                    return (
                      <div className="d-flex gap-3 mb-3" key={index}>
                        <input className="form-control col" type="text" disabled value={getAddon(item?.option_id)?.addon_name} />
                        <div className="position-relative col">
                          <select className="form-control cp" name={`addons[${index}].item_id`} value={editItemForm?.values?.addons[index].item_id} onChange={editItemForm.handleChange}>
                            <option value={""}>{t("label_select_item")}</option>
                            {getAddon(item?.option_id)?.assigned_items.map((option, optionIndex) => {
                              return (
                                <option key={optionIndex} value={option?._id}
                                disabled={isOptionSelected(item, option, editItemForm?.values?.addons)}>
                                  {option?.item_name}
                                </option>
                              );
                            })}
                          </select>
                          {editItemForm?.errors?.addons?.length && editItemForm?.errors?.addons[index]?.item_id ? (
                            <span className="d-block text-danger">{t(editItemForm?.errors?.addons[index]?.item_id)}</span>
                          ) : null}
                        </div>
                        <button
                          type="button"
                          className="btn-icon"
                          onClick={() => {
                            removeAddon(item, editItemForm);
                          }}
                        >
                          <i className="ti ti-x"></i>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            {/* Dynamic addons <<<< */}
            <div className="mb-3">
              <label className="form-label">
                {t("label_price")}
                <span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("label_price") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <div className="row">
                <div className="col">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      ¥
                    </span>
                    <input
                      type="number"
                      className="form-control no_spring_input"
                      placeholder={t("placeholder_price")}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="price"
                      value={editItemForm?.values?.price}
                      onChange={editItemForm.handleChange}
                      onBlur={editItemForm.handleBlur}
                    />
                  </div>
                </div>
                <div className="col">
                  <select className="form-control cp" value={editItemForm?.values?.item_tax_type} onChange={editItemForm.handleChange} name="item_tax_type">
                    {Object.keys(ITEM_TAX_OPTIONS).map((key, i) => (
                      <option key={i} value={ITEM_TAX_OPTIONS[key]}>
                        {t(`item_tax_option_${key.toLocaleLowerCase()}`)}
                      </option>
                    ))}
                  </select>
                </div>
                <CustomError name="price" form={editItemForm} className="text-danger" />
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_cost_price")}
                <span>*</span>
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("label_cost_price") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                  ¥
                </span>
                <input
                  type="number"
                  className="form-control no_spring_input"
                  placeholder={t("placeholder_cost_price")}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="cost_price"
                  value={editItemForm?.values?.cost_price}
                  onChange={editItemForm.handleChange}
                  onBlur={editItemForm.handleBlur}
                />
              </div>
              <CustomError name="cost_price" form={editItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("consumption_tax_rate")}
                <Whisper placement="top" trigger="hover" speaker={<Tooltip> {t("consumption_tax_rate") + " " + t("tooltip_label")}</Tooltip>}>
                  <div className="label-tooltip" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-class="custom-tooltip" data-bs-title="This top tooltip is themed via CSS variables.">
                    <i className="ti ti-help-circle"></i>
                  </div>
                </Whisper>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder={t("placeholder_cost_price")}
                value={`${editItemForm.values.consumption_tax_rate}% tax`}
                disabled
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              {/* <CustomError name="consumption_tax_rate" form={addItemForm} className="text-danger" /> */}
            </div>
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <span>{t("price_with_tax")}</span>
                {editItemForm?.values?.category_id && editItemForm?.values?.price ? <span>¥ {roundFigurePrice(findTax(editItemForm, editItemForm?.values?.category_id)?.price_with_tax , restSettingDetails?.tax_when_register_product)}</span> : null}
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <span>{t("price_without_tax")}</span>
                <span>¥ {roundFigurePrice(findTax(editItemForm, editItemForm?.values?.category_id)?.price_without_tax , restSettingDetails?.tax_when_register_product)}</span>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_short_description")}
                <span>*</span>
              </label>
              <textarea
                className="form-control"
                style={{ height: "120px" }}
                name="short_description"
                value={editItemForm?.values?.short_description}
                onChange={editItemForm.handleChange}
                onBlur={editItemForm.handleBlur}
              ></textarea>
              <CustomError name="short_description" form={editItemForm} className="text-danger" />
            </div>
            <div className="mb-3">
              <label className="form-label">
                {t("label_short_description_ja")}
                <span>*</span>
              </label>
              <textarea
                className="form-control"
                style={{ height: "120px" }}
                name="short_description_ja"
                value={editItemForm?.values?.short_description_ja}
                onChange={editItemForm.handleChange}
                onBlur={editItemForm.handleBlur}
              ></textarea>
              <CustomError name="short_description_ja" form={editItemForm} className="text-danger" />
            </div>
            <div className="button-form">
              <button type="submit" className="btn btn-primary w-100">
                {t("btn_update")}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>

      {/* view modal for menu item  */}
      <div className="offcanvas offcanvas-end right-modal-w" id="view-new-item" data-bs-backdrop="static" tabIndex="-1" aria-labelledby="staticBackdropLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasRightLabel">
            {t("item_detail_label")}
          </h5>
          <button type="button" className="btn btn-icon ms-auto" data-bs-dismiss="offcanvas" aria-label="Close">
            <i className="ti ti-x"></i>
          </button>
        </div>
        <div
          className="offcanvas-body"
          onScroll={(e) => {
            handleOffcanvasScroll(e, "view-new-item");
          }}
        >
          <div className="view-info">
            <div className="row gap-4">
              <div className="col-md-12">
                <h4 >{t("label_category")} :</h4>
                <p >{categoryList.find((item) => item?._id === ItemDetail.category_id)?.name}</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("menu_item_code_label")} :</h4>
                <p >{ItemDetail?.item_code}</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("item_name_label")}(English) :</h4>
                <p >{ItemDetail?.item_name}</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("item_name_label")}(Japanese) :</h4>
                <p >{ItemDetail?.item_name_ja}</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("label_photo")} :</h4>
                {editItemForm.values.image && (
                  <ul className="question-image-preview questions-ul">
                    <li className="pr_img_box">
                      <img
                        src={editItemForm.values.image instanceof File ? URL.createObjectURL(editItemForm.values.image) : editItemForm.values.image}
                        style={{ height: "100px" }}
                        alt={"Profile_Img"}
                      />
                    </li>
                  </ul>
                )}
              </div>
              <div className="col-md-12">
                <h4 >{t("type_item_label")} :</h4>

                <div className="input-group">
                  <p >{t(ItemDetail?.type)} </p>
                </div>
              </div>
              <div className="col-md-12">
                <h4 >{t("spice_level_label")} :</h4>

                <div className="input-group">
                  <p >{`${t(booleanMask(ItemDetail?.spice_level_needed))}`}</p>
                </div>
              </div>

              {ItemDetail?.type === ITEM_TYPES.SET_OF_ITEMS && (
                <div className="col-md-12">
                  <h4 >{t("label_options")} :</h4>

                  {/* <div className="bordered-div"> */}
                  {ItemDetail && ItemDetail?.addons_data && ItemDetail?.addons_data?.length > 0 ? (
                    <>
                      {" "}
                      {ItemDetail?.addons_data.map((data, index) => (
                        <div key={index} className="border border-1 p-2 mb-2 rounded-2">
                          <h6 className="m-0">
                            {data.addon_name} - <span style={{ fontWeight: "400" }}>{data.item_name}</span>
                          </h6>
                        </div>
                      ))}
                    </>
                  ) : null}

                  {/* </div> */}
                </div>
              )}

              <div className="col-md-12">
                <label >{t("label_price")} :</label>
                <div className="row">
                  <div className="col">
                    <div className="input-group">
                      <p >¥ {roundFigurePrice(ItemDetail?.total_price , restSettingDetails?.tax_when_register_product)}</p>
                    </div>
                  </div>
                  <p >{}</p>
                </div>
              </div>
              <div className="col-md-12">
                <h4 >{t("label_cost_price")} :</h4>
                <div className="input-group">
                  <p >¥ {roundFigurePrice(ItemDetail?.cost_price , restSettingDetails?.tax_when_register_product)}</p>
                </div>
              </div>
              <div className="col-md-12">
                <h4 >{t("item_tax_type")} :</h4>
                <div className="input-group">
                  <p >{ItemDetail?.item_tax_type}</p>
                </div>
              </div>
              <div className="col-md-12">
                <h4 >{t("consumption_tax_rate")} :</h4>
                <p >{ItemDetail?.consumption_tax_rate} % Tax</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("price_with_tax")} :</h4>
                <p >¥ {roundFigurePrice(ItemDetail?.total_price , restSettingDetails?.tax_when_register_product)}</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("price_without_tax")} :</h4>
                <p >¥ {roundFigurePrice(ItemDetail?.price , restSettingDetails?.tax_when_register_product)}</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("label_short_description")} :</h4>
                <p >{ItemDetail?.short_description}</p>
              </div>
              <div className="col-md-12">
                <h4 >{t("label_short_description_ja")}:</h4>
                <p >{ItemDetail?.short_description_ja}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
