import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import {
    getSessionKey,
    handleNavigation,
    handleServerValidations,
    removeSessionKey,
    setLocalKey,
    setSessionKey,
} from "../../../utils/commonfunction";
import { SWAL_SETTINGS, COOKIES_EXPIRATION, ROLE, REST_LOGIN_ERROR_TYPES, USER_ENQUIRY_TYPES } from "../../../utils/Constants";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import CustomError from "../../../utils/customError";
import logo from '../../../assets/restaurant/images/logo.svg';
import { useSelector } from "react-redux";
import { useValidationSchemas } from "../../restaurant/validator/validation";
import Inquiry from "../common/inquiry";


const Support = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { loginValidationSchema } = useValidationSchemas();
    const [show, setShow] = useState(true);
    const navigate = useNavigate();
    const myGlobalData = useSelector((state) => state.globalData?.data);
    const [getGlobalData, setGlobalData] = useState({});

    useEffect(() => {
        if (myGlobalData) {
            const settingsData = JSON.parse(myGlobalData);
            setGlobalData(settingsData);
        }
    }, [myGlobalData]);

    /** Remember last logged-in user >>>> */
    useEffect(() => {
        const storedUsername = Cookies.get("rest_email");
        const storeUserpass = Cookies.get("rest_password");
        if (storedUsername) {
            formik.setFieldValue("email", storedUsername);
        }
        if (storeUserpass) {
            formik.setFieldValue("password", storeUserpass);
        }
    }, []);
    /** Remember last logged-in user <<<< */

    const handleshow = () => {
        setShow(!show);
    };



    function handleRememberMe(e) {
        if (e.target.checked) {
            setSessionKey("remember-me-rest", "true");
        } else {
            removeSessionKey("remember-me-rest");
            Cookies.remove("rest_email");
            Cookies.remove("rest_password");
        }
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: loginValidationSchema,
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const formData = new FormData();

            formData.append("email", values.email);
            formData.append("password", values.password)


            // login(formData)
            //     .then((response) => {
            //         if (response.success) {
            //             if (getSessionKey("remember-me-rest") === "true") {
            //                 Cookies.set("rest_email", response?.data?.email, {
            //                     expires: COOKIES_EXPIRATION,
            //                 });
            //                 Cookies.set("rest_password", values.password, {
            //                     expires: COOKIES_EXPIRATION,
            //                 });
            //             } else {
            //                 Cookies.remove("rest_email");
            //                 Cookies.remove("rest_password");
            //             }
            //             setLocalKey(ROLE.RESTAURANT, JSON.stringify(response.data));
            //             Swal.fire({
            //                 icon: "success",
            //                 text: response.message,
            //                 ...SWAL_SETTINGS,
            //             });
            //             handleNavigation({
            //                 successURL: "/restaurant/dashboard",
            //                 role: ROLE.RESTAURANT
            //             });
            //         } else {
            //             let message = handleServerValidations(response);
            //             /** Handling different types of server errors */
            //             const supportUrlConditions = [REST_LOGIN_ERROR_TYPES.ACCOUNT_IS_BLOCKED, REST_LOGIN_ERROR_TYPES.SUBSCRIPTION_EXPIRED, REST_LOGIN_ERROR_TYPES.ACCOUNT_NOT_ENROLLED, REST_LOGIN_ERROR_TYPES.PASSWORD_NOT_SET_YET];
            //             if(supportUrlConditions.indexOf(response.error_type) !== -1){
            //                 message += `<a class="d-block mt-2 no-decoration link-text" href="mailto:${getGlobalData?.admin_email}">${t('btn_text_contact_us')}</a>`;
            //             }
            //             Swal.fire({
            //                 icon: "error",
            //                 html: message,
            //                 ...SWAL_SETTINGS
            //             });
            //         }
            //     })
            //     .catch((error) => {
            //         console.log("error===>", error)
            //     })
        }
    });



    return (
        <div className="home-page-section">
            <Inquiry id="inquiry2" className="image-support" heading="web_support_form_heading" msgFieldLabel="web_support_msg_field_label" type={USER_ENQUIRY_TYPES.NEED_SUPPORT} errorType={params.support_type}/>
        </div>
    )
}

export default Support