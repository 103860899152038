import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { setGlobalLoader } from "../../../redux/slices/globalLoader";
import GlobalLoader from "./globalLoader";
import { setUserType } from "../../../redux/slices/userType";
import { useDispatch, useSelector } from "react-redux";
import Header from "./header";
import { generateToken } from '../services/order.service';
import { getSessionKey, setSessionKey } from "../../../utils/commonfunction";
import { addQrPanelToken } from "../../../redux/slices/qrcodeDetails";

const QrOrderLayout = () => {
    const dispatch = useDispatch();
    const loaderState = useSelector((state) => state.globalLoader);
    const queryParams = new URLSearchParams(window.location.search);
    const restaurant_id = queryParams.get('restaurant_id');
    const table_id = queryParams.get('table_id');
    const base = queryParams.get('base');
    const [token, setToken] = useState(null);
    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.classList.add('restaurant_inner_body');
        }
    }, []);

    useEffect(() => {
        if(getSessionKey("qr_user_token")){
            dispatch(addQrPanelToken(getSessionKey("qr_user_token")));
        }else{
            if (base === "web") {
                generateToken({ restaurant_id: restaurant_id, table_id: table_id })
                    .then((response) => {
                        setToken(response?.data?.token);
                        setSessionKey("qr_user_token", response?.data?.token);
                        dispatch(addQrPanelToken(response?.data?.token));
                    })
                    .catch((err) => { console.log(err) });
            }
            else {
                setSessionKey("qr_user_token", queryParams.get('token'));
                dispatch(addQrPanelToken(queryParams.get('token')));
            }
        }

    }, []);
    

    useLayoutEffect(() => {
        dispatch(setUserType("admin"));
        Promise.all([
            import("../../../assets/qrOrder/css/style.css"),
            import("../../../assets/restaurant/css/style.css"),
        ]).then(() => {
            dispatch(setGlobalLoader(false));
        });
    }, []);

    return (
        <>
            {loaderState && token? (
                <GlobalLoader />
            ) : (
                <div className='mobile-container'>
                    <div className='mobile-container-wraper'>
                        <Header />
                        <Outlet />
                    </div>
                </div>
            )}
        </>
    );
};
export default QrOrderLayout;
