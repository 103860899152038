import { ROLE } from "../../../utils/Constants";
import axiosInstance from "../../../utils/axios";
import { getLocalKey } from "../../../utils/commonfunction";

const path = "cashier/auth"
const options = {role: ROLE.CASHIER};

export const login = async (values) => {
    return await axiosInstance().post(`${path}/login`, values);
}

export const forgetPassword = async (values) => {
    return await axiosInstance().post(`${path}/forget-password`, values);
}

export const otpVerification = async (values) => {
    return await axiosInstance().post(`${path}/verify-otp`, values);
}

export const resetPassword = async (values) => {
    return await axiosInstance().patch(`${path}/reset-password`, values);
}

export const resendOtp = async (values) => {
    return await axiosInstance().post(`${path}/resend-otp`, values);
}

export const staffList = async (values) => {
    return await axiosInstance(options).get(`${path}/staff/list`, values);
}
  
export const authenticateAdmin = async (values) => {
    const cashierOptions = {role: ROLE.CASHIER,'active-user-email':values.email,'active-user-password':values.password};
    return await axiosInstance(cashierOptions).get(`${path}/staff/authenticate`, values);
}
  