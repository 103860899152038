import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form, Field} from "formik";
import { otpVerification, resendOtp } from "../../services/auth.services";
import {Link, useLocation, useNavigate } from "react-router-dom";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import Swal from "sweetalert2";
import { globalLoader, handleServerValidations } from "../../../../utils/commonfunction";
import OtpInput from "react-otp-input";
import { useValidationSchemas } from "../../validator/validation";
import logo from "../../../../assets/restaurant/images/logo.svg";

function Verifyotp() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const { otpValidationSchema } = useValidationSchemas();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [counter, setCounter] = useState(0);

  const initialValues = {
    otp: "",
  };

  useEffect(() => {
    if (!state?.email|| state?.email.length === 0) {
      navigate("/restaurant/login");
  }
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    } else {
      setResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [counter]);

  const handleSubmit = async (values, actions) => {
    const otpValue = values.otp;
    console.log(otpValue)
    globalLoader(true);

    try {
      await otpValidationSchema.validate(values, { abortEarly: false });
      let formData = new FormData();
      formData.append("email", state?.email);
      formData.append("otp", otpValue);
      formData.append("type", "password_reset");
      otpVerification(formData)
        .then((response) => {
          globalLoader(false)
          if (response.success && location.pathname.includes("otp-verification")) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            navigate("/restaurant/reset-password", { state: { validate_string: response?.data?.validate_string } });
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
      actions.setSubmitting(false);
    } catch (error) {
      console.log(error)
      const validationErrors = {};
      actions.setErrors(validationErrors);
      globalLoader(false);
    }
  };

  const handleResend = async () => {
    globalLoader(true);
    setResendDisabled(true);
    setCounter(30);

    let formData = new FormData();
    formData.append("email", state?.email);
    formData.append("type", "restaurant_password_reset");
    resendOtp(formData)
      .then((response) => {
        globalLoader(false);
        if (response.success) {
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
        } else {
          Swal.fire({
            icon: "error",
            text: response.message,
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        console.log("error: ", error);
      });
  };

  return (
    <div className="login-wrap">
      <div className="login-wrap-in">
        <div className="login-logo">
         
            <img src={logo} alt="Logo" />
         
        </div>
        <div className="login-form-wrap">
          <div className="login-form-header">
            <h4>{t("heading_otp_verification")}</h4>
            <p>{t("otp_sent_message")}</p>
          </div>
          <div className="login-form">
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={otpValidationSchema}>
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="d-flex w-100 justify-content-between mb-4">
                    <div className="col-md-3 number-otp-section">
                      <Field name="otp">
                        {({ field }) => (
                          <OtpInput
                            {...field}
                            value={otp}
                            inputType="number"
                            onChange={(value) => {
                              setOtp(value);
                              setFieldValue("otp", value);
                            }}
                            numInputs={4}
                            separator={<span>-</span>}
                            renderInput={(props, index) => (
                              <input
                                {...props}
                                key={index}
                                className="form-control"
                                style={{ width: "6rem", marginRight: "10px"  , textAlign: "center", fontWeight:600}}
                                maxLength="1"
                                autoFocus={index === 0 ? true : false}
                                placeholder="-"
                              />
                            )}
                          />
                        )}
                      </Field>
                    </div>
                  
                    
                  </div>
                  {errors.otp && touched.otp && <div className="text-danger mb-4 mx-5">{errors.otp}</div>}
                
                  <button className={"btn btn-primary w-100 justify-content-center"} type="submit">
                    {t("verify_otp")}
                  </button>
                </Form>
              )}
            </Formik>

            <div className="login-cta-bottom text-center mt-3">
              <p>
                {t("otp_not_receive")}{" "}
                <a
                  className={`${resendDisabled ? " disabled" : ""}`}
                  onClick={(e)=>{
                    e.preventDefault();
                    if(!resendDisabled){
                      handleResend();
                    }
                  }}
                  href="/"
                >
                  {t("btn_resend")}
                </a>
                {resendDisabled && <span className="text-secondary ms-2">({counter})</span>}
              </p>
              <p>
                <Link
         
                to={'/restaurant'}
                >
                  {t("link_back_to_login")}
                </Link>
               
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verifyotp;
