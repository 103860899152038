import React from 'react';
import notFoundImg from '../../../assets/restaurant/images/not-found.svg';
import { useTranslation } from 'react-i18next';

export default function RecordNotFound({ heading = "no_addons_found_text", text = null }) {
    const { t } = useTranslation();
    return (
        <div className='page-not-found-ui'>
            <img src={notFoundImg} alt={t(heading)} />
            <h4>{t(heading)}</h4>
            {text ? <p>{t(text)}</p> : null}
        </div>
    );
}