import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/staff";
const options = {role: ROLE.RESTAURANT};


export const list = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const add = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const edit = async (values) => {
  return await axiosInstance(options).put(`${path}/edit`, values);
}

export const deleteStaff = async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?o_id=${values.o_id}`);
}

export const detail = async (values) => {
  return await axiosInstance(options).get(`${path}/details?o_id=${values.o_id}`, values);
}

export const changeStatus = async (values) => {
  return await axiosInstance(options).patch(`${path}/change-status`, values);
}

export const staffAttendance = async (staff_id,values) => {
  return await axiosInstance(options).post(`${path}/shift-list?staff_id=${staff_id}`,values);
}

export const latest_employee_id = async () => {
  return await axiosInstance(options).get(`${path}/employee-id`);
}