import React, { useMemo } from 'react'
import { GlobalSettingsDetails } from './website/services/website.services';
import { useDispatch } from 'react-redux';
import { addData } from '../redux/slices/globalDetails';
import { Outlet, useNavigate } from 'react-router-dom';

export default function GlobalLayout() {
    const navigate = useNavigate();
    window.navigate_ = navigate;
    const dispatch = useDispatch();
    useMemo(() => {
        GlobalSettingsDetails().then(async (globalDetails) => {
            dispatch(addData(JSON.stringify(globalDetails?.data)));
        });
    }, []);
  return <Outlet/>
}
