import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const CashierHomeLayout = ({ currentTable, menuItems }) => {
  const navigate = useNavigate();
    return (
        <>
            <div className='dashboard-cashier'>
                
                <Outlet />
                <div className='dashboard-cashier-right'>
                    <div className='dashboard-cashier-right-in'>
                        <div className='menu-qty d-flex flex-column gap-2 p-3'>
                            <div className='menu-qty-row'>
                                <h4>Coca cola</h4>
                                <div className='menu-qty-right'>
                                    <span>2</span>
                                    <h4>¥ 70</h4>
                                </div>
                            </div>
                            <div className='menu-qty-row'>
                                <h4>Tea</h4>
                                <div className='menu-qty-right'>
                                    <span>2</span>
                                    <h4>¥ 70</h4>
                                </div>
                            </div>
                        </div>
                        <div className='buttom-bill-info'>
                            <div className='coupon-enter d-flex flex-column gap-3 p-3'>
                                <div className='coupon-enter-in'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="spice_level_needed" />
                                        <label class="form-check-label" for="spice_level_needed">Coupon code</label>
                                    </div>
                                    <div className='coupon-input'>
                                        <label className='form-label'>Enter code</label>
                                        <input type='text' className='form-control' placeholder='Enter coupon' />
                                        <button className='btn btn-primary'>Apply</button>
                                    </div>
                                </div>
                                <div className='coupon-enter-in'>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="spice_level_needed" />
                                        <label class="form-check-label" for="spice_level_needed">Gift card</label>
                                    </div>
                                    <div className='coupon-input'>
                                        <label className='form-label'>enter Gift card amount</label>
                                        <input type='text' className='form-control' placeholder='Enter coupon' />
                                        <button className='btn btn-primary'>Apply</button>
                                    </div>
                                </div>
                            </div>
                            <div className='subtotal-info p-3'>
                                <ul className=' d-flex flex-column gap-3'>
                                    <li>
                                        <h4>Subtotal(2 item)</h4>
                                        <h4>¥ 200.00</h4>
                                    </li>
                                    <li>
                                        <h4>Service tax</h4>
                                        <h4>¥ 10.00</h4>
                                    </li>
                                    <li>
                                        <h4>Consumption tax</h4>
                                        <h4>¥ 36.00</h4>
                                    </li>
                                </ul>
                                <p>8% take-out tax on ¥16.00</p>
                                <p>10% dine-in tax on ¥20.00</p>
                            </div>
                            <button className='btn btn-primary d-flex justify-content-between w-100 btn-total' onClick={()=>navigate('/cashier/payment-via-cash')}><span>Total</span><span>¥ 246.00</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CashierHomeLayout;