import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../../common/breadcrumbs";
import CustomCkeditor from "../../../common/customeditor";
import { SWAL_SETTINGS } from "../../../../../utils/Constants";
import { globalLoader, handleServerValidations } from "../../../../../utils/commonfunction";
import * as bannerService from "../../../services/banner.services";
import * as Yup from "yup";
import { Tooltip, Whisper, Loader } from "rsuite";
import { useTranslation } from "react-i18next";

const BannerEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const [previewimage, setPreviewImage] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [saveType, setSaveType] = useState("");
  const { t } = useTranslation()

  const breadcrumbs = [
    { title: t('sidebar_link_dashboard'), url: "/admin/dashboard" },
    { title: t("sidebar_link_banners"), url: "/admin/cms/banner/list/1" },
    { title: t("btn_edit"), url: "" },
  ];

  useEffect(() => {
    bannerService
      .Details(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setPreviewImage(response.data.image);
        setTimeout(() => {
          setDataLoaded(true);
        }, 100);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(("label_title_error")).max(15, ("max_length_label"))
      .test("no-spaces", t("label_title_error"), (value) => value.trim()),
    title_ja: Yup.string()
      .max(15, ("max_length_label")),
    // richtext: Yup.string()
    //   .required(t("label_richtext_error"))
    //   .test("no-spaces", t("label_richtext_error"), (value) => value.trim()),
    files: Yup.mixed()
      .required(("label_image_error"))
      .test(
        'fileOrUrl',
        'supported_file_error',
        value => {
          if (!value) return true; 
          if (typeof value === 'string') return /^(http|https):\/\/[^\s$.?#].[^\s]*$/gm.test(value);
          return ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/gif'].includes(value.type);
        }
      )
      .test(
        'fileSize',
        'image_max_size_error',
        value => {
          if (!value || typeof value === 'string') return true;
          return value.size <= 1.5 * 1024 * 1024;
        }
      ),
  });

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {dataLoaded ? (
        <Formik
          enableReinitialize
          initialValues={{
            title: showdefault && showdefault.title ? showdefault.title : "",
            title_ja: showdefault && showdefault.title_ja ? showdefault.title_ja : "",
            link: showdefault && showdefault.link ? showdefault.link : "",
            // richtext: showdefault && showdefault.description ? showdefault.description : "",
            // richtext_de:showdefault && showdefault.description_ja ? showdefault.description_ja : "",
            files: showdefault && showdefault.image ? showdefault.image : "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            globalLoader(true);
            let formData = new FormData();
            formData.append("o_id", params.id);
            formData.append("title", values.title);
            formData.append("title_ja", values.title_ja.trim());
            formData.append("link", values.link.trim());
            // formData.append("description", values.richtext);
            // formData.append("description_ja", values.richtext_de);
            formData.append("image", values.files);
            bannerService
              .Edit(formData)
              .then((response) => {
                setSubmitting(false);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  if (saveType !== "Save") {
                    setTimeout(() => {
                      navigate(`/admin/cms/banner/list/${params.pgno}`);
                    }, 1000);
                  }
                  globalLoader(false);
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
                globalLoader(false);
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                  <div className="card custom-card">
                    <div className="card-body">
                      <div>
                        <h6 className="main-content-label mb-3">{t("btn_edit") + " " + t("sidebar_link_banners")}</h6>
                      </div>
                      <div className="row row-sm">
                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            {t("label_Banner_title")}:<span className="requirestar">*</span>{" "}
                          </label>
                          <input
                            name="title"
                            id="title"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title}
                            className="form-control"
                            placeholder={t("banner_title_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.title && touched.title && t(errors.title)}
                          </span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="title" className="text-left d-flex">
                            {t("label_Banner_title_ja")}:
                          </label>
                          <input
                            name="title_ja"
                            id="title_ja"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.title_ja}
                            className="form-control"
                            placeholder={t("banner_title_placeholder")}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.title_ja && touched.title_ja && errors.title_ja}
                          </span>
                        </div>

                        <div className="col-md-12 text-center form-group">
                          <label htmlFor="link" className="text-left d-flex">
                            {t("label_link")}:
                          </label>
                          <input
                            name="link"
                            id="link"
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.link}
                            className="form-control"
                            placeholder={t("banner_link_placeholder")}
                          />
                          {/* <span className="text-danger d-flex text-left">
                            {errors.link && touched.link && errors.link}
                          </span> */}
                        </div>

                        {/* <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            {t("label_description")}:<span className="requirestar">*</span>
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"richtext"}
                              setFieldValue={setFieldValue}
                              value={values.richtext}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            ""
                          )}
                          <span className="text-danger d-flex text-left">
                            {errors.richtext &&
                              touched.richtext &&
                              errors.richtext}
                          </span>
                        </div>

                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="subject" className="text-left d-flex">
                            {t("description_japanese")}:
                          </label>
                          {dataLoaded ? (
                            <CustomCkeditor
                              fieldname={"richtext_de"}
                              setFieldValue={setFieldValue}
                              value={values.richtext_de}
                              setFieldTouched={setFieldTouched}
                            />
                          ) : (
                            ""
                          )}
                        </div> */}

                        <div className="col-lg-12 text-center form-group">
                          <label htmlFor="files" className="text-left d-flex">
                            {t("label_image")}:<span className="requirestar">*</span>
                            <Whisper
                              placement="top"
                              controlId="control-id-hover"
                              trigger="hover"
                              speaker={
                                <Tooltip>
                                  {t("image_support_tooltip")}
                                </Tooltip>
                              }
                            >
                              <span className="field-more-info mt-1 ms-1 cp">
                                ?
                              </span>
                            </Whisper>
                          </label>
                          <input
                            className="form-control imgInput"
                            id="files"
                            name="files"
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                              setFieldValue(
                                "files",
                                event.currentTarget.files[0] || ""
                              );
                              event.currentTarget.files.length === 1
                                ? setPreviewImage(
                                  URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  )
                                )
                                : setPreviewImage("");
                            }}
                          />
                          <span
                            className="text-danger d-flex text-left"
                            id="errortext"
                          >
                            {errors.files && touched.files && t(errors.files)}
                          </span>
                        </div>
                        {previewimage ? (
                          <ul className="question-image-preview questions-ul">
                            <li className="pr_img_box">
                              <img
                                src={previewimage}
                                style={{ height: "100px" }}
                                alt={"img"}
                              />
                              <div className="img_options">
                                <button type="button" className="text-danger"
                                  onClick={() => {
                                    // console.log(previewResImage , previewimage)
                                    setPreviewImage("");
                                    setFieldValue("image", null);
                                    document.getElementById("files").value = ""
                                  }}>
                                  <i className="ri-delete-bin-6-fill"></i>
                                </button>
                              </div>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}

                        <div className="">
                          <button
                            onClick={() => {
                              setSaveType("Save");
                            }}
                            className="btn btn-info mr-2"
                            type="submit"
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            {t("btn_save")}
                          </button>
                          <button
                            onClick={() => {
                              setSaveType("");
                            }}
                            className="btn btn-success mr-2"
                            type="submit"
                          >
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            {t("btn_save_exit")}
                          </button>
                          <button
                            className="btn ripple btn-secondary"
                            type="button"
                            onClick={() => navigate(-1)}
                          >
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            {t("btn_cancel")}
                          </button>
                        </div>
                      </div>
                      {/* // : "Loader"} */}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default BannerEdit;
