import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { menuList, detail, categoryDetail, updateCustomerInfo } from "../../../services/table.services";
import { CUSTOMER_TAGS, DISPLAY_METHOD_TAX_OPTIONS, LOADER_TIMEOUT_TIME, PHONE_NO_LENGTH,SWAL_SETTINGS } from '../../../../../utils/Constants';
import { TrimText, getColorType, getLocalKey, handleServerValidations, showPrice ,getTranslatedKey} from '../../../../../utils/commonfunction';
import GridLoader from '../../../../restaurant/shared/gridLoader';
import RecordNotFound from '../../../../restaurant/shared/recordNotFound';
import { useFormik } from 'formik';
import CustomError from '../../../../../utils/customError';
import Swal from "sweetalert2";
import * as Yup from 'yup';
import PhoneInput from '../../../../../utils/PhoneInput';
import { Button, Modal } from 'rsuite';




export default function CashierMenu() {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const [menuItemList, setMenuItemList] = useState([]);
  const [refreshList, setRefreshList] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tableDetail, setTableDetail] = useState({});
  const [combinedOrderItems, setCombinedOrderItems] = useState([])
  const [category, setCategory] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [customerInfo, setCustomerInfo] = useState(null);
  const [numberOfPersonOptions, setNumberOfPersonOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const cashierData = getLocalKey('cashierDetail') ? JSON.parse(getLocalKey('cashierDetail')) : {};
  const [langChanged, setLangChanged] = useState(false);
  const restSettingDetails = useSelector((state) => state?.restSettings?.data);

  const getTableDetail = (tableId) => {
    detail({ o_id: tableId })
      .then((response) => {
        setTableDetail(response?.data);
          let i = 1;
          const numberOfPersonOptionsTemp = [];
          for (i; i <= response?.data?.capacity; i++) {
            numberOfPersonOptionsTemp.push(i);
          }
          setNumberOfPersonOptions(numberOfPersonOptionsTemp);
          if (response?.data?.is_available === false) {
            const orderData = response?.data?.activeOrder ? response?.data?.activeOrder : null
            setOrderDetail(orderData)
            const combinedOrderedItems = [...response?.data?.activeOrder?.ordered_items];
            response?.data?.activeOrder?.subOrders.forEach(subOrder => {
              combinedOrderedItems.push(...subOrder.ordered_items);
            });
            setCombinedOrderItems(combinedOrderedItems);
            if (orderData) {
              let customerDetails = {
                customer_name: orderData?.customerDetails?.customer_name || '',
                customer_phone_number: orderData?.customerDetails?.customer_phone_number || '',
                number_of_persons: orderData?.number_of_persons || '',
                customer_tag: orderData?.customer_tag || '',
              }
              setCustomerInfo(customerDetails)
            }

          }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getCategoryDetail = (categoryId) => {
    categoryDetail({ o_id: categoryId })
      .then((response) => {
        setCategory(response?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (params.tableId && params.category) {
      getTableDetail(params.tableId);
      getCategoryDetail(params.category)
    }
    else {
      navigate('/cashier/dashboard')
    }
  }, [params.tableId, params.category , langChanged]);

  useEffect(() => {
    setLoader(true);
    let formData = {};
    if (params.category) {
      formData.category_id = params.category;
    }
    menuList(formData)
      .then((data) => {
        if (data && data.data) {
          setMenuItemList(data.data.list || []);
        } else {
          setMenuItemList([]);
        }

        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      })
      .catch((error) => {
        console.error("error ====> ", error);
        setTimeout(() => {
          setLoader(false);
        }, LOADER_TIMEOUT_TIME);
      });
  }, [refreshList , langChanged]);

  const handleRefreshList = () => {
    setRefreshList(!refreshList)
  }

  const isItemSelected = (itemId) => {
    const exists = combinedOrderItems.some(item => item._id === itemId);
    return exists
  };



  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const getLabelByValue = (value) => {
    const item = CUSTOMER_TAGS.find(shapeType => shapeType.value === value);
    return item ? item.label : '';
  };

  const customerForm = useFormik({
    initialValues: {
      order_id: orderDetail._id,
      cashier_id: cashierData?._id,
      customer_name: orderDetail?.customerDetails?.customer_name || '',
      customer_phone_number: orderDetail?.customerDetails?.customer_phone_number || '',
      number_of_persons: orderDetail?.number_of_persons || '',
      tag: orderDetail?.tag || CUSTOMER_TAGS.REGULAR,
    },
    validationSchema: Yup.object({
      customer_name: Yup.string().trim().required("label_name_error").max(100, "max_length_error"),
      number_of_persons: Yup.string().trim().required("err_no_of_person"),
      customer_phone_number: Yup.string()
        .test('phone_required', 'label_phone_number_required_error', (value) => {
          const phoneNumber = value ? (value.split(" "))[1] : null;
          return phoneNumber && phoneNumber.length;
        }
        )
        .test('phone_digit_error', 'label_phone_number_digits_error', (value) => {
          const phoneNumber = value ? (value.split(" "))[1] : null;
          return phoneNumber && /^\d+$/.test(phoneNumber);
        }
        )
        .test('phone_min_length', 'label_phone_number_min_length_error', (value) => {
          const phoneNumber = value ? (value.split(" "))[1] : null;
          return phoneNumber && phoneNumber.length >= PHONE_NO_LENGTH.min;
        }
        )
        .test('phone_max_length', 'label_phone_number_max_length_error', (value) => {
          const phoneNumber = value ? (value.split(" "))[1] : null;
          return phoneNumber && phoneNumber.length <= PHONE_NO_LENGTH.max;
        }
        ),
    }),
    enableReinitialize: true,
    onSubmit: (values, { resetForm, setFieldValue }) => {
      updateCustomerInfo(values)
        .then((response) => {
          if (response.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setIsEditing(false)
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          console.error("Error updating profile", error);
        });
    },
  });

  useMemo(()=>{
    document.addEventListener('language_change',function(){
      setLangChanged(!langChanged);
    })
  });

  return (
    <>

      <div className='dashboard-cashier-left d-flex flex-column'>
        <div className='cashier-head text-center'>
          <div className='btns-head-in-left gap-2 d-flex'>
            <button className='btn btn-icon' onClick={() => { navigate(-1); }}>
              <i className='ti ti-arrow-left'></i>
            </button>
          </div>
          <h4>{TrimText(tableDetail.table_number, 20)}</h4>
          <div className='btns-head-in gap-2 d-flex'>
            <button className='btn btn-icon'  onClick={() => setIsOpen(true)}>
              <i className='ti ti-user'></i>
            </button>
            <button className='btn btn-icon'>
              <i className='ti ti-printer'></i>
            </button>
          </div>
        </div>


        <div className='cashier-category-wrap'>
          <h4 className='mb-3'>{TrimText(category.name, 20)}</h4>
          {
            loader ?
              <GridLoader />
              :
              menuItemList.length ?
                <div className='category-list-grid menu-list-grid'>
                  {menuItemList.map((item, index) => (
                    <div key={index} className={`category-box cp category-menu-box ${isItemSelected(item?._id) ? 'item-selected' : ''}`} >
                      <div className="category-name menu-img">
                        <img src={item.image} alt="" />
                      </div>
                      <div className="menu-item-info">
                        <h4>{TrimText(getTranslatedKey('item_name',item),15)}</h4>
                        <span>¥ {restSettingDetails?.display_method_product_price===DISPLAY_METHOD_TAX_OPTIONS.TAX_EXCLUDED ?showPrice(item?.price):showPrice(item?.total_price)}</span>
                      </div>
                    </div>
                  ))}

                </div>
                :
                <RecordNotFound heading="no_assigned_item_text" text="no_category_found_text_para" />
          }
        </div>

        <Modal backdrop="static" open={isOpen} className='modal-cashier'>
          <Modal.Header className="modal-header d-flex justify-content-between">
            <button type="button" className="btn btn-icon" aria-label="Close" onClick={()=>isEditing ? setIsEditing(false) : setIsOpen(false)}>
              <i className="ti ti-arrow-left"></i>
            </button>
            <h1 className="modal-title fs-5">Customer info</h1>
            <button type="button" className="btn btn-icon" onClick={() => setIsOpen(false)} aria-label="Close">
              <i className="ti ti-x"></i> {/* Replace this with your custom icon if needed */}
            </button>
          </Modal.Header>

          <Modal.Body className="modal-body">
            {!isEditing ? (
              <>
                <div className='card-list'>
                  <ul>
                    <li>
                      <h4>{t('list_heading_name')}</h4>
                      <h3>{TrimText(customerInfo?.customer_name)}</h3>
                    </li>
                    <li>
                      <h4>{t('label_phone_number')}</h4>
                      <h3>{customerInfo?.customer_phone_number}</h3>
                    </li>
                    <li>
                      <h4>{t('number_of_person_text')}</h4>
                      <h3>{customerInfo?.number_of_persons}</h3>
                    </li>
                    <li>
                      <h4>{t('label_tag')}</h4>
                      <h3>{customerInfo?.tag ? t(getLabelByValue(customerInfo?.tag)) : t(CUSTOMER_TAGS.REGULAR)}</h3>
                    </li>
                  </ul>
                </div>
                <Button onClick={toggleEdit} className='btn btn-primary w-100 mt-3'>
                  {t('btn_edit')}
                </Button>
              </>
            ) : (
              <form onSubmit={customerForm.handleSubmit}>
                <div className='mb-3'>
                  <label className='form-label'>{t('list_heading_name')}<span>*</span></label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder={t('placeholder_enter_addon_option_name')}
                    name="customer_name"
                    value={customerForm.values.customer_name}
                    onChange={customerForm.handleChange}
                  />
                  <CustomError name="customer_name" form={customerForm} className="text-danger" />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>{t('label_phone_number')}<span>*</span></label>
                  <PhoneInput
                    placeholder={t('placeholder_phone_number')}
                    value={customerForm.values.customer_phone_number}
                    onChange={(e) => customerForm.setFieldValue('customer_phone_number', e?.phone_number)}
                    onBlur={customerForm.handleBlur}
                  />
                  <CustomError name="customer_phone_number" form={customerForm} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                </div>

                <div className='mb-3'>
                  <label className='form-label'>{t('number_of_person_text')}<span>*</span></label>
                  <select
                    className='form-control cp'
                    name='number_of_persons'
                    value={customerForm.values.number_of_persons}
                    onChange={(e) => customerForm.setFieldValue("number_of_persons", e.target.value ? Number(e.target.value) : "")}
                  >
                    <option value="">{t('input_select_text')}</option>
                    {numberOfPersonOptions.map((option, index) => (
                      <option value={option} key={index}>{option}</option>
                    ))}
                  </select>
                  <CustomError className="text-danger" name="number_of_persons" form={customerForm} />
                </div>

                <div className="mb-3">
                  <label className="form-label">{t("label_customer_tag")}</label>
                  <select
                    className="form-control cp"
                    name="tag"
                    value={customerForm.values.tag}
                    onChange={customerForm.handleChange}
                    onBlur={customerForm.handleBlur}
                  >
                    <option value="" label={t("placeholder_select_table_customer_tag")} />
                    <option value={CUSTOMER_TAGS.VIP}>{CUSTOMER_TAGS.VIP}</option>
                    <option value={CUSTOMER_TAGS.REGULAR}>{CUSTOMER_TAGS.REGULAR}</option>
                  </select>
                  <CustomError className="text-danger" name="tag" form={customerForm} />
                </div>
                <Button type="submit" className="btn btn-primary w-100 mt-3" block>
                  {t('btn_save')}
                </Button>
              </form>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}




{/* <div class="modal fade modal-cashier" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
      <button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
        <i className='ti ti-arrow-left'></i>
      </button>
      <h1 class="modal-title fs-5" id="exampleModalLabel">Customer info</h1>
      <button type="button" class="btn btn-icon" data-bs-dismiss="modal" aria-label="Close">
        <i className='ti ti-x'></i>
      </button>
    </div>
    <div class="modal-body">
      <div className='card-list'>
        <ul>
          <li>
            <h4>Name</h4>
            <h3>Lissa watson</h3>
          </li>
          <li>
            <h4>Phone number</h4>
            <h3>+81 45124 45124</h3>
          </li>
          <li>
            <h4>Number of persons </h4>
            <h3>5</h3>
          </li>
          <li>
            <h4>Tag</h4>
            <h3>VIP</h3>
          </li>
        </ul>
      </div>
      <button className='btn btn-primary w-100 mt-3'>Edit</button>
    </div>
  </div>
</div>
</div> */}