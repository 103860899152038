import axiosInstance from "../../../utils/axios";
import { ROLE } from "../../../utils/Constants";

const path = "restaurant/menu-management/categories";
const options = {role: ROLE.RESTAURANT};


export const getCategories = async (values) => {
  return await axiosInstance(options).post(`${path}/list`, values);
}

export const addCategory = async (values) => {
  return await axiosInstance(options).post(`${path}/add`, values);
}

export const editCategory = async (values) => {
  return await axiosInstance(options).put(`${path}/edit`, values);
}

export const deleteCategory = async (values) => {
  return await axiosInstance(options).delete(`${path}/delete?o_id=${values.o_id}`);
}

export const categoryDetail = async (values) => {
  return await axiosInstance(options).get(`${path}/details?o_id=${values.o_id}`, values);
}

export const sortCategories = async (values) => {
  return await axiosInstance(options).patch(`${path}/sort`, values);
}

export const categoryOptions = async (includeUnCategorized = false) => {
  return await axiosInstance(options).get(`${path}/options?include_un_categorized=${includeUnCategorized}`);
}