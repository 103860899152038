import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUser, setReturnURL} from '../../../utils/commonfunction';
import { ROLE } from "../../../utils/Constants";

const RestaurantPrivateRoute = ({ component: Component }) => {
    const userData = getUser(ROLE.RESTAURANT)
    return (
        <>
            {(function(){
                if(Object.keys(userData).length && userData.token){
                    return <Component/>
                }else{
                    setReturnURL(ROLE.RESTAURANT);
                    return <Navigate to="/restaurant/login" />;
                }
            })()}
        </>
    )
}

export default RestaurantPrivateRoute;
