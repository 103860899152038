import axiosInstance from "../../../utils/axios";


const path = "/waiter/tables";



export const tableList = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).post(`${path}/list`, values);
}


export const tableDetail = async (values,option) => {
  return await axiosInstance({extraHeaders: {authorization:`Bearer ${option.token}`, bypass_origin:"true"}}).post(`${path}/details` , values);
}


