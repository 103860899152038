import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import {
  getUser,
  handleServerValidations,
  setLocalKey,
  blockInvalidChar,
  DT,
} from "../../../utils/commonfunction";
import { useSelector } from "react-redux";
import * as userServices from "../services/user.service";
import { SWAL_SETTINGS, EMAIL_REGEX, INPUT_LENGTH_15, ROLE, PHONE_NO_LENGTH } from "../../../utils/Constants";
// import PhoneInput from 'react-phone-input-2'
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { PHONE_VALIDATION } from "../../../utils/commonValidations";
import CustomError from "../../../utils/customError";
import PhoneInput from "../../../utils/PhoneInput";
// import 'react-phone-number-input/style.css'

const EditProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showprofile = useSelector((state) => state.profile);
  const [showdefault, setShowDefault] = useState({});
  const [updateData, setUpdateData] = useState("false");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const getuser = getUser(ROLE.SUPER_ADMIN);
    setShowDefault(getuser);
  }, [updateData]);

  console.log("showdefalut",showdefault)

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .trim()
      .required("label_first_name_error")
      .max(INPUT_LENGTH_15,"validation_max_input_characters")
      .matches(/^[A-Za-z\s]+$/, t("label_first_name_format_error")),
    last_name: Yup.string()
    .trim()
    .max(INPUT_LENGTH_15,"validation_max_input_characters")
    .required("label_last_name_error"),
    email: Yup.string()
      .trim()
      .required("label_email_error")
      .matches(EMAIL_REGEX, "label_email_validation_error"),
    files: Yup.mixed()
      .required("label_image_error")
      .test("fileType", "label_image_format_error", (value) => {
        if (!value) {
          const supportedFormats = ["image/jpeg", "image/png", "image/gif"];
          return supportedFormats.includes(value.type);
        } else {
          return true;
        }
      }),
    phone_number: PHONE_VALIDATION,
  });

 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name:
        showdefault && showdefault.first_name ? showdefault.first_name : "",
      middle_name:
        showdefault && showdefault.middle_name ? showdefault.middle_name : "",
      last_name:
        showdefault && showdefault.last_name ? showdefault.last_name : "",
      email: showdefault && showdefault.email ? showdefault.email : "",
      files:
        showdefault && showdefault.profile_image
          ? showdefault.profile_image
          : "",
      phone_number:showdefault && showdefault.phone_number ? showdefault.phone_number : "",
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitted(true);
      let formData = new FormData();
      formData.append("first_name", values.first_name);
      formData.append("middle_name", values.middle_name);
      formData.append("last_name", values.last_name);
      formData.append("email", values.email);
      formData.append("phone_number", values.phone_number);
      // if (values.phone_number) {
      //   formData.append(
      //     "phone_number",
      //     JSON.stringify({
      //       number: values.phone_number,
      //     })
      //   );
      // }

      if (showprofile && showprofile.file) {
        formData.append('image', showprofile.file);
      }

      userServices
        .updateProfileUsers(formData)
        .then((response) => {
          setSubmitted(false);
          if (response.success) {
            setLocalKey("super_admin", JSON.stringify(response.data));
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            resetForm({ values: "" });
            setUpdateData(!updateData);
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
          }
        })
        .catch((error) => {
          setSubmitted(false);
          console.log("error ====> ", error);
        });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="mt-5">
              <div className="form-group ">
                <div className="row row-sm">
                  <div className="col-md-4">
                    <label className="text-left d-flex" htmlFor="Email">
                      {t("label_first_name")}:<span className="requirestar">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name}
                      placeholder={t("placeholder_first_name")}
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.first_name &&
                        formik.touched.first_name &&
                        DT(t(formik.errors.first_name), [INPUT_LENGTH_15])}
                    </span>
                  </div>
                </div>
              </div>

              {/* <div className="form-group ">
                <div className="row row-sm">
                  <div className="col-md-4">
                    <label className="text-left d-flex" htmlFor="Email">
                    {t("label_middle_name")}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="middle_name"
                      name="middle_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.middle_name}
                      placeholder={t("placeholder_middle_name")}
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.middle_name &&
                        formik.touched.middle_name &&
                        formik.errors.middle_name}
                    </span>
                  </div>
                </div>
              </div> */}

              <div className="form-group ">
                <div className="row row-sm">
                  <div className="col-md-4">
                    <label className="text-left d-flex" htmlFor="Email">
                    {t("label_last_name")}:<span className="requirestar">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      name="last_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name}
                      placeholder={t("placeholder_last_name")}
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.last_name &&
                        formik.touched.last_name &&
                        DT(t(formik.errors.last_name), [INPUT_LENGTH_15])}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group ">
                <div className="row row-sm">
                  <div className="col-md-4">
                    <label className="text-left d-flex" htmlFor="email">
                    {t("label_email")}:<span className="requirestar">*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder={t("placeholder_email")}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                    />
                    <span className="text-danger d-flex text-left">
                      {formik.errors.email &&
                        formik.touched.email &&
                        t(formik.errors.email)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group ">
                <div className="row row-sm">
                  <div className="col-md-4">
                    <label className="text-left d-flex" htmlFor="phone_number">
                    {t("label_phone_number")}:
                    </label>
                      <PhoneInput
                        value={formik?.values?.phone_number}
                        name={'phone_number'} onChange={(e) => { formik.setFieldValue('phone_number', e?.phone_number) }} onBlur={formik.handleBlur} />
                    <CustomError name="phone_number" form={formik} className="text-danger" shortCodes={{ PHONE_MIN_LENGTH: PHONE_NO_LENGTH.min, PHONE_MAX_LENGTH: PHONE_NO_LENGTH.max }} />
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <button
                  className="btn btn-info mr-2"
                  type="submit"
                  disabled={submitted ? true : null}
                >
                  <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                  {t("btn_submit")}
                </button>
                <button
                  className="btn ripple btn-secondary"
                  type="button"
                  disabled={submitted ? true : null}
                  onClick={() => {navigate(-1)}}
                >
                  <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                  {t("btn_cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditProfile;
