import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as restaurantService from "../../services/restaurant.services";
import Loader from "../../common/loader";
import { formateDate, removeSpecialCharacter } from "../../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
// import $ from "jquery";

const ViewTransaction = (props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const params = useParams();
    const [query] = useSearchParams();
    const [showdefault, setShowDefault] = useState({});

    useEffect(() => {
        restaurantService
            .RestTransactionList({ restaurant_id: params.id })
            .then((response) => {
                setShowDefault(response && response.data ? (response.data.list).filter((data) => data._id === query.get('id'))[0] : []);

                console.log("View response-", (response.data.list).filter((data) => data._id ===     query.get('id')));
            })
            .catch((error) => {
                console.log("error=====>", error);
            });
    }, [params.id]);

    console.log("showdefault---", showdefault.amount);

    return (
        <>
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">

                        {/* <div className="m-4">
              <h6 className="main-content-label mb-3">
                {t("label_restaurants_name")}
              </h6>
            </div> */}

                        <div className="">

                            {showdefault && Object.keys(showdefault).length > 0 ? (
                                <div className="row">
                                    <div className="col-lg-12 form-group">
                                        <table
                                            id="simple-table"
                                            className="table  table-bordered table-hover"
                                        >
                                            <tbody>
                                                <tr>
                                                    <th>{t("label_company_name")}</th>
                                                    <td>
                                                        {
                                                            showdefault && showdefault.restaurent_info[0].company_name
                                                                ? showdefault.restaurent_info[0].company_name
                                                                : "N/A"
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_representative_name")}</th>
                                                    <td>
                                                        {
                                                            showdefault && showdefault.restaurent_info[0].representative_name
                                                                ? showdefault.restaurent_info[0].representative_name
                                                                : "N/A"
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_plan_name")}</th>
                                                    <td>
                                                        {
                                                            showdefault && showdefault.restaurent_info[0].plan_name
                                                                ? removeSpecialCharacter(showdefault.restaurent_info[0].plan_name) 
                                                                : "N/A"
                                                        }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>{t("title_amount")}</th>
                                                    <td className="text-capitalize">
                                                    {showdefault && showdefault.amount >= 0
                                                            ? ("¥ "+showdefault.amount)
                                                            : "N/A"}
                                                    </td>
                                                </tr>


                                                <tr>
                                                    <th>{t("contract_plan_duration_type")}</th>
                                                    <td>
                                                        {showdefault && showdefault.restaurent_info[0].contract_plan_duration_type
                                                            ? showdefault.restaurent_info[0].contract_plan_duration_type
                                                            : "N/A"}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>{t("list_heading_created_date")}</th>
                                                    <td>
                                                        {showdefault && showdefault.createdAt
                                                            ? formateDate(showdefault.createdAt)
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>{t("label_modified_date")}</th>
                                                    <td>
                                                        {showdefault && showdefault.updatedAt
                                                            ? formateDate(showdefault.updatedAt)
                                                            : "N/A"}
                                                    </td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                <Loader />
                            )}
                        </div>

                        <div className="mt-2 back-button mx-0">
                            <button
                                className="btn ripple btn-dark"
                                type="button"
                                onClick={() =>
                                    navigate(-1)
                                }
                            >
                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                {t("btn_back")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewTransaction;


