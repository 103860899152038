import React, { useEffect, useRef, useState } from 'react';
import { Banners } from '../services/website.services';
import { createSlider } from '../../../utils/commonfunction';
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next';



const WebsiteBanners = ({ id = "bannerSlider" }) => {

    const [bannerData, setBannerData] = useState([]);
    const [apiLoaded, setApiLoaded] = useState(false);
    const { t } = useTranslation()
    const BannerHomeSliderRef = useRef();


    useEffect(() => {
        Banners().then((response) => {
            setApiLoaded(true);
            setBannerData(response?.data?.list||[]);
        }).catch((err) => {
            console.log("err", err);
        })
    }, []);

    useEffect(() => {
        if (BannerHomeSliderRef?.current) {
            createSlider(`#${id}`, {
                loop: false,
                margin: 20,
                nav: true,
                dots: false,
                responsive: {
                    0: {
                        items: 1
                    },
                    600: {
                        items: 3
                    },
                    1000: {
                        items: 4
                    }
                }
            });
        }
    }, [BannerHomeSliderRef, apiLoaded]);

    return (
        <>
            {apiLoaded ?
                bannerData.length ?
                    <section className="banner-ads-section section-padding-top">
                        <div className="container" style={{ minHeight: '270px' }}>
                            <div className='headings text-center mb-5'>
                                <h2>{t('web_banner_add')}</h2>
                            </div>
                            <>
                                {bannerData ?
                                    <div className="owl-carousel owl-theme enable-nav-flex" id={id} ref={BannerHomeSliderRef}>
                                        {bannerData && bannerData.map((item, index) => (
                                            <div className="item" key={index}>
                                                <a href={item?.link} target='_blank' rel="noreferrer" >
                                                    <img className='banner_slider_image' title={item?.title} src={item?.image} alt="" />
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                    : null}
                            </>
                        </div>
                    </section>

                    : null

                :
                <section className="banner-ads-section section-padding-top">
                    <div className="row">
                        <div className="col-lg-3 col-md-4 ">
                            <Skeleton className='rounded-4' height={200} />
                        </div>
                        <div className="col-lg-3 col-md-4  d-none d-md-block">
                            <Skeleton className='rounded-4' height={200} />
                        </div>
                        <div className="col-lg-3 col-md-4  d-none d-md-block">
                            <Skeleton className='rounded-4' height={200} />
                        </div>
                        <div className="col-lg-3 col-md-4  d-none d-lg-block">
                            <Skeleton className='rounded-4' height={200} />
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default WebsiteBanners
