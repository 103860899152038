import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toHtml from 'html-react-parser';
import * as bannerService from '../../../services/banner.services';
import Breadcrums from '../../../common/breadcrumbs';
import Loader from '../../../common/loader';
import { formateDate, showStatus } from '../../../../../utils/commonfunction';
import { useTranslation } from 'react-i18next';
import { breakWord } from '../../../../../utils/commonfunction';


const BannerView = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const params = useParams()
  const [showdefault, setShowDefault] = useState({})
  const breadcrumbs = [{ title: t('sidebar_link_dashboard'), url: "/admin/dashboard" }, { title: t("sidebar_link_banners"), url: "/admin/cms/banner/list/1" }, { title: t("btn_view"), url: "" }];


  useEffect(() => {
    bannerService.Details(params.id).then(response => {
      setShowDefault(response && response.data ? response.data : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">{t("btn_view")+" "+t("sidebar_link_banners")}</h6>
              </div>
              {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                <div className="col-lg-12 form-group">
                  <table id="simple-table" className="table  table-bordered table-hover">
                    <tbody>
                      <tr>
                        <th>{t("label_Banner_title")}</th>
                        <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>{t("label_Banner_title_ja")}</th>
                        <td>{showdefault && showdefault.title_ja ? showdefault.title_ja : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>{t("label_link")}</th>
                        <td>{showdefault && showdefault.link ? showdefault.link : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>{t("label_image")}</th>
                        <td><img src={showdefault && showdefault.image ? showdefault.image : ""} alt={showdefault?.title} style={{ height: "100px" }} /></td>
                      </tr>
                      <tr>
                        <th>{t("list_heading_status")}</th>
                        <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                      </tr>
                      <tr>
                        <th>{t("list_heading_created_date")}</th>
                        <td>{showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>{t("label_modified_date")}</th>
                        <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
                      </tr>
                      {/* <tr>
                        <td colSpan={2}>
                          <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description ? breakWord(showdefault.description): "N/A" }}></div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description_ja ? breakWord(showdefault.description_ja) : "N/A" }}></div>
                        </td>
                      </tr> */}
                    </tbody>
                    </table>
                  <div className="mt-5">
                    <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                      <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                      {t("btn_back")}
                    </button>
                  </div>
                </div>
              </div> : <Loader />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BannerView