import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink} from 'react-router-dom'


export default function Sidebar() {

  const {t} = useTranslation();

  return (
    <div className='sidebar'>
      <div className='sidebar-menu'>
        <ul>
          <li >
            <NavLink end to={'/restaurant/menu'}>
              <i className='ti ti-category'></i>
              <span>{t("label_categories")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={'/restaurant/menu/menu-item/list'}>
              <i className='ti ti-template'></i>
              <span>{t("label_menu_item")}</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={'/restaurant/menu/options/list'}>
              <i className='ti ti-settings-2'></i>
              <span>{t("label_options")}</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}
