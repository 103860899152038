import React from "react";
import { Outlet } from "react-router-dom";
// import GlobalLoader from "../../../shared/globalLoader";
import Sidebar from "./sidebar";

const restaurantTableManagementLayout = () => {
  return (
    <>
    <div>
        <Sidebar />
        <Outlet />
    </div>
    </>
  );
};
export default restaurantTableManagementLayout;
