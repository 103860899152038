import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../common/loader";
import { formateDate } from "../../../../utils/commonfunction";
import { useTranslation } from "react-i18next";
// import $ from "jquery";
import { Details } from "../../services/usermgmt.services";

const UsersView = (props) => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const params = useParams();
  // const [query] = useSearchParams();
  const [showdefault, setShowDefault] = useState({});
  

  useEffect(() => {
    Details(params.id)
      .then((response) => {
        // console.log("View response-", response);
        setShowDefault(response && response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  return (
    <>
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">

            {/* <div className="m-4">
              <h6 className="main-content-label mb-3">
                {t("label_restaurants_name")}
              </h6>
            </div> */}

            <div className="">

              {showdefault && Object.keys(showdefault).length > 0 ? (
                <div className="row">
                  <div className="col-lg-12 form-group">
                    <table
                      id="simple-table"
                      className="table  table-bordered table-hover"
                    >
                      <tbody>
                        <tr>
                          <th>{t("label_logo")}</th>
                          <td>
                            <img
                              src={
                                showdefault && showdefault.restaurant_logo
                                  ? showdefault.restaurant_logo
                                  : "N/A"
                              }
                              alt="profile"
                              style={{ height: "100px" }}
                            />
                          </td>
                        </tr>
                        {/* <tr>
                          <th>{t("label_name")}</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault.name
                              ? showdefault.name
                              : "N/A"}
                          </td>
                        </tr> */}
                        <tr>
                          <th>{t("label_restaurant_name")}</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault.restaurant_name
                              ? showdefault.restaurant_name
                              : "N/A"}
                          </td>
                        </tr>

                        <tr>
                          <th>{t("label_restaurant_id")}</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault.id ? showdefault.id : "N/A"}
                          </td>
                        </tr>
                       
                        <tr>
                          <th>{t("label_email")}</th>
                          <td>
                            {showdefault && showdefault.email
                              ? showdefault.email
                              : "N/A"}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>Role</th>
                          <td>{decideRole(showdefault.role)}</td>
                        </tr> */}
                        <tr>
                          <th>{t("label_cuisine_type")}</th>
                          <td>
                            {showdefault && showdefault.cuisine_type
                              ? showdefault.cuisine_type.map((item)=>{return <span className="badge badge-pill badge-primary-light text-capitalize mx-1">{item}</span>})
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("label_address")}</th>
                          <td>
                            {showdefault && showdefault.address
                              ? showdefault.address
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>{t("label_phone_number")}</th>
                          <td>
                            {showdefault && showdefault?.phone_number
                              ? showdefault.phone_number
                              : "N/A"}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>Branch Details</th>
                          <td>
                            {showdefault && showdefault.branch_details
                              ? showdefault.branch_details
                              : "N/A"}
                          </td>
                        </tr> */}
                        <tr>
                          <th>{t("list_heading_created_date")}</th>
                          <td>
                            {showdefault && showdefault.createdAt
                              ? formateDate(showdefault.createdAt)
                              : "N/A"}
                          </td>
                        </tr>
                        {/* <tr>
                          <th>{t("label_modified_date")}</th>
                          <td>
                            {showdefault && showdefault.updatedAt
                              ? formateDate(showdefault.updatedAt)
                              : "N/A"}
                          </td>
                        </tr> */}
                        {/* <tr>
                          <th>Restaurant Images</th>
                          <td>
                            {showdefault && showdefault.images ? (
                              showdefault.images.map((img, i) => (
                                <img
                                  key={i}
                                  src={img}
                                  alt="Img"
                                  style={{
                                    height: "100px",
                                    marginLeft: ".5rem",
                                  }}
                                />
                              ))
                            ) : (
                              <div>N/A</div>
                            )}
                          </td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>

            <div className="mt-2 back-button mx-0">
              <button
                className="btn ripple btn-dark"
                type="button"
                onClick={() =>
                  navigate("/admin/restaurant-management/restaurant/list/1")
                }
              >
                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                {t("btn_back")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersView;
