import React, { useEffect, useState } from "react";
import banner from "../../../assets/website/images/main-banner.png";
import about from "../../../assets/website/images/about.png";
import successOverview from "../../../assets/website/images/successOverview.jpeg";
import WebsiteBanners from "../common/banner";
import WebsiteFeaturedRestaurant from "../common/featuredrestaurants";
import WebsiteTestimonials from "../common/websitetestimonials";
import { useTranslation } from "react-i18next";
import Inquiry from "../common/inquiry";
import { useSelector } from "react-redux";
import { setTitle } from "../../../utils/commonfunction";

function WebsiteHomePage() {
  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData?.data);


  useEffect(() => {
    if (myGlobalData) {
      const settingsData = JSON.parse(myGlobalData);
      setGlobalData(settingsData);
      setTitle(settingsData.title);
    }
   
  }, [myGlobalData]);
  const { t } = useTranslation();
  return (
    <div className="home-page-section">
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="banner-text">
                <h4>{t("web_home_sub_heading")}</h4>
                <h1>{t("web_home_heading")}</h1>
                <p>{t("web_home_sub_content")}</p>
                <a href="#inquiry" className="btn btn-white">
                  {t("web_nav_link_inquire_now")}
                </a>
              </div>
            </div>
            <div className="col-md-6 ms-auto">
              <figure className="m-0">
                <img src={banner} alt=""/>
              </figure>
            </div>
          </div>
        </div>
      </section>
      <WebsiteBanners id="banner1" />
      <WebsiteFeaturedRestaurant id="featured-1" />
      <section id="about_us" className="about-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="about-sec">
                <div className="headings">
                  <h4>{t("web_nav_link_about_us")}</h4>
                  <h2>{t("web_about_us_heading")}</h2>
                  <p>{t("web_about_us_content1")}</p>
                  <p>{t("web_about_us_content2")}</p>
                  <a href="#" className="btn btn-primary">
                    {t("web_about_us_link_read_more")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 ms-auto">
              <figure className="m-0">
                <img src={about} alt=""/>
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section id="service_overview" className="service-section section-padding">
        <div className="container">
          <div className="headings mb-5 text-center">
            <h4>{t("web_nav_link_service_overview")}</h4>
            <h2>{t("web_service_overview_section_heading")}</h2>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="service-sec-box">
                <div className="headings">
                  <h2>{t("web_service_overview_heading")}</h2>
                  <p>{t("web_service_overview_content")}</p>
                  <ul className="list-ui">
                    <li>{t("web_service_overview_list1")}</li>
                    <li>{t("web_service_overview_list2")}</li>
                    <li>{t("web_service_overview_list3")}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-7 ms-auto">
              <figure className="m-0">
                <img src={successOverview} alt=""/>
              </figure>
            </div>
            <div className="text-center mt-5 d-block">
              <a href="#" className="btn btn-primary">
                {t("web_about_us_link_read_more")}
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="how_it_works" className="how-work-sec section-padding">
        <div className="container">
          <div className="headings  mb-5 text-center">
            <h4>{t("web_how_does_it_work_heading")}</h4>
            <h2>{t("web_nav_link_how_does_it_operate")}</h2>
          </div>
          <div className="row how-box">
            <div className="col-md-3">
              <article>
                <figure>
                  <span>1</span>
                </figure>
                <figcaption>
                  <h4>{t("web_how_does_it_work_card1_heading")}</h4>
                  <p>{t("web_how_does_it_work_card1_content")} </p>
                </figcaption>
              </article>
            </div>
            <div className="col-md-3">
              <article>
                <figure>
                  <span>2</span>
                </figure>
                <figcaption>
                  <h4>{t("web_how_does_it_work_card2_heading")}</h4>
                  <p>{t("web_how_does_it_work_card2_content")} </p>
                </figcaption>
              </article>
            </div>
            <div className="col-md-3">
              <article>
                <figure>
                  <span>3</span>
                </figure>
                <figcaption>
                  <h4>{t("web_how_does_it_work_card3_heading")}</h4>
                  <p>{t("web_how_does_it_work_card3_content")} </p>
                </figcaption>
              </article>
            </div>
            <div className="col-md-3">
              <article>
                <figure>
                  <span>4</span>
                </figure>
                <figcaption>
                  <h4>{t("web_how_does_it_work_card4_heading")}</h4>
                  <p>{t("web_how_does_it_work_card4_content")} </p>
                </figcaption>
              </article>
            </div>
          </div>
        </div>
      </section>
      <WebsiteTestimonials id="testimonial-1" />
      <Inquiry id="inquiry" />      
    </div>
  );
}

export default WebsiteHomePage;