import { useLocation } from "react-router-dom";
import $ from "jquery";
import { Link } from "react-router-dom";
// import { hasPermission } from "../../../utils/commonfunction";
import logo from "../../../../src/assets/admin/img/logos/WiL_POS_logo.png";
import { useTranslation } from "react-i18next";

const SideBar = () => {
  const loc = useLocation();
  const { t } = useTranslation();

  const toggleDropdown = (e) => {
    e.preventDefault();
    const dropDown = e.target.parentElement;
    if (dropDown.classList.contains("show")) {
      dropDown.classList.remove("show");
    } else {
      dropDown.classList.add("show");
    }
  };

  const hoverSidebar = (e) => {
    if ($("body").hasClass("main-sidebar-hide")) {
      $("body").addClass("main-sidebar-open");
    } else {
      $("body").removeClass("main-sidebar-open");
    }
  };

  const removeHoverSidebar = (e) => {
    $("body").removeClass("main-sidebar-open");
  };

  return (
    <div
      className="main-sidebar main-sidebar-sticky side-menu ps ps--active-y"
      id="app_sidebar"
      onMouseEnter={hoverSidebar}
      onMouseLeave={removeHoverSidebar}
    >
      <div className="sidemenu-logo">
        <a className="main-logo" href="/admin/dashboard">
          <img
            src={logo}
            className="header-brand-img icon-logo admin-logo"
            alt="logo"
            style={{ height: "1.6rem", width: "4rem" }}
            id="logo_short"
          />
          <img
            src={logo}
            className="header-brand-img desktop-logo admin-logo rounded"
            style={{ height: "2.3rem", width: "6rem" }}
            alt="logo"
            id="logo_full"
          />
        </a>
      </div>
      {/* Dashboard section start */}
      <div className="main-sidebar-body">
        <ul className="nav">
          <li
            className={
              "nav-item" +
              (loc.pathname === "/admin/dashboard" ? " active" : "")
            }
          >
            <Link className="nav-link" to="/admin/dashboard">
              <span className="shape1"></span>
              <span className="shape2"></span>
              <i className="fa fa-home sidemenu-icon"></i>
              <span className="sidemenu-label">
                {t("sidebar_link_dashboard")}
              </span>
            </Link>
          </li>
          {/* Dashboard section end */}

          {/* restaurant management section end */}

          {/* {hasPermission("/admin/user-management") ? ( */}
            <li
              className={
                `nav-item ` +
                (loc.pathname.includes("/restaurant-management")
                  ? "show active"
                  : "")
              }
            >
              <Link
                className="nav-link"
                to={`/admin/restaurant-management/restaurant/list/${1}`}
              >
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fa fa-utensils sidemenu-icon"></i>
                <span className="sidemenu-label">
                  {t("sidebar_link_restaurants")}
                </span>
              </Link>
            </li>
          {/* ) : null} */}
          {/* restaurant management section end */}

          {/* Cms section start */}
          {/* {hasPermission("/admin/cms") ? ( */}
            <li
              className={
                `nav-item ` +
                (loc.pathname.includes("/cms") ? "show active" : "")
              }
            >
              <a
                className="nav-link with-sub submenu"
                onClick={toggleDropdown}
                href={"/"}
              >
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fa fa-edit sidemenu-icon"></i>
                <span className="sidemenu-label">{t("sidebar_link_cms")}</span>
                <i className="angle fe fe-chevron-right"></i>
              </a>
              <ul className="nav-sub">
                <li
                  className={`nav-sub-item ${loc.pathname.includes("/cms/pages") ? " active" : ""
                    }`}
                >
                  <Link
                    className="nav-sub-link"
                    to={`/admin/cms/pages/list/${1}`}
                  >
                    {t("sidebar_link_pages")}
                  </Link>
                </li>
                <li
                  className={`nav-sub-item ${loc.pathname.includes("/cms/banner") ? " active" : ""
                    }`}
                >
                  <Link
                    className="nav-sub-link"
                    to={`/admin/cms/banner/list/${1}`}
                  >
                    {t("sidebar_link_banners")}
                  </Link>
                </li>
                <li
                  className={
                    "nav-sub-item" +
                    (loc.pathname.includes("/cms/default-email-template")
                      ? " active"
                      : "")
                  }
                >
                  <Link
                    className="nav-sub-link"
                    to={`/admin/cms/default-email-template/list/${1}`}
                  >
                    {t("sidebar_link_email_template")}
                  </Link>
                </li>

                <li
                  className={
                    "nav-sub-item" +
                    (loc.pathname.includes("/cms/settings") ? " active" : "")
                  }
                >
                  <Link className="nav-sub-link" to={`/admin/cms/settings`}>
                    {t("sidebar_link_global_settings")}
                  </Link>
                </li>
              </ul>
            </li>
          {/* // ) : null} */}
          {/* Cms section end */}

          {/* testimonial management section end */}
           <li
              className={
                `nav-item ` +
                (loc.pathname.includes("/testimonial-management")
                  ? "show active"
                  : "")
              }
            >
              <Link
                className="nav-link"
                to={`/admin/testimonial-management/testimonial/list/${1}`}
              >
                <span className="shape1"></span>
                <span className="shape2"></span>
                <i className="fa fa-comment-dots sidemenu-icon"></i>
                <span className="sidemenu-label">
                {t("sidebar_link_testimonials")}
                </span>
              </Link>
            </li>
          {/* testimonial management section end */}


          {/*  Inquiries section end */}

          <li
            className={
              `nav-item ` +
              (loc.pathname.includes("/inquiries")
                ? "show active"
                : "")
            }
          > 
            <Link
              className="nav-link"
              to={`/admin/inquiries/list/${1}`}
            >
              <span className="shape1"></span>
              <span className="shape2"></span>
              {/* <i className="fa fa-comment-dots mr-2 ml-2 mb-0 h5"></i> */}
              <i className="fas fa-question-circle sidemenu-icon"></i>
              <span className="sidemenu-label">
              {t("sidebar_link_inquiries")}
              </span>
            </Link>
          </li>
          {/* testimonial management section end */}


        </ul>
      </div>
    </div>
  );
};

export default SideBar;
